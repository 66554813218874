import Model from "./Model";
import ModbusRTUClient from "./rtu/ModbusRTUClient";
import Packet from "./Packet";
import ReadHoldingRegistersResponseBody from "./rtu/response/ReadHoldingRegistersResponseBody";
import MemoryMapper from "./registers/MemoryMapper";
import ReadInputRegistersResponseBody from "./rtu/response/ReadInputRegistersResponseBody";

export type Register = {
    address: number,
    model:Model,
    fn: {
        read: (value:Register) => void,
        write: (value:Register, state:any) => void,
    },
};

export default class Registers {

    private rtu:ModbusRTUClient;
    protected mapper:MemoryMapper;
    // private registers:Register[] = [];


    constructor(rtu: ModbusRTUClient, mapper:MemoryMapper) {
        this.rtu = rtu;
        this.mapper = mapper;
    }

    clear() {};

    customFunction(cmd:number, addr:number, len:number) {
        var buffer = Buffer.alloc(8);
        buffer.writeUInt16BE(cmd, 0);
        buffer.writeUInt32BE(addr, 2);
        buffer.writeUInt16BE(len, 6);
        return buffer;
    };

    readHoldingRegistersBuffer(addr:number, size: number) : Promise<Buffer> {
        return new Promise((resolve, reject) => {

            this.rtu.readHoldingRegisters(addr, size).then(packet => {
                if(packet) {
                    resolve((packet.body as ReadHoldingRegistersResponseBody).valuesAsBuffer as Buffer);
                } else {
                    reject("Invalid Response");
                }
            }).catch(reject);
        });
    }
    readHoldingRegistersArray(addr:number, size: number) : Promise<number[]> {
        return new Promise((resolve, reject) => {

            this.rtu.readHoldingRegisters(addr, size).then(packet => {
                if(packet) {
                    resolve((packet.body as ReadHoldingRegistersResponseBody).values);
                } else {
                    reject("Invalid Response");
                }
            }).catch(reject);
        });
    }

    readInputRegisters(addr:number, size: number) : Promise<ReadInputRegistersResponseBody> {
        return new Promise((resolve, reject) => {

            this.rtu.readInputRegisters(addr, size).then(packet => {
                if(packet) {
                    resolve((packet.body as ReadInputRegistersResponseBody));
                } else {
                    reject("Invalid Response");
                }
            }).catch(reject);
        });
    }
    writeMultipleRegisters(addr:number, values: number[] | Buffer) {
        return this.rtu.writeMultipleRegisters(addr, values);
    }
    readCustomRegisters(addr: number, size: number) : Promise<Buffer> {
        return new Promise((resolve, reject) => {
            this.rtu.writeMultipleRegisters(4608, this.customFunction(33536, addr, size))!.then(value1 => {
                this.rtu.readHoldingRegisters(4612, size / 2)!.then(packet => {
                    resolve((packet.body as ReadHoldingRegistersResponseBody).valuesAsBuffer as Buffer);
                }).catch(reject)
            }).catch(reject);
        });
    }
    async writeCustomRegisters(addr:number, data:Buffer) {
        let buff = Buffer.concat([this.customFunction(33280, addr, data.length), data]);
        if(buff.length > 250) {
            let length2 = buff.length / 2;
            let buffer1 = Buffer.alloc(length2);
            let buffer2 = Buffer.alloc(length2);

            buff.copy(buffer1, 0, 0, length2);
            buff.copy(buffer2, 0, length2, length2 + length2);

            await this.rtu.writeMultipleRegisters(4608 + length2 / 2, buffer2);
            await this.rtu.writeMultipleRegisters(4608, buffer1);
            return true;
        }
        return await this.rtu.writeMultipleRegisters(4608, buff);
    }
    // defineAddress(addr:number, def:Definition) {
    //     this.registers.push({
    //         address: addr,
    //         model: new Model(def),
    //         fn: this.readHoldingRegisters(),
    //     })
    // }
    //
    // defineCustomAddress(addr:number, def:Definition, size?:number) {
    //
    //     this.registers.push({
    //         address: addr,
    //         model: new Model(def, size),
    //         fn: this.customRegisters(),
    //     })
    // }
    //
    // readHoldingRegisters() {
    //     return {
    //         read: (value:Register) => {
    //             return new Promise((resolve, reject) => {
    //                 let model = value.model;
    //                 this.rtu.readHoldingRegisters(value.address, model.size)!.then(packet => {
    //                     resolve(model.parse(this.createPacketFromList((packet.body as ReadHoldingRegistersResponseBody).values, true)));
    //                 }).catch(reject)
    //             });
    //         },
    //         write: (value:Register, state:any) => {
    //             let model = value.model;
    //             let data = model.build(state);
    //             return this.rtu.writeMultipleRegisters(value.address, data.getBuffer());
    //         }
    //     }
    // }
    //
    // customRegisters() {
    //     return {
    //         read: (value:Register) => {
    //             return new Promise((resolve, reject) => {
    //                 let model = value.model;
    //                 this.rtu.writeMultipleRegisters(4608, this.customFunction(33536, value.address, model.size * 2))!.then(value1 => {
    //                     this.rtu.readHoldingRegisters(4612, model.size)!.then(packet => {
    //                         resolve(model.parse(this.createPacketFromList((packet.body as ReadHoldingRegistersResponseBody).values, true)));
    //                     }).catch(reject)
    //                 }).catch(reject);
    //             });
    //         },
    //         write: (value:Register, state:any) => {
    //             let model = value.model;
    //             let data = model.build(state);
    //             var buff = Buffer.concat([this.customFunction(33280, value.address, model.size * 2), data.getBuffer()]);
    //             return this.rtu.writeMultipleRegisters(4608, buff);
    //         }
    //     };
    // }
    //
    // async Read() {
    //     try {
    //         let result:any[] = [];
    //         for (let i = 0; i < this.registers.length; i++) {
    //             let res = await this.registers[i].fn.read(this.registers[i]);
    //             result.push(res);
    //         }
    //         return result;
    //     } catch (e) {
    //         throw e;
    //     }
    // };
    // async Write(state:any) {
    //
    //     if(!state) {
    //         state = this;
    //     }
    //     for (let i = 0; i < this.registers.length; i++) {
    //         if(state[i]) {
    //             await this.registers[i].fn.write(this.registers[i], state[i]);
    //         }
    //     }
    //     return state;
    // }

    public async Read() : Promise<any> {
        return [];
    }

    public async Write() {

    }

    private createPacketFromList(list:any[], bigendian:boolean = false) {
        var packet = new Packet();
        for (let i = 0; i < list.length; i++) {
            packet.write(list[i], bigendian);
        }
        return packet;
    };

    static short = Model.value;
    static int32 = Model.values(2);
    static toString8Array(array:number[]) {
        let str = "";
        for (let i = 0; i < array.length; i++) {
            let a1 = (array[i] >> 8) & 0xFF;
            let a2 = (array[i]) & 0xFF;

            if(a1 !== 0) {
                str += String.fromCharCode(a1);
            }
            if(a2 !== 0) {
                str += String.fromCharCode(a2);
            }
        }
        return str;
    }

    static toString16Array(array:number[]) {
        let res = "";
        for (let i = 0; i < array.length; i++) {
            if(array[i] !== 0) {
                res += String.fromCharCode(array[i]);
            }
        }
        return res;
    }

    static toArray16String(string: string, size: number, buffer16: number[]) {

        for (let i = 0; i < string.length; i++) {
            buffer16.push(string.charCodeAt(i));
        }
        for (let i = string.length; i < size; i++) {
            buffer16.push(0);
        }
    }

    static byte16ArrayToByte8Array(data:number[]) {
        let buffer = Buffer.alloc(data.length * 2)
        for (let i = 0; i < data.length; i++) {
            buffer.writeUInt8((data[i] >> 8) & 0xFF, i * 2);
            buffer.writeUInt8((data[i] & 0xFF), i * 2 + 1);
        }
        return buffer;

    }

    static IPStringToArray(ip: string, buffer: number[]) {
        let items = ip.split(".");
        if(items.length === 4) {
            for (let i = 0; i < items.length; i++) {
                buffer.push(parseInt(items[i]));
            }
        } else {
            for (let i = 0; i < 4; i++) {
                buffer.push(0);
            }
        }


    }
}
