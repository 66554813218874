
export default class LoginService {

    static newInstance(persist:boolean = false) {
        if(localStorage.getItem("token") || persist) {
            return new LoginService(localStorage);
        } else {
            return new LoginService(sessionStorage);
        }
    }

    private storage:Storage;

    constructor(storage: Storage) {
        this.storage = storage;
    }

    isLogin() {
        return this.storage.getItem("token") !== null;
    }

    login(token: string) {
        this.storage.setItem("isLogin", "true");
        this.storage.setItem("token", token);
        this.storage.setItem("remember", String(true));
    }

    logout() {
        this.storage.removeItem("isLogin");
        this.storage.removeItem("token");
        this.storage.removeItem("remember");
    }

    token() : string {
        return String(this.storage.getItem("token"));
    }

    clear() {
        this.storage.clear();
    }
}
