import {
    IonBadge,
    IonButton,
    IonButtons,
    IonCheckbox,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonModal,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToggle,
    IonToolbar
} from "@ionic/react";
import {
    arrowBack,
    reload
} from "ionicons/icons";
import { inject, observer } from "mobx-react";
import { Store } from "../../service/Store";
import { APIBase, Device, DeviceOutput } from "../../service/API";
import EventBox from "../../service/EventBox";
import { RouteComponentProps } from "react-router";
import Lang from "../../service/Lang";
import React from "react";
import moment from "moment-timezone";

export type LoraStation = {

    relaysState: number,
    digitalInputs: number,
    batteryLevel: number,
    analogInput1: number,
    analogInput2: number,
    pulseCounter1: number,
    pulsePartial1: number,
    pulseCounter2: number,
    pulsePartial2: number,
    temperature: number,
    unixTime: number,
};


export type LoraTimersBank = {
    enable: boolean,
    time: number,
    min: number,
    hour: number,
    week: number[],

    operation: number
    action: number
    output1: number
    output2: number
    output3: number
    output4: number
    output5: number
    output6: number
}

export interface DeviceOutputServiceProps extends RouteComponentProps<{ id: string, output: string }> {
    store?: Store;
    lang?: Lang;
    routerOutlet: HTMLIonRouterOutletElement,
}
export interface DeviceOutputServiceState {
    device?: Device,
    output?: DeviceOutput,
    loading?: boolean,
    error?: string,
    stationData?: any,
    outputs: number,
    bank?: LoraTimersBank[],
    bankNumber?: number,
    editTimer?: LoraTimersBank,
    meta ? : {
        rssi: number,
        snr : number,
        channel_index : number,
        channel_rssi : number,
        gateway_ids: {
            eui: string,
            gateway_id: string,
        }

    }
}

@inject("store", "lang")
@observer
export default class DeviceOutputService extends React.Component<DeviceOutputServiceProps, DeviceOutputServiceState> {
    // slider: RefObject<any>;
    events?: EventBox;
    state: DeviceOutputServiceState = {
        outputs: 0,
    };

    constructor(props: any, context: any) {
        super(props, context);
        this.state = { loading: false, outputs: 0 };
    }

    componentDidMount(): void {
        let id = parseInt(this.props.match.params.id);
        this.loadDevice(id);
    }

    componentDidUpdate(prevProps: Readonly<DeviceOutputServiceProps>, prevState: Readonly<DeviceOutputServiceState>, snapshot?: any): void {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.loadDevice(parseInt(this.props.match.params.id));
        }

    }

    componentWillUnmount(): void {
        this.unloadDevice();
    }

    unloadDevice() {
        let store: Store = this.props.store!;
        store.unsubscribeDevice();
        this.events?.off();
    }
    loadDevice(id: number) {
        this.unloadDevice();
        this.setState({ loading: true });
        this.updateDevice(id, 'outputModbus');
        this.events = new EventBox(this.props.store?.events!);
        this.events.on('online', (id: number) => {
            if (this.state.device && this.state.device?.id === id) {
                this.setState({ device: { ...this.state.device, online: true } });
            }
        })
        this.events.on('offline', (id: number) => {
            if (this.state.device && this.state.device?.id === id) {
                this.setState({ device: { ...this.state.device, online: false } });
            }
        })
        this.events.on('update', () => {
            this.updateDevice(id, 'outputModbus');
        })
        this.events.on('reconnect', () => {
            this.updateDevice(id, 'outputModbus');
        })

        let store: Store = this.props.store!;
        store.subscribeToDevice(id);
    }

    updateDevice(id: number, section: string) {

        this.props.store!.api.deviceGet(id, section).then((value: APIBase<Device>) => {
            if (value.success) {
                let output_id = parseInt(this.props.match.params.output);
                let output = value.data.outputs.find((a) => a.id === output_id);
                this.setState({ device: value.data, output: output });
                if (output) {
                    if (output.outputService) {
                        this.updateCommand();
                    } else {
                        this.setState({ loading: false, error: 'No se ha configurado el servicio' });
                    }
                } else {
                    this.setState({ loading: false, error: 'Inposible obtener la salida' });
                }
            } else {
                this.setState({ loading: false, error: 'Inposible obtener la información' });
            }
        });
    }

    updateCommand() {
        this.commandDevice(0x40).then(data => {
            this.setState({ loading: false });
            this.setState({ meta: data.data.meta });
            if (data.success) {
                let stationData = this.parseStationData(data.data.data);
                this.setState({ stationData, outputs: stationData.relaysState });
            }
        })
    }

    parseStationData(array: number[]): LoraStation {

        let data = Buffer.from(array);

        return {
            relaysState: data.readUint8(0),
            digitalInputs: data.readUint16LE(1),
            batteryLevel: data.readUint16LE(3),
            analogInput1: data.readUint16LE(5),
            analogInput2: data.readUint16LE(7),
            pulseCounter1: data.readUint32LE(9),
            pulsePartial1: data.readUint32LE(13),
            pulseCounter2: data.readUint32LE(17),
            pulsePartial2: data.readUint32LE(21),
            temperature: data.length > 25 ? data.readUint32LE(25) / 100 : 0,
            unixTime: data.length > 29 ? data.readUint32LE(29) : 0,
        }
    }

    commandDevice(command: number, data: number[] = []) {
        let deviceId = parseInt(this.props.match.params.id);
        let outputId = parseInt(this.props.match.params.output);
        return this.props.store!.api.deviceServiceOutputRequest(deviceId, outputId, command, data);
    }

    handleReload = (ev: any) => {
        let id = parseInt(this.props.match.params.id);
        this.loadDevice(id);
    }

    handleSetOutputs = (ev: any) => {

        this.setState({ loading: true });

        let op = (0b11 << 14) + this.state.outputs;
        this.commandDevice(0x80, [op & 0xFF, (op >> 8) & 0xFF]).then(() => {
            this.setState({ loading: false });
        });
    }

    handleReset = (control: number) => (ev: any) => {

        this.setState({ loading: true });

        this.commandDevice(0x81, [control]).then(() => {
            this.setState({ loading: false });
        });
    }

    voltage(n: number, r1: number, r2: number) {
        // 3.3 v => 4096
        return (((n / 4095) * 2.4) * (r1 + r2)) / r2;
    }

    handleChangeOutput = (n: number) => (ev: any) => {
        let state = (ev.detail.checked);
        console.log(n, state);
        let outputs = this.state.outputs;
        if (state) {
            outputs = outputs | ((1 << n));
        } else {
            outputs = outputs & ~((1 << n));

        }
        this.setState({ outputs: outputs })
        // this.state.loraStation!.readOutputs! = outputs;
        // this.setState({loading: true});

        // let modbus1 = this.state.output!.modbusWrite!;
        // let modbus2 = {...modbus1};
        // modbus2.functionCode = 5;
        // modbus2.address = n;
        // modbus2.value = (state ? 1 : 0);
        // modbus2.dataFormat = Format.UINT16;
        // this.props.store?.api.deviceModbusQuery(this.state.device?.id!, modbus2 ).then(res => {
        //     this.setState({loading: false});
        // })
    }
    
    handleLoadBank = (bank: number) => (ev:any) => {
        this.setState({ loading: true });
        
        this.commandDevice(0x42, [bank, 10]).then(data => {
            this.setState({ loading: false });
            if (data.success) {
                let items = this.handleModbusTimerRegisters(data.data.data);
                this.setState({loading: false, bank: items, bankNumber: bank});
                console.log(data);
            }
        })

    }
    
    /*
    timer.week = protocol.readUint8();
    timer.dayTime = protocol.readUint16();
    timer.op = protocol.readUint16();
    */
    handleModbusTimerRegisters(data:number[]) : LoraTimersBank[] {
        let result : LoraTimersBank[] = [];
        // let bankId = data[0];
        let count = data[1];
        for (let index = 0; index < count; index++) {
            let i = (index * 5) + 2;
            let week = data[i + 0];
            let dayTime = data[i + 1] + (data[i + 2] << 8 );
            let operation = data[i + 3] + (data[i + 4] << 8 );
            let weeks = [];
            for (let index = 1; index < 8; index++) {
                if((week & (1 << (index % 7))) > 0) {
                    weeks.push((index % 7));
                }    
            }
            let enable = ((week >> 7) & 0b1) === 0b1;

            result.push({
                enable: enable,
                hour: ((dayTime) & 0b11111),
                min: ((dayTime >> 5) & 0b111111),
                week: (weeks),
                time: dayTime,
                operation,
                action: (operation >> 14) & 0b11,
                output1: (operation & 0b1),
                output2: ((operation >> 1) & 0b1),
                output3: ((operation >> 2) & 0b1),
                output4: ((operation >> 3) & 0b1),
                output5: ((operation >> 4) & 0b1),
                output6: ((operation >> 5) & 0b1),
            })
            
        }
        return result;
    }
    
    /*
    timer.week = protocol.readUint8();
    timer.dayTime = protocol.readUint16();
    timer.op = protocol.readUint16();

                hour: ((dayTime) & 0b11111),
                min: ((dayTime >> 5) & 0b111111),
    */
    buildModbusTimerRegister(timer: LoraTimersBank[]) {
        let result : number[] = [];
        result.push(this.state.bankNumber!);
        result.push(timer.length);
        for (let index = 0; index < timer.length; index++) {
            const element = timer[index];
            let week = element.week.map((v) => (1 << v)).reduce((a, b) => a + b, 0);
            week += (element.enable ? (1 << 7) : 0);
            let time = (element.hour) + (element.min << 5);
            let operation = (element.output1) + (element.output2 << 1) + (element.output3 << 2) + (element.output4 << 3) + (element.output5 << 4) + (element.output6 << 5);
            operation += (element.action << 14);
            result.push(week);
            result.push((time) & 0xFF);
            result.push((time >> 8) & 0xFF);
            result.push((operation) & 0xFF);
            result.push((operation >> 8) & 0xFF);
        }
        return result;
    }

    handleEditTimer = (timer: LoraTimersBank) => (ev:any) => {
        this.setState({editTimer: timer});
    }


    handleTimerChange = (name: keyof LoraTimersBank) => (ev: any) => {
        let item = (this.state.editTimer) as any;
        if(ev.detail.checked !== undefined) {

            item[name] = (ev.detail.checked ? 1 : 0);
        } else {
            
            item[name] = parseInt(ev.detail.value);
        }
    }
    handleTimerChangeArray = (name: keyof LoraTimersBank) => (ev: any) => {
        let item = (this.state.editTimer) as any;
        item[name] = ev.detail.value;
    }
    handleTimerChangeString = (name: keyof LoraTimersBank) => (ev: any) => {
        let item = (this.state.editTimer) as any;
        item[name] = ev.detail.value;
    }

    handleSaveTimer = (ev:any) => {
        if(!this.state.bank) {
            return;
        }
        this.setState({ loading: true });
        let data = this.buildModbusTimerRegister(this.state.bank!);
        
        this.commandDevice(0x43, data).then(data => {
            this.setState({ loading: false });
            if (data.success && data.data && data.data.data[0] === 1) {
                alert("Se han programado los eventos correctamente.");
            } else {
                alert("No se ha podido establecer el programador debido a un error.");

            }
        })

    }

    handleClearTimer = (ev:any) => {
        if(this.state.bank) {
            this.state.bank.forEach(v => {
                v.operation = 0;
                v.time = 0;
                v.action = 0;
                v.hour = 0;
                v.min = 0;
                v.enable = false;
                v.week = [];
            });

            this.setState({})
        }
    }

    getWeekName(week: number[]) {
        return week.map(a => this.getWeekNameItem(a)).join(", ");
    }
    getWeekNameItem(week: number) {
        if(week === 0) {
            return "D";
        }
        if(week === 1) {
            return "L";
        }
        if(week === 2) {
            return "M";
        }
        if(week === 3) {
            return "X";
        }
        if(week === 4) {
            return "J";
        }
        if(week === 5) {
            return "V";
        }
        if(week === 6) {
            return "S";
        }
    }
    getActionName(n:LoraTimersBank) {
        // if(n.action === 0) {
        //     return "(Desactivado)";
        // }
        if(!n.enable) {
            return "(Desactivado)";
        }
        if(n.action === 1) {
            return "Apertura - " + (this.getOutputNames(n).filter(a => a.value).map(a => a.name).join(", "));
        }
        if(n.action === 2) {
            return "Cierre - " + (this.getOutputNames(n).filter(a => a.value).map(a => a.name).join(", "));
        }
        if(n.action === 3) {
            return "Establecer - " + (this.getOutputNames(n).map(a => (a.name + "=" + (a.value ? "ON": "OFF"))).join(", "));
        }
    }

    getOutputNames(n:any) {
        let indexs = ["output1", "output2", "output3", "output4", "output5", "output6"];
        return indexs.map((v, k) => {return {value: n[v], name: "S" + (k + 1)}});
    }

    handleSetTime = (ev:any) => {
        
        this.setState({ loading: true });
        let time = moment().unix() + (moment().utcOffset() * 60);
        let array = [];
        array.push(time & 0xFF);
        array.push((time >> 8) & 0xFF);
        array.push((time >> 16) & 0xFF);
        array.push((time >> 24) & 0xFF);
        this.commandDevice(0x41, array).then(data => {
            this.setState({ loading: false });
            if (data.success) {
                this.handleReload(ev);
            }
        })
    }

    renderTimerRegisters() {
        if(!this.state.bank) {
            return;
        }
        return <>
            <IonItem>
                Programador {this.state.bankNumber! + 1}
            </IonItem>
            {this.state.bank.map((v, k) => {
                return <IonItem button key={k} detail onClick={this.handleEditTimer(v)}>
                    <IonLabel>{this.getActionName(v)} </IonLabel>
                    {v.enable && <IonText>{v.hour}:{v.min} - {this.getWeekName(v.week)}</IonText>}
                </IonItem>
            })}
            <IonButton onClick={this.handleSaveTimer}>Guardar Cambios</IonButton>
            <IonButton onClick={this.handleClearTimer}>Limpiar</IonButton>
            <IonItem>
                <IonText>L = Lunes, M = Martes, X = Miércoles, J = Jueves, V = Viernes, S = Sábado, D = Domingo</IonText>
            </IonItem>
        </>

    }


    dateFromUnixTime = (unix: number) => {
        if(unix === 0) {
            return "No soportado. Actualice el firmware de la placa.";
        }
        let date = moment(unix, 'X').tz('UTC').format("YYYY-MM-DD HH:mm:ss");
        return date;
    }

    
    renderModalAdd() {
        return <IonModal isOpen={!!this.state.editTimer}>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="end">
                        <IonButton onClick={() => this.setState({ editTimer: undefined })}>OK</IonButton>
                    </IonButtons>
                    <IonTitle>Editar</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    <IonItem>
                        <IonLabel>Activar</IonLabel>
                        <IonToggle slot="end" checked={this.state.editTimer?.enable} onIonChange={this.handleTimerChange('enable')} />
                    </IonItem>
                    <IonItem>
                        <IonInput label="Hora" labelPlacement="stacked" placeholder="Hora del evento" value={this.state.editTimer?.hour} onIonInput={this.handleTimerChange('hour')}></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonInput label="Minuto" labelPlacement="stacked" placeholder="Minuto del evento" value={this.state.editTimer?.min} onIonInput={this.handleTimerChange('min')}></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonSelect label="Dia de la semana" placeholder="Funcion" value={this.state.editTimer?.week} multiple={true} onIonChange={this.handleTimerChangeArray('week')}>
                            <IonSelectOption value={1}>Lunes</IonSelectOption>
                            <IonSelectOption value={2}>Martes</IonSelectOption>
                            <IonSelectOption value={3}>Miércoles</IonSelectOption>
                            <IonSelectOption value={4}>Jueves</IonSelectOption>
                            <IonSelectOption value={5}>Viernes</IonSelectOption>
                            <IonSelectOption value={6}>Sábado</IonSelectOption>
                            <IonSelectOption value={0}>Domingo</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                    <IonItem>
                        <IonSelect label="Acción" placeholder="Funcion" value={this.state.editTimer?.action} onIonChange={this.handleTimerChange('action')}>
                            <IonSelectOption value={1}>Abrir salidas seleccionadas</IonSelectOption>
                            <IonSelectOption value={2}>Cerrar salidas seleccionadas</IonSelectOption>
                            <IonSelectOption value={3}>Establecer todas las salidas</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                    <IonItem>
                        <IonCheckbox checked={this.state.editTimer?.output1 === 1} onIonChange={this.handleTimerChange('output1')}>Salida 1</IonCheckbox>
                    </IonItem>
                    <IonItem>
                        <IonCheckbox checked={this.state.editTimer?.output2 === 1} onIonChange={this.handleTimerChange('output2')}>Salida 2</IonCheckbox>
                    </IonItem>
                    <IonItem>
                        <IonCheckbox checked={this.state.editTimer?.output3 === 1} onIonChange={this.handleTimerChange('output3')}>Salida 3</IonCheckbox>
                    </IonItem>
                    <IonItem>
                        <IonCheckbox checked={this.state.editTimer?.output4 === 1} onIonChange={this.handleTimerChange('output4')}>Salida 4</IonCheckbox>
                    </IonItem>
                    <IonItem>
                        <IonCheckbox checked={this.state.editTimer?.output5 === 1} onIonChange={this.handleTimerChange('output5')}>Salida 5</IonCheckbox>
                    </IonItem>
                    <IonItem>
                        <IonCheckbox checked={this.state.editTimer?.output6 === 1} onIonChange={this.handleTimerChange('output6')}>Salida 6</IonCheckbox>
                    </IonItem>
                </IonList>
            </IonContent>
        </IonModal>
    }

    loraPercent(rssi: number) {
        // ()
    }

    renderDigitalInputs (inputs : number) {
        let list = [];
        for (let index = 0; index < 7; index++) {
            const element = (inputs & (1 << index)) > 0;
            list.push(element);
        }
        return list.map((a, i) => {
            return <div>{i + 1} <IonBadge color={a ? "primary" : "danger"}>{a ? "ON": "OFF"}</IonBadge></div>
        })
    }
    renderStation(loraStation: LoraStation) {
        let outputs = this.state.outputs;
        return <IonList>
            <IonItem lines={"none"}>
                <IonLabel>Control Salidas: </IonLabel>
            </IonItem>
            <IonGrid>
                <IonRow>
                    <IonCol sizeMd="4" size="6">
                        <IonItem>
                            <IonToggle enableOnOffLabels={true} slot="start" checked={(outputs & 0b1) === 1} onIonChange={this.handleChangeOutput(0)} />
                            <IonLabel>
                                Salida 1
                            </IonLabel>
                        </IonItem>
                    </IonCol>
                    <IonCol sizeMd="4" size="6">
                        <IonItem>
                            <IonToggle enableOnOffLabels={true} slot="start" checked={((outputs >> 1) & 0b1) === 1} onIonChange={this.handleChangeOutput(1)} />
                            <IonLabel>
                                Salida 2
                            </IonLabel>
                        </IonItem>
                    </IonCol>
                    <IonCol sizeMd="4" size="6">
                        <IonItem>
                            <IonToggle enableOnOffLabels={true} slot="start" checked={((outputs >> 2) & 0b1) === 1} onIonChange={this.handleChangeOutput(2)} />
                            <IonLabel>
                                Salida 3
                            </IonLabel>
                        </IonItem>
                    </IonCol>
                    <IonCol sizeMd="4" size="6">
                        <IonItem>
                            <IonToggle enableOnOffLabels={true} slot="start" checked={((outputs >> 3) & 0b1) === 1} onIonChange={this.handleChangeOutput(3)} />
                            <IonLabel>
                                Salida 4
                            </IonLabel>
                        </IonItem>
                    </IonCol>
                    
                    <IonCol sizeMd="4" size="6">

                        <IonItem>
                            <IonToggle enableOnOffLabels={true} slot="start" checked={((outputs >> 4) & 0b1) === 1} onIonChange={this.handleChangeOutput(4)} />
                            <IonLabel>
                                Salida 5
                            </IonLabel>
                        </IonItem>
                    </IonCol>
                    <IonCol sizeMd="4" size="6">
                        <IonItem>
                            <IonToggle enableOnOffLabels={true} slot="start" checked={((outputs >> 5) & 0b1) === 1} onIonChange={this.handleChangeOutput(5)} />
                            <IonLabel>
                                Salida 6
                            </IonLabel>
                        </IonItem>
                    </IonCol>
                </IonRow>
            </IonGrid>
            <IonItem>

                <IonButton onClick={this.handleSetOutputs}>Establecer</IonButton>
            </IonItem>
            <IonItem>
                <IonLabel>Entradas: </IonLabel>
                <IonText>{this.renderDigitalInputs(loraStation.digitalInputs)}</IonText>
            </IonItem>
            <IonItem>
                <IonLabel>Contador 1: (Total / Parcial)</IonLabel>
                <IonText>{loraStation.pulseCounter1} / {loraStation.pulsePartial1}</IonText>
                <IonButton onClick={this.handleReset(1)}>Reset</IonButton>
            </IonItem>
            <IonItem>
                <IonLabel>Contador 2: (Total / Parcial)</IonLabel>
                <IonText>{loraStation.pulseCounter2} / {loraStation.pulsePartial2}</IonText>
                <IonButton onClick={this.handleReset(2)}>Reset</IonButton>
            </IonItem>
            <IonItem>
                <IonLabel>Entrada Analogica 1: </IonLabel>
                <IonText>{loraStation.analogInput1}</IonText>
            </IonItem>
            <IonItem>
                <IonLabel>Entrada Analogica 2: </IonLabel>
                <IonText>{loraStation.analogInput2}</IonText>
            </IonItem>
            <IonItem>
                <IonLabel>Voltage Batería: </IonLabel>
                <IonText>{this.voltage(loraStation.batteryLevel, 10000, 1000).toFixed(2)} V</IonText>
            </IonItem>
            <IonItem>
                <IonLabel>Temperatura Ambiente: </IonLabel>
                <IonText>{(loraStation.temperature)} ºC</IonText>
            </IonItem>
            {this.state.meta && <>
                <IonItem>
                    <IonLabel>Potencia Señal</IonLabel>
                    <IonText>{this.state.meta.rssi} RSSI  / {this.state.meta.snr} SNR</IonText>
                    <IonButton>?</IonButton>
                </IonItem>
            
            </>}
            
            <IonItem>
                <IonLabel>Fecha y Hora en dispositivo: </IonLabel>
                <IonText>{this.dateFromUnixTime(loraStation.unixTime)}</IonText>
                <IonButton onClick={this.handleSetTime}>Sincr.</IonButton>
            </IonItem>
            <IonItem>
                <IonButton onClick={this.handleLoadBank(0)}>Cargar Programador 1</IonButton>
            </IonItem>
            <IonItem>
                <IonButton onClick={this.handleLoadBank(1)}>Cargar Programador 2</IonButton>
            </IonItem>
            <IonItem>
                <IonButton onClick={this.handleLoadBank(2)}>Cargar Programador 3</IonButton>
            </IonItem>
            {this.renderTimerRegisters()}
            {/* <IonItem>
                <IonLabel>Calidad Comunicación: </IonLabel>
                <IonText>{loraStation.rssi}</IonText>
            </IonItem>
            <IonItem>
                <IonLabel>SNR: </IonLabel>
                <IonText>{loraStation.snr}</IonText>
            </IonItem>
            <IonItem>
                <IonLabel>Online: </IonLabel>
                <IonText>{loraStation.online}</IonText>
            </IonItem>
            <IonItem>
                <IonLabel>Establecer Reloj: </IonLabel>
                <IonText>{loraStation.timeSet}</IonText>
            </IonItem>
            <IonItem>
                <IonLabel>Ultima actualizacion: </IonLabel>
                <IonText>{loraStation.timeGet}</IonText>
            </IonItem>
            <IonItem>
                <IonLabel>Tiempo arranque: </IonLabel>
                <IonText>{loraStation.timeRunning}</IonText>
            </IonItem>
            <IonItem>
                <IonButton onClick={this.handleLoadBank(0)}>Cargar Programador 1</IonButton>
            </IonItem>
            <IonItem>
                <IonButton onClick={this.handleLoadBank(1)}>Cargar Programador 2</IonButton>
            </IonItem>
            <IonItem>
                <IonButton onClick={this.handleLoadBank(2)}>Cargar Programador 3</IonButton>
            </IonItem>
            <IonItem>
                <IonButton onClick={this.handleLoadBank(3)}>Cargar Programador 4</IonButton>
            </IonItem>
            <IonItem>
                <IonButton onClick={this.handleLoadBank(4)}>Cargar Programador 5</IonButton>
            </IonItem>
            {this.renderTimerRegisters()} */}
        </IonList>
    }

    renderDevice() {
        // const { lang } = this.props;
        let id = this.state.device?.id
        return <>

            <IonHeader color={"secondary"}>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">
                        <IonButton routerDirection={"back"} routerLink={"/device/" + id + "/details"} ><IonIcon icon={arrowBack} /></IonButton>
                    </IonButtons>
                    <IonTitle>{this.state.device?.name} - {this.state.output?.name} </IonTitle>

                    <IonButtons slot="end">
                        <IonButton onClick={this.handleReload}><IonIcon icon={reload} /></IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent scrollEvents={true}>
                {this.state.stationData && this.renderStation(this.state.stationData)}

            </IonContent>
        </>
    }

    renderLoading() {
        // const { lang } = this.props;
        return <IonLoading
            isOpen={this.state.loading!}
            message={"Descargando datos de estacion..."}
        />
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        // const {id} = this.props.match.params;
        return <IonPage>
            {this.state.error && <IonText>{this.state.error}</IonText>}
            {this.renderLoading()}
            {this.renderDevice()}
            {this.renderModalAdd()}
            {/* {this.renderSubscribe()} */}
        </IonPage>

    }
}
