import React, { RefObject } from "react";
import {
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonListHeader,
    IonLoading,
    IonPage,
    IonRange,
    IonReorder,
    IonReorderGroup,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToggle,
    IonToolbar,
    ItemReorderEventDetail
} from "@ionic/react";
import { withRouter } from "react-router";
import { inject } from "mobx-react";
import { Store } from "../../service/Store";
import { Device, DeviceInputGroup, DeviceInputs, InputItemSettings, ServiceSettings } from "../../service/API";
import Utils from "../../service/Utils";
import { RouteComponentProps } from "react-router-dom";
import { addOutline } from "ionicons/icons";
import ServiceEditor from "../../components/ServiceEditor";
export interface DeviceSettingsInputGroupProps extends RouteComponentProps<{ id: string, inputGroup: string }> {
    store?: Store,
    routerOutlet: HTMLIonRouterOutletElement,
}

export type DeviceSettingsInputGroupState = {
    loading?: boolean,
    // device?: Device,
    inputGroups?: DeviceInputGroup[],
    device_id?: number,
    edit?: boolean,
    order: boolean,
    inputGroup: DeviceInputGroup,
    device?: Device,
}

@inject("store", "lang")
class DeviceSettingsInputGroup extends React.Component<DeviceSettingsInputGroupProps, DeviceSettingsInputGroupState> {

    state: DeviceSettingsInputGroupState = {
        inputGroup: {} as any,
        edit: false,
        order: false
    }

    inputGroupModal: RefObject<HTMLIonModalElement> = React.createRef();
    inputModal: RefObject<HTMLIonModalElement> = React.createRef();

    unregister: any;
    componentDidMount(): void {
        this.loadDevice();
        this.unregister = this.props.history.listen(location => {
            if (this.props.history.action === 'POP') {
                this.loadDevice();
            }
            if (this.props.history.action === 'REPLACE') {
                this.loadDevice();
            }
        })
    }

    componentWillUnmount() {
        if (this.unregister) {
            this.unregister();
        }
    }
    loadDevice() {
        this.updateData()!.then(() => {
            let inputGroupId = parseInt(this.props.match.params.inputGroup);
            let inputGroup = this.state.inputGroups?.find(a => a.id === inputGroupId);
            if (inputGroup) {
                this.setState({ inputGroup });
            }
        });
    }

    componentDidUpdate(prevProps: Readonly<DeviceSettingsInputGroupProps>, prevState: Readonly<DeviceSettingsInputGroupState>, snapshot?: any): void {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.loadDevice();
        }
        else if (this.props.match.params.inputGroup && this.props.match.params.inputGroup !== prevProps.match.params.inputGroup) {
            this.loadDevice();
        }
    }

    async updateData() {
        let id = this.props.match?.params.id!;
        //deviceSettingsInputGroupGet
        this.setState({ loading: true, device_id: parseInt(id) });
        let value = await this.props.store?.api.deviceSettingsInputGroupGet(Number(id));
        if (value && value.success) {
            this.setState({ inputGroups: value.data });
            let device = await this.props.store?.api.deviceGet(Number(id), 'all');
            if (device) {
                this.setState({ device: device.data });
            }
            if (this.state.inputGroup) {
                let item = value.data.find(value1 => value1.id === this.state.inputGroup.id);
                if (item) {
                    this.setState({ inputGroup: item });
                }
            }
        }
        this.setState({ loading: false });
    }

    editInput = (input: DeviceInputGroup) => (ev: any) => {
        // if (!input.settings) {
        //     input.settings = Utils.createDefaultSettings(input, JSON.parse(input.data!));
        // }
        this.setState({ edit: true, inputGroup: input });

    };

    handleEdit = (ev: any) => {
        let id = this.props.match?.params.id!;
        this.setState({ loading: true });
        this.props.store?.api.deviceSettingsInputGroup(this.state.device_id!, [this.state.inputGroup]).finally(() => this.setState({ loading: false })).then(() => {
            this.setState({ loading: false });
            this.props.history.replace("/device/" + id + "/settings/input");

        })

    }

    handleInputGroupChange = (name: string) => (ev: CustomEvent<any>) => {
        if (typeof ev.detail.checked !== 'undefined') {
            this.setState({ inputGroup: { ...this.state.inputGroup, [name]: ev.detail.checked } });
        } else {
            this.setState({ inputGroup: { ...this.state.inputGroup, [name]: ev.detail.value } });

        }
    }
    handleInputChange = (input: DeviceInputs, name: keyof DeviceInputs) => (ev: CustomEvent<any>) => {
        // let input = this.state.inputGroup.inputs[index];
        if (typeof ev.detail.checked !== 'undefined') {
            (input as any)[name] = ev.detail.checked;
        } else {
            (input as any)[name] = ev.detail.value;
        }
        // eslint-disable-next-line react/no-direct-mutation-state
        // this.state.inputGroup.inputs[index] = input;
        this.setState({})
    }

    handleSettingsServiceChange = (name: string, value: any) => {
        let settings = (this.state.inputGroup.serviceSettings! || {}) as ServiceSettings;

        if (settings.error) {
            settings.error = undefined;
        }
        settings = { ...settings, [name]: value };
        this.setState({ inputGroup: { ...this.state.inputGroup, serviceSettings: settings } });
    }

    handleGraphChange = (index: number, name: string) => (ev: CustomEvent) => {
        let settings = Utils.fromJSON(this.state.inputGroup.graphProps!) as InputItemSettings[];
        settings = settings || [];
        settings[index] = { ...settings[index], [name]: ev.detail.value }
        this.setState({ inputGroup: { ...this.state.inputGroup, graphProps: JSON.stringify(settings) } });
    }

    handleReset = (ev: any) => {
        this.setState({ inputGroup: { ...this.state.inputGroup, serviceSettings: null } });
    }

    handleRemove = (ev: any) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("¿Realmente deseas eliminar esta entrada? Es posible que las automatizaciones asociadas también se eliminen.")) {

            this.setState({ loading: true, edit: false });

            this.props.store?.api.deviceInputGroupDelete(this.state.device_id!, this.state.inputGroup.id).then(value => {
                this.setState({ loading: false });

                if (value.success) {
                    this.updateData();
                }
            });
        }
    }

    handleAdd = (ev: any) => {
        this.setState({ loading: true, edit: false });

        this.props.store?.api.deviceInputGroupAdd(this.state.device_id!, "modbus").then(value => {
            this.setState({ loading: false });

            if (value.success) {
                this.setState({ edit: true, inputGroup: value.data })
            }
        });
    }


    handleAddInput = (ev: any) => {

        this.setState({ loading: true });
        this.props.store?.api.deviceInputAdd(this.state.device_id!, this.state.inputGroup.id).then(value => {
            this.setState({ loading: false });

            if (value.success) {
                let ig = this.state.inputGroup;
                if (!ig.inputs) {
                    ig.inputs = []
                }
                ig.inputs.push(value.data);
                this.handleEditInput(value.data)(ev);
                //this.setState({edit: true, input: value.data})
            }
        });

    }

    handleEditInput = (input: DeviceInputs) => (ev: any) => {
        let id = this.props.match.params.id;
        let inputGroup = this.props.match.params.inputGroup;
        let url = "/device/" + id + "/settings/inputGroup/" + inputGroup + "/input/" + input.id;

        this.props.history.push(url);
    }

    handleReorderButton = () => {
        this.setState({ order: !this.state.order })
    };

    doReorder = (inputGroup: DeviceInputGroup) => (event: CustomEvent<ItemReorderEventDetail>) => {
        // The `from` and `to` properties contain the index of the item
        // when the drag started and ended, respectively
        console.log('Dragged from index', event.detail.from, 'to', event.detail.to);
        let from = event.detail.from;
        let to = event.detail.to;

        let inputs = Utils.sortInputs(inputGroup.inputs);
        for (let index = 0; index < inputs.length; index++) {
            const element = inputs[index];
            element.display_order = index;
        }

        inputs[event.detail.from].display_order = to;

        if (to > from) {
            for (let index = from + 1; index < to + 1; index++) {
                inputs[index].display_order--;

            }

        } else {

            for (let index = to; index < from; index++) {
                inputs[index].display_order++;

            }
        }

        inputs = Utils.sortInputs(inputs);
        // Finish the reorder and position the item in the DOM based on
        // where the gesture ended. This method can also be called directly
        // by the reorder group
        event.detail.complete();
        inputGroup.inputs = inputs;
        this.setState({});
    }

    // handleSaveInput = (ev: any) => {

    //     this.setState({loading: true});
    //     this.props.store?.api.deviceInputSave(this.state.device_id!, this.state.input!).then(value => {
    //         this.setState({loading: false});

    //         if (value.success) {
    //             this.setState({edit: true, input: undefined})
    //         }
    //     });
    // }
    // setSetting(index: number, name: string, value: string) {
    //     let settings = Utils.getInputSettings(this.state.input);
    //     if (settings instanceof Array) {
    //         settings = settings as InputItemSettings[];
    //         settings[index] = {...settings[index], [name]: value};
    //         this.setState({input: {...this.state.input, settings: settings}});
    //     }
    //     else if ((settings as ServiceSettings).name !== undefined) {
    //         settings = settings as ServiceSettings;
    //         settings.inputs[index] = {...settings.inputs[index], [name]: value};
    //         this.setState({input: {...this.state.input, settings: settings}});
    //     }
    // }


    renderEditorInputService(input: DeviceInputGroup) {
        return <>

            <IonItemDivider>
                <IonLabel>
                    Servicio Externo
                </IonLabel>
            </IonItemDivider>
            <ServiceEditor inputGroup={input} onChange={this.handleSettingsServiceChange} />

            {this.renderEditorInputItems(this.state.inputGroup)}
        </>

    }

    renderEditorInputModbus(input: DeviceInputGroup) {
        return <>

            <IonItemDivider>
                <IonLabel>
                    Entradas Modbus
                </IonLabel>
            </IonItemDivider>
            <IonReorderGroup disabled={!this.state.order} onIonItemReorder={this.doReorder(input)}>
                {input.inputs && input.inputs.map(value => {
                    return <IonItem key={value.id} button detail={!this.state.order} onClick={(ev) => !this.state.order && this.handleEditInput(value)(ev)}>
                        
                        <IonLabel>{value.name}</IonLabel>
                        <IonBadge>{value.tag}</IonBadge>
                        <IonReorder slot="end" />
                        <IonIcon icon={Utils.getInputIcon(value)} slot="end" />
                    </IonItem>
                })}
            </IonReorderGroup>
            <IonItem onClick={this.handleAddInput} color={"success"} button detail detailIcon={addOutline}>
                <IonLabel>Añadir entrada</IonLabel>
            </IonItem>
        </>

    }

    renderItemGraph(index: number, input: DeviceInputs, graphProps: InputItemSettings[]) {

        let value = (graphProps && graphProps[index]) || {};
        return <>
            <IonItemDivider>
                <IonLabel>
                    {input.name}
                </IonLabel>
            </IonItemDivider>

            <IonItem>
                <IonLabel>Ocultar</IonLabel>
                <IonToggle onIonChange={this.handleGraphChange(index, 'hidden')}
                    checked={!!value.hidden} />
            </IonItem>

            <IonItem>
                <IonLabel>Relleno</IonLabel>
                <IonToggle onIonChange={this.handleGraphChange(index, 'fill')}
                    checked={!!value.fill} />
            </IonItem>
            <IonItem>
                <IonLabel>Tensión Curva</IonLabel>
                <IonRange min={0} max={1.0} step={0.01} value={value.tension ?? 0.4} color="secondary"
                    onIonChange={this.handleGraphChange(index, 'tension')} />
            </IonItem>

            <IonItem>
                <IonLabel>Color</IonLabel>

                <IonSelect style={{ backgroundColor: value.color }} value={value.color} placeholder="Select One"
                    onIonChange={this.handleGraphChange(index, 'color')}>
                    <IonSelectOption value={"#40ad3a"}>Verde</IonSelectOption>
                    <IonSelectOption value={"#32adbc"}>Azul</IonSelectOption>
                    <IonSelectOption value={"#ffce00"}>Amarillo</IonSelectOption>
                    <IonSelectOption value={"#f25454"}>Rojo</IonSelectOption>
                    <IonSelectOption value={"#ffffff"}>Blanco</IonSelectOption>
                </IonSelect>
            </IonItem>
        </>

    }

    renderEditorInputItems(input: DeviceInputGroup) {
        return <IonReorderGroup disabled={!this.state.order} onIonItemReorder={this.doReorder(input)}>
            {input.inputs && input.inputs.map(value => {
                return <IonItem key={value.id} button detail={!this.state.order} onClick={(ev) => !this.state.order && this.handleEditInput(value)(ev)}>
                    <IonLabel>{value.type === 'output' ? Utils.getOutputNameByIndex(this.state.device?.outputs!, value.index, value.name) : value.name}</IonLabel>
                    <IonBadge>{value.tag}</IonBadge>
                    <IonIcon icon={Utils.getInputIcon(value)} slot="end" />
                    <IonReorder slot="end" />
                </IonItem>
            })}

        </IonReorderGroup>
        // return input.inputs && input.inputs.map((value, index) => {
        //     return <>
        //         <IonItemDivider>
        //             <IonLabel>
        //                 {value.name}
        //             </IonLabel>
        //         </IonItemDivider>
        //         <IonItem>
        //             <IonLabel>Nombre</IonLabel>
        //             <IonInput onIonChange={this.handleInputChange(value, 'name')}
        //                       value={value.name}/>
        //         </IonItem>
        //         {input.type === "analog" && this.renderPropsAnalog(value, index)}

        //     </>
        // })
    }

    renderEditorInput(input: DeviceInputGroup) {
        if (input.type === 'service') return this.renderEditorInputService(input);
        if (input.type === 'modbus') return this.renderEditorInputModbus(input);
        return this.renderEditorInputItems(input);
    }

    renderGraphEditor() {
        if (!this.state.inputGroup) return "";
        const { inputGroup } = this.state;
        let graphProps = Utils.fromJSON(inputGroup.graphProps!);
        return <>
            <IonItem>
                <IonLabel>Intervalo</IonLabel>
                <IonSelect value={this.state.inputGroup.graphInterval}
                    onIonChange={this.handleInputGroupChange('graphInterval')}>
                    <IonSelectOption value="1">Segundos</IonSelectOption>
                    <IonSelectOption value="minute">Minutos</IonSelectOption>
                    <IonSelectOption value="15minute">15 Minutos</IonSelectOption>
                    <IonSelectOption value="30minute">30 Minutos</IonSelectOption>
                    <IonSelectOption value="hour">Horas</IonSelectOption>
                    <IonSelectOption value="day">Dias</IonSelectOption>
                    <IonSelectOption value="month">Meses</IonSelectOption>
                </IonSelect>
            </IonItem>
            <IonItem>
                <IonLabel>Rango</IonLabel>
                <IonSelect value={this.state.inputGroup.graphRange} placeholder="Select One"
                    onIonChange={this.handleInputGroupChange('graphRange')}>
                    <IonSelectOption value={1}>1 Hora</IonSelectOption>
                    <IonSelectOption value={24}>1 Dia</IonSelectOption>
                    <IonSelectOption value={24 * 7}>7 Dias</IonSelectOption>
                    <IonSelectOption value={24 * 14}>14 Dias</IonSelectOption>
                    <IonSelectOption value={24 * 30}>1 Mes</IonSelectOption>
                    <IonSelectOption value={24 * 30 * 3}>3 Mes</IonSelectOption>
                    <IonSelectOption value={24 * 30 * 6}>6 Mes</IonSelectOption>
                </IonSelect>
            </IonItem>

            <IonListHeader>
                <IonLabel>Series</IonLabel>
            </IonListHeader>

            {this.state.inputGroup.inputs && this.state.inputGroup.inputs.map((value, index) => this.renderItemGraph(index, value, graphProps))}
            {/*{settings && (settings as ServiceSettings).inputs && (settings as ServiceSettings).inputs.map((value, index) => this.renderItemGraph(index, value))}*/}
        </>;
    }

    renderEditor() {
        if (!this.state || !this.state.inputGroup) return "";
        let input = this.state.inputGroup;
        return <>
            <IonItem>
                <IonInput value={input.name}
                    label="Título de las entradas"
                    labelPlacement="floating"
                    onIonInput={this.handleInputGroupChange('name')} />
            </IonItem>
            <IonItem>
                <IonLabel>Asociar a gráfica</IonLabel>
                <IonToggle slot={"end"} checked={input.graph} onIonChange={this.handleInputGroupChange('graph')} />
            </IonItem>
            <IonItem>
                <IonLabel>Visible</IonLabel>
                <IonToggle slot={"end"} checked={input.visible} onIonChange={this.handleInputGroupChange('visible')} />
            </IonItem>
            {/*<IonItem detail button onClick={(ev: any) => this.setState({editGraph: true})}>*/}
            {/*    <IonLabel>Propiedades de la gráfica</IonLabel>*/}
            {/*</IonItem>*/}
            {this.renderEditorInput(input)}

            <IonItem detail={false} button color={"warning"} onClick={this.handleReset}>
                <IonLabel>Valores por defecto</IonLabel>
            </IonItem>
            {this.state.inputGroup.id > 0 &&
                <IonItem detail={false} button color={"danger"} onClick={this.handleRemove}>
                    <IonLabel>Eliminar</IonLabel>
                </IonItem>}
        </>
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let id = this.props.match?.params.id!;

        if (!this.state) return "";
        return <IonPage>

            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={"/device/" + id + "/settings/input"} />
                    </IonButtons>
                    <IonTitle>Editar Grupo {this.state.inputGroup.name}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={this.handleReorderButton}>Ordernar</IonButton>
                        <IonButton onClick={this.handleEdit}>Guardar</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {this.renderEditor()}
                <IonLoading
                    isOpen={this.state.loading!}
                    message={'Cargando...'}
                    duration={5000}
                />
            </IonContent>
        </IonPage>
    }

}

export default withRouter(DeviceSettingsInputGroup);
