import {Capacitor} from "@capacitor/core";
import { ClearWatchOptions, Geolocation as GeolocationAPI, WatchPositionCallback } from '@capacitor/geolocation';
export interface Coordinates {
    /**
     * a double representing the position's latitude in decimal degrees.
     */
    latitude: number;
    /**
     * A double representing the position's longitude in decimal degrees.
     */
    longitude: number;
    /**
     * A double representing the accuracy of the latitude and longitude properties,
     * expressed in meters.
     */
    accuracy: number;
    /**
     * A double representing the position's altitude in metres, relative to sea
     * level. This value can be null if the implementation cannot provide the data.
     */
    altitude: number;
    /**
     * A double representing the accuracy of the altitude expressed in meters.
     * This value can be null.
     */
    altitudeAccuracy: number;
    /**
     * A double representing the direction in which the device is traveling. This
     * value, specified in degrees, indicates how far off from heading true north
     * the device is. 0 degrees represents true north, and the direction is
     * determined clockwise (which means that east is 90 degrees and west is 270
     * degrees). If speed is 0, heading is NaN. If the device is unable to provide
     * heading information, this value is null.
     */
    heading: number;
    /**
     * A double representing the velocity of the device in meters per second.
     * This value can be null.
     */
    speed: number;
}
export interface Geoposition {
    /**
     * A Coordinates object defining the current location
     */
    coords: Coordinates;
    /**
     * A timestamp representing the time at which the location was retrieved.
     */
    timestamp: number;
}
class Geolocation {
    static watchId?: number;
    static getCurrentPosition() : Promise<Geoposition> {
        return new Promise<Geoposition>((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    resolve({coords: position.coords} as Geoposition);

                },err => {
                    reject(err.message);
                });
              } else {
                reject("Geolocation is not supported by this browser.");
              }
        });
    }

    static watchPosition(options: PositionOptions, callback: WatchPositionCallback) : Promise<string> {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                let id = navigator.geolocation.watchPosition((position) => {
                    // resolve({coords: position.coords} as Geoposition);
                    callback(position);
                },err => {
                    callback(null, err);
                });
                resolve(String(id));
              } else {
                reject("Geolocation is not supported by this browser.");
              }
        });

    }

    static clearWatch(options: ClearWatchOptions) {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                resolve(true);
                navigator.geolocation.clearWatch(parseInt(options.id));
                this.watchId = undefined;
              } else {
                reject("Geolocation is not supported by this browser.");
              }
        });
    }

    static checkPermissions() : Promise<{location: string}> {

        return new Promise((resolve, reject) => {
            
            if (navigator.permissions) {
                navigator.permissions.query({name:'geolocation'}).then(res => {
                    //PermissionState = 'prompt' | 'prompt-with-rationale' | 'granted' | 'denied'
                    resolve({
                        location: res.state
                    })
                })
              } else {
                reject("Geolocation is not supported by this browser.");
              }
        });
    }
}
export default Capacitor.getPlatform() !== 'web'?(GeolocationAPI):Geolocation
