import ModbusClientRequestHandler from "./ModbusClientRequestHandler";
import ModbusClient from "./ModbusClient";
import ModbusRTURequest from "./ModbusRTURequest";
import ModbusRequestBody from "./request/ModbusRequestBody";
import {ModbusResponse} from "./ResponseHandler";


const CRC = require('crc')
export default class ModbusRTUClientRequestHandler extends ModbusClientRequestHandler {
    private address:number;

    constructor(client: ModbusClient, address: number) {
        super(client, 5000);
        this.address = address;
    }

    registerBody(requestBody: ModbusRequestBody) {
        const request = new ModbusRTURequest(this.address, requestBody, false);
        return super.register(request);
    }

    handle(response: ModbusResponse) {

        const userRequest = this.currentRequest

        if (!userRequest) {
            // debug('something is strange, received a respone without a request')
            return
        }

        const buf = Buffer.concat([Buffer.from([response.address]), response.body.createPayload()])
        // debug('create crc from response', buf)

        const crc = CRC.crc16modbus(buf)

        if (response.crc !== crc) {
            // debug('CRC does not match', response.crc, '!==', crc)
            userRequest.reject({
                'err': 'crcMismatch',
                'message': 'the response payload does not match the crc'
            })
            this.clearAllRequests()
            return
        }

        super.handle(response)
    }


}
