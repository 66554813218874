import React from "react";
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonHeader, IonItem, IonList, IonModal, IonSpinner, IonText, IonTitle, IonToolbar } from "@ionic/react";
import { locationOutline } from "ionicons/icons";
import { inject } from "mobx-react";
import { Store } from "../service/Store";
import Lang from "../service/Lang";
import Geolocation from "../service/Geolocation";

import moment from "moment-timezone";


export interface ForecastCardProps {
    store?: Store;
    lang?: Lang;
    selection?: string;
    onChange?: (item: string) => void;
    onForecast?: (item: Forecast) => void;
    routerOutlet: any,
}

export interface ForecastCardState {

    modal: boolean,
    error?: boolean,
    allowLocation?: boolean,
    loading?: boolean,
    errorMessage?: string,
    weatherItem?: any,
    locations: { lat: number, lng: number }[],
    forecasts: Forecast[],
    /*
    [
    {
        "dt": 1645984800,
        "main": {
            "temp": 287.28,
            "feels_like": 286.26,
            "temp_min": 285.91,
            "temp_max": 287.28,
            "pressure": 1020,
            "sea_level": 1020,
            "grnd_level": 942,
            "humidity": 58,
            "temp_kf": 1.37
        },
        "weather": [
            {
                "id": 803,
                "main": "Clouds",
                "description": "broken clouds",
                "icon": "04d"
            }
        ],
        "clouds": {
            "all": 61
        },
        "wind": {
            "speed": 0.74,
            "deg": 42,
            "gust": 1.74
        },
        "visibility": 10000,
        "pop": 0.01,
        "sys": {
            "pod": "d"
        },
        "dt_txt": "2022-02-27 18:00:00"
    },
    */

}

export type ForecastList = {

    "dt": number,
    "sunrise": number,
    "sunset": number,
    "temp": {
        "day": number,
        "min": number,
        "max": number,
        "night": number,
        "eve": number,
        "morn": number
    },
    "feels_like": {
        "day": number,
        "night": number,
        "eve": number,
        "morn": number
    },
    "pressure": number,
    "humidity": number,
    "weather": [
        {
            "id": number,
            "main": string,
            "description": string,
            "icon": string
        }
    ],
    "speed": number,
    "deg": number,
    "gust": number,
    "clouds": number,
    "pop": number
}
export type Forecast = {
    cod: string;
    message: number;
    cnt: number;
    list: ({
        dt: number;
        main: {
            temp: number;
            temp_min: number;
            temp_max: number;
            pressure: number;
            sea_level: number;
            grnd_level: number;
            humidity: number;
            temp_kf: number;
        };
        weather: {
            id: number;
            main: string;
            description: string;
            icon: string;
        }[];
        clouds: {
            all: number;
        };
        wind: {
            speed: number;
            deg: number;
        };
        sys: {
            pod: string;
        };
        dt_txt: string;
        rain?: undefined;
        snow?: undefined;
    } | {
        dt: number;
        main: {
            temp: number;
            temp_min: number;
            temp_max: number;
            pressure: number;
            sea_level: number;
            grnd_level: number;
            humidity: number;
            temp_kf: number;
        };
        weather: {
            id: number;
            main: string;
            description: string;
            icon: string;
        }[];
        clouds: {
            all: number;
        };
        wind: {
            speed: number;
            deg: number;
        };
        rain: {
            '3h': number;
        };
        sys: {
            pod: string;
        };
        dt_txt: string;
        snow?: undefined;
    } | {
        dt: number;
        main: {
            temp: number;
            temp_min: number;
            temp_max: number;
            pressure: number;
            sea_level: number;
            grnd_level: number;
            humidity: number;
            temp_kf: number;
        };
        weather: {
            id: number;
            main: string;
            description: string;
            icon: string;
        }[];
        clouds: {
            all: number;
        };
        wind: {
            speed: number;
            deg: number;
        };
        rain: {
            '3h': number;
        };
        snow: {
            '3h': number;
        };
        sys: {
            pod: string;
        };
        dt_txt: string;
    } | {
        dt: number;
        main: {
            temp: number;
            temp_min: number;
            temp_max: number;
            pressure: number;
            sea_level: number;
            grnd_level: number;
            humidity: number;
            temp_kf: number;
        };
        weather: {
            id: number;
            main: string;
            description: string;
            icon: string;
        }[];
        clouds: {
            all: number;
        };
        wind: {
            speed: number;
            deg: number;
        };
        rain: {
            '3h'?: undefined;
        };
        snow: {
            '3h'?: undefined;
        };
        sys: {
            pod: string;
        };
        dt_txt: string;
    })[];
    city: {
        id: number;
        name: string;
        coord: {
            lat: number;
            lon: number;
        };
        country: string;
    };
}

@inject("store", "lang")
export default class ForecastCard extends React.Component<ForecastCardProps, ForecastCardState> {

    state: ForecastCardState = {
        locations: [
            // { lat: 37.8477382, lng: -3.3530046 }

        ],
        forecasts: [],
        modal: false,
        weatherItem: null,
    };


    async loadForecastCard() {
        this.setState({ forecasts: [] });
        for (let index = 0; index < this.state.locations.length; index++) {
            const element = this.state.locations[index];
            let list = await this.props.store?.weatherMap.getThreeHourForecastByGeoCoordinates(element.lat, element.lng);
            if (list && list.cod && list.cod === '200') {
                if (this.props.onForecast) {
                    this.props.onForecast(list);
                }
                this.setState({ forecasts: [...this.state.forecasts, list] })
            } else {
                console.log(list); // error
            }

        }
        this.setState({ loading: false });
    }

    componentDidMount(): void {
        Geolocation.checkPermissions().then(res => {
            if (res.location === 'granted') {
                this.getLocation();
            } else {
                this.setState({ allowLocation: true });
            }
        }).catch(err => {
            this.setState({ error: true, errorMessage: err.toString() });
        });
    }

    getLocation() {
        try {
            this.setState({ loading: true });

            Geolocation.getCurrentPosition().then(res => {
                if (res) {
                    this.setState({ error: false, allowLocation: false, locations: [{ lat: res.coords.latitude, lng: res.coords.longitude }] }, () => {
                        this.loadForecastCard();
                    });
                }
            }).catch(err => {
                this.setState({ error: true, errorMessage: err.toString() });
            }).finally(() => {
                this.loadForecastCard();
            });
        } catch (e: any) {
            this.setState({ error: true, errorMessage: e.message, loading: false });
        }
    }

    handleClick = (item: any) => (ev: any) => {
        console.log(item);
        if (!this.state.modal) {
            this.setState({ modal: true, weatherItem: item });
        }
    }

    handleClickLocation = () => {
        this.getLocation();
    }
    renderCard(forecast: Forecast) {
        // const slideOpts = {
        //     initialSlide: 1,
        //     speed: 400
        // };
        return <IonCard className="welcome-card">
            <IonCardHeader>
                <IonCardTitle>
                    {forecast.city.name}
                </IonCardTitle>


            </IonCardHeader>
            <IonCardContent className="weather-card-content">
                <div className="weather-container">
                    {forecast.list.map((a, k) => {
                        let b: any = a;
                        return <div key={k} className="weather-item">
                            <IonText onClick={this.handleClick(a)}>
                                {moment(a.dt_txt).format("ddd DD MMM HH:mm")} <br />
                                <img alt="Weather" src={"https://openweathermap.org/img/wn/" + a.weather[0].icon + "@2x.png"} /> <br />
                                {a.weather[0].description} <br />
                                {b.pop > 0 ? (b.pop * 100) + "%" : ""}

                            </IonText>
                        </div>
                    })}

                </div>
            </IonCardContent>
        </IonCard>
    }

    render(): React.ReactNode {
        if (!this.state) return "";
        return <div>
            {this.state.forecasts.map((a) => this.renderCard(a)).map((a, k) => a)}

            {this.state.error && <IonItem color=""><IonText>{this.state.errorMessage}</IonText></IonItem>}
            {this.state.loading && <IonItem><IonSpinner /> <IonText>Ubicando... </IonText></IonItem>}
            {this.state.allowLocation && <IonList>
                <IonItem onClick={this.handleClickLocation} button color="primary" detail detailIcon={locationOutline}>
                    <IonText>Permitir a CloudRTU conocer su ubicación para mostrar la previsión del tiempo</IonText>
                </IonItem>
            </IonList>}

            <IonModal isOpen={this.state.modal} onDidDismiss={() => this.setState({ modal: false })} initialBreakpoint={0.8}
                breakpoints={[0, 0.8, 1]}>
                <IonHeader translucent={true}>
                    <IonToolbar>
                        <IonTitle>Previsión Meteorológica</IonTitle>
                    </IonToolbar>

                </IonHeader>
                <IonContent>
                    {this.state.weatherItem && <IonList>
                        <IonItem>{moment(this.state.weatherItem.dt, "X").format("ddd DD MMM HH:mm")}</IonItem>

                        {this.state.weatherItem.weather.map((a: any) => <><IonItem><img alt="Weather" src={"https://openweathermap.org/img/wn/" + a.icon + ".png"} /> <br />{a.description}</IonItem></>)}

                        <IonItem>{this.state.weatherItem.main.temp} ºC</IonItem>
                        <IonItem>Sensación {this.state.weatherItem.main.feels_like} ºC</IonItem>
                        <IonItem>Mínima {this.state.weatherItem.main.temp_min} ºC</IonItem>
                        <IonItem>Máxima {this.state.weatherItem.main.temp_max} ºC</IonItem>
                        <IonItem>Presión {this.state.weatherItem.main.pressure} hPa</IonItem>
                        <IonItem>Humedad {this.state.weatherItem.main.humidity} %</IonItem>
                        <IonItem>Probabilidad de precipitación {this.state.weatherItem.pop * 100} %</IonItem>

                        {this.state.weatherItem.clouds && <IonItem>Nubes: {this.state.weatherItem.clouds.all} %</IonItem>}

                        <IonItem>Viento {this.state.weatherItem.wind.speed} m/s {this.state.weatherItem.wind.deg} º</IonItem>
                        {this.state.weatherItem.rain && <IonItem>Lluvia {this.state.weatherItem.rain['3h']} mm</IonItem>}
                        {this.state.weatherItem.snow && <IonItem>Nieve {this.state.weatherItem.snow['3h']} (Ult. Hora)</IonItem>}

                    </IonList>}

                </IonContent>

            </IonModal>
        </div>
    }

}
