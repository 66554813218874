import {
    IonAvatar,
    IonButton,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonPage,
    IonPopover,
    IonRouterOutlet,
    IonSplitPane,
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonTitle,
    IonToast,
    IonToggle,
    IonToolbar
} from "@ionic/react";
import {Route, withRouter, Redirect} from "react-router-dom";
import Dashboard from "./Dashboard";
import Devices from "./Devices";
import {albums, apps, map} from "ionicons/icons";
import React, { RefObject } from "react";
import {inject, observer} from "mobx-react";
import {Store} from "../service/Store";
import Invitation from "./Invitation";
import UserAccount from "./UserAccount";
import UserAccountInvoices from "./UserAccountInvoices";
import {menuController} from "@ionic/core";
import Configurator from "./Configurator";
import DevicesMap from "./DevicesMap";
import App from "../App";
import Settings from "./Settings";
import TestPage from "./TestPage";
import Utils from "../service/Utils";
import WebSerialPort from "../service/WebSerialPort";
import Lang from "../service/Lang";
import { Locales } from "../i18n/i18n-types";
import AddDevices from "./AddDevices";
import MapRoute from "./MapRoute";
import ModbusApp from "./ModbusApp";

@inject("store", "lang")
@observer
class MainSplitPane extends React.Component<any, any> {

    state = {alarm: '', extension: false, popoverState: {showPopover: false, event: undefined}, user: {fullname: '', email: ''}, avatar: 'https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y'};

    
    routerOutlet: RefObject<HTMLIonRouterOutletElement>;
    constructor(props: any, context: any) {
        super(props, context);
        this.routerOutlet = React.createRef();

    }
    componentDidMount(): void {

        let store:Store = this.props.store;
        store.events.on('alarm', (data:any) => {
            Utils.log("alarm", data);
            // this.setState({alarm: "Yeah!: " + JSON.stringify(data)});
        } );
        if(WebSerialPort.available()) {
            this.setState({extension: true});
        }
        store.getUser(false).then(user => {
            this.setState({ user: user });

            if (user?.email) {

            }

        });

        this.props.store!.push.check(this.props);
    }



    handleThemeToggle = (ev: CustomEvent) => {
        this.props.store.setDarkMode(ev.detail.checked);
    };

    handleTab = (name:string) => (ev:any) => {

        let store:Store = this.props.store;
        store.setItem('home', name);
        // this.props.location.push()
    }

    handleClickLanguage = (loc:Locales) => (ev:any) => {
        let lang : Lang = this.props.lang;
        lang.language = loc;
        this.setState({popoverState: {showPopover: false, event: undefined}})
    }

    renderRedirect = () => {
        return App.getRedirect(this.props.store!);
    }
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {

        const {lang} = this.props;
        const {popoverState} = this.state;
         
        // @ts-ignore
        let ionSplitPane = <IonSplitPane contentId="menuContent">
            {/*--  our side menu  --*/}
            <IonMenu contentId="menuContent">

                <IonHeader>
                    <IonToolbar color={"primary"}>
                        <IonTitle>{lang.l.main.menu()}</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonList>
                        <IonItem>
                            <IonAvatar slot="start">
                                <img src={this.state.avatar} alt="Avatar" />
                            </IonAvatar>

                            <IonLabel>
                                <p>{this.state.user?.fullname}</p>
                                <p>{this.state.user?.email}</p>
                            </IonLabel>
                        </IonItem>
                        <IonItem routerLink={"/"} onClick={async () => await menuController.toggle()}>
                            <IonLabel>{lang.l.main.menu_home()}</IonLabel>
                        </IonItem>

                        <IonItem routerLink={"/hmi"} onClick={async () => await menuController.toggle()} >
                            <IonLabel>{lang.l.main.menu_hmi()}</IonLabel>
                        </IonItem>
                        <IonItem routerLink={"/account"} onClick={async () => await menuController.toggle()}>
                            <IonLabel>{lang.l.main.menu_account()}</IonLabel>
                        </IonItem>
                        <IonItem routerLink={"/settings"} onClick={async () => await menuController.toggle()}>
                            <IonLabel>{lang.l.main.menu_settings()}</IonLabel>
                        </IonItem>
                        {this.state.extension &&
                        <IonItem routerLink={"/usb"} onClick={async () => await menuController.toggle()}>
                            <IonLabel>{lang.l.main.menu_usb()}</IonLabel>
                        </IonItem>}
                        {this.state.extension &&
                        <IonItem routerLink={"/modbusApp"} onClick={async () => await menuController.toggle()}>
                            <IonLabel>Modbus APP</IonLabel>
                        </IonItem>}
                        <IonItem routerLink={"/help"} onClick={async () => await menuController.toggle()}>
                            <IonLabel>{lang.l.main.menu_help()}</IonLabel>
                        </IonItem>
                        <IonItem button onClick={(ev:any) => this.setState({popoverState: {showPopover: true, event: ev.nativeEvent}})}>
                            <IonLabel>{lang.l.main.language()}</IonLabel>
                        </IonItem>
                        <IonPopover
                            event={popoverState.event}
                            isOpen={popoverState.showPopover}
                            onDidDismiss={() => this.setState({popoverState: {showPopover: false, event: undefined}})}
                        >
                            <IonList>
                                <IonListHeader>{lang.l.main.language()}</IonListHeader>
                                <IonItem button onClick={this.handleClickLanguage('es-ES')}>Español</IonItem>
                                <IonItem button onClick={this.handleClickLanguage('en-GB')}>English (GB)</IonItem>
                                {/* <IonItem button onClick={this.handleClickLanguage('fr-FR')}>Français</IonItem> */}
                                <IonItem lines="none" detail={false} button onClick={() => this.setState({popoverState: {showPopover: false, event: undefined}})}>
                                Close
                                </IonItem>
                            </IonList>
                        </IonPopover>
                        <IonItem routerLink={"/logout"}>
                            <IonLabel>{lang.l.main.menu_logout()}</IonLabel>
                        </IonItem>

                        {/*<IonList>*/}
                        {/*    {[0, 1, 2, 3, 4, 5].map((i) => Devices.renderItem(i))}*/}
                        {/*</IonList>*/}
                        <IonItem>
                            <IonLabel>{lang.l.main.menu_theme()}</IonLabel>
                            <IonToggle slot="end" onIonChange={this.handleThemeToggle}
                                       checked={this.props.store.isDarkMode()}></IonToggle>
                        </IonItem>
                    </IonList>
                </IonContent>
            </IonMenu>

            {/*-- the main content --*/}
            <IonPage id="menuContent">
                <IonContent>


                    <IonToast
                        htmlAttributes={{tabindex: undefined}}
                        isOpen={!!this.state.alarm}
                        onDidDismiss={() => this.setState({alarm: false})}
                        message={String(this.state.alarm)}
                        duration={5000}
                        position="top"
                    />

                    <IonTabs>
                        <IonRouterOutlet ref={this.routerOutlet}>
                            <Route path="/devices/:token" render={(p) => <Redirect to={"/add_device/" + p.match.params.token} />}/>
                            <Route path="/devices" component={Devices} exact />
                            <Route path="/add_device/:token?" component={AddDevices}/>
                            <Route path="/settings" component={Settings} />
                            <Route path="/invitation/:token" component={Invitation}/>
                            <Route path="/account" render={(props) => <UserAccount {...props} routerOutlet={this.routerOutlet.current!} />} exact />
                            <Route path="/account/invoices" component={UserAccountInvoices} exact />
                            <Route path="/test" component={TestPage}/>
                            <Route path="/usb/:port?" component={Configurator}/>
                            <Route path="/modbusApp" component={ModbusApp}/>
                            <Route key={"map"} exact path="/map" render={(props) => <DevicesMap {...props} routerOutlet={this.routerOutlet.current!} />}/>
                            <Route path="/map/route/:id" render={(props) => <MapRoute {...props} routerOutlet={this.routerOutlet.current!} />}/>
                            <Route path="/dashboard" render={() => <Dashboard routerOutlet={this.routerOutlet.current!} />} exact/>
                            {/*<Route path="/" exact={true} render={this.renderRedirect}/>*/}
                            <Route>
                                <IonButton routerLink={"/"} >{lang.l.main.tab_back()}</IonButton>
                            </Route>
                        </IonRouterOutlet>
                        <IonTabBar slot="bottom">
                            <IonTabButton tab="dashboard" href="/dashboard" onClick={this.handleTab('dashboard')}>
                                <IonIcon icon={apps}/>
                                <IonLabel>{lang.l.main.tab_dashboard()}</IonLabel>
                            </IonTabButton>
                            <IonTabButton tab="devices" href="/devices" onClick={this.handleTab('devices')}>
                                <IonIcon icon={albums}/>
                                <IonLabel>{lang.l.main.tab_devices()}</IonLabel>
                            </IonTabButton>
                            <IonTabButton tab="map" href="/map" onClick={this.handleTab('map')}>
                                <IonIcon icon={map}/>
                                <IonLabel>{lang.l.main.tab_map()}</IonLabel>
                            </IonTabButton>
                        </IonTabBar>
                    </IonTabs>
                </IonContent>
            </IonPage>
        </IonSplitPane>;
        return ionSplitPane
    }
}

export default withRouter(MainSplitPane);
