import { FunctionCode } from "../codes";

export default class ModbusRequestBody {
    private _fc: FunctionCode;


    constructor(fc:FunctionCode) {
        this._fc = fc;
    }


    /** Create byte representation.
     * @returns {Buffer}
     */
    createPayload () : Buffer {
        throw new Error('Not implemented yet.')
    }

    /** Returns the byte count of this request for the byte representation.
     * @returns {Number}
     */
    get byteCount () : number {
        throw new Error('No implemented yet.')
    }

    get name () : string {
        throw new Error('No implemented yet.')
    }

    get fc(): FunctionCode {
        return this._fc;
    }

    set fc(value: FunctionCode) {
        this._fc = value;
    }
}
