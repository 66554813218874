import Utils from "./Utils";

class CSV {
    endl = "";
    contents = "";

    constructor(endl = "\r\n") {
        this.endl = endl;
    }


    row(data:any[]) {
        data = data.map((a) => {
            if(a !== undefined && a !== null) return a.toString();
            return "";
        });
        this.contents += "\"" + data.join("\";\"").replace(/\./g, ",") + "\"" + this.endl;
    }

    download(name:string) {
        return Utils.downloadFile(this.contents, name, "text/csv;charset=utf-8");
    }


}

export default CSV;
