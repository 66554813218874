import {AutomationCondition, Device, DeviceOutput} from "./API";
import Expressions from "./Expressions";

export type Operand = {
    name: string,
    title: string,
    type: "decimal" | "boolean" | "separator",
    value: any,
}
export enum ConditionConnector {
    NONE,
    AND ,
    OR,
    GROUP_START,
    GROUP_END,
}

export class ConditionsValues {

    private device: Device;
    private operands: Operand[] = [];
    private expressions:Expressions;

    constructor(device: Device) {
        this.device = device;
        this.expressions = new Expressions(name => {
            return this.getOperandByName(name);
        });

        if(!device) return;
        if(!device.inputGroups) return;
        for (let i = 0; i < device.inputGroups.length; i++) {
            let input = device.inputGroups[i];
            this.operands.push({
                name: '',
                title: input.name,
                type: "separator",
                value: '',
            })
            for (let j = 0; j < input.inputs.length; j++) {
                let item = input.inputs[j];
                if (item.type === 'output') {
                    let output = this.getOutputByIndex(this.device.outputs, item.index);
                    this.operands.push({
                        name: 'input[' + item.id + ']',
                        title: "Relé " + ( output?output.name:item.name ),
                        type: output && output.type === "toggle"?"boolean":"decimal",
                        value: item.value,
                    })
                } else {

                    this.operands.push({
                        name: 'input[' + item.id + "]",
                        title: item.name,
                        type:"decimal",
                        value: item.value,
                    })
                }
            }
        }





    }

    private getOutputByIndex(outputs:DeviceOutput[], index:number) {
        for (let i = 0; i < outputs.length; i++) {
            if(outputs[i].oindex === index) return outputs[i];
        }
        return null;

    }

    private getSettings(json: string) {
        try {
            return JSON.parse(json);
        } catch (e) {
            return [];
        }

    }

    getOperands(): Operand[] {

        return this.operands;
    }

    getOperandByName(name: string) {
        for (let i = 0; i < this.operands.length; i++) {
            if(this.operands[i].name === name) return this.operands[i];
        }
    }

    getContext() {
        let result:any = {input: {}};

        for (let i = 0; i < this.device.inputGroups.length; i++) {
            let input = this.device.inputGroups[i];
            for (let j = 0; j < input.inputs.length; j++) {
                let item = input.inputs[j];
                result.input[item.id] = item.value;
            }
        }
        return result;

    }

    static operators = [
        {
            "name": "==",
            "title": "Igual",
            "types": ["decimal", "boolean"]
        },
        {
            "name": "!=",
            "title": "Diferente",
            "types": ["decimal", "boolean"]
        },
        {
            "name": ">",
            "title": "Mayor",
            "types": ["decimal"]
        },
        {
            "name": "<",
            "title": "Menor",
            "types": ["decimal"]
        },
        {
            "name": ">=",
            "title": "Mayor Igual",
            "types": ["decimal"]
        },
        {
            "name": "<=",
            "title": "Menor Igual",
            "types": ["decimal"]
        }
    ];

    // static get(name:string) {
    //     for (let i = 0; i < this.operands.length; i++) {
    //         if (this.operands[i].name === name) return this.operands[i];
    //     }
    // }

    static title(name: string) {
        for (let i = 0; i < this.operators.length; i++) {
            if (this.operators[i].name === name) return this.operators[i];
        }
    }

    type(name: string) {
        var item = this.getOperandByName(name);
        return (item ? item.type : "Desconocido *");
    }

    static supported(item: any, type: string) {
        return item.types.indexOf(type) !== -1;
    }

    static isCustomOperation(name:string) {
        return name.indexOf("$") >= 0;
    }



    toString(condition:AutomationCondition) {
        if(condition.left && condition.op && condition.right !== undefined) {
            let leftName = this.getOperandByName(condition.left);
            let opName = ConditionsValues.title(condition.op);
            let result = this.expressions.evaluate(condition, this.getContext());
            if (ConditionsValues.isCustomOperation(condition.left)) {
                let stack2 : any[] = [];
                let leftResults = this.expressions.parseExpresionToString(condition.left, stack2);
                return {left: leftResults, stack: stack2.pop(), op: opName?opName.title:condition.op, right: condition.right, result, connector: condition.connector};
            } else {
                return {left: leftName?leftName.title:condition.left, op: opName?opName.title:condition.op, right: condition.right, result, connector: condition.connector};
            }
        }
    }
}
