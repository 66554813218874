import React from "react";
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonFab,
    IonFabButton,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonListHeader,
    IonLoading,
    IonModal,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToggle,
    IonToolbar
} from "@ionic/react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { inject } from "mobx-react";
import { Store } from "../../service/Store";
import { DeviceOutput, DeviceOutputGroup, DeviceOutputProcess, ModbusType, ModbusValue, DeviceServiceOutput, Device } from "../../service/API";
import { add, closeOutline, cube, extensionPuzzle, eyeOff, reorderTwoOutline } from "ionicons/icons";
import ModbusEdit from "../../components/ModbusEdit";
import OutputServiceModal from "../../components/OutputServiceModal";

export interface DeviceSettingsOutputProps extends RouteComponentProps<{ id: string }> {
    store?: Store,
    routerOutlet: HTMLIonRouterOutletElement,
}
export type DeviceSettingsOutputState = {
    loading?: boolean,
    edit?: boolean,
    outputs?: DeviceOutput[],
    outputsGroups?: DeviceOutputGroup[],
    output?: DeviceOutput,
    outputsGroup?: DeviceOutputGroup,
    index?: number,
    processIndex?: number,
    editProcess?: boolean,
    process?: DeviceOutputProcess,
    outputServices?: DeviceServiceOutput[],

    addOutputService: boolean,
    editOutputService?: DeviceServiceOutput,
    device?: Device,
}

export const DefaultOutputGroup = { id: 0, name: '', expandable: false, expanded: true, visible: true, };
export const DefaultOutputService: DeviceServiceOutput = { id: 0, name: '', title: '', device_id: 0, settings: '', input_group: '', service_type: 'FieldStation2' };

@inject("store", "lang")
class DeviceSettingsOutput extends React.Component<DeviceSettingsOutputProps, DeviceSettingsOutputState> {

    state: DeviceSettingsOutputState = {
        edit: false,
        editProcess: false,
        addOutputService: false,
        outputsGroups: [],

    };
    componentDidMount(): void {
        this.loadDevice()
    }

    private getDeviceId() {
        let id = this.props.match?.params.id!;
        return Number(id);
    }
    loadDevice() {
        this.setState({ loading: true });
        this.props.store?.api.deviceGet(this.getDeviceId()).then(res => {
            if (res.success) {
                this.setState({ device: res.data });
                return Promise.all([
                    this.loadSettings(),
                    this.loadServices()
                ])
            }

        }).finally(() => {
            this.setState({ loading: false });
        })
    }

    loadServices() {
        return this.props.store!.api.deviceGetSettingsServiceOutput(this.getDeviceId()).then(value => {
            if (value.success) {
                this.setState({ outputServices: value.data });
            }
        })

    }
    loadSettings() {
        // let id = this.props.match?.params.id!;
        return this.props.store!.api.deviceGetSettingsOutput(this.getDeviceId()).then(value => {
            if (value.success) {
                return this.props.store?.api.deviceGetSettingsOutputGroups(this.getDeviceId()).then(value2 => {
                    this.setState({ outputs: value.data, outputsGroups: value2.data })
                });
            } else {
                return Promise.reject(value.error);
            }
        }).finally(() => this.setState({ loading: false }));
    }



    componentDidUpdate(prevProps: Readonly<DeviceSettingsOutputProps>, prevState: Readonly<DeviceSettingsOutputState>, snapshot?: any): void {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.loadDevice();
        }
    }

    handleSave = (ev: any) => {
        this.setState({ loading: true });
        const { history } = this.props;
        this.props.store?.api.deviceSettingsOutput(this.getDeviceId(), this.state.outputs!).finally(() => this.setState({ loading: false })).then(() => history.goBack());
    };

    handleChangeName = (output: DeviceOutput) => (ev: any) => {
        output.name = ev.detail.value;
        this.setState({});
    }
    handleChangeType = (output: DeviceOutput) => (ev: any) => {
        output.type = ev.detail.value;
        if (output.type === 'select') {
            if (output.values?.length === 0) {
                output.values?.push({ title: '', value: 0 });
            }
        }
        if (output.type === 'process') {
            if (output.process?.length === 0) {
                output.process?.push({ delay: 0 });
            }
        }
        this.setState({});
    }

    handleChangeTimeout = (output: DeviceOutput) => (ev: any) => {
        output.timeout = ev.detail.value;
        this.setState({});
    }
    handleChangeProcessDelay = (process: DeviceOutputProcess) => (ev: any) => {
        process.delay = ev.detail.value;
        this.setState({});
    }

    handleChangeProcessOutput = (process: DeviceOutputProcess) => (ev: any) => {
        process.exec_output_id = ev.detail.value;
        this.setState({});
    }

    handleChangeProcessState = (process: DeviceOutputProcess) => (ev: any) => {
        process.exec_output_state = ev.detail.value;
        this.setState({});
    }

    handleRemoveProcess = (ev: any) => {
        if (this.state.process && this.state.output && this.state.processIndex! >= 0) {
            this.state.output.process!.splice(this.state.processIndex!, 1);
            this.setState({ editProcess: false });
        }
    }

    handleChangeService = (output: DeviceOutput) => (ev: any) => {
        if (ev.detail.value === 'add') {

            this.setState({ editOutputService: DefaultOutputService })
            return;
        }
        output.output_service_id = ev.detail.value;
        this.setState({});
    }
    handleChangeIot2Operation = (output: DeviceOutput) => (ev: any) => {
        output.iot2operation = ev.detail.value;
        this.setState({});
    }
    handleChangeIot2Output = (output: DeviceOutput) => (ev: any) => {
        output.iot2output = ev.detail.value;
        this.setState({});
    }

    handleClick = (output: DeviceOutput) => (ev: any) => {
        this.setState({ output: { ...output }, index: this.state.outputs?.indexOf(output), edit: true });

    }

    handleClickProcess = (processIndex: number, process: DeviceOutputProcess) => (ev: any) => {
        this.setState({ editProcess: true, process, processIndex });
    }

    handleAddGroup = (ev: any) => {
        this.setState({ outputsGroup: DefaultOutputGroup })

    }

    handleAddService = (ev: any) => {
        this.setState({ editOutputService: DefaultOutputService })

    }

    handleEditGroup = (group: DeviceOutputGroup) => (ev: any) => {
        this.setState({ outputsGroup: group });
    }


    handleSaveOutputGroup = (ev: any) => {
        this.setState({ loading: true });
        this.props.store?.api.deviceSettingsOutputGroup(this.getDeviceId(), this.state.outputsGroup!).then(() => {
            this.loadDevice();
            this.setState({ outputsGroup: undefined });
        })
    }

    handleSaveServiceModal = (ev: any) => {
        this.setState({ loading: true });
        this.props.store?.api.deviceSettingsServiceOutput(this.getDeviceId(), this.state.editOutputService!).then((res1) => {
            if (this.state.output) {
                let output = this.state.output;
                output.output_service_id = res1.data.id;
            }
            this.loadServices().then(() => {

                this.setState({ loading: false, editOutputService: undefined });
            })
        })
    }

    handleRemoveGroup = (group: DeviceOutputGroup) => (ev: any) => {
        this.state.outputs!.map(o => {
            if (o.group_id === group.id) {
                o.group_id = null;
            }
            return o;
        });
        this.state.outputsGroups?.splice(this.state.outputsGroups.indexOf(group), 1);

        this.setState({ loading: true });
        this.props.store?.api.deviceSettingsOutputGroupDelete(this.getDeviceId(), group).then(() => {
            this.loadDevice();
            this.setState({ outputsGroup: undefined });
        })


    }

    handleAddSiri = async (ev: any) => {
        let device_id = this.props.match.params.id;
        let output_id = this.state.output?.oindex;
        let output_name = this.state.output?.name;
        if (this.state.output?.type === "fixed") {
            await this.props.store?.siri!.addToSiri(
                'output-' + device_id + "-" + output_id + "-on",
                'Activar ' + output_name
            )
        } else {
            await this.props.store?.siri!.addToSiri(
                'output-' + device_id + "-" + output_id + "-on",
                'Encender ' + output_name,
            )
            await this.props.store?.siri!.addToSiri(
                'output-' + device_id + "-" + output_id + "-off",
                'Apagar ' + output_name,
            )

        }

    }
    handleAddAppActions = async (ev: any) => {
        this.props.store?.appActions.add();

    }

    handleRemove = (ev: any) => {

        this.setState({ loading: true });
        this.props.store!.api.deviceDeleteSettingsOutput(this.getDeviceId(), this.state.output?.id!).then(value => {
            this.setState({ loading: false });
            if (value.success) {
                let outputs = this.state.outputs!;
                const i = this.state.index!;
                if (i >= 0) {
                    outputs.splice(i, 1);
                }
                this.setState({ edit: false, outputs });
            }
        })

    }

    handleAdd = (ev: any) => {
        if (!this.state.outputs) return;
        let oindex = 100;
        for (let i = 0; i < this.state.outputs!.length; i++) {
            if (oindex === this.state.outputs![i].oindex) {
                oindex++;
            }
        }

        let output: DeviceOutput = {
            id: 0,
            oindex: oindex,
            name: "",
            type: "toggle",
            timeout: 15,
            sending_log_id: 0,
            internal: 0,
            values: [],
            process: [],
            units: '',
            visible: 1,
            sound: false,
            off_value: 0,
            on_value: 1,
            on_name: 'ON',
            off_name: 'OFF',
            iot2operation: 0,
            iot2output: 0,

        }
        this.setState({ edit: true, output: output, index: -1 });
    }

    handleChangeOutputs = (ev: any) => {
        if (this.state.output && this.state.outputs) {
            let outputs = this.state.outputs;
            let i = this.state.index!;
            if (i >= 0) {
                outputs[i] = this.state.output; // Editando
            } else {
                outputs.push(this.state.output); // Añadiendo
            }
            this.props.store?.api.deviceSettingsOutput(this.getDeviceId(), this.state.outputs!).then(() => {
                this.loadDevice();

                this.setState({ edit: false });
            })

        }
    }
    handleChangeProcess = (ev: any) => {
        if (this.state.process && this.state.output && this.state.processIndex! >= 0) {
            this.state.output.process![this.state.processIndex!] = this.state.process;

            this.setState({ editProcess: false });
        }
    }

    handleChangeModbusValue = (index: number, values: ModbusValue[]) => (ev: any) => {

        values![index] = { value: ev.detail.value }
    }

    renderModbusWriteValues(modbus: ModbusType, valuesOn: ModbusValue[], valuesOff: ModbusValue[]) {
        let res = [];
        for (let index = 0; index < modbus.registers; index++) {
            res.push(<IonItem>
                <IonLabel>Registro {Number(modbus.address) + index}: </IonLabel>
                <IonInput placeholder="Valor ON" onIonInput={this.handleChangeModbusValue(index, valuesOn)} value={valuesOn![index]?.value}></IonInput>
                <IonInput placeholder="Valor OFF" onIonInput={this.handleChangeModbusValue(index, valuesOff)} value={valuesOff![index]?.value}></IonInput>
            </IonItem>)

        }
        return res;
    }

    renderModbusWrite() {
        const { output } = this.state;
        if (!output?.modbusValuesOn) {
            this.state.output!.modbusValuesOn = [];
        }
        if (!output?.modbusValuesOff) {
            this.state.output!.modbusValuesOff = [];
        }
        return <>

            <ModbusEdit routerOutlet={this.props.routerOutlet} modbus={output?.modbusWrite} type={'output'} onSave={modbus => this.state.output && this.setState({ output: { ...this.state.output, modbusWrite: modbus } })}>

            </ModbusEdit>




            {output?.modbusWrite && output?.type === 'toggle' && output?.modbusWrite?.registers! > 1 && <>

                <IonItem>
                    <IonLabel>Valores de escritura múltiple</IonLabel>
                    <IonLabel>ON</IonLabel>
                    <IonLabel>OFF</IonLabel>
                </IonItem>
                {this.renderModbusWriteValues(output?.modbusWrite, output?.modbusValuesOn!, output?.modbusValuesOff!)}

            </>}
        </>
    }
    renderExternalOutput() {

        const { output } = this.state;
        if (!output?.modbusValuesOn) {
            this.state.output!.modbusValuesOn = [];
        }
        if (!output?.modbusValuesOff) {
            this.state.output!.modbusValuesOff = [];
        }
        return <>

            <IonItem>
                <IonInput label="Unidades" labelPlacement="floating" placeholder={"Unidades"} onIonInput={(ev: any) => this.state.output && this.setState({ output: { ...this.state.output, units: ev.detail.value } })} value={this.state.output?.units}></IonInput>
            </IonItem>
            <IonItem>
                <IonSelect label="Tipo" labelPlacement="floating" value={output?.type} placeholder="Tipo" onIonChange={this.handleChangeType(output!)}>
                    <IonSelectOption value={'toggle'}>Tipo ON/OFF</IonSelectOption>
                    <IonSelectOption value={'value'}>Valor numérico</IonSelectOption>
                    <IonSelectOption value={'fixed'}>Valor fijo</IonSelectOption>
                    <IonSelectOption value={'select'}>Valor seleccionable</IonSelectOption>
                    <IonSelectOption value={'process'}>Proceso</IonSelectOption>
                    <IonSelectOption value={'panel'}>Panel Servicio</IonSelectOption>
                    <IonSelectOption value={'input'}>Mostrar valor de entrada</IonSelectOption>
                </IonSelect>
            </IonItem>
            {output?.type === 'value' && <IonItem>
                <IonIcon icon={closeOutline} />
                <IonInput labelPlacement="floating" placeholder={"Factor multiplicativo"} onIonInput={(ev: any) => this.state.output && this.setState({ output: { ...this.state.output, factor: ev.detail.value } })} value={this.state.output?.factor}></IonInput>
            </IonItem>}
            {output?.type === 'select' && <>
                {output.values?.map((value, index) => {
                    return <IonItem>
                        <IonInput placeholder="Nombre" value={value.title} onIonInput={(ev: any) => value.title = ev.detail.value}></IonInput>
                        <IonInput placeholder="Valor" value={value.value} onIonInput={(ev: any) => value.value = ev.detail.value}></IonInput>
                        <IonButton onClick={(ev: any) => output.values?.splice(index, 1) && this.setState({})}><IonIcon icon={closeOutline}></IonIcon></IonButton>
                    </IonItem>
                })}
                <IonItem>
                    <IonButton onClick={((ev: any) => output.values?.push({ title: '', value: 0 }) && this.setState({}))}>Añadir</IonButton>

                </IonItem>

            </>}
            {output?.type === 'process' && <>
                {output.process?.map((value, index) => {
                    return <IonItem key={index} onClick={this.handleClickProcess(index, value)} button detail={true}>
                        <IonText>{value.exec_output_id! >= 0 ? "SALIDA" : "(Nada)"}</IonText>
                    </IonItem>
                })}
                <IonItem>
                    <IonButton onClick={((ev: any) => output.process?.push({ delay: 0 }) && this.setState({}))}>Añadir</IonButton>
                </IonItem>

            </>}
            {output?.type === 'fixed' && <IonItem>
                <IonIcon icon={reorderTwoOutline} />
                <IonInput placeholder={"Valor de escritura"} onIonInput={(ev: any) => this.state.output && this.setState({ output: { ...this.state.output, fixed_value: ev.detail.value } })} value={this.state.output?.fixed_value}></IonInput>
            </IonItem>}
            {output?.type === 'input' && <>
                <IonItem>
                    <IonSelect label="Seleccionar entrada a mostrar" labelPlacement="floating" interface={"action-sheet"} onIonChange={(ev: any) => this.state.output && this.setState({ output: { ...this.state.output, input_id: ev.detail.value } })} value={this.state.output?.input_id}>
                        {this.state.device?.inputGroups.map((value, index) => {
                            return value.inputs.map((value2, index2) => {
                                return <IonSelectOption value={value2.id}>{value.name} - {value2.name}</IonSelectOption>
                            })
                        })}
                    </IonSelect>

                </IonItem>
            </>}
            {output?.type === 'panel' && <>

            </>
            }

            <IonItem>
                <IonSelect labelPlacement="floating" label="Servicio" value={output?.output_service_id} placeholder="Utilizar el servicio" onIonChange={this.handleChangeService(output!)}>
                    <IonSelectOption value={undefined}>(Desactivado)</IonSelectOption>
                    {this.state.outputServices && this.state.outputServices.map((v, k) => {
                        return <IonSelectOption key={k} value={v.id}>{v.title}</IonSelectOption>
                    })}

                    <IonSelectOption value={'add'}>+ Añadir</IonSelectOption>
                </IonSelect>
            </IonItem>
            {this.renderModbusWrite()}

            <ModbusEdit routerOutlet={this.props.routerOutlet} modbus={output?.modbusRead} type={'input'} onSave={modbus => this.state.output && this.setState({ output: { ...this.state.output, modbusRead: modbus } })}>
                <IonItem>
                    <IonLabel>Función MODBUS de escritura</IonLabel>
                </IonItem>
            </ModbusEdit>
        </>
    }
    renderInternalOutput() {

        return <>
            {this.renderModbusWrite()}
        </>


    }

    renderOperationsOutput() {
        const { output } = this.state;
        if (!output) return;
        return <>
            <IonItemDivider>Configuración Operaciones Salida</IonItemDivider>
            <IonItem>
                <IonSelect label="Operacion" value={output.iot2operation} onIonChange={this.handleChangeIot2Operation(output)}>
                    <IonSelectOption value={0}>(Desactivado)</IonSelectOption>
                    <IonSelectOption value={1}>Reiniciar Contador de Pulsos Parcial</IonSelectOption>
                    <IonSelectOption value={2}>Reiniciar Contador de Pulsos Total</IonSelectOption>
                    <IonSelectOption value={3}>Establecer Límite de Pulsos Parcial</IonSelectOption>
                    <IonSelectOption value={4}>Establecer Límite de Pulsos Total</IonSelectOption>
                </IonSelect>
            </IonItem>
            <IonItem>
                <IonSelect label="Número de salida" value={output.iot2output} onIonChange={this.handleChangeIot2Output(output)}> 
                    <IonSelectOption value={0}>Contador 0</IonSelectOption>
                    <IonSelectOption value={1}>Contador 1</IonSelectOption>
                    <IonSelectOption value={2}>Contador 2</IonSelectOption>
                    <IonSelectOption value={3}>Contador 3</IonSelectOption>
                    <IonSelectOption value={4}>Contador 4</IonSelectOption>
                    <IonSelectOption value={5}>Contador 5</IonSelectOption>
                    <IonSelectOption value={6}>Contador 6</IonSelectOption>
                    <IonSelectOption value={7}>Contador 7</IonSelectOption>
                </IonSelect>
            </IonItem>
        </>
    }

    renderOutputEditor() {
        const { output } = this.state;
        if (!output) return;

        return <>
            <IonList>
                <IonItem>
                    <IonInput label="Nombre" labelPlacement="floating" value={output.name} onIonInput={this.handleChangeName(output)}></IonInput>
                </IonItem>
                <IonItem>
                    <IonInput label="Tiempo de espera (minutos)" labelPlacement="floating" value={output.timeout} onIonInput={this.handleChangeTimeout(output)}></IonInput>
                </IonItem>
                <IonItem>
                    <IonInput label="Índice de salida" labelPlacement="floating" value={output.oindex} type={"number"} onIonInput={(e) => this.setState({ output: { ...this.state.output!, oindex: parseInt(e.detail.value!) } })}></IonInput>
                </IonItem>
                <IonItem>
                    <IonToggle checked={output.visible === 1} onIonChange={(e) => this.setState({ output: { ...this.state.output!, visible: e.detail.checked ? 1 : 0 } })}>
                        Visible en control
                    </IonToggle>
                </IonItem>

                <IonItem>
                    <IonToggle checked={output.sound === true} onIonChange={(e) => this.setState({ output: { ...this.state.output!, sound: e.detail.checked } })}>
                        Sonido en notificaciones
                    </IonToggle>
                </IonItem>
                <IonItem>
                    <IonSelect label="Grupo de salidas" value={output.group_id} onIonChange={(e) => this.setState({ output: { ...this.state.output!, group_id: e.detail.value } })}>

                        <IonSelectOption value={null}>(Por defecto)</IonSelectOption>
                        {this.state.outputsGroups?.map((value, key) => {
                            return <IonSelectOption value={value.id}>{value.name}</IonSelectOption>
                        })}
                    </IonSelect>
                </IonItem>



                {!!output.internal || this.renderExternalOutput()}
                {!!output.internal && this.renderInternalOutput()} {/*Doble !! para evitar que muestre por pantalla un 0 */}
                {this.state.device?.protocol === 'IotModbus2' && this.renderOperationsOutput()}

                {output?.type === 'toggle' && <>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                            </IonCol>
                            <IonCol>
                                <IonLabel>ON</IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonLabel>OFF</IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonLabel>Valor</IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonInput placeholder={"Valor ON (1)"} onIonInput={(ev: any) => this.state.output && this.setState({ output: { ...this.state.output, on_value: ev.detail.value } })} value={this.state.output?.on_value}></IonInput>
                            </IonCol>
                            <IonCol>
                                <IonInput placeholder={"Valor OFF (0)"} onIonInput={(ev: any) => this.state.output && this.setState({ output: { ...this.state.output, off_value: ev.detail.value } })} value={this.state.output?.off_value}></IonInput>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>

                                <IonLabel>Representación</IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonInput placeholder={"Nombre para ON"} onIonInput={(ev: any) => this.state.output && this.setState({ output: { ...this.state.output, on_name: ev.detail.value } })} value={this.state.output?.on_name}></IonInput>
                            </IonCol>
                            <IonCol>
                                <IonInput placeholder={"Nombre para OFF"} onIonInput={(ev: any) => this.state.output && this.setState({ output: { ...this.state.output, off_name: ev.detail.value } })} value={this.state.output?.off_name}></IonInput>
                            </IonCol>
                        </IonRow>

                    </IonGrid>

                </>}
                <IonItem hidden={!this.props.store!.siri || !this.props.store!.siri!.available} detail={false} color={"success"} button onClick={this.handleAddSiri}>
                    <IonLabel>Añadir a Siri</IonLabel>
                </IonItem>
                <IonItem hidden={!this.props.store!.appActions || !this.props.store!.appActions!.available} detail={false} color={"success"} button onClick={this.handleAddAppActions}>
                    <IonLabel>Añadir a Google</IonLabel>
                </IonItem>

                <IonItem detail={false} button color={"danger"} onClick={this.handleRemove}>
                    <IonLabel>Eliminar</IonLabel>
                </IonItem>
            </IonList>

            {/*<IonText className={"ion-padding"}>(1) Tiempo que el sistema esperará para cambiar la salida si el dispositivo no está en línea.</IonText>*/}

        </>
    }

    renderProcessEditor() {
        const { process } = this.state;
        if (!process) return;


        return <>
            <IonList>
                <IonItem>
                    <IonInput label="Tiempo de espera (segundos)" labelPlacement="floating" value={process.delay} onIonInput={this.handleChangeProcessDelay(this.state.process!)}></IonInput>
                </IonItem>
                <IonItem>
                    <IonSelect labelPlacement="floating" label="Salida" value={process.exec_output_id} onIonChange={this.handleChangeProcessOutput(this.state.process!)}>
                        {this.state.outputs?.filter(o => o.type !== 'process').map((value, key) => {
                            return <IonSelectOption value={value.id}>{value.name}</IonSelectOption>
                        })}
                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonInput label="Valor" labelPlacement="floating" value={process.exec_output_state} onIonInput={this.handleChangeProcessState(this.state.process!)}></IonInput>
                </IonItem>

                <IonItem detail={false} button color={"danger"} onClick={this.handleRemoveProcess}>
                    <IonLabel>Eliminar</IonLabel>
                </IonItem>
            </IonList>
        </>
    }
    handleChangeGroupValue = (group: any, key: string) => (ev: any) => {
        if (ev.detail.checked !== undefined) {
            group[key] = ev.detail.checked;

        } else {
            group[key] = ev.detail.value;
        }
    }

    handleServiceOutputClose = (result: boolean) => {
        this.setState({ editOutputService: undefined });
        if (result) {
            this.handleSaveServiceModal(null)
        }
    }
    renderGroupEditor() {
        const { outputsGroup } = this.state;
        if (!outputsGroup) return;


        return <>
            <IonList>
                <IonItem>
                    <IonInput label="Nombre" labelPlacement="floating" value={outputsGroup.name} onIonInput={this.handleChangeGroupValue(outputsGroup, 'name')}></IonInput>
                </IonItem>
                <IonItem>
                    <IonToggle checked={outputsGroup.visible} onIonChange={this.handleChangeGroupValue(outputsGroup, 'visible')}>
                        Visible en control
                    </IonToggle>
                </IonItem>
                <IonItem>
                    <IonToggle checked={outputsGroup.expandable} onIonChange={this.handleChangeGroupValue(outputsGroup, 'expandable')}>
                        Permitir desplegar
                    </IonToggle>
                </IonItem>
                <IonItem>
                    <IonToggle checked={outputsGroup.expanded} onIonChange={this.handleChangeGroupValue(outputsGroup, 'expanded')}>Desplegado</IonToggle>
                </IonItem>

                <IonItem detail={false} button color={"danger"} onClick={this.handleRemoveGroup(outputsGroup)}>
                    <IonLabel>Eliminar</IonLabel>
                </IonItem>
            </IonList>
        </>
    }

    getGroups() {
        let default1: DeviceOutputGroup = DefaultOutputGroup;
        if (this.state.outputsGroups!) {
            return [default1, ...this.state.outputsGroups!];
        }
        return [];

    }
    getOutputs(group: DeviceOutputGroup) {
        return this.state.outputs?.filter(a => !group.id ? !a.group_id : a.group_id === group.id);
    }


    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let id = this.props.match?.params.id!;


        if (!this.state) return "";
        let groups = this.getGroups();
        return <IonPage>

            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={"/device/" + id + "/settings"} />
                    </IonButtons>
                    <IonTitle>Salidas</IonTitle>

                    <IonButtons slot="primary">
                        <IonButton onClick={this.handleSave}>Guardar</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    {this.state && this.state.outputs && groups && groups.map(group => {
                        let outputs = this.getOutputs(group);
                        return <>
                            <IonListHeader>
                                <IonLabel>
                                    {group.name && <>{group.name} <IonButton onClick={this.handleEditGroup(group)}>Editar</IonButton></>}
                                    {(!!group.name) || <>Grupo por defecto <IonButton onClick={this.handleAddGroup}>Añadir</IonButton></>}
                                </IonLabel>
                            </IonListHeader>
                            {outputs?.map(value => {
                                return <IonItem detail button onClick={this.handleClick(value)}>
                                    <IonIcon icon={value.internal ? cube : extensionPuzzle} slot="start" />

                                    <IonLabel>
                                        {!value.visible && <IonIcon icon={eyeOff}></IonIcon>}

                                        {value.name}
                                    </IonLabel>

                                </IonItem>
                            })}

                            {/*<IonListHeader>*/}
                            {/*    <IonLabel>Salida Relé {value.oindex + 1}</IonLabel>*/}
                            {/*</IonListHeader>*/}

                        </>
                    })}
                </IonList>

                <IonModal isOpen={this.state.edit ? true : false}
                    onDidDismiss={() => this.setState({ edit: false })}>
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>Editar Salida</IonTitle>
                            <IonButtons slot={"secondary"}>
                                <IonButton onClick={() => this.setState({ edit: false })}>Cancelar</IonButton>
                            </IonButtons>
                            <IonButtons slot={"primary"}>
                                <IonButton onClick={this.handleChangeOutputs}>OK</IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>

                    <IonContent>
                        {this.renderOutputEditor()}
                    </IonContent>

                </IonModal>
                <IonModal isOpen={this.state.editProcess! ? true : false}
                    onDidDismiss={() => this.setState({ editProcess: false })}>
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>Editar Proceso</IonTitle>
                            <IonButtons slot={"secondary"}>
                                <IonButton onClick={() => this.setState({ editProcess: false })}>Cancelar</IonButton>
                            </IonButtons>
                            <IonButtons slot={"primary"}>
                                <IonButton onClick={this.handleChangeProcess}>OK</IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>

                    <IonContent>
                        {this.renderProcessEditor()}
                    </IonContent>

                </IonModal>
                <IonModal isOpen={this.state.outputsGroup! ? true : false}
                    onDidDismiss={() => this.setState({ outputsGroup: undefined })}>
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>Editar Grupo</IonTitle>
                            <IonButtons slot={"secondary"}>
                                <IonButton onClick={() => this.setState({ outputsGroup: undefined })}>Cancelar</IonButton>
                            </IonButtons>
                            <IonButtons slot={"primary"}>
                                <IonButton onClick={this.handleSaveOutputGroup}>OK</IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>

                    <IonContent>
                        {this.renderGroupEditor()}
                    </IonContent>

                </IonModal>
                <OutputServiceModal editOutputService={this.state.editOutputService} inputGroups={this.state.device && this.state.device.inputGroups} onClose={this.handleServiceOutputClose} />
                {/* <IonModal isOpen={this.state.editOutputService! ? true : false}
                    onDidDismiss={() => this.setState({ editOutputService: undefined })}>
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>Nuevo Servicio Externo</IonTitle>
                            <IonButtons slot={"secondary"}>
                                <IonButton onClick={() => this.setState({ editOutputService: undefined })}>Cancelar</IonButton>
                            </IonButtons>
                            <IonButtons slot={"primary"}>
                                <IonButton onClick={this.handleSaveServiceModal}>OK</IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>

                    <IonContent>
                        {this.renderServiceEditor()}
                    </IonContent>

                </IonModal> */}
                <IonLoading
                    isOpen={this.state.loading!}
                    message={'Cargando...'}
                />

                <IonFab vertical="bottom" horizontal="end" slot="fixed">
                    <IonFabButton onClick={this.handleAdd}>
                        <IonIcon icon={add} />
                    </IonFabButton>
                </IonFab>
            </IonContent>
        </IonPage>
    }

}

export default withRouter(DeviceSettingsOutput);
