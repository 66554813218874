import React from "react";
import {DeviceInputGroup, ServiceSettings} from "../service/API";
import {IonInput, IonItem, IonLabel, IonSelect, IonSelectOption, IonText} from "@ionic/react";

type ServiceProp = {
    name: keyof ServiceSettings,
    title: string,
    type: 'text' | 'password',

}
type Service = {
    name: string,
    title: string,
    props: ServiceProp[],

}

export type ServiceEditorProps = {
    inputGroup: DeviceInputGroup,
    onChange: (name: string, value: any) => void,
}
export type ServiceEditorState = {
    settings?: ServiceSettings,
}
export default class ServiceEditor extends React.Component<ServiceEditorProps, ServiceEditorState> {

    private readonly services:Service[] = [
        {
            name: 'demo',
            title: 'Tiempo',
            props: [
                {
                    name: 'location',
                    title: 'Ubicación',
                    type: 'text',
                }
            ]
        },
        {
            name: 'deepfield',
            title: 'Bosch - Deepfield Monitoring',
            props: [
                {
                    name: 'user',
                    title: 'Nombre Usuario',
                    type: 'text',
                }, {
                    name: "pass",
                    title: "Contraseña",
                    type: "password",
                }
            ]
        }
    ]

    getService(name:string) : Service | false {
        for (let i = 0; i < this.services.length; i++) {
            if(this.services[i].name === name) return this.services[i];
        }
        return false;
    }

    getSettings() {
        return (this.props.inputGroup.serviceSettings! || {}) as ServiceSettings;
    }
    handleSettingsServiceChange = (name: string) => (ev: CustomEvent) => {
        this.props.onChange(name, ev.detail.value);
    }

    renderPropControl(prop:ServiceProp) {
        let settings = this.getSettings();
        if(prop.type === 'text') {
            return <IonInput labelPlacement="stacked" label={prop.title} onIonInput={this.handleSettingsServiceChange(prop.name)} value={settings[prop.name]}></IonInput>;
        } else if(prop.type === 'password') {
            return <IonInput labelPlacement="stacked" label={prop.title}  onIonInput={this.handleSettingsServiceChange('pass')} value={settings.pass}
                      placeholder={"(Sin cambiar)"} type={"password"}/>
        }
    }
    renderProp = (prop:ServiceProp) => {
        return <IonItem>
            {this.renderPropControl(prop)}
        </IonItem>

    }

    render() {
        let settings = this.getSettings();
        let service = this.getService(settings.name);
        return <>
            <IonItem>
                <IonLabel>Tipo de servicio</IonLabel>

                <IonSelect value={settings.name} placeholder="Tipo de servicio"
                           onIonChange={this.handleSettingsServiceChange('name')}>
                    {this.services && this.services.map((value, key) => {
                        return <IonSelectOption value={value.name} key={key}>{value.title}</IonSelectOption>

                    })}
                </IonSelect>

            </IonItem>

            {settings.error && <IonItem color={"danger"}>
                <IonLabel>Error en el servicio</IonLabel>
                <IonText>{settings.error}</IonText>
            </IonItem>}
            {service && service.props.map(this.renderProp)}
            <IonItem>
                <IonLabel>Periodo de actualización</IonLabel>

                <IonSelect value={settings.interval} placeholder="Comprobar cada"
                           onIonChange={this.handleSettingsServiceChange('interval')}>
                    <IonSelectOption value={0}>Desactivar</IonSelectOption>
                    <IonSelectOption value={30}>30 minutos</IonSelectOption>
                    <IonSelectOption value={60}>1 hora</IonSelectOption>
                    <IonSelectOption value={60 * 8}>8 horas</IonSelectOption>
                    <IonSelectOption value={60 * 24}>24 horas</IonSelectOption>
                </IonSelect>

            </IonItem>
        </>;
    }
}
