import {ConfigurationSection} from "../../pages/Configurator";
import ModbusRTUClient from "../rtu/ModbusRTUClient";

export enum MemoryAddress {
    BASIC_SETTINGS,
    VERSION_MODEL,
    GPRS_ENABLE,
    GPRS_APN_CONNECT,
    GPRS_APN_SET,
    GPRS_DOMAIN_ENABLE,

    GPRSINFO_ADDRESS,
    GPRSIP_ADDRESS,
    GPRSAPN_ADDRESS,
    GPRSUSER_ADDRESS,
    GPRSPWD_ADDRESS,
    GPRSDATAPACK_ADDRESS,
    GPRSPACKREG_ADDRESS,
    GPRSPACKREGRESP_ADDRESS,
    GPRSPACKDISCONN_ADDRESS,
    GPRSPACKHEART_ADDRESS,
    GPRSPACKHEARTRESP_ADDRESS,

    PERIODIC_TIMER,
    PERIODIC_TIMER_SAVE,

    RS485,
    SERIAL_NUMBER,

    DIN_SETTINGS,
    DIN_VALUES_SETTINGS,
    
    A_DEV_INFO,

    MEMORY_SIZE
}
export default class MemoryMapper {
    private addr: any = {};

    set(key:MemoryAddress, addr: number) {
        this.addr[key] = addr;
    }

    get(key:MemoryAddress) : number {
        if(this.addr[key] === undefined) throw new Error("Invalid memory key: " + key)
        return this.addr[key];
    }

    has(key:MemoryAddress) : boolean {
        return (this.addr[key] !== undefined);
    }


    getSections() : ConfigurationSection[] {
        return [];
    };

    public async setDefaultConfig(rtu:ModbusRTUClient) {};
}
