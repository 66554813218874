import App from './App';
import * as serviceWorker from './serviceWorker';
import {defineCustomElements} from '@ionic/pwa-elements/loader';
import {observer, Provider} from 'mobx-react'
import {Store} from "./service/Store";
import {Elements} from "@stripe/react-stripe-js";
import Lang from "./service/Lang";
import TypesafeI18n from './i18n/i18n-react';
import { SplashScreen } from '@capacitor/splash-screen';
import { Buffer } from 'buffer';
import 'swiper/scss'
import { createRoot } from 'react-dom/client';
import Theme from './theme';
// import RemoteSupportService from './service/RemoteSupportService';

// @ts-ignore
window.Buffer = Buffer;
const lang = new Lang();

const store = new Store();

const theme = Theme.detectFromHost();

const ProviderView = observer(({lang, location}:any) => <Provider store={store} lang={lang} theme={theme}><TypesafeI18n initialLocale='es-ES'><App /></TypesafeI18n></Provider>)

let hide = false;
const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
store.startRemoteSupport(root);

root.render(<Elements stripe={store.stripe}><ProviderView location={lang.location.language} lang={lang} /></Elements>);
if (!hide) {
    SplashScreen.hide({ fadeOutDuration: 300 });
    hide = true;
}

// Call the element loader after the app has been rendered the first time
defineCustomElements(window);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
    onUpdate: async (registration: ServiceWorkerRegistration) => {
        await registration.update();
    }
});