import React from "react";
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonText,
    IonTitle,
    IonToolbar
} from "@ionic/react";


export interface TaxEditorProps {
    onEdit: (result:boolean, data:any) => void;
    values?:any;
}
export interface TaxEditorState {

}
export default class TaxEditor extends React.Component<TaxEditorProps, TaxEditorState> {
    private nombreApellidos: React.RefObject<any>;
    private razonSocial: React.RefObject<any>;
    private direccion: React.RefObject<any>;
    private localidad: React.RefObject<any>;
    private provincia: React.RefObject<any>;
    private codigoPostal: React.RefObject<any>;
    private nifCif: React.RefObject<any>;
    private telefono: React.RefObject<any>;


    constructor(props: TaxEditorProps, context: any) {
        super(props, context);
        this.nombreApellidos = React.createRef();
        this.razonSocial = React.createRef();
        this.direccion = React.createRef();
        this.localidad = React.createRef();
        this.provincia = React.createRef();
        this.codigoPostal = React.createRef();
        this.nifCif = React.createRef();
        this.telefono = React.createRef();
    }

    handleSetupTax = (result:boolean) => (ev:any) => {
        let data = {
            nombreApellidos: this.nombreApellidos.current.value,
            razonSocial: this.razonSocial.current.value,
            direccion: this.direccion.current.value,
            localidad: this.localidad.current.value,
            provincia: this.provincia.current.value,
            codigoPostal: this.codigoPostal.current.value,
            nifCif: this.nifCif.current.value,
            telefono: this.telefono.current.value,
        }
        this.props.onEdit(result, data);
    };


    handleChange() {
        if(this.props.values) {
            let item = this.props.values;
            this.nombreApellidos.current.value = item.customer.name;
            this.razonSocial.current.value = item.customer.description;
            this.direccion.current.value = item.customer.address.line1;
            this.localidad.current.value = item.customer.address.city;
            this.provincia.current.value = item.customer.address.state;
            this.codigoPostal.current.value = item.customer.address.postal_code;
            this.telefono.current.value = item.customer.phone;
            this.nifCif.current.value = item.tax;
        }
    }

    componentDidUpdate(prevProps: Readonly<TaxEditorProps>, prevState: Readonly<TaxEditorState>, snapshot?: any): void {
        this.handleChange();
    }

    componentDidMount(): void {
        this.handleChange();
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return <>

            <IonHeader>
                <IonToolbar>
                    <IonTitle>Configurar facturación</IonTitle>
                    <IonButtons slot={"secondary"}>
                        <IonButton onClick={this.handleSetupTax(false)}>Cerrar</IonButton>
                    </IonButtons>
                    <IonButtons slot={"primary"}>
                        <IonButton onClick={this.handleSetupTax(true)}>Aceptar</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonItem>
                    <IonText>Estos son los datos que utilizaremos para emitir facturar a su nombre.</IonText>
                </IonItem>
                <IonItem>
                    <IonInput label="Nombre y Apellidos" name={"nombreApellidos"} ref={this.nombreApellidos}></IonInput>
                </IonItem>
                <IonItem>
                    <IonInput label="Razón social" name={"razonSocial"} ref={this.razonSocial} ></IonInput>
                </IonItem>
                <IonItem>
                    <IonInput label="Dirección" name={"direccion"} ref={this.direccion} ></IonInput>
                </IonItem>
                <IonItem>
                    <IonInput label="Localidad" name={"localidad"} ref={this.localidad} ></IonInput>
                </IonItem>
                <IonItem>
                    <IonInput label="Provincia" name={"provincia"} ref={this.provincia} ></IonInput>
                </IonItem>
                <IonItem>
                    <IonInput label="Código Postal" name={"codigoPostal"} ref={this.codigoPostal} ></IonInput>
                </IonItem>
                <IonItem>
                    <IonInput label="Teléfono" name={"telefono"} ref={this.telefono} ></IonInput>
                </IonItem>
                <IonItem>
                    <IonInput label="NIF/CIF" name={"nifCif"} ref={this.nifCif} ></IonInput>
                </IonItem>
            </IonContent>
        </>

    }
}
