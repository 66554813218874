import React, {RefObject} from "react";
import {
    IonActionSheet,
    IonBadge,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonFab,
    IonFabButton,
    IonGrid,
    IonHeader,
    IonIcon, IonInput, IonItem, IonItemDivider, IonLabel, IonList,
    IonListHeader,
    IonLoading,
    IonMenuButton, IonModal,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonSpinner, IonText,
    IonTextarea,
    IonTitle,
    IonToast,
    IonToolbar
} from "@ionic/react";

import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

import {add, camera, checkmarkOutline, close, heart, layers, navigateOutline, pin, pinOutline, stop, trashBin} from 'ionicons/icons';
import {Device, Route, SimpleDevice} from "../service/API";
import {inject} from "mobx-react";
import {Store} from "../service/Store";
import EventBox from "../service/EventBox";
import {RouteComponentProps} from "react-router-dom";
import {FeatureGroup, LayersControl, Marker, Tooltip} from "react-leaflet";
import {FeatureGroup as FeatureGroupType, LeafletMouseEvent} from "leaflet";
import Utils from "../service/Utils";
import "./DevicesMap.css"
import L from 'leaflet'
import Geolocation from "../service/Geolocation";
import Lang from "../service/Lang";
import { Filesystem } from "@capacitor/filesystem";
import moment from "moment-timezone";
import Maps, { MapsMapType } from "../components/Maps";




export const onlineIcon = new L.Icon({
    iconUrl: 'assets/marker/markerOnline.svg',
    iconRetinaUrl: 'assets/marker/markerOnline.svg',
    iconAnchor: [30, 55],
    popupAnchor: [-3, -54],
    iconSize: [55, 55],
    shadowUrl: 'assets/marker/marker-shadow.png',
    shadowSize: [80, 80],
    shadowAnchor: [28, 80],
    tooltipAnchor: [-3, -55],
})
export const offlineIcon = new L.Icon({
    iconUrl: 'assets/marker/markerOffline.svg',
    iconRetinaUrl: 'assets/marker/markerOffline.svg',
    iconAnchor: [30, 55],
    popupAnchor: [-3, -54],
    iconSize: [55, 55],
    shadowUrl: 'assets/marker/marker-shadow.png',
    shadowSize: [80, 80],
    shadowAnchor: [28, 80],
    tooltipAnchor: [-3, -55],
})
export type AttachmentMedia = {
    // Display URL
    url: string,

    // Media Blob Data
    blob: Blob,
}


export interface DevicesMapProps extends RouteComponentProps<{ token?: string }> {
    store?: Store,
    lang?: Lang,
    routerOutlet?:HTMLIonRouterOutletElement,
}

export interface DevicesMapState {
    devices: SimpleDevice[],
    loading: boolean,
    error: boolean,


    selectedLoc?: any;
    clickMenu?: boolean;
    setDeviceLocation?: boolean,

    showDeviceInfo?: boolean;
    addRoute?: boolean;
    routeName: string
    poiName: string
    poiNotes: string
    poiAttachments: AttachmentMedia[]

    deviceInfo?: Device;
    userLocation?: any;
    clickLocation?: any;
    clickLocationToast?: any;

    routeTracking: boolean;
    addPoi: boolean;
    manageLayers: boolean;

    positions: any[];
    mapType: MapsMapType;
    routes : Route[],
}

type RoutePOIType = {
    title: string
}

@inject("store", "lang")
class DevicesMap extends React.Component<DevicesMapProps, DevicesMapState> {
    events?: EventBox;
    state: DevicesMapState = {
        
        error: false,
        loading: false,
        devices: [],
        routeName: "",
        routeTracking: false,
        positions: [],
        clickLocationToast: false,
        addPoi: false,
        poiAttachments: [],
        poiName: "",
        poiNotes: "",
        manageLayers: false,
        mapType: 'hybrid',
        routes: [],
    }

    private map?: L.Map = undefined;
    private groupRef: RefObject<FeatureGroupType<any>>;
    private group1Ref: RefObject<FeatureGroupType<any>> = React.createRef();
    private userLocationRef: RefObject<any>;
    private layerControl: RefObject<any>;
    private hibridoLayer: RefObject<any> = React.createRef();
    private terrenoLayer: RefObject<any> = React.createRef();

    private layers: any[] = [];

    // private routeRender: any = {};

    createPoint(lat: number, lng: number, poi  ? : RoutePOIType) {
        return { lat, lng, poi };
    }

    constructor(props: DevicesMapProps, context: any) {
        super(props, context);
        this.groupRef = React.createRef();
        this.userLocationRef = React.createRef();
        this.layerControl = React.createRef();


    }

    componentDidMount(): void {
        this.reload(true);
        this.events = new EventBox(this.props.store!.events);
        this.events.on('online', (device_id: number) => {
            if (this.state.devices) {
                this.state.devices.map((value, index) => {
                    if (value.id === device_id) {
                        value.online = true;

                        this.setState({});
                    }
                    return value;
                })
            }

        });
        this.events.on('offline', (device_id: number, reason: string) => {

            if (this.state.devices) {
                this.state.devices.map((value, index) => {
                    if (value.id === device_id) {
                        value.online = false;
                        this.setState({});
                    }
                    return value;
                })
            }

        });
        this.events.on('location', (device_id: number, reason: string) => {
            this.reload();
        });
        // this.map.current!.render();

        if (this.props.store?.locationRouteTracking.isEnabled()) {
            this.setState({ routeTracking: true });
        }
    }

    componentWillUnmount(): void {
        this.events?.off();
    }

    reload(fitToViewBox: boolean = false) {
        this.setState({loading: true});

        this.props.store!.api.getDevices().then((value => {
            this.setState({loading: false});
            if (value.success) {

                this.setState({error: false, devices: value.data.devices}, () => {
                    if (fitToViewBox && this.state.devices.filter(value1 => value1.lat && value1.lng).length > 0) {

                        setTimeout(() => {
                            this.fitZoom();
                        }, 1000);
                    }
                });
                this.props.store?.api.getRoutes().then(res => {
                    if (res.success) {
                        this.setState({ routes: res.data.reverse() });
                    }
                })

                // for (let i = 0; i < value.data.devices.length; i++) {
                //     this.props.store!!.subscribeToDevice(value.data.devices[i].id);
                // }

            } else {
                this.setState({error: true});
            }
        }));
    }

    handleClick = () => {
        var latlng = this.state.userLocation;
        this.setState({selectedLoc: latlng, clickMenu: true});

    }
    handleLayerClick = () => {
        
        this.setState({ manageLayers: true });
        // let s = this.layerControl.current!.getContainer()!.classList.contains('leaflet-control-layers-expanded');
        // if (!s) {
        //     this.layerControl.current!.expand();
        // } else {
        //     this.layerControl.current!.collapse();
        // }
    };
    fitZoom() {
        //const map = this.map.current!.leafletElement;  //get native Map instance
        const group = this.groupRef.current!; //get native featureGroup instance
        if(this.map) {
            this.map.fitBounds(group.getBounds(), {animate: true, duration: 2, easeLinearity: 0.5});
        }
    }

    gotoLocation() {
        const {lang} = this.props
        Geolocation.getCurrentPosition().then((value:any) => {
            this.setCurrentPosition(value.coords.latitude, value.coords.longitude, value.coords.accuracy / 10000);
        }).catch((e:any) => {
            alert(lang?.l.main.map_access_error());
        })

    }

    setCurrentPosition(lat:number, lng:number, acc: number) {
        this.setState({userLocation: {lat, lng}}, () => {
            setTimeout(() => {
                if(this.userLocationRef.current && this.map) {
                    const map = this.map;  //get native Map instance
                    map.flyTo(this.userLocationRef.current!._latlng, 18);
                }
            }, 1000);
        });


    }


    private handleDeviceClick = (value: SimpleDevice) => (p1: LeafletMouseEvent) => {
        if (!this.state.deviceInfo || this.state.deviceInfo.id !== value.id) {
            this.setState({deviceInfo: undefined, showDeviceInfo: true});
            this.props.store!.api.deviceGet(value.id!).then(value1 => {
                if (value1.success) {
                    this.setState({deviceInfo: value1.data});
                }
            })
        } else {
            this.setState({showDeviceInfo: true});

        }
    }

    private handleSetLocation(value: SimpleDevice) {
        this.setState({loading: true});
        this.props.store!.api.setDeviceLocation(value.id, this.state.selectedLoc.lat, this.state.selectedLoc.lng).then(value1 => {
            this.reload();
            this.setState({selectedLoc: false});
        });
    }

    handleRouteName = (ev: any) => {
        this.setState({routeName : ev.detail.value})
    }

    handlePOIName = (ev: any) => {
        this.setState({poiName : ev.detail.value})
    }

    handlePOINotes = (ev: any) => {
        this.setState({poiNotes : ev.detail.value})
    }

    handleAddPoiAttachment = async (ev: any) => {
        // let permission = await Camera.checkPermissions();
        // if (permission.camera === "prompt") {
            
        // }
        const photo = await Camera.getPhoto({
            resultType: CameraResultType.Uri,
            source: CameraSource.Camera,
            quality: 100,
        });
        if (photo.webPath) {
            if (photo.path) {
                // Capacitor Photo
                Filesystem.readFile({ path: photo.path }).then(data => {
                    Utils.b64toBlob(data.data as string).then(blob => {
                        this.setState({ poiAttachments: [...this.state.poiAttachments, {url: photo.webPath!, blob}] });
                    })                    
                })
            } else {

                fetch(photo.webPath).then(res => {
                    res.blob().then(blob => {
                        this.setState({ poiAttachments: [...this.state.poiAttachments, {url: photo.webPath!, blob}] });
                    })
                })
            }
        }
    }

    handleStartRoute = () => {

        // this.props.store.


        this.props.store?.locationRouteTracking.setLocationListener((position) => {
            
            this.setState({positions: [...this.state.positions, [position.coords.latitude, position.coords.longitude]]});
        });
        this.props.store?.locationRouteTracking.start(this.state.routeName);
        this.setState({ routeTracking: true, addRoute: false,positions: [] });

    }

    handleStopRoute = () => {
        this.props.store?.locationRouteTracking.stop();
        this.setState({ routeTracking: false });

    }

    handleAddPoi = () => {
        if (!this.props.store?.locationRouteTracking.hasPosition()) {
            alert("Espera a que se reciba la posición GPS");
            return;
        }

        this.setState({ addPoi: true, poiName: '', poiNotes: '', poiAttachments: [] });
    }

    handleSavePoi = () => {
        this.props.store?.locationRouteTracking.addPOI(
            this.state.poiName,
            this.state.poiNotes,
            this.state.poiAttachments.map(a => { return { contentType: 'image/jpeg', path: a.blob }; })
        )

        this.setState({ addPoi: false });

    }

    handleRemoveAttachment = (path: AttachmentMedia) => (ev: any) => {
        let i = this.state.poiAttachments.indexOf(path);
        if (i >= 0) {
            this.state.poiAttachments.splice(i, 1);
        }

        this.setState({});
    }

    handleOnSetMap = (map: L.Map) => {
        // map.add
        this.map = map;

    }

    handleOnMapClick = (click:any) => {
        this.setState({ clickLocation: click.latlng, clickLocationToast: true });

    }

    bool: boolean = false;
    handleSetLayer = (ev: any) => {
        let index = ev.detail.value;
        this.setState({ mapType: index });
        if (this.bool) {
            this.groupRef.current!.getLayers().forEach(a => this.map?.removeLayer(a));
        } else {
            this.groupRef.current!.getLayers().forEach(a => this.map?.addLayer(a));
        }
        this.bool = !this.bool;
    }

    renderFabButtons() {
        if (!this.state.routeTracking) {
            return <IonFab vertical="bottom" horizontal="end" slot="fixed">

                <IonFabButton onClick={() => { this.setState({ addRoute: true }) }}>
                    <IonIcon icon={add} />
                </IonFabButton>
            </IonFab>
        } else {
            return <>
                <IonFab vertical="bottom" horizontal="end" slot="fixed">
                    <IonFabButton onClick={() => this.handleStopRoute()}>
                        <IonIcon icon={stop} />
                    </IonFabButton>
                </IonFab>
                <IonFab vertical="bottom" horizontal="start" slot="fixed">
                    <IonFabButton onClick={() => this.handleAddPoi()}>
                        <IonIcon icon={pin} />
                    </IonFabButton>
                </IonFab>
            </>
        }
    }

    

    
    toLocation(coodrs: any) {

        return coodrs.lat + "," + coodrs.lng;
        
    }
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        
        const {lang} = this.props;
        return <IonPage>
            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot={"start"}><IonMenuButton/></IonButtons>
                    <IonTitle>{lang?.l.main.map_title()}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => this.gotoLocation()}>
                            <IonIcon slot="icon-only" icon={navigateOutline}/>
                        </IonButton>
                        <IonButton onClick={() => this.fitZoom()}>
                            <IonIcon slot="icon-only" icon={pinOutline}/>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            {/* <IonToast isOpen={true} message="Sincronizando Datos..." /> */}
            <IonToast isOpen={!!this.state.clickLocationToast} buttons={[
                {
                    text: 'Copiar',
                    role: 'info',
                    handler: () => { 
                        navigator.clipboard.writeText(this.toLocation(this.state.clickLocation));
                        this.setState({ clickLocationToast: false });
                    }
                },
                {
                    text: 'Ok',
                    role: 'cancel',
                    handler: () => {
                        this.setState({ clickLocationToast: false });
                    }
                }
            ]} message={ this.state.clickLocation ? this.toLocation(this.state.clickLocation) : "" } />
            <IonContent>
                <Maps mapType={this.state.mapType} onMap={this.handleOnSetMap} onMapClick={this.handleOnMapClick}>
                    <LayersControl ref={this.layerControl} >
                        <LayersControl.Overlay name="Dispositivos Registrados" checked>
                            <FeatureGroup ref={this.groupRef}>
                                {this.state.devices.map((value, index) => {
                                    if (!value.lat || !value.lng) return "";
                                    return <Marker key={index} position={{ lat: value.lat, lng: value.lng }}
                                        eventHandlers={{ click: this.handleDeviceClick(value) }}
                                        icon={value.online ? onlineIcon : offlineIcon}

                                    >
                                        <Tooltip permanent={true} direction={"top"}>{value.name}</Tooltip>
                                    </Marker>
                                })}
                            </FeatureGroup>
                        </LayersControl.Overlay>

                    </LayersControl>

                    {this.state.clickLocation && <Marker position={this.state.clickLocation}>

                    </Marker>}
                    {this.state.selectedLoc && <Marker position={this.state.selectedLoc} />}
                    {this.state.userLocation && <Marker position={this.state.userLocation} eventHandlers={{ click: this.handleClick }}
                        ref={this.userLocationRef}
                    >
                        <Tooltip permanent={true} direction={"top"}>{lang?.l.main.map_current_location()}</Tooltip>
                    </Marker>}
                </Maps>

                <IonFab vertical="top" horizontal="end" slot="fixed">
                    <IonFabButton onClick={this.handleLayerClick}>
                        <IonIcon icon={layers} />
                    </IonFabButton>

                </IonFab>
                
            </IonContent>
            {this.renderFabButtons()}

            <IonActionSheet

                isOpen={this.state.clickMenu!}
                onDidDismiss={() => this.setState({clickMenu: false})}
                buttons={[{
                    text: lang?.l.main.map_set_device_location(),
                    icon: heart,
                    handler: () => {
                        this.setState({setDeviceLocation: true});
                    }
                }, {
                    text: lang?.l.main.cancel(),
                    icon: close,
                    handler: () => {
                        this.setState({clickMenu: false, selectedLoc: undefined});
                    }
                }]}
            />
            <IonActionSheet

                isOpen={this.state.setDeviceLocation!}
                onDidDismiss={() => this.setState({setDeviceLocation: undefined})}
                buttons={[...this.state.devices.map(value => {
                    return {
                        text: value.name,
                        icon: checkmarkOutline,
                        role: '',
                        handler: () => {
                            this.handleSetLocation(value);

                        }
                    }
                }), {
                    text: lang?.l.main.cancel(),
                    icon: close,
                    role: 'cancel',
                    handler: () => {
                        this.setState({setDeviceLocation: false, selectedLoc: false});
                    }
                }]}
            />
            <IonLoading isOpen={this.state.loading} message={lang?.l.main.loading()} 
                    duration={5000}/>

            <IonModal
                initialBreakpoint={0.25} breakpoints={[0, 0.25, 0.5, 0.75]} presentingElement={this.props.routerOutlet} isOpen={this.state.showDeviceInfo! ? true : false} onDidDismiss={() => this.setState({ showDeviceInfo: false })}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>{lang?.l.main.map_device_status()}</IonTitle>

                        <IonButtons slot={"primary"}>
                            <IonButton onClick={() => this.setState({showDeviceInfo: false})}>{lang?.l.main.close()}</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    {!this.state.deviceInfo && <IonSpinner/>}
                    {this.state.deviceInfo && <IonList>
                        <IonItem>
                            <IonLabel>{lang?.l.main.map_name()}</IonLabel>
                            <IonText>{this.state.deviceInfo.name}</IonText>
                        </IonItem>
                        <IonItem>
                            <IonLabel>{lang?.l.main.map_status()}</IonLabel>
                            <IonText>{this.state.deviceInfo.online ? lang?.l.main.online() : lang?.l.main.offline()}</IonText>
                        </IonItem>
                        <IonItem button color={"primary"} detail onClick={() => {
                            this.setState({showDeviceInfo: false}, () => {

                                this.props.history.push("/device/" + this.state.deviceInfo!.id + "/details");
                            });
                        }}>
                            <IonLabel>{lang?.l.main.map_control()}</IonLabel>
                        </IonItem>
                        {Utils.parseDeviceInputs(this.state.deviceInfo).map(value1 => {
                            return <>

                                <IonItemDivider>
                                    <IonLabel>{value1.input.name}</IonLabel>
                                    <IonText slot={"end"}>{lang?.l.main.map_value()}</IonText>
                                </IonItemDivider>
                                {value1.data.map((value2, k: number) => {
                                    return <IonItem key={k}>
                                        <IonLabel>{value2.title}</IonLabel>
                                        <IonText>{value2.value}</IonText>
                                    </IonItem>
                                })}
                            </>
                        })}
                    </IonList>}
                    {/*<IonButton routerLink={"/device/" + value.id + "/details"}>Controlar</IonButton>*/}

                </IonContent>
            </IonModal>
            <IonModal presentingElement={this.props.routerOutlet} isOpen={this.state.addRoute! ? true : false} onDidDismiss={() => this.setState({addRoute: false})}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Añadir Ruta</IonTitle>

                        <IonButtons slot={"primary"}>
                            <IonButton onClick={() => this.setState({addRoute: false})}>{lang?.l.main.close()}</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    <IonItem>
                        <IonInput label="Nombre de la Ruta" onIonInput={this.handleRouteName}></IonInput>
                    </IonItem>

                    <IonButton>Comprobar GPS</IonButton>
                    <IonButton onClick={() => this.handleStartRoute()}>Comenzar Ruta</IonButton>
                </IonContent>
            </IonModal>

            <IonModal initialBreakpoint={0.5} breakpoints={[0, 0.5, 0.75]} presentingElement={this.props.routerOutlet} isOpen={this.state.addPoi! ? true : false} onDidDismiss={() => this.setState({addPoi: false})}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Añadir Punto de Interes</IonTitle>

                        <IonButtons slot={"primary"}>
                            <IonButton onClick={this.handleSavePoi}>Guardar</IonButton>
                        </IonButtons>
                        <IonButtons slot={"secondary"}>
                            <IonButton onClick={() => this.setState({addPoi: false})}>{lang?.l.main.close()}</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent scrollEvents={true}>
                    <IonList>
                        <IonItem>
                            <IonInput label="Nombre" onIonInput={this.handlePOIName}></IonInput>
                        </IonItem>
                        <IonItem>
                            <IonTextarea label="Notas" onIonInput={this.handlePOIName}></IonTextarea>
                        </IonItem>
                        <IonItem>
                            <IonLabel>Fotos</IonLabel>
                            <IonButton onClick={this.handleAddPoiAttachment}><IonIcon icon={camera} /> Añadir Foto</IonButton>
                        </IonItem>
                        <IonGrid>
                            <IonRow>
                                {this.state.poiAttachments.map((a, k) => {
                                    return <IonCol size="3" key={k}>
                                        <img alt="POI Attachment" src={a.url} />
                                        <IonButton onClick={this.handleRemoveAttachment(a)}><IonIcon icon={trashBin} /></IonButton>
                                    </IonCol>
                                })}
                            </IonRow>
                        </IonGrid>

                    </IonList>
                    {/*<IonButton routerLink={"/device/" + value.id + "/details"}>Controlar</IonButton>*/}

                </IonContent>
            </IonModal>

            <IonModal
                initialBreakpoint={1} breakpoints={[0, 0.25, 0.5, 0.75, 1]}
                // presentingElement={this.props.routerOutlet}
                isOpen={this.state.manageLayers! ? true : false} onDidDismiss={() => this.setState({ manageLayers: false })}>
                
                <IonHeader>
                        <IonToolbar>
                            <IonTitle>Capas y Rutas</IonTitle>

                            <IonButtons slot={"secondary"}>
                                <IonButton onClick={() => this.setState({manageLayers: false})}>{lang?.l.main.close()}</IonButton>
                            </IonButtons>
                        </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonList>
                        <IonRadioGroup value={this.state.mapType} onIonChange={this.handleSetLayer}>
                            <IonListHeader>
                                <IonLabel>Capas</IonLabel>
                            </IonListHeader>

                            <IonItem>
                                <IonLabel>Híbrido</IonLabel>
                                <IonRadio slot="start" value="hybrid" />
                            </IonItem>

                            <IonItem>
                                <IonLabel>Terreno</IonLabel>
                                <IonRadio slot="start" value="terrain" />
                            </IonItem>

                            <IonItem>
                                <IonLabel>Satélite</IonLabel>
                                <IonRadio slot="start" value="satellite" />
                            </IonItem>
                            <IonItem>
                                <IonLabel>Tráfico</IonLabel>
                                <IonRadio slot="start" value="roadmap" />
                            </IonItem>
                        </IonRadioGroup>
                        <IonListHeader>
                            <IonLabel>Tus Rutas</IonLabel>
                        </IonListHeader>
                        {this.state.routes.map((v, k) => {
                            return <IonItem key={k} onClick={() => this.setState({manageLayers: false})} button detail routerLink={"/map/route/" + v.id}>
                                
                                <IonText>
                                    {v.name} <IonBadge>{moment(v.createdAt).format("YYYY-MM-DD HH:mm")}</IonBadge>
                                </IonText>
                            </IonItem>
                        })}
                    </IonList>
                    
                </IonContent>
            </IonModal>
        </IonPage>;
    }
}


export default DevicesMap;
