import ModbusRTUResponse from "./ModbusRTUResponse";
import ModbusClientResponseHandler from "./ModbusClientResponseHandler";

export default class ModbusRTUClientResponseHandler extends ModbusClientResponseHandler {

    handleData(data: Buffer): void {
        this.buffer = Buffer.concat([this.buffer, data])

        // debug('buffer', this.buffer)

        do {
            const response = ModbusRTUResponse.fromBuffer(this.buffer)

            if (!response) {
                // debug('not enough data available to parse')
                return
            }

            // debug('crc', response.crc)

            // debug('reset buffer from', this._buffer.length, 'to', (this._buffer.length - response.byteCount))

            /* reduce buffer */
            this.buffer = this.buffer.slice(response.byteCount)

            this.messages.push(response)
        } while (1)
    }
}
