import React from "react";
import {
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonLoading,
    IonModal,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import {inject} from "mobx-react";
import {RouteComponentProps} from "react-router-dom";
import {Store} from "../../../service/Store";
import {Device, RemoticHardwareSettings} from "../../../service/API";

export interface DeviceSettingsRemoticAutomationProps extends RouteComponentProps<{id: string}> {
    store?:Store,
}
export type DeviceSettingsRemoticAutomationState = {
    loading?: boolean,
    settings: RemoticHardwareSettings,
    index: number,
    device:Device,
    error: boolean | string,
    edit: boolean,
}

@inject("store", "lang")
export default class DeviceSettingsRemoticAutomation extends React.Component<DeviceSettingsRemoticAutomationProps, DeviceSettingsRemoticAutomationState> {

    componentDidMount(): void {

        this.loadDevice();
    }

    loadDevice() {
        this.setState({loading: true});
        let id = this.props.match?.params.id!;
        this.props.store?.api.deviceGet(Number(id)).then(value => {
            if(value.success) {
                this.setState({device: value.data})
            }
            this.props.store?.api.getRemoticHardwareSettings(Number(id), 2).then(value => {
                if(value.success) {
                    this.setState({settings: value.data});
                } else {
                    this.setState({error: 'El dispositivo no está conectado'});
                }
            }).finally(() => this.setState({loading: false}));
        });
    }
    componentDidUpdate(prevProps: Readonly<DeviceSettingsRemoticAutomationProps>, prevState: Readonly<DeviceSettingsRemoticAutomationState>, snapshot?: any) {
        if(this.props.match.params.id !== prevProps.match.params.id) {
            this.loadDevice();
        }
    }

    handleSave = (ev: any) => {
        this.setState({loading: true, error: false});
        let id = this.props.match?.params.id!;
        const {history} = this.props;

        this.props.store?.api.setRemoticHardwareSettings(Number(id), this.state.settings).then(value => {
            this.setState({loading: false});
            if(value.success) {
                if(value.data.result) {
                    history.goBack();
                } else {
                    this.setState({error: 'No se puede configurar el dispositivo'});
                }
            }
        });

    };

    handleChange = (item:any, name:string) => (ev:any) => {
        item[name] = ev.detail.value;
        this.setState({});
    }

    operatorName(op: number) {

        // <IonSelectOption value={0}>&gt;</IonSelectOption>
        // <IonSelectOption value={1}>&ge;</IonSelectOption>
        // <IonSelectOption value={2}>&lt;</IonSelectOption>
        // <IonSelectOption value={3}>&le;</IonSelectOption>
        // <IonSelectOption value={4}>=</IonSelectOption>
        // <IonSelectOption value={5}>&ne;</IonSelectOption>
        switch (op) {
            case 0: return ">";
            case 1: return ">=";
            case 2: return "<";
            case 3: return "<=";
            case 4: return "=";
            case 5: return "!=";
        }
        return String(op);
    }

    getAutomationName(index: number) {
        let auto = this.state.settings.automations[index];
        if(auto.inputType > 0) {
            if(auto.inputType === 1) {
                let name = this.state.device.inputGroups[1].inputs[auto.inputIndex].name;
                return "" + name + " " + this.operatorName(auto.cond) + " " + auto.value;
            }
            if(auto.inputType === 2) {
                let name = this.state.device.outputs[auto.inputIndex].name;
                return "" + name + " " + this.operatorName(auto.cond) + " " + auto.value;
            }
        }
        return "Automatización " + index;
    }

    renderItem(value:any, index:number) {

        return <IonItem detail button onClick={() => this.setState({edit: true, index: index})}>
            <IonLabel>{value.title}</IonLabel>
            <IonBadge color={value.inputType === 0 ? "danger" : "success"}>{value.inputType === 0 ? "OFF" : this.getAutomationName(index)}</IonBadge>
        </IonItem>

    }

    renderEditor() {
        if(!this.state.device) return "";
        if(!this.state.settings) return "";
        if(this.state.index === undefined) return "";
        let index = this.state.index;
        let value = this.state.settings.automations[index];
        return <>

            <IonItemDivider>
                <IonLabel>
                    Automatización {index}
                </IonLabel>
            </IonItemDivider>
            {/*<IonItem>*/}
            {/*    <IonLabel>Tipo</IonLabel>*/}

            {/*    <IonSelect value={value.type} onIonChange={this.handleChange(value, 'type')}>*/}
            {/*        <IonSelectOption value={0}>Desactivar</IonSelectOption>*/}
            {/*        <IonSelectOption value={1}>0-5V</IonSelectOption>*/}
            {/*        <IonSelectOption value={2}>0-20mA</IonSelectOption>*/}
            {/*        <IonSelectOption value={3}>4-20mA</IonSelectOption>*/}
            {/*    </IonSelect>*/}
            {/*</IonItem>*/}

            <IonItem>
                <IonInput label="Nombre de la automatización" labelPlacement="stacked" value={value.title} maxlength={16} onIonInput={this.handleChange(value, 'title')}></IonInput>
            </IonItem>
            <IonItem>
                <IonSelect label="Tipo de valor de lectura (Si)" labelPlacement="stacked" value={value.inputType} onIonChange={this.handleChange(value, 'inputType')}>
                    <IonSelectOption value={0}>Desactivar</IonSelectOption>
                    <IonSelectOption value={1}>Entrada analógica</IonSelectOption>
                    <IonSelectOption value={2}>Estado de la Salida Relé</IonSelectOption>
                </IonSelect>
            </IonItem>
            {value.inputType === 1 &&
            <IonItem>
                <IonSelect label="Entrada Analógica" labelPlacement="stacked" value={value.inputIndex} onIonChange={this.handleChange(value, 'inputIndex')}>
                    {this.state.device.inputGroups[1].inputs.map((value1, index1) => <IonSelectOption key={index1} value={index1}>{value1.name}</IonSelectOption>)}
                </IonSelect>
            </IonItem>}
            {value.inputType === 2 &&
            <IonItem>
                <IonSelect label="Salida relé" labelPlacement="stacked" value={value.inputIndex} onIonChange={this.handleChange(value, 'inputIndex')}>
                    {this.state.device.outputs.map((value1, index1) => <IonSelectOption key={index1} value={index1}>{value1.name}</IonSelectOption>)}
                </IonSelect>
            </IonItem>}
            {value.inputType > 0 && <>
                <IonItem>
                    <IonSelect label="Condición (Es)" labelPlacement="stacked" value={value.cond} onIonChange={this.handleChange(value, 'cond')}>
                        <IonSelectOption value={0}>&gt;</IonSelectOption>
                        <IonSelectOption value={1}>&ge;</IonSelectOption>
                        <IonSelectOption value={2}>&lt;</IonSelectOption>
                        <IonSelectOption value={3}>&le;</IonSelectOption>
                        <IonSelectOption value={4}>=</IonSelectOption>
                        <IonSelectOption value={5}>&ne;</IonSelectOption>
                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonInput label="Valor (A)" labelPlacement="stacked" value={value.value} step={"0.01"} max={"9999"} min={"-9999"} onIonInput={this.handleChange(value, 'value')}></IonInput>
                </IonItem>
                <IonItem>
                    <IonSelect label="Acción a realizar (Entonces)" labelPlacement="stacked" value={value.action} onIonChange={this.handleChange(value, 'action')}>
                        <IonSelectOption value={0}>No hacer nada</IonSelectOption>
                        <IonSelectOption value={1}>Cerrar Relé (ON)</IonSelectOption>
                        <IonSelectOption value={2}>Abrir Relé (OFF)</IonSelectOption>
                    </IonSelect>
                </IonItem>
                {value.action > 0 && <>

                    <IonItem>
                        <IonSelect label="Relé a modificar" labelPlacement="stacked" value={value.actionArg1} onIonChange={this.handleChange(value, 'actionArg1')}>
                            {this.state.device.outputs.map((value1, index1) => <IonSelectOption key={index1} value={index1}>{value1.name}</IonSelectOption>)}

                        </IonSelect>
                    </IonItem>
                </>}
                {value.action > 0 && <>

                    <IonItem>
                        
                        
                        <IonInput label="Tiempo a esperar antes de ejecutar (s)" 
                                    labelPlacement="stacked"
                                    value={value.secondsWait} step={"1"} max={"9999"} min={"0"} 
                                    onIonInput={this.handleChange(value, 'secondsWait')}></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonInput label="Tiempo a esperar antes de volver a evaluar (s)" labelPlacement="stacked" value={value.secondsRearm} step={"1"} max={"9999"} min={"0"} onIonInput={this.handleChange(value, 'secondsRearm')}></IonInput>
                    </IonItem>
                </>}


                <IonItem>
                    <IonSelect label="Concatenar con (Ejecutar solo si se cumple la aut. seleccionada)" labelPlacement="stacked" value={value.prevAnd} onIonChange={this.handleChange(value, 'prevAnd')}>
                        <IonSelectOption value={0}>(Desactivar)</IonSelectOption>
                        {this.state.settings.automations.map((value1, index1) => (index1 !== index && value1.inputType > 0) && <IonSelectOption key={index1} value={index1 + 1}>{this.getAutomationName(index1)}</IonSelectOption>)}
                    </IonSelect>
                </IonItem>
            </>}
        </>
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let id = this.props.match?.params.id!;

        if(!this.state) return "";
        return <IonPage>

            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={"/device/" + id + "/settings"}/>
                    </IonButtons>
                    <IonTitle>Automatizaciones en Hardware</IonTitle>

                    <IonButtons slot="primary">
                        <IonButton onClick={this.handleSave}>Guardar</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    {this.state.error && <IonItem color={"danger"}>
                        <IonLabel>{this.state.error}</IonLabel>
                    </IonItem>}
                    {this.state.settings && this.state.settings.automations.map((value:any, index:number) => this.renderItem(value, index))}

                </IonList>
                <IonLoading
                    isOpen={this.state.loading!}
                    message={'Cargando...'}
                    
                />
            </IonContent>


            <IonModal isOpen={this.state.edit! ? true: false} onDidDismiss={() => this.setState({edit: false})}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Editar Automatización</IonTitle>
                        <IonButtons slot={"primary"}>
                            <IonButton onClick={() => this.setState({edit: false})}>OK</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    {this.renderEditor()}
                </IonContent>

            </IonModal>
        </IonPage>
    }

}
