import type { BaseTranslation } from 'typesafe-i18n'

const fr: BaseTranslation = {
    "errors": {
        "INTERNAL_ERROR": "Erreur interieur",
        "DEVICE_ADD_ALREADY": "",
        "DEVICE_ADD_ANOTHER": "",
        "DEVICE_ADD_GENERIC": "",
        "DEVICE_ADD_CODE": "",
        "DEVICE_ADD_NOT_SUPPORTED": "",
        "DEVICE_UPDATE_GENERIC": "",
        "DEVICE_UPDATE_ACCOUNT": "",
        "DEVICE_DELETE_ACCOUNT": "",
        "DEVICE_DELETE_FILED": "",
        "DEVICE_DATA_NOT_ONLINE": "",
        "DEVICE_DATA_NOT_SUPPORTED": "",
        "DEVICE_DATA_NOT_FOUND": "",
        "DEVICE_SETTINGS_NOT_FOUND": "",
        "DEVICE_AIN_INVALID": "",
        "DEVICE_AIN_NOT_ONLINE": "",
        "DEVICE_AIN_NOT_SUPPORTED": "",
        "DEVICE_AIN_NOT_ACCOUNT": "",
        "DEVICE_OUTPUT_NOT_FOUND": "",
        "DEVICE_OUTPUT_ERROR": "",
        "DEMO_NOT_ALLOWED": "",
        "INPUT_NOT_FOUND": "",
        "DEVICE_NOT_FOUND": "",
        "DEVICE_INVALID_RESPONSE": "",
        "DEVICE_UNDEFINED_RESPONSE": "",
        "QTHING_NOT_FOUND": "",
        "QR_NOT_FOUND": "",
        "USER_NOT_ALLOWED": "",
        "SCHEDULER_NOT_FOUND": "",
        "AUTOMATION_NOT_FOUND": "",
        "USER_EMAIL_TAKEN": "",
        "USER_USERNAME_TAKEN": "",
        "USER_LOGIN_INCORRECT": "",
        "USER_LOGIN_EMPTY": "",
        "USER_RECOVERY_INVALID": "",
        "USER_RECOVERY_EXPIRED": "",
        "USER_TOKEN_NOT_VALID": "",
        "USER_PASSWORD_INVALID_OLD": "",
        "USER_PASSWORD_SHORT": "",
        "USER_INVALID_VERIFICATION_CODE": "",
        "USER_ALREADY_VERIFIED": "",
        "SMS_PROVIDER_ERROR": "",
        "PERMISSION_NOT_ALLOWED": "",
        "PERMISSION_NOT_FOUND": "",
        "PERMISSION_REMOVE_YOURSELF": "",
        "PERMISSION_ADD_OWNER": "",
        "PERMISSION_USER_NOT_FOUND": "",
        "PERMISSION_NOT_VALID": "",
        "PERMISSION_USER_ALREADY": "",
        "PERMISSION_RESEND_ERROR": "",
        "NOTIFICATIONS_PROVIDER_NOT_VALID": "",
        "NOT_IMPLEMENTED_ERROR": "",
        "PAYMENT_METHOD_ERROR": "",
        "PAYMENT_ERROR": "",
        "PAYMENT_LIST_ERROR": "",
        "PAYMENT_NOT_ENABLED_ERROR": "",
        "DEVICE_DELETE_PAYMENT": "",
        "SIM_ACTIVATION_ERROR": "",
        "DEVICE_NOT_ONLINE": "",
        "DEVICE_NOT_COMPATIBLE": "",
        "INVITATION_NOT_VALID": "",
        "INVITATION_REVOKED": "",
        "INVITATION_USED": "",
        "INVITATION_YOU": "",
        "NOT_FOUND": "",
        "PANEL_FOUND": "",
        "CLIENT_UUID_REQUIRED": "",
        "DEVICE_TRANSFER_USER_NOT_FOUND": "",
        "DEVICE_TRANSFER_OWNER": "",
        "DEVICE_NOT_VALID": "",
        "INVALID_PARAMETERS": "",
        "CREATE_USER_EMAIL_USED": "",
        "CREATE_USER_USERNAME_USED": "",
        "FIREBASE_NOT_ENABLED": "",
        "CREATE_USER_EMAIL_EMPTY": "",
        "CREATE_USER_PASSWORD_EMPTY": "",
        "CREATE_USER_USERNAME_EMPTY": "",
        "CREATE_USER_USERNAME_NOT_VALID": "",
        "CREATE_USER_PHONE_EMPTY": "",
        "DEVICE_NOT_SUBSCRIBED": "",
        "PAYMENT_USER_NOT_ALLOWED": ""
    },
    "main": {
        "login": "",
        "language": "",
        "software_demo": "",
        "start_demo": "",
        "demo_version": "",
        "login_header": "",
        "login_user_email": "",
        "logn_password": "",
        "login_remember": "",
        "login_remember_me": "",
        "login_button": "",
        "login_new_account": "",
        "login_recovery": "",
        "login_download": "",
        "menu": "",
        "menu_home": "",
        "menu_hmi": "",
        "menu_account": "",
        "menu_settings": "",
        "menu_usb": "",
        "menu_help": "",
        "menu_logout": "",
        "menu_theme": "",
        "tab_back": "",
        "tab_dashboard": "",
        "tab_devices": "",
        "tab_map": "",
        "tab_control": "",
        "tab_status": "",
        "tab_log": "",
        "tab_settings": "",
        "dashboard_verify_title": "",
        "dashboard_button": "",
        "dashboard_content": "",
        "dashboard_email_sent": "",
        "dashboard_code_input": "",
        "dashboard_resend": "",
        "dasbhoard_head": "",
        "dashboard_welcome": "",
        "dashboard_welcome_message": "",
        "dashboard_understood": "",
        "dashboard_title": "",
        "dashboard_panels": "",
        "dashboard_devices": "",
        "online": "",
        "offline": "",
        "dashboard_error": "",
        "reload": "",
        "Loading": "",
        "loading": "",
        "dasbhoard_button_control": "",
        "devices_title": "",
        "search": "",
        "devices_error": "",
        "devices_retry": "",
        "map_access_error": "",
        "map_title": "",
        "map_current_location": "",
        "map_set_device_location": "",
        "cancel": "",
        "map_device_status": "",
        "close": "",
        "map_name": "",
        "map_status": "",
        "map_control": "",
        "map_value": "",
        "add_device_error_internet": "",
        "add_device_error_name": "",
        "add_device_error_code": "",
        "add_device_error_payment": "",
        "add_device_error_subscription": "",
        "ok": "",
        "add_device_coupon_charged": "",
        "add_device_help": "",
        "add_device_admin": "",
        "add_device_name": "",
        "add_device_example_name": "",
        "add_device_qrcode": "",
        "add_device_subscriptions_load": "",
        "add_device_payment_method": "",
        "add_device_coupon": "",
        "add_device_coupon_check": "",
        "add_simulator_title": "",
        "add_simulator_model": "",
        "add_simulator_protocol": "",
        "add_device_header": "",
        "add_device_device": "",
        "add_device_simulator": "",
        "edit": "",
        "panel_create_new": "",
        "title": "",
        "size": "",
        "remove": "",
        "add_payment_error": "",
        "add_payment_not_implemented": "",
        "add_payment_error_internet": "",
        "add_payment_holder_name": "",
        "add_payment_credit_message": "",
        "add_payment_sepa_holder": "",
        "add_payment_sepa_message": "",
        "add_payment_title": "",
        "add_payment_help_message": "",
        "add_payment_method": "",
        "add_payment_credit_card": "",
        "add_payment_sepa": "",
        "add_panel_success": "",
        "add_panel_select": "",
        "add_panel_title": "",
        "add_panel_help": "",
        "password_short": "",
        "password_not_match": "",
        "error": "",
        "password_current": "",
        "password_current_help": "",
        "password_new": "",
        "password_new_help": "",
        "password_repeat": "",
        "password_repeat_help": "",
        "change": "",
        "demo_conditions": "",
        "demo_header": "",
        "demo_subtitle": "",
        "demo_fullname": "",
        "demo_business": "",
        "demo_phone": "",
        "email": "",
        "legal_terms": "",
        "legal_terms_url": "",
        "privacy_terms": "",
        "privacy_terms_url": "",
        "automation_confirm": "",
        "atomation_output_name": "",
        "automation_change_state": "",
        "automation_on": "",
        "automation_off": "",
        "automation_set_value": "",
        "automation_select_value": "",
        "automation_destination_email": "",
        "automation_email_message": "",
        "automation_send_email_to": "",
        "automation_show_notification": "",
        "automation_action_type": "",
        "automation_set_output": "",
        "automation_action_send_email": "",
        "automation_action_notify": "",
        "automation_condition_title": "",
        "automation_boolean": "",
        "and": "",
        "or": "",
        "automation_if": "",
        "automation_is": "",
        "automation_to_value": "",
        "automation_if_short": "",
        "automation_to_short": "",
        "automation_operation": "",
        "automation_enable": "",
        "automation_wait_title": "",
        "automation_rearm": "",
        "add": "",
        "automation_actions": "",
        "notes": "",
        "automation_executed": "",
        "automation_enabled": "",
        "automation_disabled": "",
        "automation_automations": "",
        "automation_automation": "",
        "save": "",
        "automation_edit_condition": "",
        "automation_edit_action": "",
        "scheduler_output": "",
        "enable": "",
        "scheduler_time": "",
        "scheduler_repeat_week": "",
        "monday": "",
        "thursday": "",
        "wednesday": "",
        "tuesday": "",
        "friday": "",
        "saturday": "",
        "sunday": "",
        "scheduler_fixed_date": "",
        "date": "",
        "scheduler_multiple_dates": "",
        "scheduler_enable": "",
        "scheduler_disable_warning": "",
        "scheduler_title_modal": "",
        "galery_error": "",
        "tab_scheduler": "",
        "tab_automation": "",
        "loading_device": "",
        "error_loading_device": "",
        "output_off": "",
        "output_on": "",
        "status_no_data": "",
        "status_inputs_hidden": "",
        "status_download_registers": "",
        "start_date": "",
        "end_date": "",
        "download": "",
        "status_input_details": "",
        "status_display_dashboard": "",
        "input_details": "",
        "input_search_registers": "",
        "input_unique": "",
        "diff": "",
        "log_load_error": "",
        "event": "",
        "details": "",
        "user": "",
        "offline_long": "",
        "log_power_recovery": "",
        "log_power_faillure": "",
        "power_supply": "",
        "sent": "",
        "sent_error": "",
        "power_supply_recovery": "",
        "hardware_timer": "",
        "log_register_details": "",
        "log_register_id": "",
        "log_register_date": "",
        "log_register_output_name": "",
        "log_register_event": "",
        "log_register_description": "",
        "log_register_manual": "",
        "log_register_scheduler": "",
        "log_register_automation": "",
        "log_register_online": "",
        "log_register_offline": "",
        "log_register_power": "",
        "log_register_error": "",
        "no_error": "",
        "log_register_username": "",
        "log_register_filter": "",
        "filter": "",
        "type": "",
        "load_more": "",
        "loading_more": "",
        "log_total": "",
        "reles": "",
        "analog_inputs": "",
        "temp_and_humd": "",
        "digital_inputs": "",
        "interlock": "",
        "modbus": "",
        "inputs": "",
        "hardware_automation": "",
        "device_not_support_settings": "",
        "device_offline": "",
        "settings_general": "",
        "settings_subscription": "",
        "settings_outputs": "",
        "share": "",
        "download_app_title": "",
        "download_get_app": "",
        "download_app_platforms": "",
        "download_pc_mac": "",
        "download_chrome": "",
        "download_install": "",
        "download_incompatible": "",
        "download_beta": "",
        "download_beta_android": "",
        "download_beta_ios": "",
        "invitation": "",
        "invitation_accepted": "",
        "see_devices": "",
        "app_settings_title": "",
        "app_settings_timezone": "",
        "app_settings_current_time": "",
        "see_bills": "",
        "modify": "",
        "enable_billing": "",
        "payment_configure": "",
        "username": "",
        "billing": "",
        "change_password": "",
        "draft": "",
        "bill_open": "",
        "bill_paid": "",
        "bill_void": "",
        "bill_unpaid": "",
        "bills_list": "",
        "bill_info_header": "",
        "problem_ocurrurs": "",
        "password_too_short": "",
        "problem_occurred": "",
        "error_occurred": "",
        "password_recovery": "",
        "password_recovery_email": "",
        "new_password_set": "",
        "back_home": "",
        "recovery_password_title": "",
        "email_address": "",
        "email_was_sent": "",
        "contact_info": "",
        "postal_address": "",
        "authentication_data": "",
        "join_newsletter": "",
        "create_account": ""
    }
};

export default fr