import {
    Capacitor,
} from "@capacitor/core";
import { API } from "./API";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { RouteComponentProps } from "react-router-dom";
import Utils from "./Utils";
import {
    ActionPerformed,
    PushNotifications,
    PushNotificationSchema,
    Token
} from "@capacitor/push-notifications";
import { Device } from "@capacitor/device";

// const { PushNotifications, Device } = Plugins;


export default class Push {

    notifications: boolean = false;
    private api: API;

    private router?: RouteComponentProps;


    constructor(api: API) {
        this.api = api;
    }

    check(router: RouteComponentProps) {
        this.router = router;
        if (!this.notifications) {
            if (Capacitor.getPlatform() === "web") {
                this.initWeb();

            } else {
                this.initCapacitor();
            }
        }
    }

    private async registerNotifications() {
        let permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === 'prompt') {
            permStatus = await PushNotifications.requestPermissions();
        }

        if (permStatus.receive !== 'granted') {
            throw new Error('User denied permissions!');
        }

        await PushNotifications.register();

    }

    private initCapacitor() {
        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration',
            async (token: Token) => {
                let device = await Device.getInfo();
                // @ts-ignore
                let item = "CloudRTU App/" + Utils.getVersion() + " " + device.model + "/" + device.osVersion;
                this.api.usersPush(token.value, item);
                this.notifications = true;
                // this.api.push
            }
        );

        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError',
            (error: any) => {
                Utils.log(error);
                alert('Error on registration: ' + JSON.stringify(error));
            }
        );

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived',
            (notification: PushNotificationSchema) => {
                Utils.log(JSON.stringify(notification, null, 4));
                // alert('Push received: ' + JSON.stringify(notification, null, 4));


            }
        );

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed',
            (notification: ActionPerformed) => {
                Utils.log(JSON.stringify(notification, null, 4));
                if (this.router) {
                    let pathname = notification.notification.data.url;
                    if (pathname.indexOf("://") > 0) {
                        pathname = pathname.substring(pathname.indexOf("/", pathname.indexOf("://") + 3))
                    }
                    this.router.history.push({
                        pathname: pathname
                    });
                }

                // alert('Push action performed: ' + JSON.stringify(notification));
            }
        );

        // Register with Apple / Google to receive push via APNS/FCM
        this.registerNotifications().catch(err => {
            alert("Se ha producido un error al recibir las notificaciones." +
                "Compruebe la configuración del dispositivo");
        })

    }

    private async initWeb() {
        // Retrieve Firebase Messaging object.
        // if (!()) return;
        if (this.notifications) return;
        this.notifications = true;
        try {
            const messaging = getMessaging();

            const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js', {
                updateViaCache: 'none'
            });
            // messaging.useServiceWorker(registration);
            // messaging.usePublicVapidKey("");
            Notification.requestPermission().then(() => {
                Utils.log('Notification permission granted.');
                // Get Instance ID token. Initially this makes a network call, once retrieved
                // subsequent calls to getToken will return from cache.
                getToken(messaging, {
                    vapidKey: 'BKcoSteOAkFK3vQtQVs3EeZh39FI24F4yNfkz9PyQdPQMOOJQNX0Ay8XuSKUUv9qX32JnxD8hhUoHkwPVoplWPM',
                    serviceWorkerRegistration: registration
                }).then((currentToken) => {
                    if (currentToken) {
                        Utils.log(currentToken);
                        this.notifications = true;

                        this.api.usersPush(currentToken, this.getDeviceInfo());
                        //this.onTokenUpdatedFn(currentToken);
                        // sendTokenToServer(currentToken);
                        // updateUIForPushEnabled(currentToken);
                    } else {
                        // Show permission request.
                        Utils.log('No Instance ID token available. Request permission to generate one.');
                        // Show permission UI.
                        // updateUIForPushPermissionRequired();
                        // setTokenSentToServer(false);
                        //this.onTokenUpdatedFn(false);
                    }
                    onMessage(messaging, function (payload) {
                        console.log('Message received. ', payload);
                        // if (payload.notification) {
                        //     var notification = payload.notification;
                        //     new Notification(notification.title, notification);
                        // }
                        
                    });
                }).catch(function (err:any) {
                    Utils.log('An error occurred while retrieving token. ', err);
                    // showToken('Error retrieving Instance ID token. ', err);
                    // setTokenSentToServer(false);
                });
            }).catch(function (err) {
                Utils.log('Unable to get permission to notify.', err);
            });


            // @ts-ignore
            window.messaging = messaging;
        } catch (ex) {
            console.error(ex);

        }
    }

    public getDeviceInfo() {
        return navigator.userAgent;
    }
}
