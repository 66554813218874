import React from "react";
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonSelect,
    IonSelectOption,
    IonSpinner,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import {trash} from "ionicons/icons";
import {inject} from "mobx-react";
import {Store} from "../service/Store";
import {Panel, PanelControl} from "../service/API";
import Lang from "../service/Lang";

export type AddPanelProps = {
    open:boolean;
    id?:number;
    store?:Store;
    lang?:Lang;
    onClose: (panel?:Panel) => void;
    routerOutlet?:HTMLIonRouterOutletElement;
}

@inject("store", "lang")
export default class AddPanel extends React.Component<AddPanelProps, any> {

    state : any = {};
    componentDidMount() {
    }

    componentDidUpdate(prevProps: Readonly<AddPanelProps>, prevState: Readonly<any>, snapshot?: any) {
        if(this.props.id !== prevProps.id) {
            this.loadData();
        }

    }

    loadData() {
        if(this.props.id) {

            let store:Store = this.props.store!;
            this.setState({loading: true});
            store.api.getPanelData(this.props.id).then(value => {
                
                this.setState({loading: false, title: value.data.title, size: value.data.size, controls: value.data.controls})

            });
        }

    }

    handleAdd = (ev:any) => {
        let store:Store = this.props.store!;
        this.setState({loading: true});
        store.api.postPanel({title: this.state.title, size: this.state.size}, this.props.id).then(value => {

            this.setState({loading: false});
            if(value.success) {
                this.props.onClose(value.data);
            }
        })
    }

    handleControlChange = (key:string) => (ev:any) => {
        this.setState({[key]: ev.detail.value});
    }

    handleControlRemove = (id: number) => (ev:any) => {
        this.setState({loading: true});
        this.props.store?.api.deletePanelControl(id).then( res => {
            this.loadData();

        })
    }
    handleDismiss = (ev:any) => {
        this.props.onClose()
    }


    handleRemove = (ev:any) => {
        this.props.store?.api.deletePanel(this.props.id!).then(value => {

            if(value.success) {
                this.handleDismiss(ev);
            }
        })
    }

    render() {
        const {lang} = this.props;
        return <>
            <IonModal presentingElement={this.props.routerOutlet} isOpen={this.props.open ? true: false} canDismiss={true} 
                      onDidDismiss={this.handleDismiss}>

                <IonHeader>
                    <IonToolbar>
                        <IonTitle>{this.props.id ? lang?.l.main.edit():lang?.l.main.panel_create_new()} panel</IonTitle>
                        <IonButtons slot={"secondary"}>
                            <IonButton onClick={this.handleDismiss}>{lang?.l.main.cancel()}</IonButton>
                        </IonButtons>
                        <IonButtons slot={"primary"}>
                            <IonButton onClick={this.handleAdd}><IonSpinner
                                hidden={!this.state.loading}/> {lang?.l.main.ok()}</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen>
                    <IonList>

                        <IonItem>
                            <IonInput label={lang?.l.main.title()} value={this.state.title} onIonInput={this.handleControlChange('title')}></IonInput>
                        </IonItem>

                        <IonItem>
                            <IonSelect label={lang?.l.main.size()} value={this.state.size} onIonChange={this.handleControlChange('size')}>
                                <IonSelectOption value={1}>1</IonSelectOption>
                                <IonSelectOption value={2}>2</IonSelectOption>
                                <IonSelectOption value={3}>3</IonSelectOption>
                                <IonSelectOption value={4}>4</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                        {this.state.controls && this.state.controls.map((v:PanelControl) => {
                            return <>
                                <IonItem>
                                    <IonLabel>{v.input?.name}</IonLabel>
                                    <IonButton onClick={this.handleControlRemove(v.id)}>Eliminar</IonButton>
                                </IonItem>
                            
                            </>
                        })}

                    </IonList>
                </IonContent>
                {this.props.id && <IonItem color={"danger"} button detail detailIcon={trash} onClick={this.handleRemove}>
                    <IonLabel>{lang?.l.main.remove()}</IonLabel>
                </IonItem>}
            </IonModal>
        </>
    }
}
