import React, { RefObject } from "react";
import {
    IonButton,
    IonButtons,
    IonCheckbox,
    IonContent,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonLoading,
    IonPage,
    IonPopover,
    IonText,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import { arrowBack, ellipsisHorizontal, refreshOutline } from "ionicons/icons";
import { Store } from "../../service/Store";
import * as H from "history";
import { match } from "react-router";
import { APIBase, Device, DeviceInputGroup, DeviceInputs } from "../../service/API";
import { inject } from "mobx-react";
import InputChart, { LongRanges } from "../../components/InputChart";
import DeviceStatus from "./DeviceStatus";
import Lang from "../../service/Lang";
import Utils from "../../service/Utils";
import Geolocation from "../../service/Geolocation";
import AddToPanel from "../../components/AddToPanel";

export interface DeviceGraphInputProps {
    store?: Store,
    lang?: Lang,
    location?: H.Location,
    history?: H.History,

    match?: match<{ id: string, input: string }>,
}
export interface DeviceGraphInputState {
    loading: boolean,
    device?: Device,
    input?: DeviceInputs,
    inputGroup?: DeviceInputGroup,



    timeTable?: any;
    // unique?: boolean,
    openAddPanel?: boolean;
    panelData?: any;
}

@inject("store", "lang")
export default class DeviceGraphInput extends React.Component<DeviceGraphInputProps, DeviceGraphInputState> {

    state: DeviceGraphInputState = { loading: false };
    valueInput: RefObject<any>;


    constructor(props: DeviceGraphInputProps, context: any) {
        super(props, context);
        this.valueInput = React.createRef();
    }

    loadDevice() {
        let id = this.props.match?.params.id!;
        this.setState({ loading: true })
        this.props.store?.api.deviceGet(parseInt(id), 'input').then((value: APIBase<Device>) => {
            this.setState({ device: value.data, loading: false });

            if (value.success) {
                let id = parseInt(this.props.match?.params.input!);
                this.props.store?.subscribeToDevice(id);
                for (let i = 0; i < value.data.inputGroups.length; i++) {
                    for (let j = 0; j < value.data.inputGroups[i].inputs.length; j++) {
                        let input = value.data.inputGroups[i].inputs[j];
                        if (input.id === id) {
                            this.setState({ input: input, inputGroup: value.data.inputGroups[i] });
                        }
                    }
                }
            }
        });
    }

    componentDidMount() {
        this.loadDevice();
    }

    componentDidUpdate(prevProps: Readonly<DeviceGraphInputProps>, prevState: Readonly<DeviceGraphInputState>, snapshot?: any) {
        if (prevProps.match?.params.id !== this.props.match?.params.id) {
            this.loadDevice();
        } else if (prevProps.match?.params.input !== this.props.match?.params.input) {
            this.loadDevice();
        }
    }

    handleRefresh = (ev: any) => {
        this.loadDevice();
    }


    // private handleClickInputLog = (ev: any) => {
    //     this.loadInputLog();
    // }
    // private handleClickTimeTable = (ev: any) => {
    //     this.loadInputLog().then((log) => {
    //         if (log && log.length > 0) {
    //             log = log.sort((a, b) => b.time - a.time);
    //             let timeTable: any = {};
    //             let lastItem = null;
    //             let tc = 0;
    //             for (let index = 0; index < log.length; index++) {
    //                 const element = log[index];
    //                 if (lastItem !== null) {
    //                     if (lastItem.data === element.data) {
    //                         tc += (lastItem.time - element.time);
    //                     } else {
    //                         if (!timeTable[lastItem.data]) {
    //                             timeTable[lastItem.data] = tc;
    //                         } else {
    //                             timeTable[lastItem.data] += tc;
    //                         }
    //                         tc = 0;
    //                     }

    //                 }
    //                 lastItem = element;
    //             }

    //             if (lastItem) {
    //                 //tc += Math.floor(new Date().getTime() / 1000) - lastItem.time;
    //                 if (!timeTable[lastItem.data]) {
    //                     timeTable[lastItem.data] = tc;
    //                 } else {
    //                     timeTable[lastItem.data] += tc;
    //                 }
    //                 tc = 0;
    //             }
    //             this.setState({ timeTable });

    //         }
    //     });
    // }

    // private handleChangeFilter = (ev: any) => {
    //     this.setState({ unique: ev.detail.checked });
    // }
    private handleChangeLocation = (input: number) => (ev: any) => {
        this.props.store?.location.setLocation(input, ev.detail.checked);
        this.setState({});
    }

    private handleSetPanel = (input: DeviceInputs) => (ev: any) => {
        this.setState({ openAddPanel: true, panelData: { input_id: input.id } });
    }
    handleEdit = (ev: any) => {
        let id = this.props.match?.params.id!;
        this.props.history?.push("/device/" + id + "/settings/inputGroup/" + this.state.input?.group_id + "/input/" + this.state.input!.id!);
    }

    handleSendPosition = (ev: any) => {
        this.setState({ loading: true });
        Geolocation.getCurrentPosition().then((value: any) => {
            this.props.store?.api.sendInputLatLng(String(this.state.input?.id), { lat: value.coords.latitude, lng: value.coords.longitude }).then(res => {

                this.setState({ loading: false });
            })
        }).catch((e: any) => {
            console.log(e);
            this.setState({ loading: false });
            alert("error: " + e);
        })
    }
    handleSetValue = (ev: any) => {
        let value = this.valueInput.current!.value;
        if (value) {
            this.setState({ loading: true });
            this.props.store?.api.sendInput(String(this.state.input?.id), value).then(res => {

                this.setState({ loading: false });
            })
        }
    }



    public renderTimeTable() {
        return <IonList>
            <IonItem>
                <IonLabel>Valor</IonLabel>
                <IonText>Tiempo</IonText>
            </IonItem>
            {Object.keys(this.state.timeTable).map((value: any, index: any) => {
                let item = this.state.timeTable[value];
                return <IonItem>
                    <IonLabel>{index}</IonLabel>
                    <IonText>{Utils.millisecondsToStr(item)}</IonText>
                </IonItem>
            })}
        </IonList>
    }

    render() {
        const { lang } = this.props;
        let title = "";

        if (this.state.input) {
            title = DeviceStatus.renderItemTitle(this.state.device!, this.state.input);
        }
        return <IonPage>
            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">

                        <IonButton routerDirection={"back"} routerLink={"/device/" + this.props.match?.params.id + "/status"}><IonIcon icon={arrowBack} /></IonButton>
                    </IonButtons>
                    <IonTitle>{title}</IonTitle>
                    <IonButtons slot="primary">
                        <IonButton onClick={this.handleRefresh}>
                            <IonIcon slot="icon-only" icon={refreshOutline} />
                        </IonButton>
                        <IonButton id="menu-inputs-button">
                            <IonIcon slot="icon-only" icon={ellipsisHorizontal} />
                        </IonButton>
                        <IonPopover trigger="menu-inputs-button" reference="event" dismissOnSelect={true}>
                            <IonContent>
                                <IonList>
                                    <IonListHeader>Opciones</IonListHeader>
                                    <IonItem onClick={this.handleSetPanel(this.state.input!)}>Añadir entrada a panel</IonItem>
                                    <IonItem onClick={this.handleEdit}>Editar</IonItem>
                                </IonList>
                            </IonContent>
                        </IonPopover>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent scrollEvents={true}>
                {this.state.input && <div style={{ padding: '25px' }}>
                    <InputChart device={this.state.device!} fullSize location={"deviceGraphInput"} ranges={LongRanges} number={this.state.input.index} input={this.state.input} title={title} time={this.state.input.updatedAt} />
                </div>}
                <IonList>
                    <IonItem button detail routerLink={"/device/" + this.props.match?.params.id + "/status/input/" + this.props.match?.params.input + "/search"}>
                        <IonLabel>{lang?.l.main.input_search_registers()}</IonLabel>
                    </IonItem>
                    <IonItem button detail routerLink={"/device/" + this.props.match?.params.id + "/status/input/" + this.props.match?.params.input + "/timetable"}>
                        <IonLabel>Generar Informe Detallado</IonLabel>
                    </IonItem>
                    {(this.state.input && this.state.input.description) && <IonItem>
                        <IonText>{this.state.input.description}</IonText>
                    </IonItem>}

                    {/* <IonListHeader>
                        <IonLabel>{lang?.l.main.input_search_registers()}</IonLabel>
                    </IonListHeader>
                    <IonItem>
                        <IonLabel position="stacked">{lang?.l.main.start_date()}</IonLabel>
                        <IonInput value={this.state.dateInputStart} onIonChange={this.handleChange('dateInputStart')}
                            type={"date"} />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">{lang?.l.main.end_date()}</IonLabel>
                        <IonInput value={this.state.dateInputEnd} onIonChange={this.handleChange('dateInputEnd')}
                            type={"date"} />
                    </IonItem>

                    <IonItem>
                        <IonCheckbox slot="start" color="primary" onIonChange={this.handleChangeFilter} />
                        <IonLabel>{lang?.l.main.input_unique()}</IonLabel>
                    </IonItem>
                    <IonItem>
                        {this.state.loading && <IonSpinner />}
                        <IonButton onClick={this.handleClickInputLog} expand={"block"}>{lang?.l.main.search()}</IonButton>
                        <IonButton onClick={this.handleClickTimeTable} expand={"block"}>Tabla de tiempo</IonButton>
                    </IonItem> */}
                </IonList>
                {/* {this.state.timeTable && this.renderTimeTable()}
                <IonList>
                    <IonItem>
                        <IonLabel>{lang?.l.main.date()}</IonLabel>
                        <IonText>{lang?.l.main.map_value()} </IonText>
                        <IonText> ({lang?.l.main.diff()})</IonText>
                    </IonItem>
                    {this.state.inputLog && this.renderLog(this.state.inputLog!)}
                </IonList> */}

                <IonList>
                    <IonItem>
                        <IonLabel>Referencia Entrada {this.state.input?.widget}</IonLabel>
                        <IonText>input-{this.state.device?.id}-{this.state.inputGroup?.iindex}-{this.state.input?.index}</IonText>
                    </IonItem>
                </IonList>
                {this.state.input && this.state.input.widget === 'distance' &&
                    <>

                        <IonList>
                            <IonItem>
                                <IonLabel>Enviar Distancia</IonLabel>
                                <IonButton onClick={this.handleSendPosition}>Enviar</IonButton>
                            </IonItem>
                        </IonList>
                        <IonList>
                            <IonItem>
                                <IonCheckbox slot="start" color="primary" onIonChange={this.handleChangeLocation(this.state.input.id)} checked={this.props.store!.location.isEnabled(this.state.input.id)} />
                                <IonLabel>Enviar Distancia Automaticamente</IonLabel>
                            </IonItem>
                        </IonList>
                    </>

                }


                <IonList>
                    <IonItem>
                        <IonInput label="Establecer Valor Manualmente" ref={ this.valueInput }></IonInput>
                        <IonButton onClick={this.handleSetValue}>Enviar</IonButton>
                    </IonItem>
                </IonList>

                <IonLoading
                    isOpen={this.state.loading!}
                    message={lang?.l.main.loading()}
                />


                <AddToPanel open={this.state.openAddPanel} data={this.state.panelData}
                    onClose={() => this.setState({ openAddPanel: false })} />
            </IonContent>
        </IonPage>
    }
}
