import {Component} from 'react';
import SizePanel from "../panels/SizePanel";
import TextPanel from "../panels/TextPanel";
import StylePanel from "../panels/StylePanel";
import ArrangePanel from "../panels/ArrangePanel";
import ImagePanel from "../panels/ImagePanel";


export default class Vector extends Component<any, any> {
  static panels = [
    SizePanel,
    TextPanel,
    StylePanel,
    ImagePanel,
    ArrangePanel
  ];

  getStyle() {
    let {object} = this.props;
    return {
      mixBlendMode: object.blendMode
    }
  }

  getTransformMatrix({rotate, x, y, width, height}:any) {
    if (rotate) {
      let centerX = width / 2 + x;
      let centerY = height / 2 + y;
      return `rotate(${rotate} ${centerX} ${centerY})`;
    }
  }

  getObjectAttributes() {
    let {object, onRender, ...rest} = this.props;
    return {
      ...object,
      transform: this.getTransformMatrix(object),
      ref: onRender,
      ...rest
    };
  }
}
