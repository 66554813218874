import factory from 'mxgraph';

(window as any)['mxBasePath'] = 'assets/mxgraph';
const mx = factory({
    // not working see https://github.com/jgraph/mxgraph/issues/479
    mxBasePath: 'assets/mxgraph',
});
const optionKeys = [
    "mxBasePath",
    "mxDefaultLanguage",
    "mxForceIncludes",
    "mxImageBasePath",
    "mxLanguage",
    "mxLanguages",
    "mxLoadResources",
    "mxLoadStylesheets",
    "mxResourceExtension",
    "mxGraphModel",
    "mxGeometry",
    "mxPoint",
    "mxStylesheet",
];
optionKeys.forEach((key: string) => {
    (window as any)[key] = (mx as any)[key];
});
export default mx;