import React from "react";
import {IonAlert} from "@ionic/react";
import { inject } from "mobx-react";
import Lang from "../service/Lang";

export interface ConfirmProps {
    lang?:Lang,
}

@inject("lang")
export default class Confirm extends React.Component<ConfirmProps, any> {

    state = {visible: false, header: '', subHeader: '', message: ''};
    callback?:(result:boolean) => void;

    confirm(header:string, subHeader:string, message:string) {
        return new Promise(resolve => {

            this.setState({visible: true, header, subHeader, message});
            this.callback = (result => {
                this.setState({visible: false});
                resolve(result);
            })

        })

    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {lang} = this.props;
        return <>
            <IonAlert
                isOpen={this.state.visible}
                onDidDismiss={() => this.setState({visible: false})}
                header={this.state.header}
                subHeader={this.state.subHeader}
                message={this.state.message}
                buttons={[
                    {
                        text: lang?.l.main.cancel()!,
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: blah => {
                            this.callback!(false);
                        }
                    },
                    {
                        text: lang?.l.main.ok()!,
                        handler: () => {

                            this.callback!(true);
                        }
                    }
                ]}
            />
        </>

    }
}
