import type { BaseTranslation } from 'typesafe-i18n'

const en: BaseTranslation = {
    "errors": {
        "INTERNAL_ERROR": "Internal error!",
        "DEVICE_ADD_ALREADY": "Error, device already in your account!",
        "DEVICE_ADD_ANOTHER": "Error, device already in another account!",
        "DEVICE_ADD_GENERIC": "Error internal. Please contact to your provider!",
        "DEVICE_ADD_CODE": "Code not valid!",
        "DEVICE_ADD_NOT_SUPPORTED": "Device model not supported",
        "DEVICE_UPDATE_GENERIC": "Error in app!",
        "DEVICE_UPDATE_ACCOUNT": "Device not found in your account!",
        "DEVICE_DELETE_ACCOUNT": "Device not found in your account!",
        "DEVICE_DELETE_FILED": "Please provide a device identification",
        "DEVICE_DATA_NOT_ONLINE": "Device not online",
        "DEVICE_DATA_NOT_SUPPORTED": "Device not support data",
        "DEVICE_DATA_NOT_FOUND": "Device not found in your account!",
        "DEVICE_SETTINGS_NOT_FOUND": "Device not found in your account!",
        "DEVICE_AIN_INVALID": "Invalid device response",
        "DEVICE_AIN_NOT_ONLINE": "Device not online",
        "DEVICE_AIN_NOT_SUPPORTED": "Device not support data",
        "DEVICE_AIN_NOT_ACCOUNT": "Device not found in your account!",
        "DEVICE_OUTPUT_NOT_FOUND": "We cant find this output in this device!",
        "DEVICE_OUTPUT_ERROR": "Error controlling device outputs",
        "DEMO_NOT_ALLOWED": "Demo user not allowed to do this",
        "INPUT_NOT_FOUND": "Input not found!",
        "DEVICE_NOT_FOUND": "Device not found in your account!",
        "DEVICE_INVALID_RESPONSE": "Invalid device response",
        "DEVICE_UNDEFINED_RESPONSE": "Undefined response",
        "QTHING_NOT_FOUND": "QThing not found!",
        "QR_NOT_FOUND": "QR not found!",
        "USER_NOT_ALLOWED": "User not allowed!",
        "SCHEDULER_NOT_FOUND": "Scheduler not found in this table!",
        "AUTOMATION_NOT_FOUND": "PowerScheduler not found in this table!",
        "USER_EMAIL_TAKEN": "Email taken",
        "USER_USERNAME_TAKEN": "Username taken",
        "USER_LOGIN_INCORRECT": "Username/password invalid",
        "USER_LOGIN_EMPTY": "No username/password",
        "USER_RECOVERY_INVALID": "Invalid recovery token!",
        "USER_RECOVERY_EXPIRED": "Token expired",
        "USER_TOKEN_NOT_VALID": "Token not valid",
        "USER_PASSWORD_INVALID_OLD": "Invalid old password",
        "USER_PASSWORD_SHORT": "Password too short",
        "USER_INVALID_VERIFICATION_CODE": "Verification code not valid",
        "USER_ALREADY_VERIFIED": "User already verified",
        "SMS_PROVIDER_ERROR": "Error sending SMS, please try later",
        "PERMISSION_NOT_ALLOWED": "You dont have permission to do this in the device",
        "PERMISSION_NOT_FOUND": "Permission pair not found",
        "PERMISSION_REMOVE_YOURSELF": "You can not remove your self",
        "PERMISSION_ADD_OWNER": "This account is the owner of the device.",
        "PERMISSION_USER_NOT_FOUND": "User not found",
        "PERMISSION_NOT_VALID": "Permission not valid",
        "PERMISSION_USER_ALREADY": "This invitation is already in sent",
        "PERMISSION_RESEND_ERROR": "Error sending invitation link",
        "NOTIFICATIONS_PROVIDER_NOT_VALID": "Provider not available for notifications service.",
        "NOT_IMPLEMENTED_ERROR": "Logic not implemented already",
        "PAYMENT_METHOD_ERROR": "Error processing your payment method",
        "PAYMENT_ERROR": "Error processing your payment",
        "PAYMENT_LIST_ERROR": "Error processing your payment listing",
        "PAYMENT_NOT_ENABLED_ERROR": "Please configure the payment method first",
        "DEVICE_DELETE_PAYMENT": "Error removing your payment",
        "SIM_ACTIVATION_ERROR": "Error activating sim",
        "DEVICE_NOT_ONLINE": "Device is not online",
        "DEVICE_NOT_COMPATIBLE": "Device is not compatible with this settings",
        "INVITATION_NOT_VALID": "Invitation not valid",
        "INVITATION_REVOKED": "Invitation was revoked by owner",
        "INVITATION_USED": "Invitation was used by other user",
        "INVITATION_YOU": "Invitation is from you",
        "NOT_FOUND": "Controller not found",
        "PANEL_FOUND": "",
        "CLIENT_UUID_REQUIRED": "",
        "DEVICE_TRANSFER_USER_NOT_FOUND": "",
        "DEVICE_TRANSFER_OWNER": "",
        "DEVICE_NOT_VALID": "",
        "INVALID_PARAMETERS": "",
        "CREATE_USER_EMAIL_USED": "",
        "CREATE_USER_USERNAME_USED": "",
        "FIREBASE_NOT_ENABLED": "",
        "CREATE_USER_EMAIL_EMPTY": "",
        "CREATE_USER_PASSWORD_EMPTY": "",
        "CREATE_USER_USERNAME_EMPTY": "",
        "CREATE_USER_USERNAME_NOT_VALID": "",
        "CREATE_USER_PHONE_EMPTY": "",
        "DEVICE_NOT_SUBSCRIBED": "",
        "PAYMENT_USER_NOT_ALLOWED": ""
    },
    "main": {
        "login": "Login",
        "language": "Language",
        "software_demo": "Are you interested in testing the Software? Click here to start the demo version of CloudRTU",
        "start_demo": "Start demo",
        "demo_version": "Demo version",
        "login_header": "Login to control panel",
        "login_user_email": "Email / User",
        "logn_password": "Password",
        "login_remember": "Remember login",
        "login_remember_me": "Remember me",
        "login_button": "Login",
        "login_new_account": "New account",
        "login_recovery": "Recovery account",
        "login_download": "Download APP",
        "menu": "Menu",
        "menu_home": "Home",
        "menu_hmi": "HMI",
        "menu_account": "Account",
        "menu_settings": "Settings",
        "menu_usb": "USB Config",
        "menu_help": "Help",
        "menu_logout": "Logout",
        "menu_theme": "Dark Theme",
        "tab_back": "Back",
        "tab_dashboard": "Dashboard",
        "tab_devices": "Devices",
        "tab_map": "Map",
        "tab_control": "Control",
        "tab_status": "Status",
        "tab_log": "Logs",
        "tab_settings": "Settings",
        "dashboard_verify_title": "Verify account",
        "dashboard_button": "Verify",
        "dashboard_content": "Please verify account",
        "dashboard_email_sent": "We have sent an email to your email account: <b> {email} </b>",
        "dashboard_code_input": "Verification code",
        "dashboard_resend": "Resend",
        "dasbhoard_head": "To start",
        "dashboard_welcome": "Welcome to CloudRTU",
        "dashboard_welcome_message": "From here you will have control of your devices.",
        "dashboard_understood": "I Understood",
        "dashboard_title": "Dashboard",
        "dashboard_panels": "Panels",
        "dashboard_devices": "Devices",
        "online": "Online",
        "offline": "Offline",
        "dashboard_error": "Failed to load. Please check your connection and try again.",
        "reload": "Reload",
        "Loading": "Loading…",
        "loading": "Loading…",
        "dasbhoard_button_control": "Control",
        "devices_title": "Devices",
        "search": "Search",
        "devices_error": "Error consulting your devices!",
        "devices_retry": "Try again",
        "map_access_error": "Location service could not be accessed",
        "map_title": "Devices map",
        "map_current_location": "Current location",
        "map_set_device_location": "Assign location to device",
        "cancel": "Cancel",
        "map_device_status": "Device status",
        "close": "Close",
        "map_name": "Name",
        "map_status": "Status",
        "map_control": "Control the device",
        "map_value": "Value",
        "add_device_error_internet": "Internet error!",
        "add_device_error_name": "You have to enter a name for the device",
        "add_device_error_code": "You have to enter the identification code",
        "add_device_error_payment": "You have to select a payment method",
        "add_device_error_subscription": "You have to select a subscription",
        "ok": "OK",
        "add_device_coupon_charged": "Applied",
        "add_device_help": "Before adding a device in the APP, follow these instructions to initialize the device: <a href=\"https://cloudrtu.com/inicio-rapido/\" rel=\"noopener noreferrer\" target=\"_blank\"> Quick Start Guide</a>",
        "add_device_admin": "Add in Admin panel",
        "add_device_name": "Device name",
        "add_device_example_name": "Drip irrigation",
        "add_device_qrcode": "QR Code",
        "add_device_subscriptions_load": "Subscriptions. Press to load",
        "add_device_payment_method": "Method of payment",
        "add_device_coupon": "Coupon",
        "add_device_coupon_check": "Check",
        "add_simulator_title": "From here you can create a simulated device.",
        "add_simulator_model": "Model to simulate",
        "add_simulator_protocol": "Protocol",
        "add_device_header": "Add device",
        "add_device_device": "Device",
        "add_device_simulator": "Simulator",
        "edit": "Edit",
        "panel_create_new": "Create new",
        "title": "Title",
        "size": "Size",
        "remove": "Remove",
        "add_payment_error": "Error setting up payment",
        "add_payment_not_implemented": "Payment method not implemented",
        "add_payment_error_internet": "Error setting up payment, check your internet connection",
        "add_payment_holder_name": "Card Holder",
        "add_payment_credit_message": "By adding a payment method, you allow CloudRTU to make the relevant charges to your bank account.",
        "add_payment_sepa_holder": "Full name Owner",
        "add_payment_sepa_message": "By providing your payment information and confirming this payment, you authorise (A) Rocket Rides and Stripe, our payment service provider and/or PPRO, its local service provider, to send instructions to your bank to debit your account and (B) your bank to debit your account in accordance with those instructions. As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. Your rights are explained in a statement that you can obtain from your bank. You agree to receive notifications for future debits up to 2 days before they occur.",
        "add_payment_title": "Add new payment method",
        "add_payment_help_message": "You can add a payment method to subscribe to multiple products.",
        "add_payment_method": "Payment method",
        "add_payment_credit_card": "Credit card",
        "add_payment_sepa": "SEPA debit",
        "add_panel_success": "Successfully added",
        "add_panel_select": "Select panel",
        "add_panel_title": "Add new panel",
        "add_panel_help": "Panels are boxes that are displayed at the start of the application. These boxes can hold the elements of different inputs from multiple devices.",
        "password_short": "New password too short",
        "password_not_match": "Passwords do not match",
        "error": "Error",
        "password_current": "Current password",
        "password_current_help": "Enter here old password",
        "password_new": "New password",
        "password_new_help": "Enter here new password",
        "password_repeat": "Repeat password",
        "password_repeat_help": "Repeat new password",
        "change": "Change",
        "demo_conditions": "Accept the conditions",
        "demo_header": "One more step before continuing",
        "demo_subtitle": "To start the demo version it is necessary that you provide us with the following information:",
        "demo_fullname": "Full name",
        "demo_business": "Business name",
        "demo_phone": "Phone",
        "email": "Email",
        "legal_terms": "Legal terms",
        "legal_terms_url": "<span>I accept <a href=\"/legal#tos\" rel=\"noopener noreferrer\" target=\"_blank\"> terms and conditions of service</a></span>",
        "privacy_terms": "Legal terms",
        "privacy_terms_url": "<span>I accept and I understand the <a href=\"/legal#privacy\" rel=\"noopener noreferrer\" target=\"_blank\">privacy policy</a></span>",
        "automation_confirm": "Are you sure to remove this automation?",
        "atomation_output_name": "Output name",
        "automation_change_state": "Change state",
        "automation_on": "ON",
        "automation_off": "OFF",
        "automation_set_value": "Set value",
        "automation_select_value": "Select value",
        "automation_destination_email": "Destination email",
        "automation_email_message": "Message",
        "automation_send_email_to": "Send email to {email}",
        "automation_show_notification": "Show notification",
        "automation_action_type": "Action type",
        "automation_set_output": "Set output",
        "automation_action_send_email": "Send email",
        "automation_action_notify": "Send notification",
        "automation_condition_title": "Custom operation (Type $ for suggestions)",
        "automation_boolean": "Boolean connector",
        "and": "And",
        "or": "Or",
        "automation_if": "If (Input value)",
        "automation_is": "Is",
        "automation_to_value": "To (Value)",
        "automation_if_short": "If",
        "automation_to_short": "to",
        "automation_operation": "Operation",
        "automation_enable": "Enable",
        "automation_wait_title": "Wait this time (seconds) before execute any action",
        "automation_rearm": "After this time (seconds) re-enable automation.",
        "add": "Add",
        "automation_actions": "Actions",
        "notes": "Notes",
        "automation_executed": "Executed",
        "automation_enabled": "Enabled",
        "automation_disabled": "Disabled",
        "automation_automations": "Automation",
        "automation_automation": "Automation",
        "save": "Save",
        "automation_edit_condition": "Edit condition",
        "automation_edit_action": "Edit action",
        "scheduler_output": "Output",
        "enable": "Enable",
        "scheduler_time": "Time",
        "scheduler_repeat_week": "Repeat week",
        "monday": "Monday",
        "thursday": "Thursday",
        "wednesday": "Wednesday",
        "tuesday": "Tuesday",
        "friday": "Friday",
        "saturday": "Saturday",
        "sunday": "Sunday",
        "scheduler_fixed_date": "Fixed date",
        "date": "Date",
        "scheduler_multiple_dates": "Multiple dates",
        "scheduler_enable": "Enable scheduler",
        "scheduler_disable_warning": "Warning! The scheduler are disabled. No scheduled action takes place.",
        "scheduler_title_modal": "Scheduler",
        "galery_error": "Error loading image gallery",
        "tab_scheduler": "Scheduler",
        "tab_automation": "Automation",
        "loading_device": "Loading device...",
        "error_loading_device": "Error loading the device",
        "output_off": "OFF",
        "output_on": "ON",
        "status_no_data": "No data retrieved from device",
        "status_inputs_hidden": "All inputs are hidden",
        "status_download_registers": "Download registers",
        "start_date": "Start Date",
        "end_date": "End date",
        "download": "Download",
        "status_input_details": "Input details",
        "status_display_dashboard": "See this control in Dashboard (Home)",
        "input_details": "Input details",
        "input_search_registers": "Search registers",
        "input_unique": "Unique values",
        "diff": "Difference",
        "log_load_error": "The device could not be load, check the internet connection.",
        "event": "Event",
        "details": "Details",
        "user": "User",
        "offline_long": "Offline",
        "log_power_recovery": "Power supply ok",
        "log_power_faillure": "Power supply faillure",
        "power_supply": "Power supply",
        "sent": "Sent",
        "sent_error": "Sent error",
        "power_supply_recovery": "Power supply recovery",
        "hardware_timer": "Hardware timer",
        "log_register_details": "Register details",
        "log_register_id": "Register ID",
        "log_register_date": "Date",
        "log_register_output_name": "Output name",
        "log_register_event": "Event type",
        "log_register_description": "Event description",
        "log_register_manual": "Manual output change",
        "log_register_scheduler": "Output change by scheduler",
        "log_register_automation": "Output changed by automation",
        "log_register_online": "Device online",
        "log_register_offline": "Device offline",
        "log_register_power": "Power supply changed",
        "log_register_error": "Internal error",
        "no_error": "No errors",
        "log_register_username": "Username",
        "log_register_filter": "Filter by type",
        "filter": "Filter",
        "type": "Type",
        "load_more": "Load more",
        "loading_more": "Loading more...",
        "log_total": "Total: {total}",
        "reles": "Relays",
        "analog_inputs": "Analog inputs",
        "temp_and_humd": "Temperature and humidity",
        "digital_inputs": "Digital inputs",
        "interlock": "Events Interlock",
        "modbus": "Modbus",
        "inputs": "Inputs",
        "hardware_automation": "Automations",
        "device_not_support_settings": "Your device does not support these settings",
        "device_offline": "Your device is not online",
        "settings_general": "General",
        "settings_subscription": "Subscription",
        "settings_outputs": "Outputs",
        "share": "Share",
        "download_app_title": "Download Mobile Applications",
        "download_get_app": "Get CloudRTU",
        "download_app_platforms": "Download CloudRTU for these platforms",
        "download_pc_mac": "PC & Mac",
        "download_chrome": "Download for Google Chrome",
        "download_install": "Install on this computer",
        "download_incompatible": "This device does not support PWA.",
        "download_beta": "Be part of the CloudRTU Open Beta",
        "download_beta_android": "Join Android Beta",
        "download_beta_ios": "Join iOS Beta",
        "invitation": "",
        "invitation_accepted": "",
        "see_devices": "",
        "app_settings_title": "",
        "app_settings_timezone": "",
        "app_settings_current_time": "",
        "see_bills": "",
        "modify": "",
        "enable_billing": "",
        "payment_configure": "",
        "username": "",
        "billing": "",
        "change_password": "",
        "draft": "",
        "bill_open": "",
        "bill_paid": "",
        "bill_void": "",
        "bill_unpaid": "",
        "bills_list": "",
        "bill_info_header": "",
        "problem_ocurrurs": "",
        "password_too_short": "",
        "problem_occurred": "",
        "error_occurred": "",
        "password_recovery": "",
        "password_recovery_email": "",
        "new_password_set": "",
        "back_home": "",
        "recovery_password_title": "",
        "email_address": "",
        "email_was_sent": "",
        "contact_info": "",
        "postal_address": "",
        "authentication_data": "",
        "join_newsletter": "",
        "create_account": ""
    }
};

export default en