import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonLabel, IonList, IonModal, IonRow, IonSelect, IonSelectOption, IonSpinner, IonText, IonTitle, IonToolbar } from "@ionic/react";
import React, { RefObject } from "react";
import { DeviceOutputExecuteType } from "../pages/Device/DeviceLog";
import { Device, LogAlarm, LogOutput } from "../service/API";
import {
    alarmOutline,
    alert,
    alertCircleOutline,
    notificationsOutline,
    personOutline,
    warningOutline
} from "ionicons/icons";
import Lang from "../service/Lang";
import { inject } from "mobx-react";
import { Store } from "../service/Store";
import CSV from "../service/CSV";

interface DeviceLogAlarmsProps {
    store ? : Store,
    lang?: Lang,
    device?: Device,
    onSeenAlarm ? : (item:LogAlarm) => void
    onSeenAlarms ? : () => void
}
type DeviceLogAlarmsState = {

    items?: LogAlarm[],
    start?: number,
    length?: number,
    filter?: string,
    search?: string,
    details?: LogAlarm,
    loading: boolean,
}

@inject("store", "lang")
export default class DeviceLogAlarms extends React.Component<DeviceLogAlarmsProps, DeviceLogAlarmsState> {

    rootComponent: RefObject<HTMLIonGridElement> = React.createRef();
    state: DeviceLogAlarmsState = {
        items: new Array<LogAlarm>(),
        start: 0,
        length: 50,
        loading: false,
    }

    filter = (el: LogAlarm) => {
        if (this.state.search) {
            let input = this.state.search.toLowerCase();
            if (this.compare(el.message, input)) return true;
            return false;
        }
        return true;
    }

    compare(a: string, b: string) {
        if (!a) return false;
        if (!b) return false;
        return a.toLowerCase().indexOf(b.toLowerCase()) >= 0;
    }

    componentDidUpdate(prevProps: Readonly<DeviceLogAlarmsProps>, prevState: Readonly<DeviceLogAlarmsState>, snapshot?: any): void {
        if (this.props.device && !prevProps.device) {
            this.loadData();
        } else if (this.props.device && prevProps.device && this.props.device!.id !== prevProps.device!.id) {
            this.setState({ items: [], start: 0 });
            this.loadData();
        }
    }

    componentDidMount(): void {
        if (this.props.device) {
            this.loadData();
        }
    }

    handleViewAll = () => {
        this.props.store?.api.setLogAlarmsSeen(this.props.device!.id!).then(res => {
            if (this.props.onSeenAlarms) {
                this.props.onSeenAlarms();
            }
        });
    }

    handleReload = () => {
        this.setState({ items: [], start: 0 }, () => {
            this.loadData();
        });
    }

    getTriggerText(item: LogOutput): string {
        const { lang } = this.props;
        if (item.trigger < DeviceOutputExecuteType.EXECUTE_ONLINE) {
            return (item.sent ? lang?.l.main.sent() : lang?.l.main.sent_error()) + " " + item.error
        }
        if (item.trigger === DeviceOutputExecuteType.EXECUTE_ONLINE) {
            return lang?.l.main.online()!;
        }
        if (item.trigger === DeviceOutputExecuteType.EXECUTE_OFFLINE) {
            return lang?.l.main.offline_long()!;
        }
        if (item.trigger === DeviceOutputExecuteType.EXECUTE_AC_STATUS) {
            return item.error === "OK" ? lang?.l.main.power_supply_recovery()! : lang?.l.main.log_power_faillure()!;
        }
        if (item.trigger === DeviceOutputExecuteType.EXECUTE_ERROR) {
            return "Error";
        }
        if (item.trigger === DeviceOutputExecuteType.EXECUTE_AUTOMATION) {
            return lang?.l.main.automation_automation() + ": " + item.error;
        }
        if (item.trigger === DeviceOutputExecuteType.EXECUTE_TIMER) {
            return lang?.l.main.hardware_timer()!;
        }
        if (item.trigger === DeviceOutputExecuteType.EXECUTE_ALARM) {
            return "Alarma"
        }
        return "";
    }

    getTypeText(value: LogAlarm): string {
        // if (value.trigger < 3) return value.oname + " " + (value.state ? "ON" : "OFF")
        // if (value.trigger === 8) return value.oname + " " + (value.state ? "ON" : "OFF")
        // else
        return DeviceLogAlarms.getTriggerName(this.props.lang!, value.event);
    }



    handleLoadList = (ev: any) => {
        
        if (this.state.start! > 0) {
            this.loadData()?.then(() => {
                if (ev.target && ev.target.complete) {
                    ev.target.complete();
                }

            })
        } else {

            if (ev.target) {
                ev.target.disabled = true;
            }
        }

    }


    async loadData() {
        const id = this.props.device?.id;
        await this.setState({ loading: true });
        return this.props.store?.api.deviceAlarmsLog(id!, this.state.start!, this.state.length!).then(value => {
            if (value.success) {
                let items = this.state.items!;
                for (let i = 0; i < value.data.log.length; i++) {
                    items.push(value.data.log[i]);
                }
                this.setState({items: items, start: value.data.next});
            }
        }).finally(() => {
            this.setState({ loading: false });
        })
    }

    handleDownload = (dateStart: string, dateEnd: string) => {
        const {lang} = this.props;
        const id = this.props.device!.id;
        return this.props.store?.api.logDate(id!, dateStart, dateEnd).then(value => {
            if (value.success) {
                let csv = new CSV();
                csv.row(["#", lang?.l.main.date(), lang?.l.main.event(), lang?.l.main.details(), lang?.l.main.scheduler_output(), lang?.l.main.user()]);
                value.data.log.map(value1 => {
                    csv.row([value1.id,
                        this.props.store?.moment(value1.date, "X").format("YYYY-MM-DD HH:mm:ss"),
                        DeviceLogAlarms.getTriggerName(lang!, value1.trigger),
                        this.getTriggerText(value1),
                        value1.oname + (value1.trigger < 3 ? (value1.state ? " ON" : " OFF") : ""),
                        value1.fullname
                    ]);
                    return value1;
                });

                return csv.download("CloudRTU_" + this.props.device!.name + "_log.csv");
            }

        })
    };

    handleDetailsItem = (item: LogAlarm) => () => {
        this.setState({ details: item });
        this.props.store?.api.setLogAlarmSeen(item.device_id, item.id).then((res) => {
            if (res.success) {
                if (this.props.onSeenAlarm) {
                    this.props.onSeenAlarm(item);
                }

                item.seen = 1;
                this.setState({  });
            }

        })
    }

    renderDetailsModal() {
        const { lang } = this.props;
        if (!this.state.details) return "";
        let item = this.state.details!;
        return <>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{lang?.l.main.log_register_details()}</IonTitle>
                    <IonButtons slot={"end"}>
                        <IonButton onClick={() => this.setState({ details: undefined })}>{lang?.l.main.close()}</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>

                <IonList lines={"full"}>
                    <IonItem>
                        <IonLabel position={"stacked"}>{lang?.l.main.log_register_id()}</IonLabel>
                        <IonText>{item.id}</IonText>
                    </IonItem>
                    <IonItem>
                        <IonLabel position={"stacked"}>{lang?.l.main.log_register_date()}</IonLabel>
                        <IonText>{this.props.store?.moment(item.createdAt).format("DD-MM-YYYY HH:mm:ss")}</IonText>
                    </IonItem>
                    <IonItem>
                        <IonLabel position={"stacked"}>{lang?.l.main.log_register_event()}</IonLabel>
                        <IonText>{this.getTypeText(item)}</IonText>
                    </IonItem>
                    <IonItem>
                        <IonLabel position={"stacked"}>{lang?.l.main.log_register_description()}</IonLabel>
                        <IonText>
                            {item.event === DeviceOutputExecuteType.EXECUTE_TYPE_MANUAL && lang?.l.main.log_register_manual()}
                            {item.event === DeviceOutputExecuteType.EXECUTE_TYPE_SCHEDULER && lang?.l.main.log_register_scheduler()}
                            {item.event === DeviceOutputExecuteType.EXECUTE_TYPE_EVENT && lang?.l.main.log_register_automation()}
                            {item.event === DeviceOutputExecuteType.EXECUTE_ONLINE && lang?.l.main.log_register_online()}
                            {item.event === DeviceOutputExecuteType.EXECUTE_OFFLINE && lang?.l.main.log_register_offline()}
                            {item.event === DeviceOutputExecuteType.EXECUTE_AC_STATUS && lang?.l.main.log_register_power()}
                            {item.event === DeviceOutputExecuteType.EXECUTE_ERROR && lang?.l.main.log_register_error()}
                            {item.event === DeviceOutputExecuteType.EXECUTE_AUTOMATION && lang?.l.main.automation_automation()}
                            {item.event === DeviceOutputExecuteType.EXECUTE_TIMER && lang?.l.main.scheduler_title_modal()}
                            {item.event === DeviceOutputExecuteType.EXECUTE_ALARM && "Alarma"}
                        </IonText>
                    </IonItem>
                    {item.event === DeviceOutputExecuteType.EXECUTE_AUTOMATION && item.entity_id &&
                    
                        <IonItem>
                            <IonLabel position={"stacked"}>Editar Automatización</IonLabel>
                            <IonText>
                                {item.message ? item.message : "(No hay información)"}
                            </IonText>
                            <IonButton routerLink={"/device/" + item.device_id + "/details?tab=programmer"}>Editar Automatización</IonButton>
                        </IonItem>
                    
                    }
                    <IonItem>
                        <IonLabel position={"stacked"}>Mensaje</IonLabel>
                        <IonText>
                            {item.message ? item.message : "(No hay información)"}
                        </IonText>
                    </IonItem>

                    <IonItem>
                        <IonLabel position={"stacked"}>{lang?.l.main.add_device_name()}</IonLabel>
                        <IonText>
                            {this.props.device?.name}
                        </IonText>
                    </IonItem>
                </IonList>
            </IonContent>

        </>
    }

    renderIcon(value: LogAlarm) {
        if (value.event === DeviceOutputExecuteType.EXECUTE_TYPE_MANUAL) return personOutline;
        if (value.event === DeviceOutputExecuteType.EXECUTE_TYPE_SCHEDULER) return alarmOutline;
        if (value.event === DeviceOutputExecuteType.EXECUTE_TYPE_EVENT) return alertCircleOutline;
        if (value.event === DeviceOutputExecuteType.EXECUTE_ONLINE) return notificationsOutline;
        if (value.event === DeviceOutputExecuteType.EXECUTE_OFFLINE) return notificationsOutline;
        if (value.event === DeviceOutputExecuteType.EXECUTE_AC_STATUS) return notificationsOutline;
        if (value.event === DeviceOutputExecuteType.EXECUTE_ERROR) return notificationsOutline;
        if (value.event === DeviceOutputExecuteType.EXECUTE_AUTOMATION) return notificationsOutline;
        if (value.event === DeviceOutputExecuteType.EXECUTE_TIMER) return notificationsOutline;
        if (value.event === DeviceOutputExecuteType.EXECUTE_ALARM) return warningOutline;
        return "";
    }

    renderDetails(item: LogAlarm) {
        // const { lang } = this.props;
        return <>
            {!item.seen && <IonButton><IonIcon icon={alert} /></IonButton>}
            <IonText>{ item.message }</IonText>
        </>
        // if (item.trigger <= DeviceOutputExecuteType.EXECUTE_TYPE_EVENT) {
        //     return <><IonIcon
        //         icon={item.sent ? checkmarkDoneOutline : warningOutline}></IonIcon> {item.error ? item.error : " OK"}
        //     </>;
        // } else if (item.trigger === DeviceOutputExecuteType.EXECUTE_ONLINE) {
        //     return <><IonIcon icon={cellularOutline}></IonIcon> {lang?.l.main.online()}</>;
        // } else if (item.trigger === DeviceOutputExecuteType.EXECUTE_OFFLINE) {
        //     return <div><IonIcon icon={cellularOutline} color={"danger"}></IonIcon> {lang?.l.main.offline_long()}</div>;
        // } else if (item.trigger === DeviceOutputExecuteType.EXECUTE_AC_STATUS) {
        //     return <div><IonIcon
        //         icon={item.error === "OK" ? flashOutline : flashOffOutline}></IonIcon> {item.error === "OK" ? lang?.l.main.log_power_recovery() : lang?.l.main.log_power_faillure()}
        //     </div>;
        // } else if (item.trigger === DeviceOutputExecuteType.EXECUTE_ERROR) {
        //     return <div><IonIcon
        //         icon={warningOutline}></IonIcon> {item.error}
        //     </div>;
        // } else if (item.trigger === DeviceOutputExecuteType.EXECUTE_AUTOMATION) {
        //     return <div><IonIcon
        //         icon={extensionPuzzleOutline}></IonIcon> {item.error}
        //     </div>;
        // } else if (item.trigger === DeviceOutputExecuteType.EXECUTE_TIMER) {
        //     return <div><IonIcon
        //         icon={timeOutline}></IonIcon> {item.error}
        //     </div>;
        // } else if (item.trigger === DeviceOutputExecuteType.EXECUTE_ALARM) {
        //     return <div>{item.error}
        //     </div>;
        // }
    }

    static getTriggerName(lang: Lang, trigger: number) {
        if (trigger === DeviceOutputExecuteType.EXECUTE_TYPE_MANUAL) {
            return lang?.l.main.tab_control()
        }
        if (trigger === DeviceOutputExecuteType.EXECUTE_TYPE_SCHEDULER) {
            return lang?.l.main.tab_scheduler()
        }
        if (trigger === DeviceOutputExecuteType.EXECUTE_TYPE_EVENT) {
            return lang?.l.main.tab_automation()
        }
        if (trigger === DeviceOutputExecuteType.EXECUTE_ONLINE) {
            return lang?.l.main.online()
        }
        if (trigger === DeviceOutputExecuteType.EXECUTE_OFFLINE) {
            return lang?.l.main.offline()
        }
        if (trigger === DeviceOutputExecuteType.EXECUTE_AC_STATUS) {
            return lang?.l.main.power_supply()
        }
        if (trigger === DeviceOutputExecuteType.EXECUTE_ERROR) {
            return lang?.l.main.error()
        }
        if (trigger === DeviceOutputExecuteType.EXECUTE_AUTOMATION) {
            return lang?.l.main.automation_automation()
        }
        if (trigger === DeviceOutputExecuteType.EXECUTE_TIMER) {
            return lang?.l.main.scheduler_title_modal()
        }
        if (trigger === DeviceOutputExecuteType.EXECUTE_ALARM) {
            return "Alarma"
        }
        return "";
    }



    render(): React.ReactNode {
        const { lang } = this.props;
        const device = this.props.device;
        // if (!device) return;
        return <>
            
            {/* <IonHeader>

                <IonToolbar color={"primary"}>
                    <IonSearchbar ref={this.search}
                        onIonChange={() => this.setState({ search: this.search.current.value })}
                        placeholder={lang?.l.main.log_register_filter()!} />
                </IonToolbar>
            </IonHeader> */}
            
            <IonGrid ref={this.rootComponent} >
                <IonRow>
                    <IonCol>
                        <IonItem>
                        <IonSelect title={lang?.l.main.filter()} okText={lang?.l.main.ok()} onIonChange={(ev: any) => {
                            this.setState({ filter: ev.currentTarget.value })
                        }} cancelText={lang?.l.main.cancel()} value={this.state.filter || ""}>
                            <IonSelectOption value="">{lang?.l.main.type()}</IonSelectOption>
                            {device?.outputs.map((value, index) => {
                                return <IonSelectOption
                                    value={String(value.oindex)}>{value.name}</IonSelectOption>
                            })}
                            </IonSelect>
                        </IonItem>
                    </IonCol>
                    <IonCol>
                        
                    <IonItem><IonLabel>{lang?.l.main.date()}</IonLabel>
                        </IonItem></IonCol>
                    <IonCol>
                    <IonItem><IonButton onClick={this.handleViewAll}>Marcar todo Visto</IonButton></IonItem></IonCol>
                </IonRow>
                {this.state.items?.filter(this.filter).map((value, index) => {
                    let date = this.props.store?.moment(value.createdAt!)!;
                    return <IonRow key={value.id} className={"ion-item"}
                        onClick={this.handleDetailsItem(value)}>
                        <IonCol>
                            <IonIcon icon={this.renderIcon(value)} />

                            <IonText>
                                {this.getTypeText(value)}
                            </IonText>
                        </IonCol>
                        <IonCol>{date.format("ddd DD/MM/YY HH:mm")}</IonCol>
                        <IonCol>{this.renderDetails(value)}</IonCol>
                    </IonRow>
                })}
                {this.state.loading && <IonRow className={"ion-item"}>
                    <IonCol size={"12"}>
                        <IonSpinner /> Cargando más elementos
                    </IonCol>
                </IonRow>}


            </IonGrid>

            {this.state.start === -1 &&
                <IonItem><IonLabel>{lang?.l.main.log_total({ total: this.state.items!.length })}</IonLabel></IonItem>}
            {this.state.start === -1 ||
                <IonItem><IonButton onClick={this.handleLoadList}>{lang?.l.main.load_more()}</IonButton></IonItem>}
            

            <IonInfiniteScroll onIonInfinite={this.handleLoadList}>
                <IonInfiniteScrollContent loadingText={lang?.l.main.loading_more()!}></IonInfiniteScrollContent>
            </IonInfiniteScroll>

            <IonModal isOpen={!!this.state.details} onDidDismiss={() => this.setState({details: undefined})}>
                {this.renderDetailsModal()}
            </IonModal>
        
        </>
    }

}