
import Registers from "../Registers";
import Exportable from "./Exportable";
import {MemoryAddress} from "./MemoryMapper";

export type DinTriggerType = {
    Status: number,
    Confirm_Alarm_Time: number,
    Confirm_Two_intervals_Alarm_Time: number,
    Counter: number,

}
export default class DinTrigger extends Registers implements Exportable {



    din : DinTriggerType[] = [];
    values2 : any[][] = [];

    async Read(): Promise<any> {
        let result = await this.readHoldingRegistersArray(this.mapper.get(MemoryAddress.DIN_SETTINGS), 32);
        if(!result) return false;
        /*
        for (int StartReg = 0; StartReg < 8; ++StartReg)
        {
            this._DIN_Status[StartReg] = Convert.ToUInt16(Modbus.GetRegValue(data1, StartReg, 1), 16);
            this._DIN_Confirm_Alarm_Time[StartReg] =
                Convert.ToUInt16(Modbus.GetRegValue(data1, StartReg + 8, 1), 16);
            this._DIN_Two_intervals_Alarm_Time[StartReg] =
                Convert.ToUInt16(Modbus.GetRegValue(data1, StartReg + 16, 1), 16);
            this._DIN_Counter1[StartReg] =
                Convert.ToUInt16(Modbus.GetRegValue(data1, StartReg + 24, 1), 16);
        }*/
        for (let index = 0; index < 8; index++) {
            this.din[index] = {
                Status: result[index],
                Confirm_Alarm_Time: result[index + 8],
                Confirm_Two_intervals_Alarm_Time: result[index + 16],
                Counter: result[index + 24],
            };
            
        }
        result = await this.readHoldingRegistersArray(this.mapper.get(MemoryAddress.DIN_VALUES_SETTINGS), 18);
        if(!result) return false;
        for (let i = 0; i < 3; i++) {
            this.values2[i] = [];
            for (let j = 0; j < 6; j++) {
                this.values2[i].push(result[ i * 6 + j ]);
            }
            
        }
        return true;
        
    }

    async Write(): Promise<any> {
        let data : number[] = [];
        this.din.forEach(a => data.push(a.Status));
        this.din.forEach(a => data.push(a.Confirm_Alarm_Time));
        this.din.forEach(a => data.push(a.Confirm_Two_intervals_Alarm_Time));
        this.din.forEach(a => data.push(a.Counter));

        await this.writeMultipleRegisters(this.mapper.get(MemoryAddress.DIN_SETTINGS), data);
        data = [];

        this.values2.forEach(a => a.forEach(b => data.push(b)));
        await this.writeMultipleRegisters(this.mapper.get(MemoryAddress.DIN_VALUES_SETTINGS), data);
        return this.writeMultipleRegisters(79, [1]);

    }

    
    export() {
        return {
            din: this.din,
            values2: this.values2,
        }
    }
    import(object: any) {
        this.din = object.din;
        this.values2 = object.values2;
    }

}
