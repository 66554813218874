import React from "react";
import {IonCol, IonGrid, IonIcon, IonRow, IonSpinner, IonText} from "@ionic/react";
import {checkmarkCircleOutline} from "ionicons/icons";
import {inject} from "mobx-react";
import {Store} from "../service/Store";
import Lang from "../service/Lang";


export interface GalleryProps {
    store?:Store;
    lang?:Lang;
    selection?:string;
    onChange?: (item:string) => void;
}

export interface GalleryState {
    selected?:string;
    gallery?: string[];
    loading?:boolean;
    error?:boolean;
}

@inject("store", "lang")
export default class Gallery extends React.Component<GalleryProps, GalleryState> {

    state:GalleryState = {};


    loadGallery() {
        this.setState({loading: true});
        this.props.store?.api.gallery().then(value => {
            if(value.success) {
                this.setState({gallery: value.data, selected: this.props.selection, loading: false});
            } else {
                this.setState({loading: false, error: true})
            }
        });
    }

    componentDidMount(): void {
        this.loadGallery();
    }

    componentDidUpdate(prevProps: Readonly<GalleryProps>, prevState: Readonly<GalleryState>, snapshot?: any): void {
        if(this.props.selection !== prevProps.selection) {
            this.setState({selected: this.props.selection});
        }
    }

    handleChange = (item:string) => (ev:any) => {
        this.setState({selected: item});
        this.props.onChange!(item);
    }

    renderItem = (item:string, key:number) => {
        return <IonCol size={"4"} key={key} onClick={this.handleChange(item)}>
            {item === this.state.selected && <div className={"gallery-selected"}><IonIcon icon={checkmarkCircleOutline} /></div>}
            <img src={item} alt={item} />
        </IonCol>

    }
    render(): React.ReactNode {
        if(!this.state) return "";
        const {lang} = this.props;
        if(this.state.loading) {
            return <IonSpinner />
        }
        if(this.state.error) {
            return <IonText>{lang?.l.main.galery_error()}</IonText>
        }
        return <IonGrid>
            <IonRow>
                {this.state.gallery && this.state.gallery.map(this.renderItem)}
            </IonRow>
        </IonGrid>
    }

}
