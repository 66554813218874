import { IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonList, IonPage, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar } from "@ionic/react";
import React from "react";

export default class Region extends React.Component<any, any> {

    handleChange = (ev: any) => {
        console.log(ev);
    }

    render(): React.ReactNode {
        return <IonPage>
            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonTitle>CloudRTU</IonTitle>

                </IonToolbar>
            </IonHeader>
            <IonContent scrollEvents={true}>
                <div className={"login-overlay login-background-1"}>
                    <IonGrid style={{ maxWidth: '600px' }}>
                        <IonRow>
                            <IonCol size="6" offset={"3"}>
                                <img alt={"CloudRTU"} className={"logo"} src={"/assets/logo.png"} /></IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol size="12">
                                <IonCard style={{ padding: 16 }}>

                                    <IonCardContent>

                                        <IonList>
                                            <IonSelect label="Seleccione la region en la que se encuentra" onIonChange={this.handleChange}>
                                                <IonSelectOption>Jaén</IonSelectOption>
                                                <IonSelectOption>Madrid</IonSelectOption>
                                                <IonSelectOption>Barcelona</IonSelectOption>
                                                <IonSelectOption>Valencia</IonSelectOption>
                                                <IonSelectOption>País Vasco</IonSelectOption>
                                            </IonSelect>
                                        </IonList>
                                    </IonCardContent>
                                </IonCard>

                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </div>

            </IonContent>
        </IonPage>
    }




}