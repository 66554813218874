import React from "react";
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCheckbox,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonInputPasswordToggle,
    IonItem,
    IonItemDivider,
    IonItemGroup,
    IonLabel,
    IonPage,
    IonRow,
    IonSpinner,
    IonTitle,
    IonToast,
    IonToolbar
} from "@ionic/react";
import { personOutline } from "ionicons/icons";
import { inject } from "mobx-react";
import { APIBase, UserCreate } from "../service/API";
import Lang from "../service/Lang";


export type UserRegisterState = {
    error: false,
    loading: false,
    accountCreated: false,

    user: {
        password1: string,
        password2: string,
        username: string,
        email: string,
        phone: string,
        fullname: string,
        business: string,
        addressLine: string,
        tos: boolean,
        privacy: boolean,
        subscribe: boolean,
    }
}

class UserRegister extends React.Component<any, any> {

    state: UserRegisterState = {
        error: false,
        loading: false,
        accountCreated: false,
        user: {
            password1: "",
            password2: "",
            username: "",
            email: "",
            phone: "",
            fullname: "",
            business: "",
            addressLine: "",
            tos: false,
            privacy: false,
            subscribe: true,
        }
    }

    handleUserChange = (name: string) => (ev: any) => {
        this.setState({ ...this.state, user: { ...this.state.user, [name]: ev.detail.value } });
    }

    handleCheckUserChange = (name: string) => (ev: any) => {
        this.setState({ ...this.state, user: { ...this.state.user, [name]: ev.detail.checked } });
    }

    handleSubmit = (ev: any) => {
        let user = this.state.user;
        console.log(user);
        if (user.tos && user.privacy) {
            let password = user.password1;
            if (password === user.password2) {

                this.setState({ loading: true });
                this.props.store.api.usersCreate(
                    user.username,
                    user.password1,
                    user.email,
                    user.phone,
                    user.fullname,
                    "Europe/Madrid",
                    user.business,
                    user.addressLine,
                    user.subscribe,
                    this.props.store.getItem('redirect')

                ).then((result: APIBase<UserCreate>) => {
                    this.setState({ loading: false });
                    if (result.success) {
                        this.setState({ accountCreated: true, token: result.data.token });
                    } else {
                        this.setState({ error: result.message });
                    }
                })

            } else {
                this.setState({ error: 'Las contraseñas no coinciden' })
            }
        } else {
            this.setState({ error: 'Tienes que aceptar las condiciones y la política de privacidad' })

        }
    }

    renderForm() {
        const lang: Lang = this.props.lang;
        return <form autoComplete={"off"} onSubmit={(ev: any) => ev.preventDefault()}>
            <IonItemGroup>
                <IonItemDivider>
                    <IonLabel>{lang?.l.main.contact_info()}</IonLabel>
                </IonItemDivider>
                {/*<IonList lines={"full"}>*/}
                <IonItem>
                    <IonInput labelPlacement="stacked" label={lang?.l.main.demo_fullname()} onIonInput={this.handleUserChange('fullname')} data-testid={"main.fullname"} required={true}></IonInput>
                </IonItem>
                <IonItem>
                    <IonInput labelPlacement="stacked" label={lang?.l.main.demo_business()} onIonInput={this.handleUserChange('business')} data-testid={"main.business"}></IonInput>
                </IonItem>
                <IonItem>
                    <IonInput labelPlacement="stacked" label={lang?.l.main.postal_address()} onIonInput={this.handleUserChange('addressLine')} data-testid={"main.postal_address"} required={true}></IonInput>

                </IonItem>
                <IonItem>
                    <IonInput labelPlacement="stacked" label={lang?.l.main.demo_phone()} onIonInput={this.handleUserChange('phone')} data-testid={"main.demo_phone"} required={true} type={"tel"}></IonInput>
                </IonItem>
                <IonItem>
                    <IonInput labelPlacement="stacked" label={lang?.l.main.email()} onIonInput={this.handleUserChange('email')} data-testid={"main.email"} required={true} type={"email"}></IonInput>
                </IonItem>
                <IonItemDivider>
                    <IonLabel>{lang?.l.main.authentication_data()}</IonLabel>
                </IonItemDivider>

                <IonItem>
                    <IonInput labelPlacement="stacked" label={lang?.l.main.username()} onIonInput={this.handleUserChange('username')} data-testid={"main.username"} required={true} pattern={"[A-Za-z0-9_-]+"}></IonInput>
                </IonItem>

                <IonItem>
                    <IonInput labelPlacement="stacked" label={lang?.l.main.logn_password()} onIonInput={this.handleUserChange('password1')} data-testid={"main.password1"} required={true} type={"password"}>
                        <IonInputPasswordToggle slot="end"></IonInputPasswordToggle>
                    </IonInput>
                </IonItem>
                <IonItem>
                    <IonInput labelPlacement="stacked" label={lang?.l.main.password_repeat()} onIonInput={this.handleUserChange('password2')} data-testid={"main.password2"} required={true} type={"password"}>
                        <IonInputPasswordToggle slot="end"></IonInputPasswordToggle>
                    </IonInput>
                </IonItem>
                <IonItem>
                    <IonCheckbox slot="start" labelPlacement="end" color="primary" onIonChange={this.handleCheckUserChange('tos')} data-testid={"main.tos"}>

                        <div dangerouslySetInnerHTML={{ __html: lang?.l.main.legal_terms_url() }}></div>
                    </IonCheckbox>

                </IonItem>
                <IonItem>
                    <IonCheckbox slot="start" labelPlacement="end" color="primary" onIonChange={this.handleCheckUserChange('privacy')} data-testid={"main.privacy"}>

                        <div dangerouslySetInnerHTML={{ __html: lang?.l.main.privacy_terms_url() }}></div>
                    </IonCheckbox>

                </IonItem>
                <IonItem>
                    <IonCheckbox slot="start" color="primary" labelPlacement="end" onIonChange={this.handleCheckUserChange('subscribe')} data-testid={"main.subscribe"}>
                        {lang?.l.main.join_newsletter()}
                    </IonCheckbox>

                </IonItem>
                <IonButton type={"submit"} expand={"block"} data-testid={"main.create_account"} onClick={this.handleSubmit}>
                    <IonSpinner hidden={!this.state.loading} />
                    {lang?.l.main.create_account()}
                </IonButton>

                {/*</IonList>*/}
            </IonItemGroup>


            <IonToast
                htmlAttributes={{ tabindex: undefined }}
                isOpen={!!this.state.error}
                onDidDismiss={() => this.setState({ error: false })}
                message={String(this.state.error)}
                duration={5000}
                position="top"
            />
        </form>
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return <IonPage>

            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/" />
                    </IonButtons>
                    <IonTitle>CloudRTU</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent scrollEvents={true}>
                <div className={"register-background"}>

                    <IonGrid style={{ maxWidth: '600px' }}>
                        <IonRow>
                            <IonCol size="12">
                                <IonCard style={{ padding: 16, background: '#ffffff' }}>
                                    <IonCardHeader>
                                        <IonCardTitle><IonIcon icon={personOutline} /> CloudRTU</IonCardTitle>
                                        <IonCardSubtitle>Crear nueva cuenta. Este es el formulario de registro de la
                                            aplicación. Comience a usar CloudRTU tras rellenar el
                                            formulario.</IonCardSubtitle>
                                    </IonCardHeader>

                                    <IonCardContent>
                                        {this.state.accountCreated || this.renderForm()}
                                        {this.state.accountCreated && <>
                                            <div data-testid="account_created">Su cuenta ha sido creada correctamente. Ahora puede acceder con su usuario y contraseña.</div>


                                            <IonButton href={"/login"} expand={"block"}>
                                                Acceder al servicio</IonButton>
                                        </>}

                                    </IonCardContent>
                                </IonCard>
                                <div style={{ padding: 8, textAlign: "center" }}>

                                    <a href={"login"}>Volver al Inicio</a>
                                </div>
                            </IonCol>
                        </IonRow>
                    </IonGrid>

                </div>
            </IonContent>
        </IonPage>
    }
}

export default inject("store", "lang")(UserRegister);
