import {RTUError} from "./ModbusClientRequestHandler";
import ModbusRTUResponse from "./ModbusRTUResponse";
import ModbusRTURequest from "./ModbusRTURequest";
import {ModbusResponse} from "./ResponseHandler";

export default class UserRequest {
    private timeout:number = 5000;
    private _request:ModbusRTURequest;
    private _resolve?:(response:ModbusRTUResponse) => void;
    private _reject?:(error:RTUError) => void;

    private timer?:any;

    private _promise: Promise<ModbusRTUResponse>;

    constructor(request: ModbusRTURequest, timeout: number = 5000) {
        this.timeout = timeout;
        this._request = request;

        this._promise = new Promise<ModbusRTUResponse>((resolve, reject) => {
            this._resolve = resolve;
            this._reject = reject;
        })


    }

    createPayload(): any {
        return this._request.createPayload();
    }

    done(): void {
        if(this.timer) {
            clearTimeout(this.timer);
        }
    }


    start(complete: () => void): void {
        this.timer = setTimeout(() => {
            if(this._reject) {
                this._reject({
                    err: 'Timeout',
                    message: 'Request timed out',
                })
            }
            complete();
        }, this.timeout);
    }


    get request(): ModbusRTURequest {
        return this._request;
    }


    get resolve(): (response: ModbusRTUResponse) => void {
        return this._resolve!;
    }

    get reject(): (error: RTUError) => void {
        return this._reject!;
    }

    get promise(): Promise<ModbusResponse> {
        return this._promise;
    }
}
