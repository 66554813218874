import React from "react";
import {
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonContent,
    IonDatetime,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonModal,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToggle,
    IonToolbar
} from "@ionic/react";
import {inject} from "mobx-react";
import {Store} from "../../service/Store";
import {Device} from "../../service/API";
import {RouteComponentProps} from "react-router-dom";

export interface DeviceSettingsHardwareTimerProps extends RouteComponentProps<{id: string}> {
    store?:Store,
}
export type DeviceSettingsHardwareTimerState = {
    loading?: boolean,
    events: any[],
    device:Device,
    error: boolean | string,
    edit: boolean,
    index: number,
}

@inject("store", "lang")
export default class DeviceSettingsHardwareTimer extends React.Component<DeviceSettingsHardwareTimerProps, DeviceSettingsHardwareTimerState> {

    ACTIONS : {[key:string]:string} = {
        0: "Reiniciar",
        // 1: "Subir datos por GPRS ",
        2: "Reiniciar contador de pulsos",
        // 3: "Reportar SMS",
        // 4: "Conectar GPRS",
        // 5: "Consultar datos RS232/485",
        // 6: "Almacenar Histórico de datos",
        7: "ON todos los Relés",
        8: "OFF todos los Relés",
        9: "Relé 1 ON",
        10: "Relé 2 ON",
        11: "Relé 3 ON",
        12: "Relé 4 ON",
        13: "Relé 1 OFF",
        14: "Relé 2 OFF",
        15: "Relé 3 OFF",
        16: "Relé 4 OFF",
    }
    componentDidMount(): void {
        this.loadDevice();
    }

    loadDevice() {
        this.setState({loading: true});
        let id = this.props.match?.params.id!;

        this.props.store?.api.deviceGet(Number(id)).then(value => {
            if(value.success) {
                this.setState({device: value.data})
                for (let i = 0; i < 4; i++) {
                    this.ACTIONS[9 + i] = value.data.outputs[i].name + " ON"
                    this.ACTIONS[13 + i] = value.data.outputs[i].name + " OFF"
                }
            }

            this.props.store?.api.getHardwareTimer(Number(id)).then(value => {
                if(value.success) {
                    this.setState({events: value.data.events})
                } else {
                    this.setState({error: 'El dispositivo no está conectado'});
                }
            }).finally(() => this.setState({loading: false}));
        });

    }

    componentDidUpdate(prevProps: Readonly<DeviceSettingsHardwareTimerProps>, prevState: Readonly<DeviceSettingsHardwareTimerState>, snapshot?: any): void {
        if(this.props.match.params.id !== prevProps.match.params.id) {
            this.loadDevice();
        }
    }

    handleSave = (ev: any) => {
        this.setState({loading: true, error: false});
        let id = this.props.match?.params.id!;
        const {history} = this.props;

        this.props.store?.api.setHardwareTimer(Number(id), this.state.events).then(value => {
            if(value.success) {
                if(value.data.result) {
                    history.goBack();
                } else {
                    this.setState({error: 'No se puede configurar el dispositivo'});
                }
            }
        });

    };

    handleClear = (ev: any) => {
        if(this.state.events) {
            for (let i = 0; i < this.state.events.length; i++) {
                let item = this.state.events[i];
                item.week = 0;
                item.hour = 0;
                item.min = 0;
                item.action = 0;
            }
            this.setState({});
        }
    }

    handleChange = (item:any, name:string) => (ev:any) => {
        item[name] = ev.currentTarget.value;
        let events = this.state.events;
        events[this.state.index] = this.buildItem(item);
        this.setState({events: events})
    }

    handleCheckChange = (item:any, name:string) => (ev:any) => {
        item[name] = ev.currentTarget.checked;

        let events = this.state.events;
        events[this.state.index] = this.buildItem(item);
        this.setState({events: events})
    }

    parseItem(value:any) {
        /*
        week

        When “Bit7” =1 is enable this Timer Inter-Lock, When “Bit7” =0 is disable

        this Timer Inter-Lock
        Bit0~Bit6 is Week
        0~6=Sunday~ Saturday
        7=Everyday
         */

        let enable = !!((value.week >> 7) & 1);
        let day = value.week ^ 0b10000000;
        // let sunday = !!((value.week >> 0) & 1);
        // let monday = !!((value.week >> 1) & 1);
        // let tuesday = !!((value.week >> 2) & 1);
        // let wednesday = !!((value.week >> 3) & 1);
        // let thursday = !!((value.week >> 4) & 1);
        // let friday = !!((value.week >> 5) & 1);
        // let saturday = !!((value.week >> 6) & 1);
        let time = (value.hour > 9?value.hour:"0" + value.hour) + ":" + (value.min > 9?value.min:"0" + value.min);
        let action = value.action;

        return {enable, day, time, action};
    }

    buildItem(item:any) {
        let result = {week: 0, hour: 0, min: 0, action: item.action};
        if(item.enable) {
            result.week = (1 << 7);
            result.week += item.day & 0b111;
        }
        result.hour = parseInt(item.time.substring(0, 2));
        result.min = parseInt(item.time.substring(3, 5));
        return result;
    }


    renderWeek(item:any) {
        let string = "";
        switch (item.day) {
            case 0: return "D";
            case 1: return "L";
            case 2: return "M";
            case 3: return "X";
            case 4: return "J";
            case 5: return "V";
            case 6: return "S";
            case 7: return "LMXJVSD";
        }
        return string;
    }

    renderItem(value:any, index:number) {
        if(!this.state.device) return "";
        let item = this.parseItem(value);

        return <>
            <IonItem detail button onClick={() => this.setState({edit: true, index: index})}>
                <IonLabel>Evento programado {index + 1}</IonLabel>

                <IonBadge slot={"end"} color={"primary"} hidden={!item.enable}>{item.time}</IonBadge>
                <IonBadge slot={"end"} color={"primary"} hidden={!item.enable}>{this.renderWeek(item)}</IonBadge>
                <IonBadge slot={"end"} color={"warning"} hidden={!item.enable}>{this.ACTIONS[item.action]}</IonBadge>
                <IonBadge slot={"end"} color={"danger"} hidden={item.enable}>Desactivado</IonBadge>
            </IonItem>
        </>
    }

    renderEditor() {
        if(!this.state.device) return "";
        if(this.state.index === undefined) return "";
        let value = this.parseItem(this.state.events[this.state.index]);

        return <>
            <IonItem>
                <IonToggle checked={value.enable} onIonChange={this.handleCheckChange(value, 'enable')}>Activar</IonToggle>
            </IonItem>
            <div hidden={!value.enable}>

                <IonItem>
                    <IonLabel>Hora</IonLabel>
                    <IonDatetime firstDayOfWeek={1}  locale="es-ES" presentation="time" value={value.time} onIonChange={this.handleChange(value, 'time')} />
                </IonItem>
                <IonItem>
                    <IonSelect label="Acción" value={value.action} interface={"action-sheet"} onIonChange={this.handleChange(value, 'action')}>
                        {Object.keys(this.ACTIONS).map((value1:string) => <IonSelectOption value={parseInt(value1)}>{this.ACTIONS[value1]}</IonSelectOption>)}
                    </IonSelect>
                </IonItem>
                <IonItem>

                    <IonSelect label="Día" value={value.day} interface={"action-sheet"} onIonChange={this.handleChange(value, 'day')}>
                        <IonSelectOption value={1}>Lunes (L)</IonSelectOption>
                        <IonSelectOption value={2}>Martes (M)</IonSelectOption>
                        <IonSelectOption value={3}>Miércoles (X)</IonSelectOption>
                        <IonSelectOption value={4}>Jueves (J)</IonSelectOption>
                        <IonSelectOption value={5}>Viernes (V)</IonSelectOption>
                        <IonSelectOption value={6}>Sábado (S)</IonSelectOption>
                        <IonSelectOption value={0}>Domingo (D)</IonSelectOption>
                        <IonSelectOption value={7}>Todos los días</IonSelectOption>
                    </IonSelect>
                </IonItem>
            </div>
        </>
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let id = this.props.match?.params.id!;

        if(!this.state) return "";
        return <IonPage>

            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={"/device/" + id + "/settings"}/>
                    </IonButtons>
                    <IonTitle>Acciones programadas</IonTitle>

                    <IonButtons slot="primary">
                        <IonButton onClick={this.handleClear}>Limpiar</IonButton>
                    </IonButtons>
                    <IonButtons slot="primary">
                        <IonButton onClick={this.handleSave}>Guardar</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    {this.state.error && <IonItem color={"danger"}>
                        <IonLabel>{this.state.error}</IonLabel>
                    </IonItem>}

                    {this.state.events && this.state.events.map((value, index) => this.renderItem(value, index))}

                </IonList>
                <IonLoading
                    isOpen={this.state.loading!}
                    message={'Cargando...'}
                    duration={5000}
                />
            </IonContent>

            <IonModal isOpen={this.state.edit! ? true: false} onDidDismiss={() => this.setState({edit: false})}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Editar Acción</IonTitle>
                        <IonButtons slot={"primary"}>
                            <IonButton onClick={() => this.setState({edit: false})}>OK</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    {this.renderEditor()}
                </IonContent>

            </IonModal>
        </IonPage>
    }

}
