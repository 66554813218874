import React, { RefObject } from "react";
import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCheckbox,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonInputPasswordToggle,
    IonItem,
    IonList,
    IonListHeader,
    IonModal,
    IonPage,
    IonPopover,
    IonRow,
    IonSpinner,
    IonText,
    IonTitle,
    IonToast,
    IonToolbar
} from "@ionic/react";
import { Redirect, withRouter } from "react-router-dom";
import { DemoRequest, UserCreate } from "../service/API";
import { inject } from "mobx-react";
import { Store } from "../service/Store";
// import firebase from 'firebase';
import {
    contrastOutline,
    cubeOutline,
    download,
    flagOutline,
    help,
    informationCircleOutline,
    lockClosedOutline,
    logoApple,
    logoGoogle,
    personCircleOutline,
    playOutline
} from "ionicons/icons";
import DemoLogin from "../components/DemoLogin";
import { Capacitor } from "@capacitor/core";
import Lang from "../service/Lang";
import { Locales } from "../i18n/i18n-types";
import { FirebaseAuthentication, User } from '@capacitor-firebase/authentication';

export interface LoginState {
    email?: string;
    password?: string;
    remember?: boolean;
    completeRegistration?: boolean;
    info?: string;
    error?: string,
    loading: boolean,
    modal: boolean,
    popoverState: any,
}

class Login extends React.Component<any, LoginState> {

    state: LoginState = { modal: false, loading: false, info: '', popoverState: { showPopover: false, event: undefined }, completeRegistration: false };
    email: RefObject<any>;
    password: RefObject<any>;
    remember: RefObject<any>;

    constructor(props: any, context: any) {
        super(props, context);
        this.email = React.createRef();
        this.password = React.createRef();
        this.remember = React.createRef();
        // this.createAccountModal = React.createRef();
    }

    componentDidMount(): void {
        if (this.props.location.state && this.props.location.state.from && this.props.location.state.from.pathname) {
            this.setState({ info: 'Es necesario acceder con su cuenta para continuar' })
        }
        this.autologin();
    }


    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<LoginState>, snapshot?: any) {
        if (this.props.location && prevProps.location && this.props.location.search !== prevProps.location.search) {
            this.autologin();
        }
    }

    autologin() {

        if (this.props.location && this.props.location.search) {
            let search = this.props.location.search;
            let index = search.indexOf("token=");
            if (index >= 0) {

                const { history } = this.props;
                let token = search.substr(index + 6);
                // alert("TOKEN: " + token);
                (this.props.store as Store).setToken(token, true);
                history.push({ pathname: "/dashboard" });
            }
        }
    }
    _doLogin() {
        return this.doLogin(this.state.email!, this.state.password!, this.state.remember!);

    }

    translateError(error: any) {
        let errors: any = this.props.lang!.l.errors;
        let message = errors[error.code];
        if (message) {
            message = message();
        }

        return message || String(error.message);
    }

    doLogin(user: string, pass: string, remember: boolean) {
        this.setState({ error: undefined, loading: true });
        const store: Store = this.props.store;
        return store.auth(user, pass, remember).then((result) => {
            if (result.success) {
                this.doRedirect();
            } else {
                this.setState({ error: this.translateError(result.error) });
            }
        }).catch((error) => {
            alert("Unexcepted error: " + error);

        }).finally(() => {
            this.setState({ loading: false });
        })
    }

    doRedirect() {
        const { history } = this.props;
        const store: Store = this.props.store;
        let redirect = "/";
        if (this.props.location.state && this.props.location.state.from.pathname) {
            redirect = this.props.location.state.from.pathname;
        } else {
            redirect = store.getItem('redirect') || "/dashboard";
            store.setItem('redirect', undefined);
        }
        if (redirect === "null") {
            redirect = "";
        }
        if (redirect === "undefined") {
            redirect = "";
        }
        history.push({ pathname: redirect });
    }

    handleChange = (name: "email" | "password") => (ev: any) => {
        this.setState({ ...this.state, [name]: ev.detail.value });
    }

    handleCheckChange = (name: "remember") => (ev: any) => {
        this.setState({ ...this.state, [name]: ev.detail.checked });
    }

    handleDemoSubmit = (demo: DemoRequest) => {
        this.doLogin(demo.username, demo.password, false);

    };
    handleSubmit = (ev: any) => {
        ev.preventDefault();
        this._doLogin();
    }

    handleClickLanguage = (loc: Locales) => (ev: any) => {
        let lang: Lang = this.props.lang;
        lang.language = loc;
        this.setState({ popoverState: { showPopover: false, event: undefined } })
    }

    handleGoogleClick = async (ev: any) => {

        await FirebaseAuthentication.signOut();

        FirebaseAuthentication.signInWithGoogle({
            scopes: ['profile',
                'email',
                'https://www.googleapis.com/auth/userinfo.email',
                'https://www.googleapis.com/auth/userinfo.profile'
            ],


        }).then(result => {
            if (result && result.user) {
                this.userLogin(result.user);
            }
        }).catch(error => {
            this.setState({ loading: false, error: error.toString() });
        })

        // if (Capacitor.getPlatform() === 'web') {

        // } else {
        //     cfaSignIn('google.com').subscribe(user => {
        //         this.userLogin(user);
        //     });
        // }
    }
    handleFacebookClick = async (ev: any) => {
        // await firebase.app().auth().signOut();
        // if (Capacitor.getPlatform() === 'web') {
        //     var provider = new firebase.auth.FacebookAuthProvider();
        //     this.handleProviderLogin(provider);
        // } else {
        //     cfaSignInFacebook().subscribe(user => {
        //         this.userLogin(user);
        //     });
        // }
    }

    handleAppleClick = async (ev: any) => {

        await FirebaseAuthentication.signOut();

        await FirebaseAuthentication.signInWithApple({
            scopes: [
                'email',
                'name'
            ],


        }).then(result => {
            if (result && result.user) {
                this.userLogin(result.user);
            }
        }).catch(error => {
            this.setState({ loading: false, error: error.toString() });
        })
        // const provider = new firebase.auth.OAuthProvider('apple.com');
        // provider.addScope('email');
        // provider.addScope('name');
        // if (Capacitor.getPlatform() === 'web') {
        //     await firebase.app().auth().signOut();
        //     this.handleProviderLogin(provider);
        // } else {
        //     try {
        //         // CapacitorFirebaseAuth.signIn({ providerId: 'apple.com' }).then((result: any) => {
        //         //     this.userLoginIdToken(result.idToken);
        //         // })
        //         cfaSignInApple().subscribe(user => {
        //             this.userLogin(user);
        //         });

        //     } catch (e) {
        //         alert("ERR3")
        //     }

        // }
    }

    // handleProviderLogin(provider: firebase.auth.AuthProvider) {
    //     firebase.auth().signInWithPopup(provider).then((result) => {
    //         // This gives you a Google Access Token.


    //         // var token = result.credential?.toJSON();
    //         // The signed-in user info.
    //         var user = result.user;
    //         this.userLogin(user!);

    //     }).catch(err => {
    //         this.setState({ loading: false, error: err.message });
    //     });
    // }

    userLoginIdToken(idToken: string) {
        let store: Store = this.props.store;
        store.api.usersGoogleAuth(idToken).then(res2 => {
            if (res2.success && res2.data.token) {
                store.setToken(res2.data.token, this.state.remember!);
            } else if (res2.success && res2.data.user) {
                // let userJson = res2.data.user;
                this.setState({ completeRegistration: false });
                // this.createAccountModal.current!.createAccount({
                //     email: userJson.email!,
                //     fullname: userJson.name,
                //     googleToken: idToken!,
                // })

                this.setState({ loading: false, error: "No se puede obtener la información de la cuenta." });
            } else if (res2.error) {

                this.setState({ loading: false, error: res2.message });
            }
        }).finally(() => {
            this.setState({ loading: false });
        }).catch(err => {
            this.setState({ loading: false, error: err.message });
        })
    }
    userLogin(user: User) {
        this.setState({ loading: true });

        FirebaseAuthentication.getIdToken().then(value => {
            this.userLoginIdToken(value.token);
        }).catch((err) => {
            alert("ERR11");
            this.setState({ loading: false, error: err.message });
        })
    }

    handleRegisterClick = (ev: any) => {
        // let store:Store = this.props.store;
        // this.createAccountModal.current!.createAccount({ googleToken: '' });
    }

    handleCreateAccountResult = (error: any, token?: UserCreate) => {
        if (token) {
            const store: Store = this.props.store;
            store.setToken(token.token, this.state.remember!);
            this.doRedirect();
        }
    }

    renderLogin() {
        const store: Store = this.props.store;
        const lang: Lang = this.props.lang;
        const isAuth = store.login.isLogin();

        if (isAuth) return <Redirect to={"/"} />;


        return <>
            <IonCard style={{ padding: 16 }}>
                <IonCardHeader>
                    <IonCardTitle><IonIcon icon={lockClosedOutline} /> CloudRTU</IonCardTitle>
                    <IonCardSubtitle>{lang.l.main.login_header()}</IonCardSubtitle>
                </IonCardHeader>

                <IonItem color={"danger"} hidden={!this.state.error}>
                    <IonText id={"error-message"}>{this.state.error}</IonText>
                </IonItem>
                <IonCardContent>
                    <form onSubmit={this.handleSubmit}>
                        <IonItem>
                            <IonInput

                                label={lang.l.main.login_user_email()}
                                labelPlacement="stacked"
                                type="email" title={lang.l.main.login_user_email()} onIonInput={this.handleChange("email")} name="email" />
                        </IonItem>
                        <IonItem>
                            <IonInput
                                label={lang.l.main.logn_password()}
                                labelPlacement="stacked"
                                type="password"
                                onIonInput={this.handleChange("password")}
                                name="password"
                                title={lang.l.main.logn_password()}
                            >
                                <IonInputPasswordToggle slot="end"></IonInputPasswordToggle>
                            </IonInput>
                        </IonItem>
                        <IonItem lines="none">
                            <IonCheckbox labelPlacement="end" slot={"start"} onIonChange={this.handleCheckChange("remember")} title={lang.l.main.login_remember_me()}>
                                {lang.l.main.login_remember()}
                            </IonCheckbox>

                        </IonItem>
                    </form>
                    <IonGrid>
                        <IonRow>
                            <IonCol>

                                <IonButton
                                    expand="block"
                                    onClick={e => {
                                        if (!e.currentTarget) {
                                            return;
                                        }
                                        e.preventDefault();
                                        return this._doLogin();
                                    }}
                                >
                                    <IonSpinner hidden={!this.state.loading} />
                                    {lang.l.main.login_button()}
                                </IonButton>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonButton expand={"block"} onClick={this.handleGoogleClick} fill="outline" color="dark" >
                                    <IonIcon slot="start" icon={logoGoogle} /> Acceder con Google</IonButton>
                            </IonCol>
                        </IonRow>
                        {/* <IonRow>
                            <IonCol>
                                <IonButton expand={"block"} onClick={this.handleFacebookClick} fill="outline" color="dark" >
                                    <IonIcon slot="start" icon={logoFacebook} /> Acceder con Facebook</IonButton>
                            </IonCol>
                        </IonRow> */}
                        <IonRow>
                            <IonCol>
                                <IonButton expand={"block"} onClick={this.handleAppleClick} fill="outline" color="dark" >
                                    <IonIcon slot="start" icon={logoApple} /> Acceder con Apple</IonButton>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol><IonButton routerLink="/register" expand={"block"} fill="outline"><IonIcon
                                icon={personCircleOutline} /> {lang.l.main.login_new_account()}</IonButton></IonCol>
                            <IonCol><IonButton expand={"block"} fill="outline" routerLink={"/recovery"}><IonIcon
                                icon={help} /> {lang.l.main.login_recovery()}</IonButton></IonCol>
                        </IonRow>
                        {Capacitor.getPlatform() === "web" && <IonRow>
                            <IonCol><IonButton expand={"block"} routerLink={"/download"} fill="outline"><IonIcon
                                icon={download} /> {lang.l.main.login_download()}</IonButton></IonCol>
                        </IonRow>}
                    </IonGrid>
                </IonCardContent>
            </IonCard>
        </>
    }

    renderDemo() {

        const { lang } = this.props;
        return <IonCard style={{ padding: 16 }}>
            <IonCardHeader>
                <IonCardTitle><IonIcon icon={cubeOutline} /> {lang.l.main.demo_version()}</IonCardTitle>

            </IonCardHeader>

            <IonCardContent>
                <p>{lang.l.main.software_demo()}</p>
                <IonButton expand={"block"} fill="outline" onClick={() => this.setState({ modal: true })}><IonIcon
                    icon={playOutline} /> {lang.l.main.start_demo()}</IonButton>
            </IonCardContent>
        </IonCard>
    }

    renderDemoModal() {
        return <IonModal isOpen={this.state.modal ? true : false} onDidDismiss={() => this.setState({ modal: false })}>
            <DemoLogin onDemo={this.handleDemoSubmit} onClose={() => this.setState({ modal: false })} />
        </IonModal>
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {

        const { lang } = this.props;
        const { popoverState } = this.state;
        return <IonPage>


            <IonToast
                htmlAttributes={{ tabindex: undefined }}
                isOpen={!!this.state.info}
                onDidDismiss={() => this.setState({ info: '' })}
                message={this.state.info}
                duration={6000}
            />
            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonTitle>CloudRTU</IonTitle>
                    <IonButtons slot={"end"}>
                        <IonButton onClick={() => (this.props.store as Store).toggleDarkMode()}><IonIcon icon={contrastOutline} /></IonButton>
                        <IonButton routerLink={"/help"}><IonIcon icon={informationCircleOutline} /></IonButton>
                    </IonButtons>
                    <IonButtons slot={"end"}>
                        <IonButton onClick={(ev: any) => this.setState({ popoverState: { showPopover: true, event: ev.nativeEvent } })}><IonIcon icon={flagOutline} /></IonButton>
                        <IonPopover
                            event={popoverState.event}
                            isOpen={popoverState.showPopover}
                            onDidDismiss={() => this.setState({ popoverState: { showPopover: false, event: undefined } })}
                        >
                            <IonList>
                                <IonListHeader>{lang.l.main.language()}</IonListHeader>
                                <IonItem button onClick={this.handleClickLanguage('es-ES')}>Español</IonItem>
                                <IonItem button onClick={this.handleClickLanguage('en-GB')}>English (GB)</IonItem>
                                {/* <IonItem button onClick={this.handleClickLanguage('fr-FR')}>Français</IonItem> */}
                                <IonItem lines="none" detail={false} button onClick={() => this.setState({ popoverState: { showPopover: false, event: undefined } })}>
                                    Close
                                </IonItem>
                            </IonList>
                        </IonPopover>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent scrollEvents={true}>
                <div className={"login-overlay login-background"}>
                    {this.renderDemoModal()}
                    <br />
                    <IonGrid style={{ maxWidth: '600px' }}>
                        <IonRow>
                            <IonCol size="6" offset={"3"}>
                                <img alt={"CloudRTU"} className={"logo"} src={"/assets/logo.png"} /></IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol size="12">{this.renderLogin()}</IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12">{this.renderDemo()}</IonCol>
                        </IonRow>
                    </IonGrid>
                </div>
            </IonContent>
        </IonPage>
    }

}

export default inject("store", "lang")(withRouter(Login));
