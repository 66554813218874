import React from "react";
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonLoading,
    IonModal,
    IonPage,
    IonSpinner,
    IonText,
    IonTitle,
    IonToggle,
    IonToolbar
    
} from "@ionic/react";
import {inject} from "mobx-react";
import {Store} from "../service/Store";
import {User} from "../service/API";
import ChangePassword from "../components/ChangePassword";
import {documentTextOutline} from "ionicons/icons";
import TaxEditor from "../components/TaxEditor";
import AddPaymentMethod from "../components/AddPaymentMethod";
import {ElementsConsumer} from "@stripe/react-stripe-js";
import Lang from "../service/Lang";
import { RouteComponentProps } from "react-router";

export interface UserAccountProps extends RouteComponentProps<{}> {
    routerOutlet:HTMLIonRouterOutletElement;
    store?:Store;
    lang?:Lang;
}
export interface UserAccountState {
    user?:User;
    loading: boolean;
    password: boolean;
    setupTax: boolean;
    loadingTax: boolean;
    validTax: boolean;
    tax: any;
    configurePayment: boolean;
    addPayment: boolean;
    whatsappUrl: string;
}

@inject("store", "lang")
export default class UserAccount extends React.Component<UserAccountProps, UserAccountState> {

    componentDidMount(): void {
        this.setState({loading: true});
        let store:Store = this.props.store!;

        store.api.user().then(value => {
            if(value.success) {
                this.setState({user: value.data, loading: false});
                this.loadTax();
            }
        })

    }

    loadTax() {
        this.setState({loadingTax: true, tax: undefined, validTax: false, configurePayment: false});
        let store:Store = this.props.store!;
        store.api.paymentsGetTax().then(value1 => {
            this.setState({loadingTax: false});
            if(value1.success) {
                this.setState({validTax: true});
                if(value1.data.length > 0) {
                    this.setState({tax: value1.data[0]})
                }
            }
            if(value1.error && value1.error.code === 'PAYMENT_NOT_ENABLED_ERROR') {
                this.setState({configurePayment: true});
            }
        })
    }

    handleEditTax = (result:boolean, data:any) => {
        this.setState({setupTax: false});
        if(result) {
            this.setState({loading: true});

            this.props.store?.api.paymentsSetTax(data).then(value => {
                this.setState({loading: false});

                if(value.success) {
                    this.loadTax();
                }
            })
        }
    };

    handleEmailBills = (ev:any) => {
        this.props.store?.api.paymentsEmailBills(ev.detail.checked);
    }

    handleOnAddPayment = (ev:any) => {
        this.setState({addPayment: false});
        this.loadTax();
    }
    //https://api.whatsapp.com/send?phone=34686612373&text=Activar%20Whatsapp

    handleWhatsappClick = (ev:any) => {
        this.setState({loading: true});
        this.props.store?.api.usersWhatsapp().then(res => {
            this.setState({loading: false});
            if(res.success) {
                let message = escape("Activar Whatsapp: " + res.data.token);
                let url = 'https://api.whatsapp.com/send?phone=34686612373&text=' + message;
                this.setState({whatsappUrl: url});
                window.open(url, '_system', 'location=yes');
            }
        });
    }

    handleRemoveAccount = () => {
        this.setState({ loading: true });
        this.props.store?.api.deleteUserAccount().then(res => {
            this.setState({ loading: false });
            let code = prompt("Introduzca el código recivido por email para confirmar");

            if (code) {
                this.props.store?.api.deleteUserAccount(code).then(res2 => {
                    if (res2.success) {
                        this.props.history.replace("/logout");
                    }

                })
            }
        })
    }

    renderTax() {
        if(!this.state.validTax) return "";
        const {lang} = this.props;
        return <>
            {this.state.tax !== undefined  && <>
                <IonItem>
                    <IonLabel>Facturar a</IonLabel>
                </IonItem>
                <IonItem>
                    <IonText>{this.state.tax.customer.name} {this.state.tax.customer.description} <br />
                        {this.state.tax.customer.phone} <br />
                        {this.state.tax.customer.address.line1} <br />
                        {this.state.tax.customer.address.city} {this.state.tax.customer.address.state} {this.state.tax.customer.address.postal_code}<br />
                        {this.state.tax.tax}
                    </IonText>
                    <IonButton slot={"end"} color={"primary"} onClick={() => this.setState({setupTax: true})}>{lang?.l.main.modify()}</IonButton>
                </IonItem>

                <IonItem>
                        <IonLabel>Recibir facturas Por email</IonLabel>
                        <IonToggle checked={this.state.user!.emailBills} onIonChange={this.handleEmailBills} />
                        
                    </IonItem>
                <IonItem detail button routerLink={"/account/invoices"}>
                    <IonLabel>{lang?.l.main.see_bills()}</IonLabel>
                </IonItem>
            </>}
            {this.state.tax === undefined  && <IonItem button detail={false} color={"primary"} onClick={() => this.setState({setupTax: true})}>
                <IonLabel><IonIcon icon={documentTextOutline} /> {lang?.l.main.enable_billing()}</IonLabel>
            </IonItem>}
        </>
    }

    renderPayment() {

        const {lang} = this.props;
        return <>
            <ElementsConsumer>
                {({stripe, elements}) => (
                    <AddPaymentMethod routerOutlet={this.props.routerOutlet} stripe={stripe} elements={elements} isOpen={this.state.addPayment} onAddPayment={this.handleOnAddPayment} />
                )}

            </ElementsConsumer>
            <IonItem onClick={() => this.setState({addPayment: true})} button color={"primary"}><IonLabel>{lang?.l.main.payment_configure()}</IonLabel></IonItem>
        </>
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        if(!this.state) return "";
        const {lang} = this.props;
        return <IonPage>
            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonTitle>Cuenta</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    <IonItem>
                        <IonLabel>{lang?.l.main.username()}</IonLabel>
                        <IonText>{this.state.user?.username}</IonText>
                    </IonItem>
                    <IonItem>
                        <IonLabel>{lang?.l.main.email()}</IonLabel>
                        <IonText>{this.state.user?.email}</IonText>
                    </IonItem>
                    <IonItem>
                        <IonLabel>{lang?.l.main.logn_password()}</IonLabel>
                        <IonButton onClick={() => this.setState({password: true})}>{lang?.l.main.change_password()}</IonButton>
                    </IonItem>
                    <IonItem>
                        <IonLabel>Whatsapp</IonLabel>
                        {this.state && this.state.whatsappUrl && <IonButton target="_blanck" href={this.state.whatsappUrl}>Abrir Whatsapp</IonButton>}
                        <IonButton onClick={this.handleWhatsappClick}>Activar Whatsapp</IonButton>
                    </IonItem>
                    <IonItem>
                        <IonLabel>Eliminar cuenta</IonLabel>
                        <IonButton onClick={this.handleRemoveAccount}>Eliminar cuenta</IonButton>
                    </IonItem>
                    <IonListHeader>
                        <IonLabel>{lang?.l.main.billing()}</IonLabel>
                    </IonListHeader>
                    {this.state.loadingTax && <IonItem><IonSpinner></IonSpinner> <IonLabel>{lang?.l.main.loading()}</IonLabel></IonItem>}
                    {this.renderTax()}

                    {this.state.configurePayment && this.renderPayment()}

                </IonList>
            </IonContent>
            <IonLoading
                isOpen={this.state.loading!}
                message={lang?.l.main.loading()}
                duration={5000}
            />
            <IonModal isOpen={this.state.password ? true: false} onDidDismiss={() => this.setState({password: false})} presentingElement={this.props.routerOutlet}>

                <IonHeader>
                    <IonToolbar>
                        <IonTitle>{lang?.l.main.change_password()}</IonTitle>
                        <IonButtons slot={"end"}>
                            <IonButton onClick={() => this.setState({password: false})}>{lang?.l.main.close()}</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <ChangePassword onChange={() => this.setState({password: false})} />
                </IonContent>
            </IonModal>
            <IonModal isOpen={this.state.setupTax ? true: false} onDidDismiss={() => this.setState({setupTax: false})}>
                <TaxEditor onEdit={this.handleEditTax} values={this.state.tax}/>
            </IonModal>
        </IonPage>
    }
}
