import React from "react";
import {
    IonAlert,
    IonButton,
    IonButtons,
    IonCheckbox,
    IonContent,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonLoading,
    IonModal,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonSpinner,
    IonText,
    IonTextarea,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import { inject } from "mobx-react";
import { Store } from "../service/Store";
import { linkOutline } from "ionicons/icons";
import { RouteComponentProps, withRouter } from "react-router";
import ModbusRTUClient from "../serial/rtu/ModbusRTUClient";
import SerialWrapper from "../serial/SerialWrapper";
import UserInfo from "../serial/registers/UserInfo";
import DeviceConfiguration from "../serial/registers/DeviceConfiguration";
import NetConfig from "../serial/registers/NetConfig";
import { InputChangeEventDetail } from "@ionic/core";
import Utils from "../service/Utils";
import WebSerialPort from "../service/WebSerialPort";
import Registers from "../serial/Registers";
import RS485V1 from "../serial/registers/RS485V1";
import GPRS from "../serial/registers/GPRS";
import MemoryMapper from "../serial/registers/MemoryMapper";
import S271 from "../serial/registers/firmware/S271";
import S272 from "../serial/registers/firmware/S272";
import S275 from "../serial/registers/firmware/S275";
import PeriodicTimer from "../serial/registers/PeriodicTimer";
import DinTrigger from "../serial/registers/DinTrigger";
import SlaveList from "../serial/registers/SlaveList";
import Exportable from "../serial/registers/Exportable";

export interface ConfiguratorProps extends RouteComponentProps<{ port: string }> {
    store?: Store,
}
export type DeviceConfigurationState = {

    userInfo?: UserInfo,
    deviceConfig?: DeviceConfiguration,
    netConfig?: NetConfig,
    netConfigOld?: GPRS,
    commv1?: RS485V1,
    din?: DinTrigger,
    periodicTimer?: PeriodicTimer,
    slave?: SlaveList,
}
export type ConfiguratorState = {

    available: boolean;
    connecting: boolean;
    connected: boolean;
    debug?: boolean;
    loading?: boolean;
    configured?: boolean;

    deviceModel?: string;
    serialNumber?: string;
    imei?: string;
    build?: string;
    devices?: SerialPort[];
    error?: string;
    recv?: string;
    // status?: {
    //     device?: { loading: boolean, state: boolean },
    //     network?: { loading: boolean, state: boolean },
    //     apn?: { loading: boolean, state: boolean },
    // };

    configuration?: DeviceConfigurationState
    section?: ConfigurationSection
    sections?: ConfigurationSection[]
    sectionParent?: ConfigurationSection
    sectionModal?: boolean,
    mapper?: MemoryMapper,

}

export type ConfigurationSection = {
    title: string,
    type: string | 'text' | 'number' | 'group' | 'check' | 'function',

    settings?: ConfigurationSection[],
    read?: (state: any) => any,
    write?: (state: any, value: any) => any,
    controlProps?: any,

    rtu?: {
        class: typeof Registers,
        stateKey: string,
    },
    fn?: () => ConfigurationSection[],
    showIf?: (state: DeviceConfigurationState) => boolean

};

@inject("store", "lang")
class Configurator extends React.Component<ConfiguratorProps, ConfiguratorState> {
    //
    // netConfig = [
    //     {
    //     "CommProtocol": 2,
    //     "Protocol": 0,
    //     "ConnWays": 0,
    //     "ResendTime": 3,
    //     "RegeditMode": 0,
    //     "reserve": 0,
    //     "ReconnTime": 30,
    //     "HeartTime": 30
    // },
    //     {
    //         "GprsIp1": "devices.cloudrtu.com", // 60
    //         "GprsPort1": 8001,
    //         "GprsIp2": "",
    //         "GprsPort2": 0
    //     },
    //     {
    //         "GprsApn": "TM"
    //     },
    //     {
    //         "GprsApnUser": ""
    //     },
    //     {
    //         "GprsPwd": ""
    //     },{
    //         "LoginMessage": Model.PackString(""),
    //     }, {
    //         "LoginACKMessage": Model.PackString("")
    //     },
    //     {
    //         "LogoutMessage": Model.PackString("")
    //     },
    //     {
    //         "HeartbeatMessage": Model.PackString("req")
    //     },
    //     {
    //         "HeartbeatACKMessage": Model.PackString("res")
    //     }]
    // serialPort?: SerialPort;
    webSerialPort?: WebSerialPort;
    state: ConfiguratorState = {
        available: false,
        connecting: false,
        connected: false,
        configuration: {},
    };


    rtu?: ModbusRTUClient;

    settings = [
        {
            name: ''
        }
    ]


    componentDidMount(): void {
        // this.loadDevices();

        this.init().then(() => {
            if (this.props.match.params.port) {
                this.connect();
            }
        });
        this.setState({ configuration: {} })
    }

    async loadDevices() {
        // let res = await this.init();
        if (this.webSerialPort) {
            let port = await this.webSerialPort.addDevice();
            if (port !== undefined && port >= 0) {
                this.props.history.push("/usb/" + this.encodePort(String(port)));

            }

            // this.serialPort.getDevicesList(devices => {
            //     if (devices.result === "ok") {
            //         this.setState({devices: devices.ports});

            //     }
            // })
        }
    }

    /**
     *
     * @param a
     * @param b
     * @param props
     */
    private compareConfiguration(a: any, b: any, props?: string[]) {
        if (!props) {
            props = Object.keys(a);
        }

        for (let i = 0; i < props.length; i++) {
            if (props[i].startsWith("_")) continue;
            let a1 = a[props[i]];
            let b1 = b[props[i]];
            if (typeof a1 === "string") {
                if (a1 !== b1) {
                    return false;
                }
            } else if (typeof a1 === "number") {
                if (a1 !== b1) return false;
            } else if (Array.isArray(a1)) {
                if (a1.length !== b1.length) return false;
                for (let j = 0; j < a1.length; j++) {
                    if (!this.compareConfiguration(a1[j], b1[j])) {
                        return false;
                    }
                }
            }
        }
        return true;

    }

    private decodePort(name: string) {
        return atob(name);
    }
    private encodePort(name: string) {
        return btoa(name);
    }

    connect() {
        if (this.webSerialPort) {
            this.setState({ connecting: true, error: undefined });
            // if (this.webSerialPort.isOpen()) {
            //     this.serialPort.closePort(() => {
            //         this._connect(this.decodePort(this.props.match.params.port));
            //     });
            // } else {
            // }

            this._connect(this.decodePort(this.props.match.params.port));
        }
    }

    disconnect() {

        this.setState({ connected: false, connecting: false, debug: false });
        if (this.webSerialPort && this.webSerialPort.isOpen()) {

            this.webSerialPort!.close();
            this.props.history.replace("/usb");
        }

    }

    handleDefaultSettings = (ev: any) => {
        this.setState({ loading: true });
        this.state.mapper?.setDefaultConfig(this.rtu!).then(value => {
            this.setState({ loading: false, configured: true });
        }).catch(reason => {
            console.log(reason);
            this.setState({ loading: false, error: reason.message, connected: false, connecting: false });

        })
    }

    private handleSerialError = (error: any) => {
        if (error === 'break') return;
        this.setState({ error: error, connected: false, connecting: false });
        this.disconnect();

    }

    private findString(string: string) {
        let build = this.state.recv!.substring(this.state.recv!.indexOf(string) + string.length);
        build = build.substring(0, build.indexOf("\n"));
        return build;
    }
    private handleSerialData = (data: ArrayBuffer) => {
        let buffer = Buffer.from(data);
        let data1 = "";
        if (this.state.recv) {
            data1 = this.state.recv;
        }
        data1 += buffer.toString('UTF-8')
        console.log(buffer.toString('hex'));
        this.setState({ recv: data1 });

        if (data1.indexOf("build: ") > 0) {
            this.setState({ build: this.findString("build: ") });
        }
        if (data1.indexOf("imei:") > 0) {
            this.setState({ imei: this.findString("imei:") });
        }
    }

    private _connect(port: string) {

        if (this.state.debug) return;
        let port1 = this.webSerialPort?.ports[parseInt(port)];
        if (!port1) {

            this.props.history.replace("/usb");
            return;
        };
        this.webSerialPort!.connect(port1!, 19200).then((res: any) => {
            this.setState({ connecting: false });
            if (res.success) {
                this.readConfiguration();
            } else {
                this.setState({ error: res.error.message });
                this.props.history.replace("/usb");
            }
        });

    }

    private async readSectionConfiguration(section: ConfigurationSection) {

        if (section.rtu) {

            let rtu = new section.rtu.class(this.rtu!, this.state.mapper!);
            if (await rtu.Read()) {

                this.setState({ configuration: { ...this.state.configuration, [section.rtu.stateKey]: rtu } })

                return rtu;
            } else {

                throw new Error("Error reading this section");
            }
        } else {
            throw new Error("This section is not configurable");
        }
    }

    private async writeSectionConfiguration(section: ConfigurationSection) {
        if (section.rtu && this.state.configuration) {

            // @ts-ignore
            let rtu = this.state.configuration[section.rtu.stateKey];
            // @ts-ignore
            let result = await rtu.Write();

            return result;
        } else {
            throw new Error("This section is not configurable");
        }
    }

    private getSectionsByDeviceType(userInfo: UserInfo) {
        /*
        if(version === 1) return "S273-RTU";
        if(version === 2) return "S271-RTU";
        if(version === 3) return "S274-RTU";
        if(version === 4) return "S272-RTU";
        if(version === 5) return "S275-RTU";
         */
        if (userInfo.Product === 2 && userInfo.Firmware === 0) {
            return (new S271());
        }
        if (userInfo.Product === 4 && userInfo.Firmware === 1) {
            return (new S272());
        }
        if (userInfo.Product === 5 && userInfo.Firmware === 1) {
            return (new S275());
        }
        return null;
    }

    private async readConfiguration() {
        try {
            this.setState({
                connected: true,
                loading: true
            });
            this.rtu = new ModbusRTUClient(new SerialWrapper(this.webSerialPort!));

            let userInfo = new UserInfo(this.rtu);

            await userInfo.Read();


            let mapper = this.getSectionsByDeviceType(userInfo);
            if (!mapper) {
                this.setState({ loading: false, error: 'Dispositivo no compatible: ' + userInfo.Product + ". Firmware: " + userInfo.Firmware });
                return;
            }
            let config = new DeviceConfiguration(this.rtu!, mapper!);
            await config.Read();

            this.setState({ mapper: mapper!, sections: mapper.getSections(), serialNumber: config.serial_number, deviceModel: userInfo.getProduct(userInfo.Product) + " / " + userInfo.Firmware + " / " + config.version_model })
            // if(this.compareConfiguration(this.deviceConfig[0], deviceConfig[0])) {
            //
            //     this.setState({status: {...this.state.status, device: {loading: false, state: true}}});
            // } else {
            //
            //     this.setState({status: {...this.state.status, device: {loading: false, state: false}}});
            // }

            //
            // let netConfig = new NetConfig(this.rtu!);
            // let netInfo = await netConfig.Read();
            // Utils.log(netInfo);

            // if (this.compareConfiguration(this.netConfig[0], netInfo[0]) && this.compareConfiguration(this.netConfig[1], netInfo[1])) {
            //     this.setState({status: {...this.state.status, network: {loading: false, state: true}}});
            // } else {
            //     this.setState({status: {...this.state.status, network: {loading: false, state: false}}});
            // }
            // if (this.compareConfiguration(this.netConfig[2], netInfo[2])) {
            //     this.setState({status: {...this.state.status, apn: {loading: false, state: true}}});
            // } else {
            //     this.setState({status: {...this.state.status, apn: {loading: false, state: false}}});
            // }
            this.setState({ configuration: { userInfo: userInfo, deviceConfig: config }, loading: false })


            // let save = new DeviceSave(this.rtu);
            // await save.Write([{Save: 0x100}]);
        } catch (e: any) {

            this.setState({ error: 'Error al comprobar la configuración: ' + e.message, loading: false });
            this.disconnect();
        }

    }

    private _connectAsDebug(port: string) {
        this.setState({ debug: true });
        // this.serialPort?.openPort({
        //     bitrate: 115200, // 115200
        //     dataBits: "eight",
        //     parityBit: "no",
        //     portName: port,
        //     stopBits: "one"
        // }, async (response) => {
        //     if(response.result === 'ok') {
        //         this.setState({debug: true, connected: true});
        //     } else {
        //         this.setState({error: 'Imposible conectar'});
        //         this.disconnect();
        //     }
        //
        // });

        let port1 = this.webSerialPort?.ports[parseInt(port)];
        if (!port1) {

            this.props.history.replace("/usb");
            return;
        };
        this.webSerialPort!.connect(port1!, 115200).then((res: any) => {
            this.setState({ connecting: false });
            if (res.success) {
                this.setState({ debug: true, connected: true });
            } else {
                this.setState({ error: res.error.message });
                this.props.history.replace("/usb");
            }
        });

    }

    async componentDidUpdate(prevProps: Readonly<ConfiguratorProps>, prevState: Readonly<ConfiguratorState>, snapshot?: any) {
        if (this.props.match.params.port !== prevProps.match.params.port) {
            if (this.props.match.params.port) {
                this.connect();
            } else {
                this.disconnect();
            }
        }
    }

    componentWillUnmount(): void {
        this.disconnect();
    }

    init() {
        return new Promise(resolve => {
            if (WebSerialPort.available()) {
                this.setState({ available: true });
                // this.serialPort = this.props.store!.getSerialPort();
                //
                // this.serialPort?.setOnDataReceivedCallback(this.handleSerialData);
                // this.serialPort?.setOnErrorReceivedCallback(this.handleSerialError);
                // this.serialPort.isExtensionInstalled((res, version) => {
                //     if (res) {
                //         this.setState({installed: true});
                //         resolve(true);
                //     } else {
                //         this.setState({installed: false});
                //         resolve(true);
                //     }
                // })

                this.webSerialPort = new WebSerialPort({
                    onSerialPortsList: (devices: SerialPort[]) => {
                        this.setState({ devices: devices });
                    },
                    onConnect: (device: SerialPort) => {

                    }
                });

                this.webSerialPort.setOnErrorReceivedCallback(this.handleSerialError);
                this.webSerialPort.setOnDataReceivedCallback(this.handleSerialData);
                this.webSerialPort.init().then(value => {
                    resolve(true);
                })

            } else {
                this.setState({ available: false });
                resolve(false);
            }
        })
    }


    handleTest = (ev: any) => {
        // this.serialPort?.write(Buffer.from("t\r\n"), (res: any) => {
        //     Utils.log(res);
        // })
    }

    handleDebug = (port: string) => (ev: any) => {
        ev.preventDefault();
        this._connectAsDebug(port);
    }

    // handleSaveConfig = async (ev:any) => {
    //     this.setState({loading: true});
    //
    //     let config = new DeviceConfiguration(this.rtu!);
    //     await config.Write(this.state.configuration!.deviceConfig);
    //     let netConfig = new NetConfig(this.rtu!);
    //     await netConfig.Write(this.state.configuration!.netConfig);
    //
    //     await this.readConfiguration();
    //
    //     this.setState({loading: false});
    //
    // }

    handleCloseSection = () => {
        this.setState({ sectionModal: false });
    }
    handleSaveAndCloseSection = () => {
        this.setState({ loading: true });
        this.writeSectionConfiguration(this.state.section!).then(value => {

            this.setState({ sectionModal: false, loading: false });
        }).catch(err => {
            console.error(err);
            this.setState({ loading: false, error: err.toString() });

        })

    }

    instanceOfExportable(data: any): data is Exportable {
        return 'export' in data;
    }

    handleClearSection = () => {
        if (window.confirm("¿Estar seguro? Esta acción no se puede desacer")) {
            if (this.state.section && this.state.section.rtu) {
                let key = this.state.section.rtu.stateKey as keyof DeviceConfigurationState;
                let config = this.state.configuration![key];
                config?.clear();
                this.setState({ configuration: { ...this.state.configuration, [key]: config } });
            }
        }
    }
    handleExportClick = () => {
        console.log(this.state.section);
        if (this.state.section && this.state.section.rtu) {
            let key = this.state.section.rtu.stateKey as keyof DeviceConfigurationState;
            let config = this.state.configuration![key];
            if (this.instanceOfExportable(config)) {
                let data = config.export();
                let results: any = {};
                results["configuration." + key] = data;
                let string = JSON.stringify(results, null, 4);
                Utils.downloadFile(string, 'CloudRTU-config.' + this.state.deviceModel + "." + key + ".json");
            }
        }
        return;

    }

    handleImportClick = () => {
        let input = document.createElement('input');
        input.type = 'file';
        input.onchange = (ev: any) => {
            console.log(this.state);
            if (ev.target.files && ev.target.files.length > 0) {
                if (this.state.section && this.state.section.rtu) {
                    let key = this.state.section.rtu.stateKey as keyof DeviceConfigurationState;
                    for (let index = 0; index < ev.target.files.length; index++) {
                        const element = ev.target.files[index];
                        const reader = new FileReader();
                        reader.onload = async (e: any) => {
                            const text = (e.target.result)
                            this.importDataSection(text, key);
                        };
                        reader.readAsText(element)
                    }

                }
            }
        }
        input.click();
    }

    handleImportAllClick = () => {
        let input = document.createElement('input');
        input.type = 'file';
        input.onchange = (ev: any) => {
            console.log(this.state);
            if (ev.target.files && ev.target.files.length > 0) {
                for (let index = 0; index < ev.target.files.length; index++) {
                    const element = ev.target.files[index];
                    const reader = new FileReader();
                    reader.onload = async (e: any) => {
                        const text = (e.target.result)
                        this.importData(text);
                    };
                    reader.readAsText(element)
                }
            }
        }
        input.click();
    }

    importDataSection(data: string, key: keyof DeviceConfigurationState) {
        let config = this.state.configuration![key];
        if (this.instanceOfExportable(config)) {
            let object = JSON.parse(data);
            if (object["configuration." + key]) {
                config.import(object["configuration." + key]);
                this.setState({ configuration: { ...this.state.configuration, [key]: config } });
            } else {
                this.setState({ error: 'No se puede importar este contenido: ' + key });
            }
        }
    }
    async importData(data: string) {
        this.setState({ loading: true });
        let object = JSON.parse(data);
        let keys = Object.keys(object);
        for (let index = 0; index < keys.length; index++) {
            const element = keys[index];
            let key = element.substring(14) as keyof DeviceConfigurationState;
            let config = this.state.sections!.find(a => a.rtu?.stateKey === key);
            await this.readSectionConfiguration(config!);
            this.importDataSection(object, key);
        }
        this.setState({ loading: false });
    }

    handleSectionChange = (section: ConfigurationSection) => (ev: CustomEvent<InputChangeEventDetail>) => {
        section.write && section.write(this.state.configuration, ev.detail.value);
        this.setState({});
    }
    handleSectionChangeCheck = (section: ConfigurationSection) => (ev: CustomEvent<any>) => {
        section.write && section.write(this.state.configuration, ev.detail.checked);
        this.setState({});
    }

    showSection = (section: ConfigurationSection) => (ev: any) => {
        this.setState({ loading: true });
        this.readSectionConfiguration(section).then(() => {
            this.setState({ loading: false, section: section, sectionModal: true });
        }).catch(reason => {

            this.setState({ loading: false, error: reason.message });
        })
    }

    readSection(section: ConfigurationSection) {
        try {
            return section.read && section.read(this.state.configuration);
        } catch (e) {
            Utils.log(e);
            return "";
        }
    }

    renderNotAvailable() {
        return <IonItem>
            <IonText>Este navegador no soporta la conexión con el dispositivo por USB. Por favor use Google
                Chrome.</IonText>
        </IonItem>
    }


    renderSections(section: ConfigurationSection[]) {
        return section.map((value, key) => <div key={key}>{this.renderSection(value)}</div>);
    }
    renderSection(section: ConfigurationSection): any {
        if (section.showIf && !section.showIf(this.state.configuration!)) return;
        if (section.type === 'group') {
            return <IonItem button detail onClick={this.showSection(section)}>
                <IonLabel>{section.title}</IonLabel>
            </IonItem>
        }
        if (section.type === 'text') {
            return <IonItem>
                <IonInput label={section.title} slot={"end"} value={this.readSection(section)} onIonInput={this.handleSectionChange(section)} {...section.controlProps}></IonInput>
            </IonItem>
        }
        if (section.type === 'number') {
            return <IonItem>
                <IonInput label={section.title} slot={"end"} value={this.readSection(section)} onIonInput={this.handleSectionChange(section)} {...section.controlProps}></IonInput>
            </IonItem>
        }
        if (section.type === 'header') {
            return <IonItemDivider>
                <IonLabel>
                    {section.title}
                </IonLabel>
            </IonItemDivider>
        }
        if (section.type === 'select') {
            return <IonItem>
                <IonSelect label={section.title} value={this.readSection(section)} placeholder="Select One" onIonChange={this.handleSectionChange(section)}>
                    {section.controlProps && section.controlProps.values.map((v: any, k: number) => <IonSelectOption key={k} value={v.value}>{v.name}</IonSelectOption>)}
                </IonSelect>
            </IonItem>
        }
        if (section.type === 'check') {
            return <IonItem>
                <IonCheckbox checked={this.readSection(section)} onIonChange={this.handleSectionChangeCheck(section)}>
                    {section.title}
                </IonCheckbox>
            </IonItem>
        }
        if (section.type === 'function') {
            return this.renderSections(section.fn!());
        }
    }

    renderConnected() {
        return <IonList>
            <IonItem>
                <IonText>
                    Conectado
                </IonText>

            </IonItem>
            {/*<IonItem>*/}
            {/*    <IonLabel position={"floating"}>Recibido</IonLabel>*/}
            {/*    <IonTextarea>{this.state.recv}</IonTextarea>*/}
            {/*</IonItem>*/}

            <IonItem>
                <IonLabel>Modelo</IonLabel>
                <IonText>{this.state.deviceModel}</IonText>
            </IonItem>
            {this.state.serialNumber && <IonItem>
                <IonLabel>Numero serie</IonLabel>
                <IonText>{this.state.serialNumber}</IonText>
            </IonItem>}

            {this.state.build &&
                <IonItem>
                    <IonLabel>Build</IonLabel>
                    <IonText>{this.state.build}</IonText>
                </IonItem>}
            {this.state.build &&
                <IonItem>
                    <IonLabel>Imei</IonLabel>
                    <IonText>{this.state.imei}</IonText>
                    <IonSpinner hidden={!!this.state.imei} />

                </IonItem>}
            {this.state.sections && this.renderSections(this.state.sections)}

            <IonModal isOpen={this.state.sectionModal! ? true : false} onDidDismiss={() => this.handleCloseSection()}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Editar configuración</IonTitle>
                        <IonButtons slot={"secondary"}>
                            <IonButton onClick={() => this.handleCloseSection()}>Cancelar</IonButton>
                        </IonButtons>
                        <IonButtons slot={"primary"}>
                            <IonButton onClick={() => this.handleSaveAndCloseSection()}>Guardar</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    {this.state.section && this.renderSections(this.state.section.settings!)}

                    <IonItem>
                        <IonButton onClick={this.handleExportClick}>Exportar</IonButton>
                        <IonButton onClick={this.handleImportClick}>Importar</IonButton>
                        <IonButton onClick={this.handleClearSection}>Reiniciar</IonButton>

                    </IonItem>
                </IonContent>

            </IonModal>
            {/*<IonItem>*/}
            {/*    <IonLabel>Configuración del Sistema</IonLabel>*/}
            {/*    <IonIcon color={this.state.status?.device!.state ? "success" : "danger"}*/}
            {/*             hidden={this.state.status?.device!.loading}*/}
            {/*             icon={this.state.status?.device!.state ? checkmarkOutline : closeOutline}></IonIcon>*/}
            {/*    <IonSpinner hidden={!this.state.status?.device!.loading}/>*/}
            {/*</IonItem>*/}
            {/*<IonItem>*/}
            {/*    <IonLabel>Configuración de Red</IonLabel>*/}
            {/*    <IonIcon color={this.state.status?.network!.state ? "success" : "danger"}*/}
            {/*             hidden={this.state.status?.network!.loading}*/}
            {/*             icon={this.state.status?.network!.state ? checkmarkOutline : closeOutline}></IonIcon>*/}
            {/*    <IonSpinner hidden={!this.state.status?.network!.loading}/>*/}
            {/*</IonItem>*/}
            {/*<IonItem>*/}
            {/*    <IonLabel>Configuración de SIM</IonLabel>*/}
            {/*    <IonIcon color={this.state.status?.apn!.state ? "success" : "danger"}*/}
            {/*             hidden={this.state.status?.apn!.loading}*/}
            {/*             icon={this.state.status?.apn!.state ? checkmarkOutline : closeOutline}></IonIcon>*/}
            {/*    <IonSpinner hidden={!this.state.status?.apn!.loading}/>*/}

            {/*</IonItem>*/}
            {/*<IonItem color={"primary"} detail={true} button detailIcon={saveOutline} onClick={this.handleSaveConfig}>*/}
            {/*    <IonLabel>Aplicar configuración</IonLabel>*/}
            {/*</IonItem>*/}
            <IonItem>
                <IonText>Para consultar el IMEI reinicie el terminal conectado en modo "SET"</IonText>
            </IonItem>
            <IonItem button color={"warning"} onClick={this.handleDefaultSettings}>
                <IonText>Establecer valores por defecto</IonText>
            </IonItem>
        </IonList>
    }

    renderList() {
        if (this.state.devices) {

            return <IonList>
                {this.state.devices?.map((value, index) => {
                    return <IonItem key={index} routerLink={"/usb/" + this.encodePort(String(index))}>
                        <IonText>{value.getInfo().usbProductId} - {value.getInfo().usbVendorId}</IonText>
                        <IonButton onClick={this.handleDebug(String(index))}>Debug</IonButton>
                    </IonItem>
                })}
            </IonList>
        } else {
            return <IonItem><IonLabel>Cargando dispositivos...</IonLabel></IonItem>
        }
    }

    renderState() {
        if (!this.state.available) {
            return this.renderNotAvailable()
        } else if (!this.state.connected) {
            return this.renderList();
        }
    }

    renderDebug() {
        return <><IonTextarea style={{ fontFamily: 'monospace' }}>
            {this.state.recv}
        </IonTextarea>
            <IonButton onClick={() => this.disconnect()}>Desconectar</IonButton>
        </>
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        // debugger;
        return <IonPage>
            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot={"start"}>

                    </IonButtons>
                    <IonTitle>Configurador de Dispositivos</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => this.loadDevices()}>
                            <IonIcon slot="icon-only" icon={linkOutline} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {this.state.error && <IonItem color={"danger"}><IonText>
                    No se puede conectar con el dispositivo. Vuelva a re-conectarlo e intentelo de nuevo.
                    {this.state.error}
                </IonText></IonItem>}
                {this.state.connecting && <IonItem><IonText>
                    Conectando...
                </IonText></IonItem>}
                {this.state.debug && this.renderDebug()}
                {this.state.debug || <>
                    {this.state.connected && this.renderConnected()}
                    {!this.state.connected && !this.state.connecting && this.renderState()}
                </>}

            </IonContent>
            <IonLoading isOpen={this.state.loading!} message={"Cargando..."} />
            <IonAlert
                isOpen={!!this.state.configured}
                onDidDismiss={() => this.setState({ configured: false })}
                header={'Dispositivo configurado'}
                subHeader={'Configuración'}
                message={'Se ha establecido la configuración por defecto con éxito.'}
                buttons={['OK']}
            />
        </IonPage>
    }
}

export default withRouter(Configurator);
