import React, { RefObject } from "react";
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonSpinner,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import { inject } from "mobx-react";
import { Store } from "../service/Store";
import AddPaymentMethod from "./AddPaymentMethod";
import { ElementsConsumer } from "@stripe/react-stripe-js";
import { PaymentMethod } from "../service/API";
import { addOutline, caretDown, timerOutline } from "ionicons/icons";
import PaymentDetails from "./PaymentDetails";

export type PaymentsType = {
    onAuthorize: (payment: string) => void,
    paylater: boolean,
    payment?: string,
    device_id?: string,
    store?: Store,

    routerOutlet: HTMLIonRouterOutletElement | undefined,
}
export type PaymentsState = {

    payment: string,
    payments: PaymentMethod[],
    loading: boolean,
    addPayment: boolean,
    selectPayment: boolean,
}

class Payments extends React.Component<PaymentsType, PaymentsState> {
    state: PaymentsState = { payment: '', payments: [], loading: false, addPayment: false, selectPayment: false };

    paymentsModal: RefObject<any> = React.createRef();

    loadPayments(payment: string) {
        return new Promise(resolve => {
            this.setState({ loading: true });
            if (this.props.device_id) {

                this.props.store?.api.getDevicePaymentMethods(parseInt(this.props.device_id)).then((response) => {
                    if (response.success) {
                        this.setState({ ...this.state, payments: response.data, payment: payment });
                    }
                    resolve(response);
                }).finally(() => {
                    this.setState({ loading: false });
                })
            } else {

                this.props.store?.api.getPaymentMethods().then((response) => {
                    if (response.success) {
                        this.setState({ ...this.state, payments: response.data, payment: payment });
                    }
                    resolve(response);
                }).finally(() => {
                    this.setState({ loading: false });
                })
            }
        });
    }

    handleChange = (value: string) => (ev: any) => {
        if (value === 'add') {
            ev.preventDefault();
            this.setState({ payment: '', addPayment: true, selectPayment: false })
        } else {
            this.setState({ payment: value, selectPayment: false });
            this.props.onAuthorize(value);

        }
    };

    handleOnAddPayment = (result: string | boolean) => {
        this.setState({ addPayment: false });
        if (result) {
            this.loadPayments(String(result));
        }
    }


    componentDidMount(): void {
        this.loadPayments(this.props.payment || this.state.payment);
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let selected = this.state.payments.find(value => value.id === this.state.payment);
        return <>

            <IonItem onClick={() => this.setState({ selectPayment: true })} button detail detailIcon={caretDown}>

                <IonSpinner hidden={!this.state.loading} />
                {!!selected && <PaymentDetails payment={selected} />}
                {!!selected || <IonLabel>Seleccionar Pago</IonLabel>}
                {/*<IonSelect onIonChange={this.handleChange} value={this.state.payment} okText="Aceptar"*/}
                {/*           cancelText="Cancelar" interface={"action-sheet"}>*/}

                {/*    <IonSelectOption value="add">+ Añadir forma de pago</IonSelectOption>*/}
                {/*    {this.state.payments && this.state.payments.map((value:any, key) => {*/}
                {/*        if(value.card)*/}
                {/*            return <IonSelectOption key={key} value={value.id}><span role={"img"} aria-label={"card"}>💳</span> {value.name} {value.card.last4}</IonSelectOption>*/}
                {/*        if(value.sepa_debit)*/}
                {/*            return <IonSelectOption key={key} value={value.id}><span role={"img"} aria-label={"card"}>🏦</span> {value.name} {value.sepa_debit.last4}</IonSelectOption>*/}


                {/*    })}*/}
                {/*    {this.props.paylater && <IonSelectOption value="paylater">Configurar más adelante</IonSelectOption>}*/}
                {/*</IonSelect>*/}
            </IonItem>

            <ElementsConsumer>
                {({ stripe, elements }) => (
                    <AddPaymentMethod device_id={this.props.device_id} routerOutlet={this.paymentsModal.current!} stripe={stripe} elements={elements} isOpen={this.state.addPayment} onAddPayment={this.handleOnAddPayment} />
                )}

            </ElementsConsumer>
            <IonModal presentingElement={this.props.routerOutlet} ref={this.paymentsModal} isOpen={this.state.selectPayment ? true : false} onDidDismiss={() => this.setState({ selectPayment: false })}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Seleccionar forma de pago</IonTitle>
                        <IonButtons slot={"secondary"}>

                            <IonButton onClick={() => this.setState({ selectPayment: false })}>Cancelar</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    <IonList>
                        <IonItem >
                            <IonLabel color={"medium"}>Formas de pago disponibles</IonLabel>
                        </IonItem>
                        {this.state.payments.map((value, key) =>
                            <IonItem button detail={false} onClick={this.handleChange(value.id)}>
                                <PaymentDetails extended={true} payment={value} selected={value.id === this.state.payment} />
                            </IonItem>)}

                        {this.state.payments.length === 0 && <IonItem color={"warning"}><IonLabel>(Sin formas de pago disponibles)</IonLabel></IonItem>}

                        <IonItem button detail={false} onClick={this.handleChange('add')} color={"primary"}>
                            <IonLabel><IonIcon icon={addOutline} /> Añadir forma de Pago</IonLabel>
                        </IonItem>

                        {this.props.paylater && <IonItem button detail={false} onClick={this.handleChange('paylater')}>
                            <IonLabel><IonIcon icon={timerOutline} /> Configurar más adelante</IonLabel>
                        </IonItem>}
                    </IonList>

                </IonContent>

            </IonModal>

        </>;
    }
}

export default inject("store", "lang")(Payments)
