export default class Packet {
    private buffer: Buffer;
    private cursor: number;
    private length: number;
    constructor() {
        this.buffer = Buffer.alloc(0x1FF);
        this.cursor = 0;
        this.length = 0;
    }

    write(data:number, bigendian:boolean = false) {
        if(!bigendian){

            this.buffer.writeUInt16LE(data, this.length);
        }
        else{

            this.buffer.writeUInt16BE(data, this.length);
        }

        this.length+=2;
    }

    read(bigendian:boolean = false) {
        let res;
        if(!bigendian) {
            res = this.buffer.readUInt16LE(this.cursor);
        } else {
            res = this.buffer.readUInt16BE(this.cursor);
        }
        this.cursor+=2;
        return res;
    }

    getBuffer() {
        return this.buffer.slice(0, this.length);
    }

    reset() {
        this.cursor = 0;
    }

    readByte() {
        return this.buffer.readUInt8(this.cursor++);
    }

    writeByte(value:number) {
        this.buffer.writeUInt8(value, this.length++);
    }

    registers() {
        return Math.ceil(this.cursor / 2);
    }
}
