import Registers from "../Registers";
import ModbusRTUClient from "../rtu/ModbusRTUClient";
import MemoryMapper from "./MemoryMapper";

export default class UserInfo extends Registers {

    public Product : number = 0;
    public Firmware : number = 0;
    constructor(rtu: ModbusRTUClient) {
        super(rtu, new MemoryMapper());
    }

    async Read(): Promise<boolean> {
        let data = await this.readHoldingRegistersArray(14, 1);
        if(data) {
            this.Product = data[0];
            data = await this.readHoldingRegistersArray(1868, 5);
            if(data[0] !== 0) {
                this.Firmware = 1;
            }
            data = await this.readHoldingRegistersArray(1496, 5);
            if(data[0] !== 0) {
                this.Firmware = 0;
            }
            return true;
        }
        return false;
    }


    getProduct(version:number) {
        if(version === 1) return "S273-RTU";
        if(version === 2) return "S271-RTU";
        if(version === 3) return "S274-RTU";
        if(version === 4) return "S272-RTU";
        if(version === 5) return "S275-RTU";
        return "?";
    }
}
