import ModbusResponseBody from "./ModbusResponseBody";
import ReadCoilsRequestBody from "../request/ReadCoilsRequestBody";
import BufferUtils from "../BufferUtils";

export default class ReadCoilsResponseBody extends ModbusResponseBody {
    private _coils: boolean[];
    private _numberOfBytes: number;
    private _valuesAsArray?: any[];
    private _valuesAsBuffer?: Buffer;
    /** Creates a response body from a request body and
     * the coils buffer
     * @param {ReadCoilsRequestBody} request
     * @param {Buffer} coils
     * @returns {ReadCoilsResponseBody}
     */
    static fromRequest (requestBody:ReadCoilsRequestBody, coils:number[]) {
        const coilsStatus = BufferUtils.bufferToArrayStatus(coils)

        const start = requestBody.start
        const end = start + requestBody.count

        // Extract the segment of coils status
        const coilsSegment = coilsStatus.slice(start, end)

        return new ReadCoilsResponseBody(coilsSegment, Math.ceil(coilsSegment.length / 8))
    }

    /** Create ReadCoilsResponseBody from buffer.
     * @param {Buffer} buffer
     * @returns {ReadCoilsResponseBody} Returns Null of not enough data located in the buffer.
     */
    static fromBuffer (buffer:Buffer) {
        try {
            const fc = buffer.readUInt8(0)
            const byteCount = buffer.readUInt8(1)
            const coilStatus = buffer.slice(2, 2 + byteCount)

            if (coilStatus.length !== byteCount) {
                return null
            }

            if (fc !== 0x01) {
                return null
            }

            return new ReadCoilsResponseBody(coilStatus as unknown as Array<boolean>, byteCount)
        } catch (e) {
            // debug('no valid read coils response body in the buffer yet')
            return null
        }
    }

    /** Create new ReadCoilsResponseBody
     * @param [Array] coils Array of Boolean.
     * @param [Number] length
     */
    constructor (coils:boolean[], numberOfBytes:number) {
        super(0x01)
        this._coils = coils
        this._numberOfBytes = numberOfBytes

        if (coils instanceof Array) {
            this._valuesAsArray = coils
            this._valuesAsBuffer = BufferUtils.arrayStatusToBuffer(coils)
        }

        if (coils instanceof Buffer) {
            this._valuesAsBuffer = coils
            this._valuesAsArray = BufferUtils.bufferToArrayStatus(coils)
        }
    }

    /** Coils */
    get values () {
        return this._coils
    }

    get valuesAsArray () {
        return this._valuesAsArray
    }

    get valuesAsBuffer () {
        return this._valuesAsBuffer
    }

    /** Length */
    get numberOfBytes () {
        return this._numberOfBytes
    }

    get byteCount () {
        return this._numberOfBytes + 2
    }

    createPayload () {
        const payload = Buffer.alloc(this.byteCount)

        payload.writeUInt8(this.fc, 0)
        payload.writeUInt8(this._numberOfBytes, 1)

        this._valuesAsBuffer!.copy(payload, 2)

        return payload
    }
}
