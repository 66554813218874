export interface WebSerialPortHandler {
    onConnect(device: SerialPort): void;

    onSerialPortsList(devices: SerialPort[]): void;
}

export default class WebSerialPort {

    ports: SerialPort[] = [];
    handler: WebSerialPortHandler;

    port?: SerialPort;
    open: boolean = false;

    writer?: any;
    reader?: ReadableStreamDefaultReader<any>;

    private onDataReceivedCallback: ((data: ArrayBuffer) => void)[] = [];
    /**
     * Callback function to call if there is the connection encountered some problems.
     */
    private onErrorReceivedCallback?: (error: any) => void = undefined;

    static available() {
        return typeof navigator !== "undefined" && "serial" in navigator;
    }

    constructor(handler: WebSerialPortHandler) {
        this.handler = handler;
        navigator.serial.addEventListener('connect', (event) => {
            // this.handler.onConnect(event.);
            // connectDevice(event.device);

            this.handler.onSerialPortsList(this.ports);
        });

        navigator.serial.addEventListener('disconnect', (event) => {
            // let index = this.devices.indexOf(event.device);
            // this.devices.splice(index, 1);
            // this.handler.onDeviceList(this.devices);
        });
        //

    }

    init() {
        return navigator.serial.getPorts().then((ports) => {
            this.ports = ports;
            this.handler.onSerialPortsList(this.ports);
            return ports;
        });
    }

    async addDevice() {
        const filters : any[] = [];
        try {
            let port = await navigator.serial.requestPort({filters: filters});
            this.ports.push(port);
            let index = this.ports.indexOf(port);
            this.handler.onSerialPortsList(this.ports);
            return index;
            // this.devices.push(event.device);
            // let index = this.devices.indexOf(device);
            // if (index === -1) {
            //     this.push('devices', device);
            //     connectDevice(device);
            // }
        } catch (error) {
            // Ignore "no device selected" error.
        }
    }

    getDeviceByIndex(index: number) {
        return this.ports[index];
    }

    async connect(port: SerialPort, baudRate: number, 
        parity : ParityType | undefined = undefined, 
        dataBits : number | undefined = undefined,
        stopBits : number | undefined = undefined,
        ) {
        // let device = this.ports.find(a => a.! === portNo);
        try {
            await port.open({baudRate, flowControl: "none", parity, dataBits, stopBits});
            this.port = port;
            this.startReceiver();
            return {success: true};
        } catch (ex) {
            return {error: ex};
        }
    }

    async close() {
        if(this.isOpen()) {
            this.open = false;
            await this.reader?.cancel();
            try {
                await this.port?.close();
            } catch(e) {
                console.error(e);
            }
            this.port = undefined;
            this.reader = undefined;
        }
    }

    isOpen() {
        return this.port && this.reader && this.open;
    }

    write(data: Buffer) {
        if (this.port) {
            const writer = this.port!.writable!.getWriter();
            writer.write(data).then(r => writer.releaseLock());
        }
    }

    async startReceiver() {
        if(!this.port) return;

        this.open = true;
        let reader = undefined;
        // const reader = this.reader = this.port!.readable!.getReader();
        // Listen to data coming from the serial device.

        while (this.port && this.port.readable && this.open) {
            try {
                this.reader = reader = this.port.readable.getReader();
                for (;;) {
                    const {value, done} = await reader.read();
                    if (value) {
                        this.handleData(value);
                    }
                    if (done) {
                        break;
                    }
                }
                reader.releaseLock();
                reader = undefined;
            } catch (e:any) {
                console.error(e);

                if(this.onErrorReceivedCallback) {
                    this.onErrorReceivedCallback(e.message);
                }
                
                //term.writeln(`<ERROR: ${e.message}>`);
            }
        }
        if(this.port) {
            try {
                await this.close();
                // await this.port.close();
            } catch (e) {
                console.log(e);
            }
        }
    }

    handleData(value: Uint8Array) {
        for (let i = 0; i < this.onDataReceivedCallback.length; i++) {
            this.onDataReceivedCallback[i](value);
        }
    }

    setOnDataReceivedCallback(callback: (data: ArrayBuffer) => void) {
        this.onDataReceivedCallback.push(callback);
    }

    /**
     * Set the error callback.
     */
    setOnErrorReceivedCallback(callBack: (error: any) => void) {
        this.onErrorReceivedCallback = callBack;
    }
}
