import Registers from "../Registers";
import Exportable from "./Exportable";
import {MemoryAddress} from "./MemoryMapper";
export enum DataType {
    BOOL,
    WORD,
    INT,
    DOUBLE,
}

export type Slave = {
    address: number,
    fn: number,
    startAddr: number,
    //no: number,
    regSeq: number,
    len: number,
    devId: number,
    dataType: DataType,
    regSeqRegister?: number,
    regSeqRegisterEnd?: number,
}
export type Bank = {
    type: number,
    startAddr: number,
    startAddrRegister: number,
    count: number,
    dataSize: number,
    offset: number,
}
const MAX_SLAVE_LIST_LENGTH = 16;
const MAX_SLAVE_BYTE_LENGTH = 16;
export default class SlaveList extends Registers implements Exportable {

/*
        public readonly int MinWord = 20000;
        public readonly int MaxWord = 20127;
        public readonly int LenWord = 128;
        public readonly int MinInt = 20128;
        public readonly int MaxInt = 20255;
        public readonly int LenInt = 64;
        public readonly int MinDouble = 20256;
        public readonly int MaxDouble = 20511;
        public readonly int LenDouble = 64;
*/
    private banks : {[key:string]: Bank} = {
        'bool' : {
            type: 0,
            startAddr: 0,
            startAddrRegister: 64,
            count: 64,
            dataSize: 1,
            offset: 0,
        },
        'word' : {
            type: 1,
            startAddr: 64,
            startAddrRegister: 20000,
            count: 128,
            dataSize: 1,
            offset: 0,
        },
        'int' : {
            type: 2,
            startAddr: 192,
            startAddrRegister: 20128,
            count: 64,
            dataSize: 2,
            offset: 0,
        },
        'double' : {
            type: 3,
            startAddr: 256,
            startAddrRegister: 20256,
            count: 64,
            dataSize: 4,
            offset: 0,
        },
    };
    
    slaves : Slave[] = [];
    async Read(): Promise<any> {
        let buffer = await this.customRead(this.mapper.get(MemoryAddress.A_DEV_INFO), 256);
        if(buffer) {
            this.slaves = [];
            for(let i = 0; i < MAX_SLAVE_LIST_LENGTH; i++) {
                let start = i * MAX_SLAVE_BYTE_LENGTH;
                let slave : Slave = {
                    address: buffer.readUInt8(start),
                    fn: buffer.readUInt8(start + 1),
                    startAddr: buffer.readUInt16LE(start + 2),
                    //no: buffer.readUInt16LE(start + 4),
                    regSeq: buffer.readUInt16LE(start + 6),
                    len: buffer.readUInt16LE(start + 8),
                    devId: buffer.readUInt8(start + 10),
                    dataType: buffer.readUInt8(start + 11),
                };
                this.slaves.push(slave);
            }
            console.table(this.slaves);
            return true;
        }
        return false;
    }

    private async customRead(addr: number, size: number) {
        await this.writeMultipleRegisters(4608, this.customFunction(33536, addr, size));

        let addr2 = 4612;
        if(size > 250) {
            let num = size / 4;
            let buff1 = await this.readHoldingRegistersBuffer(addr2, num);
            let buff2 = await this.readHoldingRegistersBuffer(addr2 + num, (size + 1) / 2 - num);

            return Buffer.concat([buff1, buff2]);
        } else {
            return await this.readHoldingRegistersBuffer(addr2, (size + 1) / 2);

        }
    }

    private async customWrite(addr: number, data:Buffer) {
        
        let buff = Buffer.concat([this.customFunction(33280, addr, data.length), data]);

        let addr2 = 4608;
        let length = buff.length / 2;
        if(buff.length > 250) {
            let buffer1 = buff.subarray(length);
            let buffer2 = buff.subarray(0, length);
            await this.writeMultipleRegisters(addr2 + (length / 4), buffer1);
            await this.writeMultipleRegisters(addr2, buffer2);

        } else {
            return await this.writeMultipleRegisters(addr2, data);

        }
        return null;
    }
    getBank(id: number) {
        return Object.values(this.banks).find(a => a.type === id);
    }

    async Write(): Promise<any> {
        Object.values(this.banks).forEach(a => a.offset = 0);
        // let total = 0;
        for (let i = 0; i < this.slaves.length; i++) {
            const element = this.slaves[i];
            if(element.address !== 255) {
                if(element.address < 1 || element.address > 254) {
                    throw new Error("The slave address ranges from 1 to 254. Element #" + i);
                }

                let bank = this.getBank(element.dataType);
                if(!bank) throw new Error("Invalid data type for " + i);

                element.regSeq = bank.startAddr + bank.offset;
                element.regSeqRegister = bank.startAddrRegister + bank.offset;
                bank.offset += element.len * bank.dataSize;
                element.regSeqRegisterEnd = bank.startAddrRegister + bank.offset - 1;
                element.devId = i;
                
            } else {
                // Disable element:
                element.fn = 0;
                element.startAddr = 0;
                element.len = 0;
                element.regSeq = 0;
                element.devId = 255;
                element.dataType = 0;
            }
        }
        let buffer = Buffer.alloc(256);
        for (let i = 0; i < this.slaves.length; i++) {
            let start = i * MAX_SLAVE_BYTE_LENGTH;
            let slave = this.slaves[i];
            buffer.writeUInt8(slave.address, start);
            buffer.writeUInt8(slave.fn, start + 1);
            buffer.writeUInt16LE(slave.startAddr, start + 2);
            buffer.writeUInt16LE(slave.len, start + 4);
            buffer.writeUInt16LE(slave.regSeq, start + 6);
            buffer.writeUInt16LE(slave.len, start + 8);
            buffer.writeUInt8(slave.devId, start + 10);
            buffer.writeUInt8(slave.dataType, start + 11);
        }
        console.table(this.slaves);

        await this.writeCustomRegisters(this.mapper.get(MemoryAddress.A_DEV_INFO), buffer);
    }

    export(): any {
        return {slaves: this.slaves};
    }
    import(object: any) {
        this.slaves = object.slaves;
    }
    clear() {
        for (let i = 0; i < this.slaves.length; i++) {
            const element = this.slaves[i];
            element.address = 255;
            element.fn = 0;
            element.startAddr = 0;
            element.len = 0;
            element.regSeq = 0;
            element.devId = 255;
            element.dataType = 0;
        }

    }
}
