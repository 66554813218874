import React, { RefObject } from "react";
import { IonIcon, IonInput, IonInputPasswordToggle, IonItem, IonLabel, IonList, IonSpinner, IonText } from "@ionic/react";
import { inject } from "mobx-react";
import { Store } from "../service/Store";
import { User } from "../service/API";
import { checkmarkOutline } from "ionicons/icons";
import Lang from "../service/Lang";

export interface ChangePasswordProps {
    store?: Store;
    lang?: Lang;

    onChange: () => void;
}

export interface ChangePasswordState {
    user?: User;
    loading: boolean;
    error: string | undefined;
}

@inject("store", "lang")
export default class ChangePassword extends React.Component<ChangePasswordProps, ChangePasswordState> {

    currentPassword: RefObject<any>;
    newPassword1: RefObject<any>;
    newPassword2: RefObject<any>;


    constructor(props: ChangePasswordProps, context: any) {
        super(props, context);
        this.currentPassword = React.createRef();
        this.newPassword1 = React.createRef();
        this.newPassword2 = React.createRef();

    }

    componentDidMount(): void {
        this.setState({ error: undefined });

    }

    handleChange = (ev: any) => {
        const { lang } = this.props;
        this.setState({ error: undefined })
        let oldPassword = this.currentPassword.current.value;
        let newPassword1 = this.newPassword1.current.value;
        let newPassword2 = this.newPassword2.current.value;
        if (newPassword1 === newPassword2) {
            if (newPassword1.length > 6) {
                this.setState({ loading: true });
                this.props.store?.api.usersChangePassword(oldPassword, newPassword1).then((value) => {
                    this.setState({ loading: false });
                    if (value.success) {
                        this.props.onChange();
                    } else {
                        this.setState({ error: value.message })
                    }
                })
            } else {
                this.setState({ error: lang?.l.main.password_short()! });
            }

        } else {
            this.setState({ error: lang?.l.main.password_not_match()! });
        }
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        if (!this.state) return "";
        const { lang } = this.props;
        return <>

            <IonList>
                {this.state.error && <IonItem color={"danger"}>
                    <IonLabel>{lang?.l.main.error()}</IonLabel>
                    <IonText>{this.state.error}</IonText>
                </IonItem>}
                <IonItem>
                    <IonInput label={lang?.l.main.password_current()} ref={this.currentPassword} type={"password"}
                        placeholder={lang?.l.main.password_current_help()!}>
                        <IonInputPasswordToggle slot="end"></IonInputPasswordToggle>
                    </IonInput>
                </IonItem>
                <IonItem>
                    <IonInput label={lang?.l.main.password_new()} ref={this.newPassword1} type={"password"}
                        placeholder={lang?.l.main.password_new_help()!}>
                        <IonInputPasswordToggle slot="end"></IonInputPasswordToggle>
                    </IonInput>
                </IonItem>
                <IonItem>
                    <IonInput label={lang?.l.main.password_repeat()} ref={this.newPassword2} type={"password"}
                        placeholder={lang?.l.main.password_repeat_help()!}>
                        <IonInputPasswordToggle slot="end"></IonInputPasswordToggle>
                    </IonInput>
                </IonItem>
                <IonItem color={"primary"} detail={false} button onClick={this.handleChange}>
                    {this.state.loading && <IonSpinner />}
                    {!this.state.loading && <IonIcon icon={checkmarkOutline} />}
                    <IonLabel>{lang?.l.main.change()}</IonLabel>
                </IonItem>
            </IonList>
        </>
    }
}
