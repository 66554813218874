import { Socket } from "socket.io-client";
import { Store } from "./Store";
import html2canvas from 'html2canvas';


export type RemoteSupportCommand = {
    id: number,
    command : string,
    data ? : any,
}

export default class RemoteSupportService {
    store: Store;
    socket ? : Socket;
    pin ? : number;
    constructor(store: Store) {
        this.store = store;
    }

    start() : Promise<number> {
        this.socket = this.store.socket;
        return new Promise((resolve, reject) => {
            this.pin = (new Date()).getTime() % 10000;
            if(this.pin < 999) {
                this.pin += 1000;
            }
            this.socket?.emit('join-support', this.pin);
            this.socket!.on('support-join-ok', () => {
                resolve(this.pin!);

            })
        } )
    }

    join() {
        

    }

    handleCommand(command: RemoteSupportCommand) {
        if(this.pin) {
            if (command.command === 'screen') {
                html2canvas(document.body).then((canvas) => {
                    const img = canvas.toDataURL('image/jpeg', 0.10);
                    this.socket!.emit('support-response', this.pin, {action: 'screen', img: img});
                });
                // RemoteSupport.screen({}).then(res => {
                //     this.socket!.emit('support-response', this.pin, res);
                //     // console.log("RemoteSupport: ", res);
                // })
            }
            if (command.command === 'ping') {
                this.socket!.emit('support-response', this.pin, {action: 'ping', success: true});
                
            }
            // if (command.command === 'web-screen') {
            //     RemoteSupport.screen({}).then(res => {
            //         this.socket!.emit('support-response', this.pin, res);
            //         // console.log("RemoteSupport: ", res);
            //     })
            // }
        }
        
    }
}