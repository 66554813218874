import React from "react";
import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonFab,
    IonFabButton,
    IonHeader,
    IonIcon,
    IonItem,
    IonList,
    IonLoading,
    IonPage,
    IonText,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { inject } from "mobx-react";
import { Store } from "../../service/Store";
import { Device, DeviceServiceOutput } from "../../service/API";
import { add } from "ionicons/icons";
import OutputServiceModal from "../../components/OutputServiceModal";


export interface DeviceSettingsServiceProps extends RouteComponentProps<{ id: string }> {
    store?: Store,
    routerOutlet: HTMLIonRouterOutletElement,
}
export type DeviceSettingsServiceState = {
    loading?: boolean,
    device?: Device,
    deviceServiceOutput?: DeviceServiceOutput[],
    editOutputService?: DeviceServiceOutput
}


@inject("store", "lang")
export default class DeviceSettingsService extends React.Component<DeviceSettingsServiceProps, DeviceSettingsServiceState> {

    state: DeviceSettingsServiceState = {
    }

    componentDidMount(): void {
        this.loadDevice();
    }

    componentDidUpdate(prevProps: Readonly<DeviceSettingsServiceProps>, prevState: Readonly<DeviceSettingsServiceState>, snapshot?: any): void {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.loadDevice();
        }
    }

    async loadDevice() {
        let id = this.props.match?.params.id!;
        this.setState({ loading: true });
        let device = await this.props.store?.api.deviceGet(Number(id), 'all');
        if (device) {
            this.setState({ device: device.data });
        }
        let serviceOutpus = await this.props.store?.api.deviceGetSettingsServiceOutput(Number(id));
        if (serviceOutpus) {
            this.setState({ deviceServiceOutput: serviceOutpus.data });
        }
        this.setState({ loading: false });
    }

    handleAdd = () => {
        let id = parseInt(this.props.match?.params.id!);
        let outputService: DeviceServiceOutput = {
            id: 0,
            device_id: id,
            input_group: "",
            name: "",
            service_type: '',
            settings: "",
            title: "",

            
        }
        this.setState({editOutputService: {...outputService}});

    }

    handleItemClick = (item:DeviceServiceOutput) => (ev:any) => {
        this.setState({editOutputService: {...item}});
    }

    renderList(list: DeviceServiceOutput[]) {
        return list.map((value, key) => {
            return <IonItem button key={key} onClick={this.handleItemClick(value)}>
                <IonText>{value.title}</IonText>
            </IonItem>
        })
    }

    handleServiceOutputClose = (result: boolean) => {
        if(result) {
            this.handleSaveServiceModal(null);
        } else {
            this.setState({editOutputService: undefined})
        }
    }

    handleSaveServiceModal = (ev: any) => {
        let id = this.props.match?.params.id!;
        this.setState({ loading: true });
        this.props.store?.api.deviceSettingsServiceOutput(Number(id), this.state.editOutputService!).then((res1) => {

            this.loadDevice().then(() => {

                this.setState({ editOutputService: undefined, loading: false });
            })
        })
    }
    
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let id = this.props.match?.params.id!;

        if (!this.state) return "";
        return <IonPage>

            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={"/device/" + id + "/settings"} />
                    </IonButtons>
                    <IonTitle>Integraciones</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    {this.state.deviceServiceOutput && this.renderList(this.state.deviceServiceOutput)}

                </IonList>
                <IonLoading
                    isOpen={this.state.loading!}
                    message={'Cargando...'}
                    duration={5000}
                />

                <IonFab vertical="bottom" horizontal="end" slot="fixed" onClick={this.handleAdd}>
                    <IonFabButton>
                        <IonIcon icon={add} />
                    </IonFabButton>
                </IonFab>
            </IonContent>
            <OutputServiceModal editOutputService={this.state.editOutputService} inputGroups={this.state.device && this.state.device.inputGroups} onClose={this.handleServiceOutputClose}   />
        </IonPage>
    }

}
