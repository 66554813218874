import {MemoryAddress} from "../MemoryMapper";
import {ConfigurationSection, DeviceConfigurationState} from "../../../pages/Configurator";
import DeviceConfiguration from "../DeviceConfiguration";
import RS485V1 from "../RS485V1";
import MemoryMapperV2_7 from "../MemoryMapperV2_7";
import NetConfig from "../NetConfig";
import ModbusRTUClient from "../../rtu/ModbusRTUClient";
import DinTrigger from "../DinTrigger";
import SlaveList from "../SlaveList";

export default class S275 extends MemoryMapperV2_7 {

    constructor() {
        super();
        this.set(MemoryAddress.GPRSINFO_ADDRESS, 40960);
        this.set(MemoryAddress.GPRSIP_ADDRESS, 41024);
        this.set(MemoryAddress.GPRSAPN_ADDRESS, 41152);
        this.set(MemoryAddress.GPRSUSER_ADDRESS, 41216);
        this.set(MemoryAddress.GPRSPWD_ADDRESS, 41280);
        this.set(MemoryAddress.GPRSDATAPACK_ADDRESS, 41472);
        this.set(MemoryAddress.GPRSPACKREG_ADDRESS, 41472);
        this.set(MemoryAddress.GPRSPACKREGRESP_ADDRESS, 41536);
        this.set(MemoryAddress.GPRSPACKDISCONN_ADDRESS, 41600);
        this.set(MemoryAddress.GPRSPACKHEART_ADDRESS, 41664);
        this.set(MemoryAddress.GPRSPACKHEARTRESP_ADDRESS, 41728);
        this.set(MemoryAddress.SERIAL_NUMBER, 5000);
        this.set(MemoryAddress.DIN_SETTINGS, 208);
        this.set(MemoryAddress.DIN_VALUES_SETTINGS, 1792);
        /*
         */
    }

    public async setDefaultConfig(rtu:ModbusRTUClient) {
        let deviceConfig = new DeviceConfiguration(rtu, this);
        deviceConfig.Equipment_DES = "S275";
        deviceConfig.Basic_Settings = 0;
        deviceConfig.Equipment_ID = 1;
        await deviceConfig!.Write();
        let commv1 = new RS485V1(rtu, this);
        commv1!.RS232_setting = 0
        commv1!.RS485_setting = 3
        commv1!.RS485_Baud_Rate = 9600
        commv1!.RS485_Data_bits = 3
        commv1!.RS485_Stop_bits = 0
        commv1!.RS485_Check_bits = 0
        commv1!.RS485_time = 0
        await commv1!.Write();
        let netConfig = new NetConfig(rtu, this);

        netConfig!.GprsApn = "TM";
        netConfig!.GprsApnUser = "";
        netConfig!.GprsPwd = "";
        netConfig!.GprsIp1 = "devices.cloudrtu.com"
        netConfig!.GprsPort1 = 8001;
        netConfig!.GprsIp2 = "devices2.cloudrtu.com"
        netConfig!.GprsPort2 = 8001;
        netConfig!.Protocol = 0;
        netConfig!.CommProtocol = 2;
        await netConfig!.Write();
    }

    private sections:ConfigurationSection[] = [
        {
            title: 'Configuración principal',
            type: 'group',
            rtu: {
                class: DeviceConfiguration,
                stateKey: 'deviceConfig'
            },
            settings: [
                {
                    title: 'Nombre Dispositivo (60 max)',
                    read: (state:DeviceConfigurationState) => state.deviceConfig!.Equipment_DES,
                    write: (state:DeviceConfigurationState, value:any) => state.deviceConfig!.Equipment_DES = value,
                    type: 'text',
                    controlProps: {maxLength: 60},
                },
                {
                    title: 'ID Dispositivo',
                    read: (state:DeviceConfigurationState) => state.deviceConfig!.Equipment_ID,
                    write: (state:DeviceConfigurationState, value:any) => state.deviceConfig!.Equipment_ID = value,
                    type: 'number',
                    controlProps: {maxValue: 276, minValue: 0,}

                }
            ]
        },
        {
            title: 'Configuración de red',
            type: 'group',
            rtu: {
                class: NetConfig,
                stateKey: 'netConfig'
            },
            settings: [
                {
                    type: 'header',
                    title: 'Configuración de red'
                },
                {
                    title: 'Nombre APN',
                    read: (state: DeviceConfigurationState) => state.netConfig!.GprsApn,
                    write: (state: DeviceConfigurationState, value: any) => state.netConfig!.GprsApn = value,
                    type: 'text',
                    controlProps: {maxLength: 60},
                },
                {
                    title: 'Usuario APN',
                    read: (state: DeviceConfigurationState) => state.netConfig!.GprsApnUser,
                    write: (state: DeviceConfigurationState, value: any) => state.netConfig!.GprsApnUser = value,
                    type: 'text',
                    controlProps: {maxLength: 60},
                },
                {
                    title: 'Contraseña APN',
                    read: (state: DeviceConfigurationState) => state.netConfig!.GprsPwd,
                    write: (state: DeviceConfigurationState, value: any) => state.netConfig!.GprsPwd = value,
                    type: 'text',
                    controlProps: {maxLength: 60},
                },
                {
                    type: 'header',
                    title: 'Configuración de servidores'
                },
                {
                    title: 'Protocolo',
                    read: (state: DeviceConfigurationState) => state.netConfig!.CommProtocol,
                    write: (state: DeviceConfigurationState, value: any) => {
                        state.netConfig!.CommProtocol = value;
                    },
                    type: 'select',
                    controlProps: {
                        values: [
                            {
                                value: 0,
                                name: '0 = Disable'
                            },
                            {
                                value: 1,
                                name: '1 = Modbus RTU (8002)'
                            },
                            {
                                value: 2,
                                name: '2 = IoT Modbus (8001)'
                            },
                            {
                                value: 3,
                                name: '3 = Modbus TCP (8003)'
                            },
                            {
                                value: 4,
                                name: '4 = MQTT'
                            }
                        ]
                    }
                },
                {
                    title: 'IP Servidor 1',
                    read: (state: DeviceConfigurationState) => state.netConfig!.GprsIp1,
                    write: (state: DeviceConfigurationState, value: any) => state.netConfig!.GprsIp1 = value,
                    type: 'text',
                    controlProps: {maxLength: 60},
                },
                {
                    title: 'Puerto Servidor 1',
                    read: (state: DeviceConfigurationState) => state.netConfig!.GprsPort1,
                    write: (state: DeviceConfigurationState, value: any) => state.netConfig!.GprsPort1 = value,
                    type: 'number',
                    controlProps: {max: 65535, minValue: 0},
                },
                {
                    title: 'IP Servidor 2',
                    read: (state: DeviceConfigurationState) => state.netConfig!.GprsIp2,
                    write: (state: DeviceConfigurationState, value: any) => state.netConfig!.GprsIp2 = value,
                    type: 'text',
                    controlProps: {maxLength: 60},
                },
                {
                    title: 'Puerto Servidor 2',
                    read: (state: DeviceConfigurationState) => state.netConfig!.GprsPort2,
                    write: (state: DeviceConfigurationState, value: any) => state.netConfig!.GprsPort2 = value,
                    type: 'number',
                    controlProps: {max: 65535, minValue: 0},
                },
                {
                    title: 'Tiempo de reconexion',
                    read: (state: DeviceConfigurationState) => state.netConfig!.ReconnTime,
                    write: (state: DeviceConfigurationState, value: any) => state.netConfig!.ReconnTime = value,
                    type: 'number',
                    controlProps: {max: 65535, minValue: 0},
                },
                {
                    title: 'Intervalo Ping',
                    read: (state: DeviceConfigurationState) => state.netConfig!.HeartTime,
                    write: (state: DeviceConfigurationState, value: any) => state.netConfig!.HeartTime = value,
                    type: 'number',
                    controlProps: {max: 65535, minValue: 0},
                },
                {
                    type: 'header',
                    title: 'Información adicional'
                },
                {
                    title: 'Mensaje de Login',
                    read: (state: DeviceConfigurationState) => state.netConfig!.LoginMessage.data,
                    write: (state: DeviceConfigurationState, value: any) => state.netConfig!.LoginMessage.data = value,
                    type: 'text',
                },
                {
                    title: 'Mensaje de Login ACK',
                    read: (state: DeviceConfigurationState) => state.netConfig!.LoginACKMessage.data,
                    write: (state: DeviceConfigurationState, value: any) => state.netConfig!.LoginACKMessage.data = value,
                    type: 'text',
                },
                {
                    title: 'Mensaje de Desconexion',
                    read: (state: DeviceConfigurationState) => state.netConfig!.LogoutMessage.data,
                    write: (state: DeviceConfigurationState, value: any) => state.netConfig!.LogoutMessage.data = value,
                    type: 'text',
                },
                {
                    title: 'Mensaje de Heartbeat',
                    read: (state: DeviceConfigurationState) => state.netConfig!.HeartbeatMessage.data,
                    write: (state: DeviceConfigurationState, value: any) => state.netConfig!.HeartbeatMessage.data = value,
                    type: 'text',
                },
                {
                    title: 'Mensaje de Heartbeat ACK',
                    read: (state: DeviceConfigurationState) => state.netConfig!.HeartbeatACKMessage.data,
                    write: (state: DeviceConfigurationState, value: any) => state.netConfig!.HeartbeatACKMessage.data = value,
                    type: 'text',
                },
            ]
        },
        {
            title: 'Configuración RS485',
            type: 'group',
            rtu: {
                class: RS485V1,
                stateKey: 'commv1'
            },
            settings: [
                {
                    type: 'header',
                    title: 'Configuración de Comunicación'
                },
                {
                    title: 'RS485_setting',
                    read: (state:DeviceConfigurationState) => state.commv1!.RS485_setting,
                    write: (state:DeviceConfigurationState, value:any) => state.commv1!.RS485_setting = value,
                    type: 'select',
                    controlProps: {
                        values: [
                            {
                                value: 0,
                                name: '0 - Apagado'
                            },
                            {
                                value: 1,
                                name: '1 - Modbus RTU Master'
                            },
                            {
                                value: 2,
                                name: '2 - Modbus RTU Slave'
                            },
                            {
                                value: 3,
                                name: '3 - Transparent Transmission'
                            },
                        ]
                    }
                },
                {
                    title: 'RS485_Baud_Rate',
                    read: (state:DeviceConfigurationState) => state.commv1!.RS485_Baud_Rate,
                    write: (state:DeviceConfigurationState, value:any) => state.commv1!.RS485_Baud_Rate = value,
                    type: 'number',
                },
                {
                    title: 'RS485_Stop_bits',
                    read: (state:DeviceConfigurationState) => state.commv1!.RS485_Stop_bits,
                    write: (state:DeviceConfigurationState, value:any) => state.commv1!.RS485_Stop_bits = value,
                    type: 'select',
                    controlProps: {
                        values: [
                            {
                                value: 0,
                                name: '1'
                            },
                            {
                                value: 1,
                                name: '2'
                            },
                        ]
                    }
                },
                {
                    title: 'RS485_Check_bits',
                    read: (state:DeviceConfigurationState) => state.commv1!.RS485_Check_bits,
                    write: (state:DeviceConfigurationState, value:any) => state.commv1!.RS485_Check_bits = value,
                    type: 'select',
                    controlProps: {
                        values: [
                            {
                                value: 0,
                                name: 'none'
                            },
                            {
                                value: 1,
                                name: 'even'
                            },
                            {
                                value: 2,
                                name: 'odd'
                            },
                        ]
                    }
                },
                {
                    title: 'RS485_Interval_Time',
                    read: (state:DeviceConfigurationState) => state.commv1!.RS485_Interval_Time,
                    write: (state:DeviceConfigurationState, value:any) => state.commv1!.RS485_Interval_Time = value,
                    type: 'number',
                    controlProps: {
                        minValue: 200
                    }
                },
                {
                    title: 'RS485_Over_Time',
                    read: (state:DeviceConfigurationState) => state.commv1!.RS485_Over_Time,
                    write: (state:DeviceConfigurationState, value:any) => state.commv1!.RS485_Over_Time = value,
                    type: 'number',
                    controlProps: {
                        minValue: 200
                    }
                },
                {
                    title: 'RS485_NoData_Time',
                    read: (state:DeviceConfigurationState) => state.commv1!.RS485_NoData_Time,
                    write: (state:DeviceConfigurationState, value:any) => state.commv1!.RS485_NoData_Time = value,
                    type: 'number'
                },
                {
                    title: 'RS485_time',
                    read: (state:DeviceConfigurationState) => state.commv1!.RS485_time,
                    write: (state:DeviceConfigurationState, value:any) => state.commv1!.RS485_time = value,
                    type: 'number'
                },
            ]
        },
        {
            title: 'Digital Input',
            type: 'group',
            rtu: {
                class: DinTrigger,
                stateKey: 'din'
            },
            settings: [
                {
                    type: 'header',
                    title: 'Entrada digital 1'
                },
                {
                    type: 'function',
                    title: 'Entradas digitales',
                    fn: () => {
                        let result : ConfigurationSection[] = [];
                        for (let index = 0; index < 8; index++) {
                            const element = index;
                            let values1:any[] = [
                                {value: 0,name: '0 - Apagado'},
                                {value: 1,name: '1 - NO - Norm. Abierto'},
                                {value: 2,name: '2 - NC - Norm. Cerrado'},
                                {value: 3,name: '3 - Cambio'},
                            ];
                            if(index === 0) {
                                values1.push({value: 4,name: '4 - Contador de pulsos'})
                            }
                            if(index === 1) {
                                values1.push({value: 4,name: '4 - Arm/Disarm'});
                                values1.push({value: 5,name: '5 - Contador de pulsos'});
                            }
                            if(index === 2) {
                                values1.push({value: 5,name: '5 - Contador de pulsos'});
                            }
                            if(index === 3) {
                                values1.push({value: 5,name: '5 - Contador de pulsos'});
                            }
                            result.push({
                                title: 'Tipo (DIN' + (index + 1) + ')',
                                type: 'select',
                                controlProps: {
                                    values: values1
                                },
                                read: (state:DeviceConfigurationState) => state.din!.din![element].Status,
                                write: (state:DeviceConfigurationState, value:any) => state.din!.din![element].Status = value,
                            });
                            //       this.StartValue0.Text = ((int) this._objMCU.DINTRIGGER.DIN_Counter1[1] << 16 | (int) this._objMCU.DINTRIGGER.DIN_Counter1[2]).ToString();
                            if(index === 0) {
                                result.push({
                                    title: 'Valor inicial',
                                    type: 'number',
                                    read: (state:DeviceConfigurationState) => (state.din!.din![1].Counter << 16) | state.din!.din![2].Counter,
                                    write: (state:DeviceConfigurationState, value:any) => {
                                        state.din!.din![1].Counter = (value >> 16) & 0xFFFF
                                        state.din!.din![2].Counter = (value) & 0xFFFF
                                    },
                                });
                                result.push({
                                    title: 'Intervalo alarma',
                                    type: 'number',
                                    read: (state:DeviceConfigurationState) => (state.din!.din![3].Counter << 16) | state.din!.din![4].Counter,
                                    write: (state:DeviceConfigurationState, value:any) => {
                                        state.din!.din![3].Counter = (value >> 16) & 0xFFFF
                                        state.din!.din![4].Counter = (value) & 0xFFFF
                                    },
                                });
                                result.push({
                                    title: 'Total alarma',
                                    type: 'number',
                                    read: (state:DeviceConfigurationState) => (state.din!.din![5].Counter << 16) | state.din!.din![6].Counter,
                                    write: (state:DeviceConfigurationState, value:any) => {
                                        state.din!.din![5].Counter = (value >> 16) & 0xFFFF
                                        state.din!.din![6].Counter = (value) & 0xFFFF
                                    },
                                });
                            }
                            if(index === 1 || index === 2 || index === 3) {
                                result.push({
                                    showIf: (state:DeviceConfigurationState) => state.din?.din![element].Status === 5,
                                    title: 'Valor inicial',
                                    type: 'number',
                                    read: (state:DeviceConfigurationState) => (state.din!.values2![index-1][0] << 16) | state.din!.values2![index-1][1],
                                    write: (state:DeviceConfigurationState, value:any) => {
                                        state.din!.values2![index-1][0] = (value >> 16) & 0xFFFF
                                        state.din!.values2![index-1][1] = (value) & 0xFFFF
                                    },
                                });
                                
                                result.push({
                                    showIf: (state:DeviceConfigurationState) => state.din?.din![element].Status === 5,
                                    title: 'Intervalo alarma',
                                    type: 'number',
                                    read: (state:DeviceConfigurationState) => (state.din!.values2![index-1][2] << 16) | state.din!.values2![index-1][3],
                                    write: (state:DeviceConfigurationState, value:any) => {
                                        state.din!.values2![index-1][2] = (value >> 16) & 0xFFFF
                                        state.din!.values2![index-1][3] = (value) & 0xFFFF
                                    },
                                });
                                
                                result.push({
                                    showIf: (state:DeviceConfigurationState) => state.din?.din![element].Status === 5,
                                    title: 'Total alarma',
                                    type: 'number',
                                    read: (state:DeviceConfigurationState) => (state.din!.values2![index-1][4] << 16) | state.din!.values2![index-1][5],
                                    write: (state:DeviceConfigurationState, value:any) => {
                                        state.din!.values2![index-1][4] = (value >> 16) & 0xFFFF
                                        state.din!.values2![index-1][5] = (value) & 0xFFFF
                                    },
                                });
                                
                            }
                            
                        }

                        // result.push({
                        //     title: 'DIN '
                        // })

                        return result;
                    }
                },
            ]
        },
        {
            title: 'RS485 Esclavo',
            type: 'group',
            rtu: {
                class: SlaveList,
                stateKey: 'slave'
            },
            settings: [
                {
                    type: 'header',
                    title: 'Esclavos'
                },
                {
                    type: 'function',
                    title: 'Lista de esclavos',
                    fn: () => {
                        let result : ConfigurationSection[] = [];
                        for (let index = 0; index < 16; index++) {
                            const element = index;
                            let values1:any[] = [
                                {value: 3,name: '03'},
                                {value: 4,name: '04'},
                                {value: 16,name: '16'},
                            ];

                            let values3:any[] = [
                                {value: 1,name: '01'},
                                {value: 2,name: '02'},
                                {value: 15,name: '15'},
                            ];
                            
                            let values2:any[] = [
                                {value: 0,name: 'BOOL'},
                                {value: 1,name: 'WORD'},
                                {value: 2,name: 'INT'},
                                {value: 3,name: 'DOUBLE'},
                            ];
                            
                            result.push(
                                {
                                    type: 'header',
                                    title: 'Esclavo #' + (element + 1) + ''
                                });
                            result.push({
                                title: 'Dirección (1-254) (255 = OFF)',
                                type: 'number',
                                read: (state:DeviceConfigurationState) => state.slave?.slaves[element].address,
                                write: (state:DeviceConfigurationState, value:any) => state.slave!.slaves![element].address = parseInt(value),
                            });
                            
                            
                            result.push({
                                title: 'Tipo dato',
                                type: 'select',
                                controlProps: {
                                    values: values2
                                },
                                showIf: (state:DeviceConfigurationState) => state.slave?.slaves[element].address !== 255,
                                read: (state:DeviceConfigurationState) => state.slave?.slaves[element].dataType,
                                write: (state:DeviceConfigurationState, value:any) => state.slave!.slaves![element].dataType = parseInt(value),
                            });
                            result.push({
                                title: 'Funcion BOOL',
                                type: 'select',
                                showIf: (state:DeviceConfigurationState) => state.slave?.slaves[element].address !== 255 && state.slave?.slaves[element].dataType === 0,
                                controlProps: {
                                    values: values3
                                },
                                read: (state:DeviceConfigurationState) => state.slave?.slaves[element].fn,
                                write: (state:DeviceConfigurationState, value:any) => state.slave!.slaves![element].fn = parseInt(value),
                            });
                            result.push({
                                title: 'Funcion',
                                type: 'select',
                                showIf: (state:DeviceConfigurationState) => state.slave?.slaves[element].address !== 255 && state.slave?.slaves[element].dataType !== 0,
                                controlProps: {
                                    values: values1
                                },
                                read: (state:DeviceConfigurationState) => state.slave?.slaves[element].fn,
                                write: (state:DeviceConfigurationState, value:any) => state.slave!.slaves![element].fn = parseInt(value),
                            });
                            result.push({
                                title: 'startAddr',
                                type: 'number',
                                showIf: (state:DeviceConfigurationState) => state.slave?.slaves[element].address !== 255,
                                read: (state:DeviceConfigurationState) => state.slave?.slaves[element].startAddr,
                                write: (state:DeviceConfigurationState, value:any) => state.slave!.slaves![element].startAddr = parseInt(value),
                            });
                            
                            // result.push({
                            //     title: 'regSeq',
                            //     type: 'number',
                            //     read: (state:DeviceConfigurationState) => state.slave?.slaves[element].regSeq,
                            //     write: (state:DeviceConfigurationState, value:any) => state.slave!.slaves![element].regSeq = value,
                            // });
                            result.push({
                                title: 'len',
                                type: 'number',
                                showIf: (state:DeviceConfigurationState) => state.slave?.slaves[element].address !== 255,
                                read: (state:DeviceConfigurationState) => state.slave?.slaves[element].len,
                                write: (state:DeviceConfigurationState, value:any) => state.slave!.slaves![element].len = parseInt(value),
                            });
                            // result.push({
                            //     title: 'devId',
                            //     type: 'number',
                            //     read: (state:DeviceConfigurationState) => state.slave?.slaves[element].devId,
                            //     write: (state:DeviceConfigurationState, value:any) => state.slave!.slaves![element].devId = value,
                            // });
                        }

                        // result.push({
                        //     title: 'DIN '
                        // })

                        return result;
                    }
                },
            ]
        }
    ];
    getSections() : ConfigurationSection[] {
        return this.sections;
    }

}
