import {MemoryAddress} from "../MemoryMapper";
import MemoryMapperV2_1 from "../MemoryMapperV2_1";
import {ConfigurationSection, DeviceConfigurationState} from "../../../pages/Configurator";
import DeviceConfiguration from "../DeviceConfiguration";
import NetConfig from "../NetConfig";
import ModbusRTUClient from "../../rtu/ModbusRTUClient";
import PeriodicTimer from "../PeriodicTimer";

export default class S271 extends MemoryMapperV2_1 {

    constructor() {
        super();
        this.set(MemoryAddress.GPRSDATAPACK_ADDRESS, 253952);
        this.set(MemoryAddress.GPRSPACKREG_ADDRESS, 253952);
        this.set(MemoryAddress.GPRSPACKREGRESP_ADDRESS, 254016);
        this.set(MemoryAddress.GPRSPACKDISCONN_ADDRESS, 254080);
        this.set(MemoryAddress.GPRSPACKHEART_ADDRESS, 254144);
        this.set(MemoryAddress.GPRSPACKHEARTRESP_ADDRESS, 254208);
        this.set(MemoryAddress.SERIAL_NUMBER, 5000);
    }

    async setDefaultConfig(rtu: ModbusRTUClient): Promise<any> {
        let deviceConfig = new DeviceConfiguration(rtu, this);
        deviceConfig.Equipment_DES = "S271";
        deviceConfig.Basic_Settings = 0;
        deviceConfig.Equipment_ID = 1;
        await deviceConfig!.Write();
        let netConfig = new NetConfig(rtu, this);

        netConfig!.GprsApn = "TM";
        netConfig!.GprsApnUser = "";
        netConfig!.GprsPwd = "";
        netConfig!.GprsIp1 = "devices.cloudrtu.com"
        netConfig!.GprsPort1 = 8001;
        netConfig!.GprsIp2 = "devices2.cloudrtu.com"
        netConfig!.GprsPort2 = 8001;
        netConfig!.Protocol = 0;
        netConfig!.CommProtocol = 2;

        await netConfig!.Write();

        let timer = new PeriodicTimer(rtu, this);
        timer.timers = [
            {
                action: 0,
                interval: 60 * 15,
                hourMinute: 0,
                weekDay: 0,
                enable: true,
            }
        ]
        await timer!.Write();
    }

    private sections: ConfigurationSection[] = [
        {
            title: 'Configuración principal',
            type: 'group',
            rtu: {
                class: DeviceConfiguration,
                stateKey: 'deviceConfig'
            },
            settings: [
                {
                    title: 'Nombre Dispositivo (60 max)',
                    read: (state: DeviceConfigurationState) => state.deviceConfig!.Equipment_DES,
                    write: (state: DeviceConfigurationState, value: any) => state.deviceConfig!.Equipment_DES = value,
                    type: 'text',
                    controlProps: {maxLength: 60},
                },
                {
                    title: 'ID Dispositivo',
                    read: (state: DeviceConfigurationState) => state.deviceConfig!.Equipment_ID,
                    write: (state: DeviceConfigurationState, value: any) => state.deviceConfig!.Equipment_ID = value,
                    type: 'number',
                    controlProps: {maxValue: 276, minValue: 0,}

                }
            ]
        }
        ,

        {
            title: 'Configuración de red',
            type: 'group',
            rtu: {
                class: NetConfig,
                stateKey: 'netConfig'
            },
            showIf: (state1 => state1.deviceConfig?.version_model !== '3EV22'),
            settings: [
                {
                    type: 'header',
                    title: 'Configuración de red'
                },
                {
                    title: 'Nombre APN',
                    read: (state: DeviceConfigurationState) => state.netConfig!.GprsApn,
                    write: (state: DeviceConfigurationState, value: any) => state.netConfig!.GprsApn = value,
                    type: 'text',
                    controlProps: {maxLength: 60},
                },
                {
                    title: 'Usuario APN',
                    read: (state: DeviceConfigurationState) => state.netConfig!.GprsApnUser,
                    write: (state: DeviceConfigurationState, value: any) => state.netConfig!.GprsApnUser = value,
                    type: 'text',
                    controlProps: {maxLength: 60},
                },
                {
                    title: 'Contraseña APN',
                    read: (state: DeviceConfigurationState) => state.netConfig!.GprsPwd,
                    write: (state: DeviceConfigurationState, value: any) => state.netConfig!.GprsPwd = value,
                    type: 'text',
                    controlProps: {maxLength: 60},
                },
                {
                    type: 'header',
                    title: 'Configuración de servidores'
                },
                {
                    title: 'Protocolo',
                    read: (state: DeviceConfigurationState) => state.netConfig!.CommProtocol,
                    write: (state: DeviceConfigurationState, value: any) => state.netConfig!.CommProtocol = value,
                    type: 'select',
                    controlProps: {
                        values: [
                            {
                                value: 0,
                                name: '0 = Disable'
                            },
                            {
                                value: 1,
                                name: '1 = Modbus RTU'
                            },
                            {
                                value: 2,
                                name: '2 = IoT Modbus'
                            },
                            {
                                value: 3,
                                name: '3 = Modbus TCP'
                            }
                        ]
                    }
                },
                {
                    title: 'IP Servidor 1',
                    read: (state: DeviceConfigurationState) => state.netConfig!.GprsIp1,
                    write: (state: DeviceConfigurationState, value: any) => state.netConfig!.GprsIp1 = value,
                    type: 'text',
                    controlProps: {maxLength: 60},
                },
                {
                    title: 'Puerto Servidor 1',
                    read: (state: DeviceConfigurationState) => state.netConfig!.GprsPort1,
                    write: (state: DeviceConfigurationState, value: any) => state.netConfig!.GprsPort1 = value,
                    type: 'number',
                    controlProps: {max: 65535, minValue: 0},
                },
                {
                    title: 'IP Servidor 2',
                    read: (state: DeviceConfigurationState) => state.netConfig!.GprsIp2,
                    write: (state: DeviceConfigurationState, value: any) => state.netConfig!.GprsIp2 = value,
                    type: 'text',
                    controlProps: {maxLength: 60},
                },
                {
                    title: 'Puerto Servidor 2',
                    read: (state: DeviceConfigurationState) => state.netConfig!.GprsPort2,
                    write: (state: DeviceConfigurationState, value: any) => state.netConfig!.GprsPort2 = value,
                    type: 'number',
                    controlProps: {max: 65535, minValue: 0},
                },
                {
                    type: 'header',
                    title: 'Información adicional (Solo lectura)'
                },
                {
                    title: 'Mensaje de Login',
                    read: (state: DeviceConfigurationState) => state.netConfig!.LoginMessage.data,
                    write: (state: DeviceConfigurationState, value: any) => state.netConfig!.LoginMessage.data = value,
                    type: 'text',
                    controlProps: {readOnly: true},

                },
            ]
        },

        {
            title: 'Subida de datos',
            type: 'group',
            rtu: {
                class: PeriodicTimer,
                stateKey: 'periodicTimer'
            },
            settings: [
                {
                    title: 'Activar',
                    read: (state: DeviceConfigurationState) => state.periodicTimer!.timers[0].enable,
                    write: (state: DeviceConfigurationState, value: any) => state.periodicTimer!.timers[0].enable = value ,
                    type: 'check',
                },
                {
                    title: 'Intervalo de datos',
                    read: (state: DeviceConfigurationState) => state.periodicTimer!.timers[0].interval,
                    write: (state: DeviceConfigurationState, value: any) => state.periodicTimer!.timers[0].interval = value,
                    type: 'number',
                    controlProps: { minValue: 60,}

                }
            ]
        }
        ,
    ];

    getSections(): ConfigurationSection[] {
        return this.sections;
    }

}
