import React from "react";
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCheckbox,
    IonContent,
    IonHeader, IonIcon, IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonModal,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonSpinner,
    IonText,
    IonTextarea,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import { inject } from "mobx-react";
import { Store } from "../../service/Store";
import { Contract, Device, DeviceSuscription, PaymentMethod } from "../../service/API";
import moment from "moment";
import Payments from "../../components/Payments";
import { RouteComponentProps } from "react-router-dom";
import PaymentDetails from "../../components/PaymentDetails";
import Utils from "../../service/Utils";
import { documentOutline } from "ionicons/icons";
import JsZip from 'jszip';
import FileSaver from 'file-saver';

export interface DeviceSettingsSuscriptionProps extends RouteComponentProps<{ id: string }> {
    store?: Store,
    routerOutlet: HTMLIonRouterOutletElement,
}
export type DeviceSettingsSuscriptionState = {
    loading?: boolean,
    edit?: boolean,
    remove?: boolean,
    payments?: PaymentMethod[],
    currentSuscription?: DeviceSuscription,
    newSuscription?: DeviceSuscription,
    suscriptions?: DeviceSuscription[],
    contract?: Contract,

    promotion?: string,
    coupon?: any,
    invoices?: boolean,
    device?: Device,
    invoiceList?: { id: string, number: string, invoice_pdf: string, selected: boolean, created: string }[],
}

@inject("store", "lang")
export default class DeviceSettingsSuscription extends React.Component<DeviceSettingsSuscriptionProps, DeviceSettingsSuscriptionState> {

    private reason: React.RefObject<any>;

    state: DeviceSettingsSuscriptionState = {
        promotion: "",
    }

    constructor(props: DeviceSettingsSuscriptionProps, context: any) {
        super(props, context);
        this.reason = React.createRef();

    }

    componentDidMount(): void {
        this.load();
    }
    componentDidUpdate(prevProps: Readonly<DeviceSettingsSuscriptionProps>, prevState: Readonly<DeviceSettingsSuscriptionState>, snapshot?: any): void {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.load();
        }
    }

    componentWillUnmount() {
        this.setState({ remove: false });
    }

    load() {
        this.setState({ loading: true });
        let id = this.props.match?.params.id!;
        this.props.store?.api.deviceGet(parseInt(id)).then(res => {
            if (res.data) {

                this.setState({ device: res.data });
            }
        })
        this.props.store?.api.getDevicePaymentMethods(parseInt(id)).then(res => {
            if (res.data) {

                this.setState({ payments: res.data });
            }
        })
        return this.props.store?.api.deviceSuscription(id).then(value => {
            if (value.success) {
                this.setState({
                    currentSuscription: value.data.suscription,
                    newSuscription: value.data.suscription || { payment_method: 'stripe' },
                    suscriptions: value.data.suscriptions,
                    contract: value.data.contract,

                })
            }
        }).finally(() => this.setState({ loading: false }));
    }


    handleSave = (ev: any) => {
        this.setState({ loading: true });
        let id = this.props.match?.params.id!;
        let product_id = String(this.state.newSuscription?.product_id!);
        let payment_method = this.state.newSuscription?.payment_method;
        let payment = this.state.newSuscription?.stripe_payment;
        this.props.store?.api.setDeviceSuscription(id, product_id, payment_method!, payment!, this.state.coupon ? this.state.coupon.id : undefined).then((result) => {
            if (result.success) {
                this.load()?.then(() => this.setState({ loading: false, edit: false }));
            } else {
                this.setState({ loading: false });
            }

        })
    }

    handleRemove = (ev: any) => {
        this.setState({ loading: true });
        let id = this.props.match?.params.id!;
        this.props.store?.api.deleteDeviceSuscription(id, this.reason.current.value).then(value => {
            this.setState({ loading: false })
            if (value.success) {
                this.setState({ remove: false }, () => {
                    this.props.history.replace({ pathname: "/dashboard" });
                });
            }
        })
    }

    handleValidateDiscount = (ev: any) => {
        if (this.state.promotion) {
            this.setState({ loading: true });
            this.props.store?.api.getPromotionCode(this.state.promotion).then(value => {
                this.setState({ loading: false });
                if (value.success && value.data.length > 0) {
                    this.setState({ coupon: value.data[0].coupon });
                } else {
                    this.setState({ coupon: undefined });
                }
            });
        }

    }

    handleInvoices = (ev: any) => {
        this.setState({ loading: true, invoices: true });
        this.props.store?.api.deviceSubscriptionInvoices(this.props.match.params.id).then(value => {
            this.setState({ loading: false });
            if (value.success) {
                this.setState({ invoiceList: value.data })
            }
        });
    }

    handleDownload = (ev: any) => {
        this.setState({ loading: true });
        const download = (url: string): Promise<Blob> => {
            return new Promise((resolve, reject) => {
                this.props.store!.api!.deviceSubscriptionInvoicePDF(url).then(resp => {
                    if (resp.status === 201 || resp.status === 200) {
                        resp.blob().then(resolve);
                    } else {
                        resp.json().then(reject);
                    }
                })
            });
        };
        let urls = this.state.invoiceList!.filter(a => a.selected).map(a => a.id);
        Promise.all(urls.map(url => download(url))).then(blobs => {
            const zip = JsZip();
            blobs.forEach((blob, i) => {
                if (blob !== undefined) {
                    zip.file(`factura-${i}.pdf`, blob);
                }
            });
            zip.generateAsync({ type: 'blob' }).then(zipFile => {
                this.setState({ loading: false });
                const currentDate = new Date().getTime();
                const fileName = `facturas-${currentDate}.zip`;
                return FileSaver.saveAs(zipFile, fileName);
            });
        }).catch(err => {
            alert("Error: " + JSON.stringify(err));
            this.setState({ loading: false });
        });
    }


    renderCoupon(coupon: any) {

        return <IonItem>
            <IonLabel>{coupon.name || ""}</IonLabel>
        </IonItem>
    }

    renderStripe(suscription: DeviceSuscription) {
        let p;
        if (suscription.stripe_payment) {
            if (this.state.payments) {
                p = this.state.payments.find(a => a.id === suscription.stripe_payment);
                return <PaymentDetails payment={p!} />
            }
        }
        return "Pendiente de configurar"


    }
    renderSepa(suscription: DeviceSuscription) {
        return "Orden Domiciliación SEPA"
    }
    renderTransferencia(suscription: DeviceSuscription) {
        return "Transferencia"
    }
    renderCurrentSuscription(suscription: DeviceSuscription) {
        let trial_remainig = suscription.trial_duration * 60 * 60 * 24 - (new Date().getTime() / 1000 - moment(suscription.date_start).unix());
        return <>


            <IonItem>
                <IonLabel>Producto</IonLabel>
                <IonText>{suscription.product.title}</IonText>
            </IonItem>
            <IonItem>
                <IonLabel>Fecha Inicio Suscripción</IonLabel>
                <IonText>{moment(suscription.date_start).format("DD/MM/YYYY")}</IonText>
            </IonItem>
            <IonItem hidden={!suscription.date_end}>
                <IonLabel>Fecha Fin Suscripción</IonLabel>
                <IonText>{moment(suscription.date_end).format("DD/MM/YYYY")}</IonText>
            </IonItem>
            <IonItem hidden={!suscription.date_end}>
                <IonLabel>Motivo Cancelación</IonLabel>
                <IonText>{suscription.cancellation_reason}</IonText>
            </IonItem>
            <IonItem hidden={!suscription.trial_duration || trial_remainig <= 0}>
                <IonLabel>Días de prueba restantes</IonLabel>
                <IonText>{Utils.toTimeDuration(trial_remainig, true, false, false, false)}</IonText>
            </IonItem>
            <IonItem>
                <IonLabel>Duración del ciclo</IonLabel>
                <IonText>{suscription.product.duration <= 30 ? "1 mes" : "1 año"}</IonText>
            </IonItem>

            <IonItem>
                <IonLabel>Método de Pago</IonLabel>
                <IonText>
                    {suscription.payment_method === 'stripe' && this.renderStripe(suscription)}
                    {suscription.payment_method === 'sepa' && this.renderSepa(suscription)}
                    {suscription.payment_method === 'transferencia' && this.renderTransferencia(suscription)}
                </IonText>
            </IonItem>
            {/* pm_1LKRVqAMxQ0VA19sHfxHAPzr */}
        </>

    }

    renderPaymentsMethods(contract: Contract) {
        let items = Utils.getPaymentMethods(contract.payments);
        return <IonSelect label="Forma de pago" value={this.state.newSuscription?.payment_method} onIonChange={(ev: any) => this.setState({ newSuscription: { ...this.state.newSuscription!, payment_method: ev.detail.value } })} okText="Aceptar" cancelText="Cancelar">
            {items.map((v, k) => <IonSelectOption key={k} value={v.value}>{v.title}</IonSelectOption>)}
        </IonSelect>

    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let id = this.props.match?.params.id!;

        if (!this.state) return "";

        return <IonPage>

            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={"/device/" + id + "/settings"} />
                    </IonButtons>
                    <IonTitle>Suscripción</IonTitle>

                    <IonButtons slot="primary">
                        <IonButton color="secondary" onClick={this.handleInvoices}>
                            <IonIcon slot="icon-only" icon={documentOutline} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    <IonItem>
                        <IonLabel>Propietario</IonLabel>
                        <IonText>{this.state.device ? this.state.device.user.fullname : "?"}</IonText>
                    </IonItem>

                    {!!this.state.currentSuscription && this.renderCurrentSuscription(this.state.currentSuscription)}

                    <IonItem button color={"primary"} onClick={() => this.setState({ edit: true })}>
                        <IonLabel>Modificar suscripción</IonLabel>
                    </IonItem>
                </IonList>
                <IonLoading
                    isOpen={this.state.loading!}
                    message={'Cargando...'}
                    duration={5000}
                />
            </IonContent>
            <IonModal isOpen={this.state.edit! ? true : false} onDidDismiss={() => this.setState({ edit: false })}>

                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Modificar Subscripción</IonTitle>
                        <IonButtons slot={"secondary"}>

                            <IonButton onClick={() => this.setState({ edit: false })}>Cancelar</IonButton>
                        </IonButtons>
                        <IonButtons slot={"primary"}>
                            <IonButton disabled={this.state.loading} onClick={this.handleSave}> <IonSpinner
                                hidden={!this.state.loading} /> Aceptar</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonItem>
                        {this.state.contract && this.renderPaymentsMethods(this.state.contract!)}
                    </IonItem>
                    <IonItem>
                        <IonSelect label="Suscripción" value={this.state.newSuscription?.product_id} onIonChange={(ev: any) => this.setState({ newSuscription: { ...this.state.newSuscription!, product_id: ev.detail.value } })} okText="Aceptar" cancelText="Cancelar">
                            {this.state.contract?.subscriptions.map((value, index) => {
                                return <IonSelectOption key={index} value={value.id}>{value.title}</IonSelectOption>
                            })}
                        </IonSelect>
                    </IonItem>
                    {this.state.newSuscription?.payment_method === 'stripe' &&
                        <>
                            <IonItem>
                                <IonInput label="Cupón" onIonInput={(e) => this.setState({ promotion: String(e.detail.value || "") })} value={this.state.promotion}></IonInput>
                                <IonButton onClick={this.handleValidateDiscount} fill={"clear"} slot={"end"}>Comprobar</IonButton>
                            </IonItem>

                            {!!this.state.coupon && this.renderCoupon(this.state.coupon)}

                            <Payments routerOutlet={this.props.routerOutlet} device_id={id} payment={this.state.newSuscription?.stripe_payment} onAuthorize={(payment: string) => { this.setState({ newSuscription: { ...this.state.newSuscription!, stripe_payment: payment } }) }} paylater={false} />
                        </>}




                    <IonItem hidden={!this.state.currentSuscription || this.state.currentSuscription?.date_end != null} color={"danger"} detail={false} button onClick={() => this.setState({ remove: true })}>
                        <IonLabel>Cancelar subscripción</IonLabel>
                    </IonItem>
                </IonContent>

            </IonModal>
            <IonModal isOpen={this.state.remove! ? true : false} onDidDismiss={() => this.setState({ remove: false })}>

                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Cancelar subscripción</IonTitle>
                        <IonButtons slot={"secondary"}>

                            <IonButton onClick={() => this.setState({ remove: false })}>Cancelar</IonButton>
                        </IonButtons>
                        <IonButtons slot={"primary"}>
                            <IonButton disabled={this.state.loading} onClick={this.handleRemove}> <IonSpinner
                                hidden={!this.state.loading} /> Confirmar</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonItem lines="none" >
                        <IonText>Sentimos que desees cancelar su subscripción a Cloud RTU.</IonText>
                    </IonItem>
                    <IonItem lines="none">
                        <IonText>Si continuas dejarás de tener acceso a este dispositivo a partir de la fecha de finalización.</IonText>
                    </IonItem>
                    <IonItem>
                        <IonLabel position={"stacked"}>Motivo cancelación</IonLabel>
                        <IonTextarea ref={this.reason} />
                    </IonItem>
                </IonContent>
            </IonModal>
            <IonModal isOpen={this.state.invoices! ? true : false} onDidDismiss={() => this.setState({ invoices: false })}>

                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Facturas</IonTitle>
                        <IonButtons slot={"secondary"}>

                            <IonButton onClick={() => this.setState({ invoices: false })}>Cancelar</IonButton>
                        </IonButtons>
                        <IonButtons slot={"primary"}>
                            <IonButton disabled={this.state.loading} onClick={this.handleDownload}> <IonSpinner
                                hidden={!this.state.loading} /> Descargar</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    {this.state.invoiceList && this.state.invoiceList.map((item, key) => {
                        return <IonItem>
                            <IonCheckbox checked={item.selected} onIonChange={e => item.selected = (e.detail.checked)}>
                                {moment(item.created, "X").format("YYYY-MM-DD")}
                                -
                                {item.number}
                            </IonCheckbox>
                        </IonItem>
                    })}
                </IonContent>
            </IonModal>
        </IonPage>
    }

}
