import React from "react";
import {IonIcon, IonLabel, IonText} from "@ionic/react";
import {PaymentMethod} from "../service/API";
import {cardOutline, cashOutline} from "ionicons/icons";


export type PaymentDetailsProps = {
    payment: PaymentMethod,
    extended?: boolean,
    selected?: boolean,
}

export default class PaymentDetails extends React.Component<PaymentDetailsProps, any> {

    renderCardType(brand: string) {
        return <IonText style={{textTransform: 'capitalize'}}>{brand}</IonText>

    }

    renderSimple() {
        if (this.props.payment.card) {
            return <IonText><IonIcon
                icon={cardOutline}/> {this.renderCardType(this.props.payment.card.brand)} &nbsp; {this.props.payment.name} ... {this.props.payment.card.last4}
            </IonText>

        }
        return <IonText><IonIcon icon={cashOutline}/> SEPA  &nbsp; {this.props.payment.name}</IonText>
    }

    renderExtended() {
        if (this.props.payment.card) {
            return <>
                <IonLabel><IonIcon icon={cardOutline}/> {this.renderCardType(this.props.payment.card.brand)}</IonLabel>
                <IonLabel>{this.props.payment.name} &nbsp;
                    ... {this.props.payment.card && this.props.payment.card.last4} {this.props.payment.sepa_debit && this.props.payment.sepa_debit.last4}
                </IonLabel>
            </>
        }
        if (this.props.payment.sepa_debit) {
            return <>
                <IonLabel><IonIcon icon={cashOutline}/> SEPA</IonLabel>
                <IonLabel>{this.props.payment.name} &nbsp; ... {this.props.payment.sepa_debit.last4}
                </IonLabel>
            </>
        }
    }

    render(): React.ReactNode {
        if (!this.props.payment) return "";
        return <div className={this.props.selected?"payment-details-selected": ""}>
            {this.props.extended && this.renderExtended()}
            {this.props.extended || this.renderSimple()}
        </div>;
    }
}
