

// import { Watch } from '../plugins/watch';
import { Store } from './Store';

export default class WatchExtension {
    store: Store;
    constructor(store: Store) {
        this.store = store;
    }
    init() {
        // Watch.addListener('watchMessage', (info: any) => {
        //     console.log("watchMessage");
        //     console.log(info);
        //     if(info.identifier === "token") {
        //         this.setToken(this.store.login.token());
        //     }
        // });
    }

    setToken(token: string) {
        // Watch.passMessage({
        //     message: token, channel: "token"
        // });

    }

    sendDevices() {
        // if(this.store.login.isLogin()) {
        //     this.store.api!.getDevices().then(res => {
        //         if(res.success) {
        //             Watch.passMessage({message: JSON.stringify(res.data), channel: 'devices'});
        //         } else {
        //             Watch.passMessage({message: "Error retrieving devices", channel: 'error'});
        //         }
        //     })
        // } else {

        //     Watch.passMessage({message: "Please login first", channel: 'error'});
        // }
    }
}