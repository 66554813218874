import React, {RefObject} from "react";
import {
    IonAvatar,
    IonBadge, IonButton,
    IonButtons,
    IonContent,
    IonFab,
    IonFabButton,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenuButton,
    IonPage,
    IonSearchbar,
    IonSkeletonText,
    IonTitle,
    IonToolbar
} from "@ionic/react";

import {add, bugOutline, cloudOffline, hardwareChipOutline} from 'ionicons/icons';
import {ClientDevices, SimpleDevice} from "../service/API";
import {inject} from "mobx-react";
import { Store } from "../service/Store";
import EventBox from "../service/EventBox";
import {RouteComponentProps} from "react-router-dom";
import Lang from "../service/Lang";

export interface DevicesProps extends RouteComponentProps<{ token?: string }> {
    store?: Store,
    lang?: Lang,
    routerOutlet:HTMLIonRouterOutletElement,
}
export interface DevicesState {
    // devices: SimpleDevice[],
    clients: ClientDevices[],
    loading: boolean,
    error: boolean,
    addDevice: boolean,
    token?:string,
    search?:string,
}

@inject("store", "lang")
class Devices extends React.Component<DevicesProps, DevicesState> {
    events?:EventBox;
    search: RefObject<any>;


    constructor(props: DevicesProps, context: any) {
        super(props, context);
        this.search = React.createRef();

    }

    componentDidMount(): void {
        this.reload();
        this.events = new EventBox(this.props.store!.events);
        this.events.on('online', (device_id:number) => {
            if(this.state.clients) {
                this.state.clients.forEach(item => {
                    item.devices.map((value, index) => {
                        if(value.id === device_id) {
                            value.online = true;
    
                            this.setState({});
                        }
                        return value;
                    })
                });
            }

        });
        this.events.on('offline', (device_id:number, reason:string) => {
            if(this.state.clients) {
                this.state.clients.forEach(item => {
                    item.devices.map((value, index) => {
                        if(value.id === device_id) {
                            value.online = false;
                            this.setState({});
                        }
                        return value;
                    })
                });
            }

        });

        if(this.props.match.params.token) {
            
            this.props.history.replace( {pathname: "/add_device/" + this.props.match.params.token});
            if(this.props.match.params.token !== "add") {
                this.setState({token: this.props.match.params.token});
            }
        }
    }

    componentDidUpdate(prevProps: Readonly<DevicesProps>, prevState: Readonly<DevicesState>, snapshot?: any) {
        if(prevProps.match.params.token !== this.props.match.params.token) {

            if(this.props.match.params.token) {
                this.setState({addDevice: true});
                if(this.props.match.params.token !== "add") {
                    this.setState({token: this.props.match.params.token});
                }
            }
        }
        if(this.props.location.search === '?reload=true') {
            this.reload();
            this.props.history.replace("/devices");
        }
        console.log(this.props.location);
    }

    componentWillUnmount(): void {
        this.events?.off();
    }

    reload() {
        this.setState({loading: true});

        this.props.store!.api.getDevicesClients().then((value => {
            this.setState({loading: false});
            if (value.success) {

                this.setState({error: false, clients: value.data.clients});

            } else {
                this.setState({error: true});
            }
        }));
    }

    handleClick = (id: number) => (ev: any) => {

    }

    handleAddDevice = (ev: any) => {
        this.props.history.push( {pathname: "/add_device/add"});
    }
    renderClient = (item:ClientDevices) => {
        return <>
        <IonListHeader>

            <IonLabel>{item.user.fullname}</IonLabel>
        </IonListHeader>

        {item.devices.filter(this.filter).map((d) => this.renderItem(d))}
        </>
    }

    renderItem(item: SimpleDevice) {
        const {lang} = this.props;
        return <IonItem detail routerLink={"/device/" + item.id + "/details"}>
            <IonAvatar slot={"start"}>
                <img src={item.image || item.model.image} alt={"shapes"}/>
            </IonAvatar>
            <IonLabel>
                <h2>{item.name}</h2>
                <h3><IonIcon icon={item.simulator?bugOutline:hardwareChipOutline} /> {item.model.name}</h3>
                <IonBadge color={item.online?"primary":"danger"}>{item.online ? lang?.l.main.online() : lang?.l.main.offline()}</IonBadge>
            </IonLabel>
        </IonItem>
    }

    renderSkeleton() {
        return <IonItem>
            <IonAvatar slot="start">
                <IonSkeletonText animated/>
            </IonAvatar>
            <IonLabel>
                <h2>
                    <IonSkeletonText animated style={{width: '50%'}}/>
                </h2>
                <h3>
                    <IonSkeletonText animated style={{width: '80%'}}/>
                </h3>
                <p>
                    <IonSkeletonText animated style={{width: '60%'}}/>
                </p>
            </IonLabel>
        </IonItem>
    }

    filter = (el:SimpleDevice) => {
        if(this.state.search && this.state.search.length > 0) {
            let search = this.state.search.toLowerCase();
            if(el.name.toLowerCase().indexOf(search) >= 0) {
                return true;
            }
            if(el.imei.toLowerCase().indexOf(search) >= 0) {
                return true;
            }
            return false;
        }
        return true;
    }

    renderError() {
        const {lang} = this.props;
        return <>
            <div className="error-container">
                <div className="error-container-icon">
                    <IonIcon icon={cloudOffline} /> 
                </div>
                <div className="error-container-message">
                    {lang?.l.main.devices_error()}
                    <div>
                        <IonButton fill="clear" onClick={() => this.reload()}>{lang?.l.main.devices_retry()}</IonButton>
                    </div>
                </div>
            </div>
            
        </>
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        if(!this.state) return "";
        const {lang} = this.props;
        return <IonPage>
            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot={"start"}><IonMenuButton/></IonButtons>
                    <IonTitle>{lang?.l.main.devices_title()}</IonTitle>
                </IonToolbar>

                <IonToolbar color={"primary"}>
                    <IonSearchbar placeholder={lang?.l.main.search()} ref={this.search} onIonChange={() => this.setState({search: this.search.current.value})}></IonSearchbar>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {/*<IonList>*/}
                {/*    {[0, 1, 2, 3, 4, 5].map((i) => Devices.renderItem(i))}*/}
                {/*</IonList>*/}
                {this.state.loading &&
                <IonList>
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((i) => this.renderSkeleton())}
                </IonList>}
                {this.state.error && this.renderError()}
                {!this.state.loading && this.state.clients && this.state.clients.map(this.renderClient)}
            </IonContent>

            <IonFab vertical="bottom" horizontal="end" slot="fixed">
                <IonFabButton routerLink="/add_device/add">
                    <IonIcon icon={add}/>
                </IonFabButton>
            </IonFab>
        </IonPage>;
    }
}


export default Devices;
