import React from "react";
import {
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonChip,
    IonCol,
    IonContent,
    IonFab,
    IonFabButton,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonLoading,
    IonModal,
    IonPage,
    IonPopover,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTextarea,
    IonTitle,
    IonToggle,
    IonToolbar
} from "@ionic/react";
import { inject } from "mobx-react";
import { API, Device, ModbusMapper, ModbusType as ModbusTypeAPI } from "../../service/API";
import { RouteComponentProps } from "react-router-dom";
import { Store } from "../../service/Store";
import ModbusExplorer from "../../components/ModbusExplorer";
import { Format } from "../../service/Formats";
import { add, ellipsisHorizontal, ellipsisVertical, trashBinOutline } from "ionicons/icons";
import Utils from "../../service/Utils";
import NumberInput from "../../components/NumberInput";


export interface DeviceSettingsModbusProps extends RouteComponentProps<{ id: string }> {
    store?: Store,
}

type ModbusType = ModbusTypeAPI;

export type DeviceSettingsModbusState = {
    loading: boolean,
    conversor: boolean,
    modbusModal: boolean,
    modbusEdit?: ModbusType,
    modbusMapperModal: boolean,
    modbusMapperEdit?: ModbusMapper,
    reference: boolean,
    console: boolean,
    conversorDecimal: number,
    conversorHex: string,
    conversorBit: string,
    conversorBitDetails: string,
    modbus: ModbusType,
    format: number,
    formatDecimal: number,
    decimalFormat: number,

    values: number[],
    readValues: { addr: number, value: number }[],
    log: string[],
    list: ModbusType[],

    device?: Device,
}

@inject("store", "lang")
export default class DeviceSettingsModbus extends React.Component<DeviceSettingsModbusProps, DeviceSettingsModbusState> {

    state: DeviceSettingsModbusState = {
        modbus: {
            station: 1,
            enabled: true,
            functionCode: 0,
            address: 0,
            registers: 1,
            dataFormat: 1,
            protocol: 0,
            host: '',
            port: 502,
            name: '',
            mappers: [],
            retries: 0,
        },
        loading: false,
        conversor: false,
        modbusModal: false,
        modbusMapperModal: false,
        conversorDecimal: 0,
        conversorHex: '',
        conversorBit: '',
        conversorBitDetails: '',
        format: 0,
        formatDecimal: 0,
        decimalFormat: 0,
        reference: false,
        console: false,

        values: [],
        readValues: [],
        log: [],
        list: [],

    };



    formats = [

        {
            title: 'Identidad',
            parse: (value: number) => {
                return value;
            },
            build: (value: number) => {
                return value;
            }
        },
        {
            title: 'Data format [2] Integer data (positive/negative): Minimum step 1',
            parse: (value: number) => {
                // let sign = ((value >> 15) & 0b1);
                // if(sign == 0) {
                //     return value;
                // }
                return -((~value & 0xFFFF) + 1);
            },
            build: (value: number) => {
                return value;

            }
        },
        {
            title: 'Data format [3] Decimal data (positive): Minimum step 0.1',
            parse: (value: number) => value * 0.1,
            build: (value: number) => value / 0.1,
        },
        {
            title: 'Data format [12] Floating point data',
            parse: (value: number) => {
                let polarity = ((value >> 15) & 0b1);
                let exp = (value >> 10) & 0b11;
                let mantisa = (value) & 0b1111111111;
                if (polarity === 0) {
                    polarity = 1;
                } else {
                    polarity = -1;
                }

                return polarity * mantisa * Math.pow(10, exp - 2);
            },
            build: (value: number) => {
                return value;

            }
        }
    ];

    componentDidMount() {
        this.loadState();
        this.loadData();
    }

    componentDidUpdate(prevProps: Readonly<DeviceSettingsModbusProps>, prevState: Readonly<DeviceSettingsModbusState>, snapshot?: any): void {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.loadData();
        }
    }


    handleChange = (modbus: ModbusType, prop: keyof ModbusType) => (ev: CustomEvent<any>) => {
        // @ts-ignore
        (modbus[prop] as any) = ev.detail.value as string;
        this.setState({});
    }

    handleCheckboxChange = (modbus: ModbusType, prop: keyof ModbusType) => (ev: CustomEvent<any>) => {
        // @ts-ignore
        (modbus[prop] as any) = ev.detail.checked as string;
        this.setState({});
    }
    handleChangeNumber = (modbus: ModbusType, prop: keyof ModbusType) => (value: number) => {
        // @ts-ignore
        (modbus[prop] as any) = value;
        this.setState({});
    }
    handleChangeMapper = (mapper: ModbusMapper, prop: keyof ModbusMapper) => (ev: CustomEvent<any>) => {

        (mapper[prop] as any) = ev.detail.value! as any;
        this.setState({});
    }

    handleChangeValue = (valueIndex: number) => (ev: CustomEvent<any>) => {
        // eslint-disable-next-line
        this.state.values[valueIndex] = ev.detail.value;

        this.setState({});
    }

    handleRemoveValue = (valueIndex: number) => (ev: any) => {
        // eslint-disable-next-line
        this.state.values.splice(valueIndex, 1);

        this.setState({});
    }
    handleAddValue = (ev: any) => {
        // eslint-disable-next-line
        this.state.values.push(0);
        this.setState({});
    }

    handleAdd = (ev: any) => {
        let modbus: ModbusType = {

            ...this.state.modbus,
            mappers: [],
            id: 0,
        }
        this.setState({ modbusModal: true, modbusEdit: modbus });

    }

    handleSaveModbus = (ev: any) => {
        this.setState({ loading: true });
        if (this.state.modbusEdit) {
            if (this.state.modbusEdit.id) {
                this.props.store?.api.editModbus(parseInt(this.props.match.params.id), this.state.modbusEdit.id, this.state.modbusEdit).then((res) => {
                    if (res.success) {
                        this.loadData();
                        this.setState({ modbusModal: false });
                    }
                }).finally(() => this.setState({ loading: false }));
            } else {
                this.props.store?.api.addModbus(parseInt(this.props.match.params.id), this.state.modbusEdit).then((res) => {
                    if (res.success) {
                        this.loadData();
                        this.setState({ modbusModal: false });
                    }
                }).finally(() => this.setState({ loading: false }));
            }
        }

    }

    handleSaveModbusMapper = (ev: any) => {
        if (this.state.modbusMapperEdit && this.state.modbusEdit) {
            if (this.state.modbusMapperEdit.id) {
                let v = this.state.modbusEdit.mappers.findIndex(a => a.id === this.state.modbusMapperEdit!.id);
                if (v >= 0) {
                    let m = this.state.modbusEdit.mappers;
                    m[v] = this.state.modbusMapperEdit;
                }
            } else {
                this.state.modbusEdit.mappers.push({...this.state.modbusMapperEdit, id: Math.floor(Math.random() * -100000)})
            }
        }
        this.setState({ modbusMapperModal: false });

    }

    handleRemoveModbus = (id: number) => (ev:any) => {
        // eslint-disable-next-line no-restricted-globals
        if(confirm("¿Realmente deseas eliminar este elemento?")) {
            this.setState({loading: true});
            let device = parseInt(this.props.match.params.id);
            this.props.store?.api.removeModbus(device, id).then(() => {
                this.setState({modbusModal: false});
                this.loadData();
            })

        }
    }

    handleRemoveModbusMapper = (mapper: ModbusMapper) => (ev:any) => {
        // eslint-disable-next-line no-restricted-globals
        if(confirm("¿Realmente deseas eliminar este elemento?")) {
            let i = this.state.modbusEdit?.mappers.findIndex(a => a.id === mapper.id) as number;
            if(i >= 0) {
                this.state.modbusEdit?.mappers.splice(i, 1);
                this.setState({modbusMapperModal: false});
            }

        }

    }

    saveState() {
        let id = this.props.match.params.id;
        localStorage.setItem('modbus.' + id, JSON.stringify(this.state.modbus));
    }

    loadState() {

        let id = this.props.match.params.id;
        if (localStorage.getItem('modbus.' + id)) {
            this.setState({ modbus: JSON.parse(localStorage.getItem('modbus.' + id)!) });
        }

    }

    loadData() {
        this.setState({ loading: true });
        let id = parseInt(this.props.match.params.id);
        this.props.store?.api.deviceGet(id).then(res1 => {
            if (res1.success) {
                this.setState({ device: res1.data });



                this.props.store?.api.getModbus(id).then(res => {
                    if (res.success) {
                        this.setState({ list: res.data, loading: false });
                    }
                })
            }
        })
    }
    handleClick = (modbus: ModbusType) => (ev: any) => {
        let id = this.props.match?.params.id!;
        let api: API = this.props.store!.api;
        this.setState({ loading: true });
        if (this.isMultipleValue()) {
            // eslint-disable-next-line
            modbus.value = this.state.values;
        }
        this.saveState();
        api.deviceModbusQuery(parseInt(id), { ...modbus, id: 0, name: '' }).then(value => {
            this.setState({ loading: false });
            if (value.success) {
                let source = this.state.readValues;
                let valuesRead = [];//value.data.modbusResponse!.map(a => {addr: (this.state.modbus.address + (i++)), value: a});
                for (let i = 0; i < value.data.modbusResponse!.length; i++) {
                    const element = value.data.modbusResponse![i];
                    const addr = i + Number(modbus.address);
                    valuesRead.push({ addr: i + Number(modbus.address), value: Number(element) });
                    if (source[i]) {
                        if (source[i].addr === addr) {
                            if (source[i].value !== element) {
                                this.state.log.push(`[${addr}] = ${source[i].value} -> ${element}`);
                            }
                        } else {
                            this.state.log.push(`[${addr}] = ${element}`);
                        }
                    } else {
                        this.state.log.push(`[${addr}] = ${element}`);
                    }
                }
                if(this.state.modbusEdit) {
                    this.mapRegisters(this.state.modbusEdit.mappers, value.data.modbusResponse);
                }
                this.setState({ readValues: valuesRead });
            }
        });
    }

    mapRegisters(mapping: ModbusMapper[], values: number[]) {
        for (let i = 0; i < mapping.length; i++) {
            const element = mapping[i];
            let j = element.offset;
            element.values = [];
            for (let k = 0; k < element.size; k++) {
                element.values.push(values[j + k]);
            }
        }

    }

    handleConversor = (ev: any) => {
        this.setState({ conversor: true });
    }

    handleReference = (ev: any) => {
        this.setState({ reference: true });
    }



    handleFormatChange = (ev: any) => {
        // let value = this.state.conversorDecimal;
        // if(ev.detail.value == 0) return;
        // let format = this.formats[ev.detail.value - 1];
        // alert(format.parse(value));

        this.setState({ format: ev.detail.value });
        this.handleConvertValue(String(this.state.conversorDecimal), 10);

    }

    displayList(row: any[]) {
        let result = row[0].map((a: number) => {
            if (a > 9) {
                return a;
            }
            return a + " ";
        }).join("| ");

        return "Position: " + result + "\n" +
            "Bits:     " + row[1].join(" | ")
    }

    handleConvertValue(input: string, radix: number) {
        if (!input) return;
        input = input.trim();
        if (input === "") return;
        if (!input.match(/[A-F0-9]+/)) return;
        let value = parseInt(input, radix);

        let bits = value.toString(2);
        let row: any[] = [[], []];
        for (let i = 0; i < bits.length; i++) {
            row[0].push(i);
            row[1].push(bits.substr(i, 1));
        }

        let result = "";
        row[0].reverse();
        result += "Big-endian:\n" + this.displayList(row) + "\n";
        result += "Little-endian:\n"
        row[0].reverse();
        result += this.displayList(row) + "\n";

        let format = this.formats[this.state.format];

        if (format) {

            let formatDecimal = format.build(value);
            let decimalFormat = format.parse(value);

            this.setState({
                conversorDecimal: value,
                conversorHex: value.toString(16).toUpperCase(),
                conversorBit: value.toString(2),
                conversorBitDetails: result,
                formatDecimal,
                decimalFormat,
            })
        }

    }

    handleChangeDecimal = (ev: any) => {
        this.handleConvertValue(ev.detail.value, 10);
    }
    handleChangeHex = (ev: any) => {
        this.handleConvertValue(ev.detail.value, 16);
    }
    handleChangeBit = (ev: any) => {
        this.handleConvertValue(ev.detail.value, 2);
    }

    handleEditModbus = (modbus: ModbusType) => (ev: any) => {
        this.setState({ modbusModal: true, modbusEdit: modbus });

    }

    handleClickMapper = (mapper: ModbusMapper) => (ev: any) => {
        this.setState({ modbusMapperModal: true, modbusMapperEdit: { ...mapper } });
    }

    handleAddMapper = (modbus: ModbusType) => (ev: any) => {
        let item = { format: 0, groupIndex: 0, id: 0, index: 0, offset: 0, size: 0, type: 'input' };
        // modbus.mappers.push(item);
        this.setState({modbusMapperModal: true, modbusMapperEdit: item});
    }

    renderFunctionCodes() {
        return <>
            <IonSelectOption value={0x01}>0x01 - ReadHoldingCoil</IonSelectOption>
            <IonSelectOption value={0x02}>0x02 - ReadDiscreteInputs</IonSelectOption>
            <IonSelectOption value={0x03}>0x03 - ReadHoldingRegister</IonSelectOption>
            <IonSelectOption value={0x04}>0x04 - ReadInputRegisters</IonSelectOption>
            <IonSelectOption value={0x05}>0x05 - WriteSingleCoil</IonSelectOption>
            <IonSelectOption value={0x06}>0x06 - WriteHoldingRegister</IonSelectOption>
            <IonSelectOption value={0x0F}>0x0F - WriteMultipleCoil</IonSelectOption>
            <IonSelectOption value={0x10}>0x10 - WriteMultipleRegisters</IonSelectOption>
        </>
    }

    renderFormat() {
        return this.formats.map((item, key) => <IonSelectOption value={key}>{item.title}</IonSelectOption>)
    }


    renderConversor() {
        return <>

            <IonItem>
                <IonInput label="Base Decimal" labelPlacement="floating" value={this.state.conversorDecimal} type={"number"} onIonInput={this.handleChangeDecimal}></IonInput>
            </IonItem>

            <IonItem>
                <IonInput label="Base Hexadecimal" labelPlacement="floating" value={this.state.conversorHex} type={"text"} onIonInput={this.handleChangeHex}></IonInput>
            </IonItem>

            <IonItem>
                <IonInput label="Base binaria" labelPlacement="floating" value={this.state.conversorBit} type={"number"} onIonInput={this.handleChangeBit}></IonInput>
            </IonItem>
            <IonItem>
                <IonTextarea rows={7} readonly={true} style={{ fontSize: '10px', fontFamily: 'monospace', width: '100%' }} value={this.state.conversorBitDetails}>

                </IonTextarea>
                {/* <textarea rows={7} readOnly={true} style={{ fontSize: '10px', fontFamily: 'monospace', width: '100%' }} value={this.state.conversorBitDetails}></textarea> */}
            </IonItem>
            <IonItem>
                <IonSelect label="Formato" labelPlacement="floating" onIonChange={this.handleFormatChange} value={this.state.format}>
                    {this.renderFormat()}

                </IonSelect>
            </IonItem>
            {this.state.format > 0 && <>

                <IonItem>
                    <IonInput label="Decimal -&gt; Formato" labelPlacement="floating" value={this.state.decimalFormat} type={"number"}></IonInput>
                </IonItem>

                <IonItem>
                    <IonInput label="Formato -&gt; Decimal" labelPlacement="floating" value={this.state.formatDecimal} type={"number"}></IonInput>
                </IonItem>
            </>
            }
        </>
    }

    isSingleValue() {
        return [0x05, 0x06].indexOf(this.state.modbus!.functionCode) >= 0;
    }
    isMultipleValue() {
        return [0x0F, 0x10].indexOf(this.state.modbus!.functionCode) >= 0;
    }

    renderModbus(modbus: ModbusType) {
        return <>
            <IonItem>
                <IonSelect labelPlacement="floating" label="Protocolo" interface="action-sheet" value={modbus.protocol} placeholder="Protocolo de comunicación" onIonChange={this.handleChange(modbus, 'protocol')} >
                    <IonSelectOption value={0}>Modbus</IonSelectOption>
                    <IonSelectOption value={1}>TCP</IonSelectOption>
                    <IonSelectOption value={2}>MQTT</IonSelectOption>
                </IonSelect>
            </IonItem>
            {modbus.protocol === 2 && <>
                    <IonItem>
                        <IonInput helperText="Dirección IP = S:ID para usar por serial" label="Dirección IP" value={modbus.host} type={"text"} onIonInput={this.handleChange(modbus, 'host')}></IonInput>
                        <IonInput helperText="Puerto por defecto" label="Puerto (502)" value={modbus.port} type={"number"} max={"65535"} step={"1"} placeholder="Default: 502" onIonInput={this.handleChange(modbus, 'port')}></IonInput>
                    </IonItem>
                </>
                
                }
            <IonItem>
                <IonInput label="Número estación" labelPlacement="stacked" value={modbus.station} type={"number"} max={"247"} step={"1"} onIonInput={this.handleChange(modbus, 'station')}></IonInput>
            </IonItem>
            <IonItem>
                <IonSelect labelPlacement="floating" label="FC" value={modbus.functionCode} interface={"action-sheet"} placeholder="Seleccionar Código de Función" onIonChange={this.handleChange(modbus, 'functionCode')}>
                    {this.renderFunctionCodes()}

                </IonSelect>
            </IonItem>
            
            <NumberInput value={modbus.address} label="Dirección" onChange={this.handleChangeNumber(modbus, 'address')} />
            
            <IonItem>
                <IonInput label="Número de Registros" labelPlacement="floating" value={modbus.registers} type={"number"} max={"100"} step={"1"} onIonInput={this.handleChange(modbus, 'registers')}></IonInput>
            </IonItem>
            <IonItem>
                <IonInput label="Reintentos" labelPlacement="floating" value={modbus.retries} type={"number"} max={"50"} step={"1"} onIonInput={this.handleChange(modbus, 'retries')}></IonInput>
            </IonItem>

            <IonItem>
                <IonSelect label="Formato" labelPlacement="floating" interface="action-sheet" value={modbus.dataFormat} placeholder="Seleccionar el formato de los datos" onIonChange={this.handleChange(modbus, 'dataFormat')} >
                    {Object.keys(Format).map(a => Number(a)).filter(key => !isNaN(key)).map((element) => {
                        return <IonSelectOption value={(element)}>{(element)} - {Format[(element)]}</IonSelectOption>
                    })}
                </IonSelect>
            </IonItem>
            {this.isSingleValue() &&
                <IonItem>
                    <IonInput label="Valor Escritura" labelPlacement="floating" value={modbus.value as number} type={"number"} max={"100"} step={"1"} onIonInput={this.handleChange(modbus, 'value')}></IonInput>
                </IonItem>}
            {this.isMultipleValue() &&
                <>
                    <IonItem>
                        <IonLabel>Valores Escritura</IonLabel>
                        <IonButton onClick={this.handleAddValue}>Añadir</IonButton>
                    </IonItem>
                    {this.state.values.map((v, k) => {
                        return <IonItem>
                            <IonInput placeholder="Valor" value={v} onIonInput={this.handleChangeValue(k)} ></IonInput>
                            <IonButton onClick={this.handleRemoveValue(k)}>Quitar</IonButton>
                        </IonItem>
                    })}


                </>
            }

            <IonItem color={"success"} button onClick={this.handleClick(modbus)}>
                <IonLabel>Consultar</IonLabel>
            </IonItem>
            <IonGrid>
                <IonRow>
                    <IonCol sizeLg={"6"} sizeMd={"6"} sizeSm={"6"} >
                        {this.state.readValues && this.state.readValues.length > 0 && <>
                            <IonItem>
                                <IonLabel>Dirección (Dec.)</IonLabel>
                                <IonLabel>Valor</IonLabel>
                            </IonItem>
                            {this.state.readValues.map(a => <IonItem><IonLabel>{a.addr}</IonLabel><IonLabel>{a.value}</IonLabel></IonItem>)}
                        </>}
                    </IonCol>
                    <IonCol sizeLg={"6"} sizeMd={"6"} sizeSm={"6"} >
                        {this.state.log && this.state.log.length > 0 && <>
                            {[...this.state.log].reverse().map(a => <IonItem><IonText>{a}</IonText></IonItem>)}
                        </>}
                    </IonCol>
                </IonRow>
            </IonGrid>
        </>
    }

    renderModbusMapper(mapper: ModbusMapper) {
        let list = Utils.getInputGroupByIndex(this.state.device?.inputGroups!, mapper.groupIndex);
        return <>

            <IonItem>
                <IonSelect label="Tipo de lectura" labelPlacement="floating" interface="action-sheet" value={mapper.type} placeholder="Tipo de lectura" onIonChange={this.handleChangeMapper(mapper, 'type')} >
                    <IonSelectOption value={'input'}>Entrada</IonSelectOption>
                    <IonSelectOption value={'status'}>Estado</IonSelectOption>
                </IonSelect>
            </IonItem>
            <IonItem>
                <IonSelect label="groupIndex" labelPlacement="floating" interface="action-sheet" value={mapper.groupIndex} placeholder="Group Index" onIonChange={this.handleChangeMapper(mapper, 'groupIndex')} >
                    {this.state.device!.inputGroups.sort((a, b) => Number(a.iindex) - Number(b.iindex)).map(a => {return {...a}}).map((element) => {
                        return <IonSelectOption value={(element.iindex)}>{(element.iindex)} - {element.name}</IonSelectOption>
                    })}
                </IonSelect>
            </IonItem>

            <IonItem>
                <IonSelect label="Index" labelPlacement="floating" interface="action-sheet" value={mapper.index} placeholder="Index" onIonChange={this.handleChangeMapper(mapper, 'index')} >
                    {list && list.inputs.map(element => {
                        return <IonSelectOption value={(element.index)}>{(element.index)} - {element.name}</IonSelectOption>
                    })}
                </IonSelect>
            </IonItem>
            <IonItem>
                
                <IonInput label="Offset" labelPlacement="stacked" value={mapper.offset} type={"number"} onIonInput={this.handleChangeMapper(mapper, 'offset')}></IonInput>
            </IonItem>
            <IonItem>
                <IonInput label="Size" labelPlacement="stacked" value={mapper.size} type={"number"} onIonInput={this.handleChangeMapper(mapper, 'size')}></IonInput>
            </IonItem>
            <IonItem>
                <IonSelect label="Formato" labelPlacement="floating" interface="action-sheet" value={mapper.format} placeholder="Seleccionar el formato de los datos" onIonChange={this.handleChangeMapper(mapper, 'format')} >
                    {Object.keys(Format).map(a => Number(a)).filter(key => !isNaN(key)).map((element) => {
                        return <IonSelectOption value={(element)}>{(element)} - {Format[(element)]}</IonSelectOption>
                    })}
                </IonSelect>
            </IonItem>

            <IonItem color="danger" button detailIcon={trashBinOutline} detail onClick={this.handleRemoveModbusMapper(mapper)}>
                <IonLabel>Eliminar</IonLabel>
            </IonItem>
        </>


    }
    renderConsole(modbus: ModbusType) {
        return <>
            {this.renderModbus(modbus)}
        </>
    }


    renderList() {
        return <IonList>
            {this.state.list.map((v, k) => {
                return <IonItem detail={true} button onClick={this.handleEditModbus(v)}>
                    {v.name} <IonChip>{Utils.modbusToString(v)}</IonChip>

                </IonItem>
            })}
        </IonList>

    }

    renderReadValues(v: ModbusMapper) {
        if(v.values) {
            return <IonBadge>{v.values.join(", ")}</IonBadge>;
        }
        return "";
    }
    render() {
        let id = this.props.match?.params.id!;
        const { modbus } = this.state;

        return <IonPage>

            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={"/device/" + id + "/settings"} />
                    </IonButtons>
                    <IonTitle>Modbus</IonTitle>

                    {/* <IonButtons slot="primary">
                        <IonButton onClick={this.handleReference}>Referencia</IonButton>
                    </IonButtons>
                    <IonButtons slot="primary">
                        <IonButton onClick={this.handleConversor}>Conversor</IonButton>
                    </IonButtons> */}
                    <IonButtons slot="primary">
                        <IonButton id="trigger-button"><IonIcon ios={ellipsisHorizontal} md={ellipsisVertical} /></IonButton>
                        <IonPopover trigger="trigger-button" dismissOnSelect={true}>
                            <IonContent>
                                <IonList>
                                    <IonItem button={true} detail={false} onClick={this.handleReference}>
                                        <IonLabel>Referencia</IonLabel>
                                    </IonItem>
                                    <IonItem button={true} detail={false} onClick={this.handleConversor}>
                                        <IonLabel>Conversor</IonLabel>
                                    </IonItem>
                                    <IonItem button={true} detail={false} onClick={() => { this.setState({ console: !this.state.console }) }}>
                                        <IonLabel>{this.state.console ? "Ocultar" : "Ver"} Consola</IonLabel>
                                    </IonItem>
                                </IonList>
                            </IonContent>
                        </IonPopover>
                    </IonButtons>

                </IonToolbar>
            </IonHeader>

            <IonContent>
                {this.state.console && this.renderConsole(modbus)}
                {!this.state.console && this.renderList()}
            </IonContent>


            <IonModal isOpen={this.state.conversor! ? true : false} onDidDismiss={() => this.setState({ conversor: false })}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Conversor de base</IonTitle>
                        <IonButtons slot={"primary"}>
                            <IonButton onClick={() => this.setState({ conversor: false })}>OK</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    {this.renderConversor()}
                </IonContent>

            </IonModal>

            <IonModal isOpen={this.state.reference! ? true : false} onDidDismiss={() => this.setState({ reference: false })}>
                <ModbusExplorer onClose={() => this.setState({ reference: false })} onAddrSelected={(addr) => this.setState({ reference: false, modbus: { ...this.state.modbus, address: addr } })} />
            </IonModal>
            <IonModal isOpen={this.state.modbusModal! ? true : false} onDidDismiss={() => this.setState({ modbusModal: false })}>

                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Editar Modbus</IonTitle>
                        <IonButtons slot={"secondary"}>
                            <IonButton onClick={() => this.setState({ modbusModal: false })}>Cancelar</IonButton>
                        </IonButtons>
                        <IonButtons slot={"primary"}>
                            <IonButton onClick={this.handleSaveModbus}>OK</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent scrollEvents>

                    <IonList>
                        {this.state.modbusEdit && <IonItem>
                            <IonInput label="Nombre" labelPlacement="stacked" value={this.state.modbusEdit.name} onIonInput={this.handleChange(this.state.modbusEdit, 'name')}></IonInput>
                        </IonItem>}
                        {this.state.modbusEdit && <IonItem><IonToggle checked={this.state.modbusEdit.enabled} onIonChange={this.handleCheckboxChange(this.state.modbusEdit, 'enabled')}>
                            Activar
                        </IonToggle></IonItem>}
                        {this.state.modbusEdit && this.renderModbus(this.state.modbusEdit)}

                        <IonListHeader>
                            <IonLabel>Direccionamiento a Registros <IonButton onClick={this.handleAddMapper(this.state.modbusEdit!)}>Añadir</IonButton></IonLabel>
                        </IonListHeader>
                        {this.state.modbusEdit && <>
                            {this.state.modbusEdit.mappers.map((v, k) => <IonItem button detail onClick={this.handleClickMapper(v)}>
                                <IonLabel><IonBadge>{v.type}</IonBadge> {Utils.getNameBySubInputGroup(this.state.device?.inputGroups!, v.groupIndex, v.index)} <IonBadge>{v.offset}/{v.size}</IonBadge> {this.renderReadValues(v)}</IonLabel>
                            </IonItem>)}
                        </>}
                        <IonItem color="danger" button detailIcon={trashBinOutline} detail onClick={this.handleRemoveModbus(this.state.modbusEdit?.id!)}>
                            <IonLabel>Eliminar</IonLabel>
                        </IonItem>
                        <IonItem>

                        </IonItem>
                    </IonList>
                </IonContent>
            </IonModal>
            <IonModal isOpen={this.state.modbusMapperModal! ? true : false} onDidDismiss={() => this.setState({ modbusMapperModal: false })}>

                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Editar Mapping</IonTitle>
                        <IonButtons slot={"secondary"}>
                            <IonButton onClick={() => this.setState({ modbusMapperModal: false })}>Cancelar</IonButton>
                        </IonButtons>
                        <IonButtons slot={"primary"}>
                            <IonButton onClick={this.handleSaveModbusMapper}>OK</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent scrollEvents>
                    <IonList>

                        {this.state.modbusMapperEdit && this.renderModbusMapper(this.state.modbusMapperEdit)}
                    </IonList>
                </IonContent>
            </IonModal>

            <IonLoading
                isOpen={this.state.loading!}
                message={'Cargando...'}
            />
            <IonFab vertical="bottom" horizontal="end" slot="fixed">
                <IonFabButton onClick={this.handleAdd}>
                    <IonIcon icon={add} />
                </IonFabButton>
            </IonFab>
        </IonPage>
    }
}
