import React from "react";
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonLoading,
    IonPage,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import {
    addCircleOutline,
    arrowBack,
    bookOutline,
    cardOutline,
    cogSharp,
    flagOutline,
    locateOutline,
    logIn,
    logOut,
    notifications,
    removeCircleOutline,
    saveOutline,
    shareOutline,
    thermometerOutline, timeOutline, timerOutline,
    warningOutline,
    wifiOutline
} from "ionicons/icons";
import { match } from "react-router";
import { inject } from "mobx-react";
import { Store } from "../../service/Store";
import { Device } from "../../service/API";
import { DeviceLogProp, DeviceLogState } from "../Device/DeviceLog";
import Lang from "../../service/Lang";

export type DeviceSettingsProps = {
    store?: Store;
    lang?: Lang;
    match?: match<{ id: string }>,
}
export type DeviceSettingsState = {
    loadingDevice?: boolean,
    device?: Device,

}

@inject("store", "lang")
export default class DeviceSettings extends React.Component<DeviceSettingsProps, DeviceSettingsState> {

    componentDidMount(): void {
        this.loadDevice();
    }

    componentDidUpdate(prevProps: Readonly<DeviceLogProp>, prevState: Readonly<DeviceLogState>, snapshot?: any): void {
        if (this.props.match?.params.id !== prevProps.match?.params.id) {
            this.loadDevice();
        }
    }

    loadDevice() {
        this.setState({ loadingDevice: true });
        const id = this.props.match?.params.id;
        this.props.store?.api.deviceGet(parseInt(id!)).then(value => {
            this.setState({ device: value.data, loadingDevice: false });
        })
    }

    renderSettingsBM27x(id: string) {
        const { lang } = this.props;
        return <>
            <IonItem detail routerLink={"/device/" + id + "/settings/hardware_relay"}>
                <IonIcon icon={logOut} slot="start" />
                <IonLabel>{lang?.l.main.reles()}</IonLabel>
            </IonItem>

            <IonItem detail routerLink={"/device/" + id + "/settings/hardware_analog"}>
                <IonIcon icon={addCircleOutline} slot="start" />
                <IonLabel>{lang?.l.main.analog_inputs()}</IonLabel>
            </IonItem>
            <IonItem detail routerLink={"/device/" + id + "/settings/hardware_temp"}>
                <IonIcon icon={thermometerOutline} slot="start" />
                <IonLabel>{lang?.l.main.temp_and_humd()}</IonLabel>
            </IonItem>
            <IonItem detail routerLink={"/device/" + id + "/settings/hardware_digital"}>
                <IonIcon icon={removeCircleOutline} slot="start" />
                <IonLabel>{lang?.l.main.digital_inputs()}</IonLabel>
            </IonItem>

            <IonItem detail routerLink={"/device/" + id + "/settings/hardware_timer"}>
                <IonIcon icon={timeOutline} slot="start" />
                <IonLabel>{lang?.l.main.hardware_timer()}</IonLabel>
            </IonItem>
            <IonItem detail routerLink={"/device/" + id + "/settings/hardware_interlock"}>
                <IonIcon icon={locateOutline} slot="start" />
                <IonLabel>{lang?.l.main.interlock()}</IonLabel>
            </IonItem>

            <IonItem detail routerLink={"/device/" + id + "/settings/hardware_backup"}>
                <IonIcon icon={saveOutline} slot="start" />
                <IonLabel>Backup</IonLabel>
            </IonItem>
        </>
    }

    renderSettingsBM27x_V2(id: string) {
        const { lang } = this.props;
        return <>

            <IonItem detail routerLink={"/device/" + id + "/settings/hardware_relay"}>
                <IonIcon icon={logOut} slot="start" />
                <IonLabel>{lang?.l.main.reles()}</IonLabel>
            </IonItem>

            <IonItem detail routerLink={"/device/" + id + "/settings/hardware_analog"}>
                <IonIcon icon={addCircleOutline} slot="start" />
                <IonLabel>{lang?.l.main.analog_inputs()}</IonLabel>
            </IonItem>
            <IonItem detail routerLink={"/device/" + id + "/settings/hardware_temp"}>
                <IonIcon icon={thermometerOutline} slot="start" />
                <IonLabel>{lang?.l.main.temp_and_humd()}</IonLabel>
            </IonItem>
            <IonItem detail routerLink={"/device/" + id + "/settings/hardware_digital"}>
                <IonIcon icon={removeCircleOutline} slot="start" />
                <IonLabel>{lang?.l.main.digital_inputs()}</IonLabel>
            </IonItem>

            <IonItem detail routerLink={"/device/" + id + "/settings/hardware_timer"}>
                <IonIcon icon={timeOutline} slot="start" />
                <IonLabel>{lang?.l.main.hardware_timer()}</IonLabel>
            </IonItem>
            <IonItem detail routerLink={"/device/" + id + "/settings/hardware_interlock"}>
                <IonIcon icon={locateOutline} slot="start" />
                <IonLabel>{lang?.l.main.interlock()}</IonLabel>
            </IonItem>
            <IonItem detail routerLink={"/device/" + id + "/settings/hardware_modbus"}>
                <IonIcon icon={bookOutline} slot="start" />
                <IonLabel>Modbus Hardware</IonLabel>
            </IonItem>
            <IonItem detail routerLink={"/device/" + id + "/settings/hardware_modbus"}>
                <IonIcon icon={timeOutline} slot="start" />
                <IonLabel>Control RTC</IonLabel>
            </IonItem>
            <IonItem detail routerLink={"/device/" + id + "/settings/hardware_modbus"}>
                <IonIcon icon={timeOutline} slot="start" />
                <IonLabel>Programas Personalizados</IonLabel>
            </IonItem>
            <IonItem detail routerLink={"/device/" + id + "/settings/hardware_backup"}>
                <IonIcon icon={saveOutline} slot="start" />
                <IonLabel>Backup</IonLabel>
            </IonItem>
        </>
    }
    renderSettingsRemotic(id: string) {
        const { lang } = this.props;
        return <>
            <IonItem detail routerLink={"/device/" + id + "/settings/remotic_inputs"}>
                <IonIcon icon={logOut} slot="start" />
                <IonLabel>{lang?.l.main.inputs()}</IonLabel>
            </IonItem>

            <IonItem detail routerLink={"/device/" + id + "/settings/remotic_timer"}>
                <IonIcon icon={timerOutline} slot="start" />
                <IonLabel>{lang?.l.main.scheduler_title_modal()}</IonLabel>
            </IonItem>

            <IonItem detail routerLink={"/device/" + id + "/settings/remotic_automation"}>
                <IonIcon icon={thermometerOutline} slot="start" />
                <IonLabel>{lang?.l.main.hardware_automation()}</IonLabel>
            </IonItem>

            <IonItem detail routerLink={"/device/" + id + "/settings/modbus"}>
                <IonIcon icon={flagOutline} slot="start" />
                <IonLabel>{lang?.l.main.modbus()}</IonLabel>
            </IonItem>
        </>
    }

    renderSettingsModbus(id: string) {

        const { lang } = this.props;
        return <>
            <IonItem detail routerLink={"/device/" + id + "/settings/modbus"}>
                <IonIcon icon={flagOutline} slot="start" />
                <IonLabel>{lang?.l.main.modbus()}</IonLabel>
            </IonItem>
        </>
    }

    renderSettingsModbusTCP(id: string) {
        const { lang } = this.props;
        return <>
            <IonItem detail routerLink={"/device/" + id + "/settings/modbus"}>
                <IonIcon icon={flagOutline} slot="start" />
                <IonLabel>{lang?.l.main.modbus()}</IonLabel>
            </IonItem>
        </>
    }

    renderSettingsModiconMQTT(id: string) {
        // const { lang } = this.props;
        return <>
            <IonItem detail routerLink={"/device/" + id + "/settings/modicon_timer"}>
                <IonIcon icon={timeOutline} slot="start" />
                <IonLabel>Programador PLC</IonLabel>
            </IonItem>
            <IonItem detail routerLink={"/device/" + id + "/settings/modicon_timer"}>
                <IonIcon icon={flagOutline} slot="start" />
                <IonLabel>Variables Sistema</IonLabel>
            </IonItem>
        </>
    }


    renderConfiguration() {
        const { lang } = this.props;
        let id = this.props.match?.params.id!;
        if (this.state.device) {
            if (this.state.device.online) {
                if (this.state.device.protocol === "IotModbus") {
                    return this.renderSettingsBM27x_V2(id);
                } else if (this.state.device.protocol === "IotModbus2") {
                    return this.renderSettingsBM27x_V2(id);
                } else if (this.state.device.protocol === "Remotic") {
                    return this.renderSettingsRemotic(id);
                } else if (this.state.device.protocol === "Modicon") {
                    return this.renderSettingsModiconMQTT(id);
                } else {
                    return <IonItem>
                        <IonIcon icon={warningOutline} slot="start" />
                        <IonLabel>{lang?.l.main.device_not_support_settings()}</IonLabel>
                    </IonItem>
                }

            } else {
                return <IonItem>
                    <IonIcon icon={warningOutline} slot="start" />
                    <IonLabel>{lang?.l.main.device_offline()}</IonLabel>
                </IonItem>
            }
        }
        return null;
    }

    renderModbus() {
        if (this.state.device) {
            let id = this.props.match?.params.id!;

            return this.renderSettingsModbus(id);
            // if (this.state.device.protocol === "Modbus") {
            //     return this.renderSettingsModbus(id);
            // } else if (this.state.device.protocol === "ModbusTCP") {
            //     return this.renderSettingsModbusTCP(id);
            // } else if (this.state.device.protocol === "MQTT-Modbus" || this.state.device.protocol === "Modicon") {
            // }
        }
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        if (!this.state) return "";

        const { lang } = this.props;
        let id = this.props.match?.params.id!;
        return <IonPage>

            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">
                        <IonButton routerLink={"/device/" + id + "/details"}  ><IonIcon icon={arrowBack} /></IonButton>
                    </IonButtons>
                    <IonTitle>{lang?.l.main.tab_settings()}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>

                <IonLoading
                    isOpen={this.state.loadingDevice!}
                    message={lang?.l.main.loading()}
                />
                <IonList>
                    <IonItemDivider>
                        <IonLabel>
                            Servidor
                        </IonLabel>
                    </IonItemDivider>
                    <IonItem detail routerLink={"/device/" + id + "/settings/general"}>
                        <IonIcon icon={cogSharp} slot="start" />
                        <IonLabel>{lang?.l.main.settings_general()}</IonLabel>
                    </IonItem>
                    <IonItem detail routerLink={"/device/" + id + "/settings/notifications"}>
                        <IonIcon icon={notifications} slot="start" />
                        <IonLabel>Notificaciones</IonLabel>
                    </IonItem>
                    {/* <IonItem detail routerLink={"/device/" + id + "/settings/subscription"}>
                        <IonIcon icon={cardOutline} slot="start" />
                        <IonLabel>{lang?.l.main.settings_subscription()}</IonLabel>
                    </IonItem> */}
                    <IonItem detail routerLink={"/device/" + id + "/settings/suscription"}>
                        <IonIcon icon={cardOutline} slot="start" />
                        <IonLabel>Suscripción</IonLabel>
                    </IonItem>
                    <IonItem detail routerLink={"/device/" + id + "/settings/output"}>
                        <IonIcon icon={logOut} slot="start" />
                        <IonLabel>{lang?.l.main.settings_outputs()}</IonLabel>
                    </IonItem>
                    <IonItem detail routerLink={"/device/" + id + "/settings/input"}>
                        <IonIcon icon={logIn} slot="start" />
                        <IonLabel>{lang?.l.main.inputs()}</IonLabel>
                    </IonItem>
                    <IonItem detail routerLink={"/device/" + id + "/settings/share"}>
                        <IonIcon icon={shareOutline} slot="start" />
                        <IonLabel>{lang?.l.main.share()}</IonLabel>
                    </IonItem>
                    <IonItem detail routerLink={"/device/" + id + "/settings/services"}>
                        <IonIcon icon={wifiOutline} slot="start" />
                        <IonLabel>Integracion LoRa</IonLabel>
                    </IonItem>

                    {this.renderModbus()}

                    <IonItemDivider>
                        <IonLabel>
                            {lang?.l.main.add_device_device()}
                        </IonLabel>
                    </IonItemDivider>

                    {this.renderConfiguration()}


                </IonList>
            </IonContent>
        </IonPage>
    }

}
