import { Capacitor } from "@capacitor/core";
import { Store } from "./Store";

import AppActions from "../AppActionsPlugin";

export default class AppActionsManager {

    private store: Store;
    readonly available: boolean = Capacitor.getPlatform() === 'android';
    constructor(store: Store) {
        this.store = store;
    }

    add() {
        AppActions.echo({ value: "test" });
    }

}