import React from "react";
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTextarea,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import { inject } from "mobx-react";
import { Store } from "../service/Store";
import { linkOutline } from "ionicons/icons";
import { RouteComponentProps, withRouter } from "react-router";
import ModbusRTUClient from "../serial/rtu/ModbusRTUClient";
import SerialWrapper from "../serial/SerialWrapper";
import UserInfo from "../serial/registers/UserInfo";
import DeviceConfiguration from "../serial/registers/DeviceConfiguration";
import NetConfig from "../serial/registers/NetConfig";
import WebSerialPort from "../service/WebSerialPort";
import RS485V1 from "../serial/registers/RS485V1";
import GPRS from "../serial/registers/GPRS";
import PeriodicTimer from "../serial/registers/PeriodicTimer";
import DinTrigger from "../serial/registers/DinTrigger";
import SlaveList from "../serial/registers/SlaveList";
import ReadHoldingRegistersResponseBody from "../serial/rtu/response/ReadHoldingRegistersResponseBody";

export interface ModbusAppProps extends RouteComponentProps<{ port: string }> {
    store?: Store,
}

export type ModbusSerial = {
    slaveId: number,
    funcion: number,
    address: number,
    quantity: number,
    scanRate: number,
    display: string,
}
export type ModbusOperation = {
    modbus: ModbusSerial,
    timer: any,
    registers: number[]
    rtu: ModbusRTUClient,
}
export type DeviceConfigurationState = {

    userInfo?: UserInfo,
    deviceConfig?: DeviceConfiguration,
    netConfig?: NetConfig,
    netConfigOld?: GPRS,
    commv1?: RS485V1,
    din?: DinTrigger,
    periodicTimer?: PeriodicTimer,
    slave?: SlaveList,
}
export type ModbusAppState = {

    available: boolean;
    connecting: boolean;
    connected: boolean;
    debug?: boolean;
    loading?: boolean;
    configured?: boolean;

    deviceModel?: string;
    serialNumber?: string;
    imei?: string;
    build?: string;
    devices?: SerialPort[];
    error?: string;
    recv?: string;

    addModbus: boolean,
    addModbusItem: ModbusSerial,
    modbusList: ModbusSerial[]
}

@inject("store", "lang")
class ModbusApp extends React.Component<ModbusAppProps, ModbusAppState> {

    webSerialPort?: WebSerialPort;
    serialWrapper?: SerialWrapper;
    state: ModbusAppState = {
        available: false,
        connecting: false,
        connected: false,
        addModbus: false,
        addModbusItem: this.createModbusSerial(),
        modbusList: [],
    };

    modbus: ModbusOperation[] = [];
    clients: ModbusRTUClient[] = [];


    // rtu?: ModbusRTUClient;

    settings = [
        {
            name: ''
        }
    ]


    componentDidMount(): void {
        // this.loadDevices();

        this.init().then(() => {
        });
    }
    componentDidUpdate(prevProps: Readonly<ModbusAppProps>, prevState: Readonly<ModbusAppState>, snapshot?: any): void {
        // console.log(arguments);

    }

    createModbusSerial(): ModbusSerial {
        return { slaveId: 1, address: 0, display: 'signed', funcion: 3, quantity: 10, scanRate: 1000 };
    }


    async loadDevices() {
        // let res = await this.init();
        if (this.webSerialPort) {
            let port = await this.webSerialPort.addDevice();
            if (port !== undefined && port >= 0) {
                this._connect(this.webSerialPort.getDeviceByIndex(port));
            }
        }
    }


    private decodePort(name: string) {
        return atob(name);
    }
    private encodePort(name: string) {
        return btoa(name);
    }

    disconnect() {

        this.setState({ connected: false, connecting: false, debug: false });
        if (this.webSerialPort && this.webSerialPort.isOpen()) {
            this.webSerialPort!.close();
        }

    }

    handleDefaultSettings = (ev: any) => {
        this.setState({ loading: true });
    }

    private handleSerialError = (error: any) => {
        if (error === 'break') return;
        this.setState({ error: error, connected: false, connecting: false });
        this.disconnect();

    }

    private findString(string: string) {
        let build = this.state.recv!.substring(this.state.recv!.indexOf(string) + string.length);
        build = build.substring(0, build.indexOf("\n"));
        return build;
    }
    private handleSerialData = (data: ArrayBuffer) => {
        let buffer = Buffer.from(data);
        let data1 = "";
        if (this.state.recv) {
            data1 = this.state.recv;
        }
        data1 += buffer.toString('UTF-8')
        console.log(buffer.toString('hex'));
        this.setState({ recv: data1 });
    }

    private _connect(port: SerialPort) {

        if (this.state.debug) return;
        let port1 = port;
        if (!port1) {

            return;
        };
        this.webSerialPort!.connect(port1!, 9600).then((res: any) => {
            this.setState({ connecting: false });
            if (res.success) {

                // this.rtu = new ModbusRTUClient(new SerialWrapper(this.webSerialPort!));

                this.readConfiguration();
            } else {
                this.setState({ error: res.error.message });
            }
        });

    }


    private async readConfiguration() {
        try {
            this.setState({
                connected: true,
                loading: true
            });
            //   this.rtu?.readHoldingRegisters(0, 16).then(result => {
            //     let body1 : ReadHoldingRegistersResponseBody = result.body as ReadHoldingRegistersResponseBody;
            //     alert("Values: " + body1.values);
            // })
            // let serial = new SerialWrapper(this.webSerialPort!);
            // serial.write(Buffer.from("TEST1"))
            // this.rtu = new ModbusRTUClient(serial);
            // this.rtu.readHoldingRegisters(0, 16).then((value) => {
            //     let body1 : ReadHoldingRegistersResponseBody = value.body as ReadHoldingRegistersResponseBody;
            //     alert("Values: " + body1.values);
            // })
        } catch (e: any) {

            this.setState({ error: 'Error al comprobar la configuración: ' + e.message, loading: false });
            this.disconnect();
        }

    }


    componentWillUnmount(): void {
        this.disconnect();
        this.setState({ addModbus: false });
        this.closeModbus();

    }

    init() {
        return new Promise(resolve => {
            if (WebSerialPort.available()) {
                this.setState({ available: true });
                // this.serialPort = this.props.store!.getSerialPort();
                //
                // this.serialPort?.setOnDataReceivedCallback(this.handleSerialData);
                // this.serialPort?.setOnErrorReceivedCallback(this.handleSerialError);
                // this.serialPort.isExtensionInstalled((res, version) => {
                //     if (res) {
                //         this.setState({installed: true});
                //         resolve(true);
                //     } else {
                //         this.setState({installed: false});
                //         resolve(true);
                //     }
                // })

                this.webSerialPort = new WebSerialPort({
                    onSerialPortsList: (devices: SerialPort[]) => {
                        this.setState({ devices: devices });
                    },
                    onConnect: (device: SerialPort) => {

                    }
                });

                this.webSerialPort.setOnErrorReceivedCallback(this.handleSerialError);
                this.webSerialPort.setOnDataReceivedCallback(this.handleSerialData);
                this.webSerialPort.init().then(value => {
                    resolve(true);
                })
                this.serialWrapper = new SerialWrapper(this.webSerialPort);

            } else {
                this.setState({ available: false });
                resolve(false);
            }
        })
    }

    updateModbus(modbus: ModbusOperation) {

        modbus.rtu.readHoldingRegisters(modbus.modbus.address, modbus.modbus.quantity).then(result => {
            let body1: ReadHoldingRegistersResponseBody = result.body as ReadHoldingRegistersResponseBody;
            modbus.registers = body1.values;
            this.setState({});
            // alert("Values: " + body1.values);
        })
    }

    closeModbus() {

        for (const iterator of this.modbus) {
            if (iterator.timer) {
                clearInterval(iterator.timer);
            }
        }
        this.modbus = [];
    }
    setupModbus() {
        this.closeModbus();
        if (this.state.modbusList.length > 0) {

            let client = new ModbusRTUClient(this.serialWrapper!, this.state.modbusList[0].slaveId);
            for (const iterator of this.state.modbusList) {
                let item: ModbusOperation = {
                    modbus: iterator,
                    registers: [],
                    rtu: client,
                    timer: setInterval(() => { this.updateModbus(item) }, iterator.scanRate)
                }
                this.modbus.push(item);

            }
        }
    }


    handleTest = (ev: any) => {
        // this.serialPort?.write(Buffer.from("t\r\n"), (res: any) => {
        //     Utils.log(res);
        // })
    }

    handleAddModbusSerial = (ev: any) => {
        console.log(this.state.addModbusItem);
        this.setState({
            addModbus: false,
            modbusList: [...this.state.modbusList, { ...this.state.addModbusItem }],
            addModbusItem: this.createModbusSerial()
        }, () => {

            this.setupModbus();
        });

    }

    handleModbusChange = (name: keyof ModbusSerial) => (ev: any) => {
        let item = (this.state.addModbusItem) as any;
        item[name] = parseInt(ev.detail.value);
    }
    handleModbusChangeString = (name: keyof ModbusSerial) => (ev: any) => {
        let item = (this.state.addModbusItem) as any;
        item[name] = ev.detail.value;
    }

    renderConnected() {
        return <IonList>
            <IonItem>
                <IonButton onClick={() => this.setState({ addModbus: true })}>Añadir</IonButton>
            </IonItem>
            {this.modbus.map((v, k) => {
                return <IonItem key={k}>
                    {v.registers.join(", ")}
                </IonItem>
            })}
        </IonList>
    }

    renderList() {
        if (this.state.devices) {

            return <IonList>
                {this.state.devices?.map((value, index) => {
                    return <IonItem key={index} button onClick={() => this._connect(value)}>
                        <IonText>{value.getInfo().usbProductId} - {value.getInfo().usbVendorId}</IonText>
                    </IonItem>
                })}
            </IonList>
        } else {
            return <IonItem><IonLabel>Cargando dispositivos...</IonLabel></IonItem>
        }
    }

    renderState() {
        if (!this.state.available) {
            return this.renderNotAvailable()
        } else if (!this.state.connected) {
            return this.renderList();
        }
    }

    renderDebug() {
        return <><IonTextarea style={{ fontFamily: 'monospace' }}>
            {this.state.recv}
        </IonTextarea>
            <IonButton onClick={() => this.disconnect()}>Desconectar</IonButton>
        </>
    }

    renderNotAvailable() {
        return <IonItem>
            <IonText>Este navegador no soporta la conexión con el dispositivo por USB. Por favor use Google
                Chrome.</IonText>
        </IonItem>
    }

    renderModalAdd() {
        return <IonModal isOpen={this.state.addModbus}>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={() => this.setState({ addModbus: false })}>Close</IonButton>
                    </IonButtons>
                    <IonButtons slot="end">
                        <IonButton onClick={this.handleAddModbusSerial}>Añadir</IonButton>
                    </IonButtons>
                    <IonTitle>Añadir Funcion Modbus</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    <IonItem>
                        <IonInput labelPlacement="stacked" label="Slave ID" placeholder="Slave ID" value={this.state.addModbusItem.slaveId} onIonChange={this.handleModbusChange('slaveId')}></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonSelect label="Funcion" placeholder="Funcion" value={this.state.addModbusItem.funcion} onIonChange={this.handleModbusChange('funcion')}>
                            <IonSelectOption value={3}>03 - ReadHoldingRegister</IonSelectOption>
                            <IonSelectOption value={4}>04 - ...</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                    <IonItem>
                        <IonInput label="Direccion" labelPlacement="stacked" placeholder="Direccion" value={this.state.addModbusItem.address} onIonChange={this.handleModbusChange('address')}></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonInput label="Registros" labelPlacement="stacked" placeholder="Registros" value={this.state.addModbusItem.quantity} onIonChange={this.handleModbusChange('quantity')}></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonInput label="Intervalo" labelPlacement="stacked" placeholder="Intervalo" value={this.state.addModbusItem.scanRate} onIonChange={this.handleModbusChange('scanRate')}></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonSelect label="Mostar" placeholder="Valores" value={this.state.addModbusItem.display} onIonChange={this.handleModbusChangeString('display')}>
                            <IonSelectOption value={"hex"}>Hexadecimal</IonSelectOption>
                            <IonSelectOption value={"signed"}>Con Signo</IonSelectOption>
                            <IonSelectOption value={"unsigned"}>Sin Signo</IonSelectOption>
                            <IonSelectOption value={"binary"}>Binario</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                </IonList>
            </IonContent>
        </IonModal>
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        // debugger;
        return <IonPage>
            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot={"start"}>

                    </IonButtons>
                    <IonTitle>Modbus App</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => this.loadDevices()}>
                            <IonIcon slot="icon-only" icon={linkOutline} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {this.state.error && <IonItem color={"danger"}><IonText>
                    No se puede conectar con el dispositivo. Vuelva a re-conectarlo e intentelo de nuevo.
                    {this.state.error}
                </IonText></IonItem>}
                {this.state.connecting && <IonItem><IonText>
                    Conectando...
                </IonText></IonItem>}
                {this.state.debug && this.renderDebug()}
                {this.state.debug || <>
                    {this.state.connected && this.renderConnected()}
                    {!this.state.connected && !this.state.connecting && this.renderState()}
                </>}
                {this.renderModalAdd()}
            </IonContent>
        </IonPage>
    }
}

export default withRouter(ModbusApp);
