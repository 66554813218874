import React from "react";
import {
    IonAvatar,
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonTitle,
    IonToolbar
} from "@ionic/react";

let data = require("../service/licenses.json");

export default class Licences extends React.Component<any, any> {

    extractNameFromGithubUrl(url: string) {
        if (!url) {
            return null;
        }

        const reg = /((https?:\/\/)?(www\.)?github\.com\/)?(@|#!\/)?([A-Za-z0-9_]{1,15})(\/([-a-z]{1,20}))?/i;
        const components = reg.exec(url);

        if (components && components.length > 5) {
            return components[5];
        }
        return null;
    }

    sortDataByKey(data: any[], key: string) {
        data.sort(function (a, b) {
            return a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0;
        });
        return data;
    }

    capitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    componentDidMount(): void {
        let licenses = Object.keys(data).map(key => {
            let {licenses, ...license} = data[key];
            let [name, version] = key.split('@');

            // const reg = /((https?:\/\/)?(www\.)?github\.com\/)?(@|#!\/)?([A-Za-z0-9_]{1,15})(\/([-a-z]{1,20}))?/i;
            let username =
                this.extractNameFromGithubUrl(license.repository) ||
                this.extractNameFromGithubUrl(license.licenseUrl);

            let userUrl;
            let image;
            if (username) {
                username = this.capitalizeFirstLetter(username)
                image = `http://github.com/${username}.png`;
                userUrl = `http://github.com/${username}`;
            }

            return {
                key,
                name,
                image,
                userUrl,
                username,
                licenses: licenses.slice(0, 405),
                version,
                ...license,
            };
        });

        this.sortDataByKey(licenses, 'username');

        this.setState({licenses})

    }

    renderItem(item:any, key:number) {
        return <IonItem key={key} href={item.licenseUrl} button>
            <IonAvatar  slot="start"><img src={item.image} alt={item.name} /></IonAvatar>
            <IonLabel>
                <h2>{item.name}  by {item.username}</h2>
                <h3>{item.licenses}</h3>
                <p>{item.version}</p>
            </IonLabel>
        </IonItem>
    }
    render(): React.ReactNode {
        return <IonPage>

            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={"/"}/>
                    </IonButtons>
                    <IonTitle>Céditos</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>

                <IonList>
                    {this.state && this.state.licenses && this.state.licenses.map(this.renderItem)}
                </IonList>
            </IonContent>
        </IonPage>
    }

}
