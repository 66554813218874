import MemoryMapper, {MemoryAddress} from "./MemoryMapper";

export default class MemoryMapperV2_1 extends MemoryMapper {

    constructor() {
        super();
        this.set(MemoryAddress.BASIC_SETTINGS, 2);
        this.set(MemoryAddress.VERSION_MODEL, 1496);
        this.set(MemoryAddress.GPRS_ENABLE, 719);
        this.set(MemoryAddress.GPRS_APN_CONNECT, 1936);
        this.set(MemoryAddress.GPRS_APN_SET, 1488);
        this.set(MemoryAddress.GPRS_DOMAIN_ENABLE, 896);


        this.set(MemoryAddress.GPRSINFO_ADDRESS, 251904);
        this.set(MemoryAddress.GPRSIP_ADDRESS, 251968);
        this.set(MemoryAddress.GPRSAPN_ADDRESS, 252096);
        this.set(MemoryAddress.GPRSUSER_ADDRESS, 252160);
        this.set(MemoryAddress.GPRSPWD_ADDRESS, 252224);
        this.set(MemoryAddress.PERIODIC_TIMER, 664);
        this.set(MemoryAddress.PERIODIC_TIMER_SAVE, 663);

        // this.set(MemoryAddress.GPRSDATAPACK_ADDRESS, 253952);
        // this.set(MemoryAddress.GPRSPACKREG_ADDRESS, 253952);
        // this.set(MemoryAddress.GPRSPACKREGRESP_ADDRESS, 254016);
        // this.set(MemoryAddress.GPRSPACKDISCONN_ADDRESS, 254080);
        // this.set(MemoryAddress.GPRSPACKHEART_ADDRESS, 254144);
        // this.set(MemoryAddress.GPRSPACKHEARTRESP_ADDRESS, 254208);
    }


}
