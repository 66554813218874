import {
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonMenu,
    IonPage,
    IonRouterOutlet,
    IonSplitPane,
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import {Redirect, Route, RouteComponentProps, withRouter} from "react-router-dom";
import {apps, pencil} from "ionicons/icons";
import React from "react";
import {inject} from "mobx-react";
import {Store} from "../service/Store";
import {SimpleDevice} from "../service/API";
import HMIView from "./HMIView";
import HMIDesigner from "./HMIDesigner";

export interface HMISplitPaneProps extends RouteComponentProps<{id: string}>{
    store?:Store,
}
export interface HMISplitPaneState {
    devices?:SimpleDevice[]
}
@inject("store", "lang")
class HMISplitPane extends React.Component<HMISplitPaneProps, HMISplitPaneState> {

    state : HMISplitPaneState = {};

    componentDidMount(): void {
        this.props.store?.api.getDevices().then(value => {
            this.setState({devices: value.data.devices})

        })

    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return <IonSplitPane contentId="menuContent">
            {/*--  our side menu  --*/}
            <IonMenu contentId="menuContent" >
                <IonHeader>
                    <IonToolbar color={"primary"}>
                        <IonTitle>Controles</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonList>

                        <IonItem routerLink={"/"}>
                            <IonLabel>Dashboard</IonLabel>
                        </IonItem>
                        <IonItem routerLink={"/help"}>
                            <IonLabel>Ayuda</IonLabel>
                        </IonItem>
                    </IonList>
                </IonContent>
            </IonMenu>

            {/*-- the main content --*/}
            <IonPage id="menuContent">
                <IonTabs>
                    <IonRouterOutlet>
                        <Route path={"/hmi"} exact>
                            <Redirect to={"/hmi/visualize"} />
                        </Route>
                        <Route path="/hmi/visualize" component={HMIView} exact/>
                        <Route path="/hmi/designer" component={HMIDesigner} exact/>


                    </IonRouterOutlet>
                    <IonTabBar slot={"bottom"}>
                        <IonTabButton tab="visualize" href={"/hmi/visualize"}>
                            <IonIcon icon={apps}/>
                            <IonLabel>Visualizacion</IonLabel>
                        </IonTabButton>
                        <IonTabButton tab="designer" href={"/hmi/designer"}>
                            <IonIcon icon={pencil}/>
                            <IonLabel>Diseño</IonLabel>
                        </IonTabButton>
                    </IonTabBar>
                </IonTabs>
            </IonPage>


        </IonSplitPane>
    }
}

export default withRouter(HMISplitPane);
