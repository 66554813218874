import React, { RefObject } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { IonApp, IonButton, IonContent, IonHeader, IonPage, IonText, IonTitle, IonToast, IonToolbar, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import './theme/ITC AVANT GARDE GOTHIC/stylesheet.css';
import './theme/variables.css';
import Login from "./pages/Login";
import MainSplitPane from "./pages/MainSplitPane";
import PrivateRoute from "./components/PrivateRoute";
import { inject, observer } from "mobx-react";
import { Store } from "./service/Store";
import "./App.css"
import UserRegister from "./pages/UserRegister";
import * as H from 'history';
import ValidateAccount from "./pages/ValidateAccount";
import UserRecovery from "./pages/UserRecovery";
import Legal from "./pages/Legal";
import DownloadApp from "./pages/DownloadApp";
import Updater from "./components/Updater";
import Help from "./pages/Help";
import LinkShorten from "./pages/LinkShorten";
import DeviceSplitPane from "./pages/DeviceSplitPane";
import Lang from "./service/Lang";
import Licences from "./pages/Licences";
import HMISplitPane from "./pages/HMISplitPane";
import Utils from "./service/Utils";
import { App as CapApp } from '@capacitor/app'
import { Capacitor } from '@capacitor/core';
import Region from './pages/Region';
import Theme from './theme';


@withRouter
export class DebugRouter extends React.Component<{ history?: H.History } & any, any> {

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        Utils.log("history", this.props.history)
        return this.props.children;
    }
}

export interface AppProps {
    store?: Store,
    lang?: Lang,
    theme?: Theme,
}

setupIonicReact();
@observer
class App extends React.Component<AppProps, any> {

    router: RefObject<IonReactRouter>;
    state = {
        error: false,
        update: false,
        navigate: '',
        notification: false,
        notificationTitle: '',
        notificationUrl: '',
        cookiesMessage: false,
        coreError: false,
        
    }


    constructor(props: AppProps, context: any) {
        super(props, context);
        this.router = React.createRef();
        // GoogleAnalytics.startTrackerWithId("G-Z615N0YVV4").then(() => {

        // });

    }

    renderLogin() {

    }

    handleReset = () => {
        document.location.href = 'index.html';
    }

    renderError() {
        /*

        return <Grid container spacing={3} direction="column"
                     justify="center"
                     alignItems="center" styles={"height: 100%"}>
            <Grid item xs={12}>
                <Typography variant={"title"}>Opps... Algo no ha salido como esperabamos</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography>El error ha sido reportado a nuestro equipo. Lamentamos las molestias.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Button variant={"raised"} onClick={this.reload}>Reiniciar Aplicación</Button>
            </Grid>
        </Grid>*/

        return <IonApp>
            <IonPage>
                <IonHeader>
                    <IonToolbar color='primary'>
                        <IonTitle>Ha ocurrido un error</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen class="ion-padding">
                    <IonText>
                        <h2>Opps... Algo no ha salido como esperabamos</h2>
                    </IonText>
                    <img alt='Error' style={{maxWidth: '300px'}} src={this.props.store?.api.url + "img/error.jpg"} />
                    <IonText>
                        <h4>El error ha sido reportado a nuestro equipo. Lamentamos las molestias.</h4>
                    </IonText>

                    <IonButton type='button' size='large' href='.'>Recargar Aplicación</IonButton>
                    <IonButton type='button' size='large' onClick={this.handleReset}>Reiniciar Aplicación</IonButton>
                </IonContent>
            </IonPage>
        </IonApp>
    }


    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {

    }

    componentDidCatch(error: any, info: any) {
        // Display fallback UI
        this.setState({ coreError: true });
        this.props.store?.api.reportError({message: error.message, stack: error.stack, info: info});
        console.log(error, info);
    }

    logout() {
        let store: Store = this.props.store!;
        store.logout();
        return <Redirect to={"/login"} />
    }

    handleOnError = (error: any) => {
        if (error.error && error.error.code) {
            let errors: any = this.props.lang!.l.errors as any;
            let message = errors[error.error.code];
            if (message) {
                message = message();
            }
            this.setState({ error: (message || error.message) });
        } else {

            this.setState({ error: (error.message) });
        }

        // GoogleAnalytics.trackException(message || error.message, false);
    };

    handleOnLoginRequired = () => {
        this.props.store!.logout();
        document.location.href = "/login";
    };

    handleAllowCookies = () => {
        this.props.store?.setItem('allowCookies', true);
        this.setState({ cookiesMessage: false });
    }

    componentDidMount(): void {
        let store: Store = this.props.store!;

        store.router = this.router.current!;
        store.api.setOnError(this.handleOnError);
        store.api.setOnLoginRequired(this.handleOnLoginRequired);
        // var currentHistoryChange = this.router.current!.handleHistoryChange;
        // this.router.current!.handleHistoryChange((location, action) => {
        //     // GoogleAnalytics.trackView(location.pathname);

        // })
        Utils.log("Ready");
        // store.api.version().then(value => {
        //     if(value.success) {
        //         let version = Utils.getVersion();
        //         if(version !== value.data.version) {
        //             if(store.getSessionItem("version") !== value.data.version) {
        //                 store.setSessionItem("version", value.data.version);
        //                 Utils.update();
        //             }
        //         }
        //     }
        // });
        store.events.on('notification', data => {
            this.setState({ notification: data.body, notificationTitle: data.title, notificationUrl: data.link });
        })
        store.events.on('alert', data => {
            alert(JSON.stringify(data, null, 4));
        })

        CapApp.addListener('appUrlOpen', (data: any) => {
            // Example url: https://beerswift.app/tabs/tab2
            // slug = /tabs/tab2
            alert("URL: " + data.url);
            const slug = data.url.split(".com").pop();
            if (slug) {
                // this.setState({navigate: slug});
                this.router.current?.history.push(slug);
            }
            // If no match, do nothing - let regular routing
            // logic take over
        });

        if (store.isDarkMode()) {
            this.setState({ darkMode: true });
        }
        if (!store.getItem("allowCookies") && Capacitor.getPlatform() === 'web') {
            this.setState({ cookiesMessage: true });
        }

    }
    static getRedirect(store: Store) {
        switch (store.getItem('home')) {
            case 'devices': return <Redirect to={"/devices"} />
            case 'map': return <Redirect to={"/map"} />
        }
        return <Redirect to={"/dashboard"} />
    }

    renderRedirect = () => {
        return App.getRedirect(this.props.store!);
    }

    renderMain() {
        return <IonApp>
            <IonReactRouter ref={this.router}>
                {/*{this.state.navigate && <Redirect to={this.state.navigate} push={true} />}*/}
                <Switch>

                    <Route path={"/"} exact>
                        {App.getRedirect(this.props.store!)}
                    </Route>
                    <Route path="/region" exact>
                        <Region />
                    </Route>
                    <Route path="/login" exact>
                        <Login />
                    </Route>
                    <Route path="/register" exact>
                        <UserRegister />
                    </Route>
                    <Route path={["/users/validate/:token", "/validate/:token?"]} exact>
                        <ValidateAccount />
                    </Route>
                    <Route path="/recovery/:token?">
                        <UserRecovery />
                    </Route>
                    <Route path="/s/:token">
                        <LinkShorten />
                    </Route>
                    <Route path="/legal">
                        <Legal />
                    </Route>
                    <Route path="/download">
                        <DownloadApp />
                    </Route>
                    <Route path="/help/:topic?">
                        <Help />
                    </Route>
                    <Route path="/licenses">
                        <Licences />
                    </Route>
                    {/* <Route path="/modbus">
                        <ModbusExplorer />
                    </Route> */}
                    <Route path="/logout" render={() => this.logout()} />
                    
                    <Route path={"/device/:id/:tab"}>
                        <PrivateRoute>
                            <DeviceSplitPane />
                        </PrivateRoute>
                    </Route>
                    <Route path={["/dashboard", "/add_device", "/devices", "/invitation/:token", "/account/invoices", "/account", "/settings", "/usb/:port?", "/modbusApp", "/map", "/test"]}>
                        <PrivateRoute>
                            <MainSplitPane />
                        </PrivateRoute>
                    </Route>
                    <Route path={["/hmi"]}>
                        <PrivateRoute>
                            <HMISplitPane />
                        </PrivateRoute>
                    </Route>
                    <Route path={["/"]}>
                        {App.getRedirect(this.props.store!)}
                    </Route>

                </Switch>
            </IonReactRouter>
            <IonToast
                htmlAttributes={{ tabindex: undefined }}
                isOpen={!!this.state.error}
                onDidDismiss={() => this.setState({ error: false })}
                message={String(this.state.error)}
                duration={5000}
                position="top"
                buttons={[
                    {
                        text: 'Ok',
                        role: 'cancel'
                    }
                ]}
            />
            <IonToast
                htmlAttributes={{ tabindex: undefined }}
                isOpen={!!this.state.notification}
                onDidDismiss={() => this.setState({ notification: false })}
                message={String(this.state.notification)}
                duration={5000}
                header={this.state.notificationTitle}
                buttons={[
                    {
                        side: 'start',
                        text: 'Ver',
                        handler: () => {
                            this.router.current?.history.push({ pathname: Utils.clearUrl(this.state.notificationUrl) })
                        }
                    },
                    {
                        text: 'OK',
                        role: 'cancel',
                        handler: () => {
                        }
                    }
                ]}
                position="bottom"
            />
            <IonToast
                translucent={true}
                htmlAttributes={{ tabindex: undefined }}
                isOpen={!!this.state.cookiesMessage}
                onDidDismiss={() => this.setState({ cookiesMessage: false })}
                message={"Usamos cookies y otras técnicas de rastreo para mejorar tu experiencia de navegación en nuestra web, para mostrarte contenidos personalizados, para analizar el tráfico en nuestra web y para comprender de donde llegan nuestros visitantes."}
                header={"Utilizamos cookies"}
                buttons={[
                    {
                        side: 'start',
                        text: 'Configurar',
                        handler: () => {
                            // this.router.current?.history.push({pathname: Utils.clearUrl(this.state.notificationUrl)})
                        }
                    },
                    {
                        text: 'OK',
                        role: 'cancel',
                        handler: this.handleAllowCookies,
                    }
                ]}
                position="bottom"
            />

            <Updater />
        </IonApp>
    }
    renderBox() {

        if (this.state.coreError) {
            return this.renderError();
        }
        return this.renderMain();
    }
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {

        // setupIonicReact({
        //     backButtonText: 'Atrás',
        //     animated: true,
        // });
        return <div className={"theme-" + this.props.theme!.name}>
            {this.renderBox()}
        </div>

    }

}

export default inject("store", "lang", "theme")(App);
