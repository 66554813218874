import React from "react";
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCheckbox,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonPage,
    IonText,
    IonTextarea,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import { inject } from "mobx-react";
import { Store } from "../../service/Store";
import { APIBase, Device } from "../../service/API";
import { RouteComponentProps } from "react-router-dom";
import { cloudDownloadOutline, cloudUploadOutline, folderOpenOutline, saveOutline } from "ionicons/icons";
import Utils from "../../service/Utils";

export interface DeviceSettingsHardwareBackupProps extends RouteComponentProps<{ id: string }> {
    store?: Store,
}
export type DeviceSettingsHardwareBackupState = {
    loading?: boolean,
    loadingProgress?: boolean,
    progress?: { max: number, value: number, message: string },
    device?: Device,
    error: boolean | string,
    backupData: string,
    selection: string[],
}

type Task = {
    title: string;
    key: string;
    backupMessage: string;
    backupTask: (id: number) => Promise<any>;
    restoreMessage: string;
    restoreTask: (id: number, object: any) => Promise<APIBase<any>>;
};

@inject("store", "lang")
export default class DeviceSettingsHardwareBackup extends React.Component<DeviceSettingsHardwareBackupProps, DeviceSettingsHardwareBackupState> {

    state: DeviceSettingsHardwareBackupState = {
        loading: false,
        loadingProgress: false,
        progress: { max: 0, value: 0, message: '' },
        error: false,
        backupData: '',
        selection: [],
    }
    tasks: Task[] = [
        {
            title: 'Reles',
            key: 'relays',
            backupMessage: 'Cargando Configuración Relés...',
            backupTask: async (id: number) => {
                return (await this.props.store!.api.getHardwareRelay(id)).data.relays;
            },
            restoreMessage: 'Descargando Configuración de Relés...',
            restoreTask: async (id: number, object: any) => {
                return await this.props.store!.api.setHardwareRelay(id, object);
            }
        },

        {
            title: 'Entradas Analógicas',
            key: 'ain',
            backupMessage: 'Cargando Configuración Entradas analógicas...',
            backupTask: async (id: number) => {
                return (await this.props.store!.api.getHardwareAIN(id)).data.ains;
            },
            restoreMessage: 'Descargando Configuración de Entradas analógicas...',
            restoreTask: async (id: number, object: any) => {
                return await this.props.store!.api.setHardwareAIN(id, object);
            }
        },

        {
            title: 'Temperatura y Humedad',
            key: 'temp',
            backupMessage: 'Cargando Configuración Temperatura y Humedad...',
            backupTask: async (id: number) => {
                return (await this.props.store!.api.getHardwareTemp(id)).data.tempHum;
            },
            restoreMessage: 'Descargando Configuración de Temperatura y Humedad...',
            restoreTask: async (id: number, object: any) => {
                return await this.props.store!.api.setHardwareTemp(id, object);
            }
        },

        {
            title: 'Entradas Digitales',
            key: 'din',
            backupMessage: 'Cargando Configuración de Entradas Digitales...',
            backupTask: async (id: number) => {
                return (await this.props.store!.api.getHardwareDIN(id)).data.dins;
            },
            restoreMessage: 'Descargando Configuración de Entradas Digitales...',
            restoreTask: async (id: number, object: any) => {
                return await this.props.store!.api.setHardwareDIN(id, object);
            }
        },

        {
            title: 'Temporizador Hardware',
            key: 'timer',
            backupMessage: 'Cargando Temporizador Hardware...',
            backupTask: async (id: number) => {
                return (await this.props.store!.api.getHardwareTimer(id)).data.events;
            },
            restoreMessage: 'Descargando Temporizador Hardware...',
            restoreTask: async (id: number, object: any) => {
                return await this.props.store!.api.setHardwareTimer(id, object);
            }
        },

        {
            title: 'Conmutador de Eventos',
            key: 'events',
            backupMessage: 'Cargando Conmutador de Eventos...',
            backupTask: async (id: number) => {
                return (await this.props.store!.api.getHardwareEvents(id)).data.events;
            },
            restoreMessage: 'Descargando Temporizador Hardware...',
            restoreTask: async (id: number, object: any) => {
                return await this.props.store!.api.setHardwareEvents(id, object);
            }
        },

        {
            title: 'Modbus Hardware',
            key: 'modbus',
            backupMessage: 'Cargando Modbus...',
            backupTask: async (id: number) => {
                return (await this.props.store!.api.getHardwareModbus(id)).data;
            },
            restoreMessage: 'Descargando Modbus...',
            restoreTask: async (id: number, object: any) => {
                return await this.props.store!.api.setHardwareModbus(id, object.modbus, object.baudRate, object.portConfig);
            }
        }
    ];
    componentDidMount(): void {
        this.handleSelectAll(null);
        this.loadDevice();
    }

    loadDevice() {
        this.setState({ loading: true });
        let id = this.props.match?.params.id!;

        this.props.store?.api.deviceGet(Number(id)).then(value => {
            if (value.success) {
                this.setState({ device: value.data })
                // for (let i = 0; i < 4; i++) {
                //     this.ACTIONS[9 + i] = value.data.outputs[i].name + " ON"
                //     this.ACTIONS[13 + i] = value.data.outputs[i].name + " OFF"
                // }
            }
            this.setState({ loading: false });

            // this.props.store?.api.getHardwareTimer(Number(id)).then(value => {
            //     if(value.success) {
            //         this.setState({events: value.data.events})
            //     } else {
            //         this.setState({error: 'El dispositivo no está conectado'});
            //     }
            // }).finally(() => this.setState({loading: false}));
        });

    }

    componentDidUpdate(prevProps: Readonly<DeviceSettingsHardwareBackupProps>, prevState: Readonly<DeviceSettingsHardwareBackupState>, snapshot?: any): void {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.loadDevice();
        }
    }

    getBackupTasks() {
        let result = [];
        for (let index = 0; index < this.tasks.length; index++) {
            const element = this.tasks[index];
            let i = this.state.selection.indexOf(element.key);
            if (i === -1) {
                continue;
            }
            result.push(element);
        }
        return result;
    }

    handleBackup = async (ev: any) => {
        let id = this.props.match?.params.id!;
        let tasks = this.getBackupTasks();
        this.setState({
            error: false,
            loadingProgress: true,
            progress: { max: tasks.length, value: 0, message: 'Iniciando backup...' },
        })

        try {
            let result: any = {};
            for (let index = 0; index < tasks.length; index++) {
                const element = tasks[index];
                this.setState({
                    loadingProgress: true,
                    progress: { max: tasks.length, value: (index + 1), message: element.backupMessage },
                })
                let data = await element.backupTask(Number(id));
                result[element.key] = data;
                this.setState({ backupData: JSON.stringify(result, null, 4) });
            }
            this.setState({ loadingProgress: false })
            alert("Copia de seguridad completada");
        } catch (ex: any) {
            alert("Error: " + ex.toString())
            this.setState({ loadingProgress: false, error: ex.toString() })

        }
    }

    handleDownload = async (ev: any) => {
        let id = this.props.match?.params.id!;
        let tasks = this.getBackupTasks();
        this.setState({
            error: false,
            loadingProgress: true,
            progress: { max: tasks.length, value: 0, message: 'Iniciando descarga...' },
        });
        let json: any = {};
        try {
            json = JSON.parse(this.state.backupData);

        } catch(ex:any) {
            this.setState({ loadingProgress: false, error: "Error en el formato JSON introducido" });
            return;
        }

        try {
            // let result: any = {};
            for (let index = 0; index < tasks.length; index++) {
                const element = tasks[index];
                this.setState({
                    loadingProgress: true,
                    progress: { max: tasks.length, value: (index + 1), message: element.backupMessage },
                });
                if(!json[element.key]) {

                    this.setState({ loadingProgress: false, error: "No existe el elemento: " + element.title + " (" + element.key + ") en los datos del backup" });
                    return;
                }
                let data = await element.restoreTask(Number(id), json[element.key]);
                if(!data.success) {
                    this.setState({ loadingProgress: false, error: "Error al descargar el elemento " + element.title + " (" + element.key + "). El servidor ha respondido: " + data.error });

                    return;
                }
            }
            this.setState({ loadingProgress: false });
            alert("Copia de seguridad restablecida");
        } catch (ex: any) {
            alert("Error: " + ex.toString())
            this.setState({ loadingProgress: false, error: ex.toString() })

        }
    }

    handleCheckChange = (task: Task) => (ev: any) => {
        let selection = this.state.selection;
        if (ev.detail.checked) {
            let i = selection.indexOf(task.key);
            if (i !== -1) {
                return;
            }
            selection.push(task.key);
        } else {
            let i = selection.indexOf(task.key);
            if (i === -1) {
                return;
            }
            selection.splice(i, 1);
        }
        this.setState({ selection })
    }

    handleSelectAll = (ev: any) => {
        for (let index = 0; index < this.tasks.length; index++) {
            const element = this.tasks[index];
            let i = this.state.selection.indexOf(element.key);
            if (i !== -1) {
                continue;
            }
            this.state.selection.push(element.key);
        }
        this.setState({});
    }
    handleUncheckAll = (ev: any) => {
        for (let index = 0; index < this.tasks.length; index++) {
            const element = this.tasks[index];
            let i = this.state.selection.indexOf(element.key);
            if (i === -1) {
                continue;
            }
            this.state.selection.splice(i, 1);
        }
        this.setState({});
    }

    handleOpenFile = () => {
        Utils.uploadFile("*.json").then(res => {
            this.setState({backupData: res.data});
        })
    }
    handleSaveFile = () => {
        Utils.downloadFile(this.state.backupData, "CloudRTU_backup_" + this.state.device?.name + ".json", "text/json").then(res => {
            
        })
    }



    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let id = this.props.match?.params.id!;

        if (!this.state) return "";
        return <IonPage>

            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={"/device/" + id + "/settings"} />
                    </IonButtons>
                    <IonTitle>Crear Backup del dispositivo</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    {this.state.error && <IonItem color={"danger"}>
                        <IonText>{this.state.error}</IonText>
                    </IonItem>}

                    <IonItem>
                        <IonLabel>Seleccionar Elementos</IonLabel>
                        <IonButton slot="end" onClick={this.handleSelectAll}>Todo</IonButton>
                        <IonButton slot="end" onClick={this.handleUncheckAll}>Nada</IonButton>
                    </IonItem>
                    {this.tasks.map((value, index) => {
                        return <IonItem key={index}>
                            <IonCheckbox onIonChange={this.handleCheckChange(value)} checked={this.state.selection.indexOf(value.key) !== -1}>{value.title}</IonCheckbox>
                        </IonItem>
                    })}
                    <IonItem button onClick={this.handleBackup}>
                        <IonIcon icon={cloudUploadOutline} slot="start" /> Cargar configuración desde el dispositivo
                    </IonItem>
                    <IonItem button onClick={this.handleDownload} disabled={!this.state.backupData}>
                        <IonIcon icon={cloudDownloadOutline} slot="start" /> Descargar configuración hacia el dispositivo
                    </IonItem>
                    <IonItem>
                        <IonTextarea onIonChange={(ev:any) => this.setState({backupData: ev.detail.value})} rows={8} style={{ fontFamily: 'monospace' }} value={this.state.backupData}></IonTextarea>
                    </IonItem>
                    <IonItem>
                        <IonButton onClick={this.handleOpenFile}><IonIcon icon={folderOpenOutline} /> Abrir</IonButton>
                        <IonButton onClick={this.handleSaveFile}><IonIcon icon={saveOutline} /> Guardar</IonButton>
                    </IonItem>

                </IonList>
                <IonLoading
                    isOpen={this.state.loading!}
                    message={'Cargando...'}
                    duration={5000}
                />
                <IonLoading
                    isOpen={this.state.loadingProgress!}
                    message={this.state.progress?.message + " " + this.state.progress?.value + " de " + this.state.progress?.max}
                    
                />
            </IonContent>

        </IonPage>
    }

}
