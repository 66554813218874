import React, { createRef, RefObject } from "react";
import {
    IonButton,
    IonButtons,
    IonContent,
    IonFab,
    IonFabButton,
    IonHeader,
    IonIcon,
    IonLabel,
    IonLoading,
    IonPage,
    IonSegment,
    IonSegmentButton,
    IonTitle,
    IonToolbar,
    IonItem
} from "@ionic/react";
import {
    add,
    arrowBack,
    calendarOutline,
    callOutline,
    notifications,
    notificationsOff,
    reload,
    thermometerOutline
} from "ionicons/icons";
import { inject, observer } from "mobx-react";
import { Store } from "../../service/Store";
import { APIBase, Device, DeviceInputGroup, DeviceOutput } from "../../service/API";
import DeviceOutputs from "../../components/DeviceOutputs";
import DeviceSchedulers from "../../components/DeviceSchedulers";
import DeviceAutomation from "../../components/DeviceAutomation";
import EventBox from "../../service/EventBox";
import Payment from "../../components/Payment";
import { RouteComponentProps } from "react-router";
import Utils, { DeviceFlags } from "../../service/Utils";
import Lang from "../../service/Lang";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class'

// import 'swiper/swiper.min.css';
// import '@ionic/react/css/ionic-swiper.css';


// import 'swiper/css'

// import 'swiper/css';
// import 'swiper/swiper.scss';
// import 'swiper/css';
// import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';
// import 'swiper/css/zoom';
// import '@ionic/react/css/ionic-swiper.css';
export interface DeviceDetailsProps extends RouteComponentProps<{ id: string }> {
    store?: Store;
    lang?: Lang;
    routerOutlet: HTMLIonRouterOutletElement,
}

export interface DeviceDetailsState {
    device?: Device,
    segment?: string,
    loading?: boolean
}

@inject("store", "lang")
@observer
export default class DeviceDetails extends React.Component<DeviceDetailsProps, DeviceDetailsState> {
    // slider: RefObject<any>;
    swiper?: SwiperClass;
    segment: RefObject<HTMLIonSegmentElement>;
    automation: RefObject<DeviceAutomation>;
    scheduler: RefObject<DeviceSchedulers>;
    events?: EventBox;
    state: DeviceDetailsState = {};

    constructor(props: any, context: any) {
        super(props, context);
        // this.slider = createRef();
        this.segment = createRef();
        this.automation = createRef();
        this.scheduler = createRef();

        this.state = { loading: false, segment: 'control' };
    }

    setSwiperInstance(swiper: SwiperClass) {
        this.swiper = swiper;
    }


    componentDidMount(): void {
        let id = parseInt(this.props.match.params.id);
        this.loadDevice(id);
        this.handleTab();
    }

    componentDidUpdate(prevProps: Readonly<DeviceDetailsProps>, prevState: Readonly<DeviceDetailsState>, snapshot?: any): void {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.loadDevice(parseInt(this.props.match.params.id));
        }

        if (this.props.location.search !== prevProps.location.search) {
            this.handleTab();
        }
    }
    handleTab() {
        let tab = this.props.location.search;
        let i = tab.indexOf("tab=");
        if (i >= 0) {
            tab = tab.substring(i + 4);
            this.setSegment(tab);

        }
    }

    componentWillUnmount(): void {
        this.unloadDevice();
    }

    unloadDevice() {
        let store: Store = this.props.store!;
        store.unsubscribeDevice();
        this.events?.off();
    }
    loadDevice(id: number) {
        this.unloadDevice();
        this.setState({ loading: true });
        this.updateDevice(id, 'all');
        this.events = new EventBox(this.props.store?.events!);
        this.events.on('online', (id: number) => {
            if (this.state.device && this.state.device?.id === id) {
                this.setState({ device: { ...this.state.device, online: true } });
            }
        })
        this.events.on('offline', (id: number) => {
            if (this.state.device && this.state.device?.id === id) {
                this.setState({ device: { ...this.state.device, online: false } });
            }
        })
        this.events.on('update', async () => {
            setTimeout(() => {
                this.updateDevice(id, 'all');
            }, 1000);
            
        })
        this.events.on('reconnect', () => {
            this.updateDevice(id, 'all');
        })
        this.events.on('inputs', (id: any, inputs: DeviceInputGroup[]) => {
            if (this.state.device && this.state.device?.id === id) {
                Utils.log("inputs", inputs);
                this.setState({ device: this.updateInputGroups(this.state.device, inputs) })
                // this.setState({device: {...this.state.device!, inputGroups: inputs}});
                // this.updateDevice('all');
            }

        });

        let store: Store = this.props.store!;
        store.subscribeToDevice(id);
    }

    updateInputGroups(device: Device, inputsGroups: DeviceInputGroup[]) {
        for (let i = 0; i < inputsGroups.length; i++) {
            let found = false;
            for (let j = 0; j < device.inputGroups.length; j++) {
                if (inputsGroups[i].id === device.inputGroups[j].id) {
                    found = true;
                    device.inputGroups[j] = inputsGroups[i];
                    break;
                }
            }
            if (!found) {
                device.inputGroups.push(inputsGroups[i]);
            }
        }

        return device;
    }
    
    updateDevice(id: number, section: string) {

        return this.props.store!.api.deviceGet(id, section).then((value: APIBase<Device>) => {
            this.setState({ device: value.data, loading: false });
        });
    }

    handleSegment = (ev: any) => {
        this.setState({ segment: ev.detail.value });
        this.props.history.replace("?tab=" + ev.detail.value);
    };

    setSegment(p: string) {

        switch (p) {
            case 'control':
                // this.slider.current?.slideTo(0);
                this.swiper!.slideTo(0);
                break;
            case 'scheduler':
                // this.slider.current?.slideTo(1);
                this.swiper!.slideTo(1);
                break;
            case 'programmer':
                // this.slider.current?.slideTo(2);
                this.swiper!.slideTo(2);
                break;
        }
        this.setState({segment: p});
    }

    handleSlide = async (ev: any) => {
        let res = this.swiper!.activeIndex;
        // alert(res);
        switch (res) {
            case 0:
                this.setState({ segment: 'control' });
                break;
            case 1:
                this.setState({ segment: 'scheduler' });
                break;
            case 2:
                this.setState({ segment: 'programmer' });
                break;
        }
        this.props.history.replace("?tab=" + this.state.segment);
    };
    handleClick = (id: number) => (ev: any) => {
        // this.props.store.test(id);
    };

    handleOutputClick = (output: DeviceOutput, state: boolean) => {
        let id = (this.state.device?.id!);
        return this.props.store?.api.deviceOutputState(id, output.oindex, 0, state ? 1 : 0).then((value: APIBase<{}>) => {
            if (value.success) {
                // this.updateDevice('output');
            }
        });
    }

    handleTitleClick = (ev: any) => {

    }

    handleReload = (ev: any) => {
        let id = parseInt(this.props.match.params.id);
        this.loadDevice(id);
    }

    renderDevice() {
        const { lang } = this.props;
        return <>

            <IonHeader color={"secondary"}>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">
                        <IonButton routerDirection={"back"} routerLink="/" ><IonIcon icon={arrowBack} /></IonButton>
                    </IonButtons>
                    <IonTitle onClick={this.handleTitleClick}>{this.state.device?.name} <div className={"connection-status " + (this.state.device?.online ? "connection-status-online" : "connection-status-offline")} /></IonTitle>

                    <IonButtons slot="end">
                        <IonButton onClick={this.handleReload}><IonIcon icon={reload} /></IonButton>
                        <IonButton routerLink={"settings/notifications"}><IonIcon icon={this.state.device && Utils.haveFlag(this.state.device.flags, DeviceFlags.NOTIFY_EVENTS) ? notifications: notificationsOff} /></IonButton>

                        
                    </IonButtons>
                </IonToolbar>
                <IonToolbar color={"primary"}>
                    <IonSegment value={this.state.segment} onIonChange={this.handleSegment} ref={this.segment}>
                        <IonSegmentButton value="control">
                            <IonIcon icon={callOutline} />
                            <IonLabel>{lang?.l.main.tab_control()}</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="scheduler">
                            <IonIcon icon={calendarOutline}  color={this.state.device && this.state.device.enableScheduler ? "": "danger"} />
                            <IonLabel color={this.state.device && this.state.device.enableScheduler ? "" : "danger"}>{lang?.l.main.tab_scheduler()}</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="programmer">
                            <IonIcon icon={thermometerOutline} />
                            <IonLabel>{lang?.l.main.tab_automation()}</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>
                </IonToolbar>
            </IonHeader>
            <IonContent scrollEvents={true}>
                {/* <IonSlides pager={false} options={{
                    initialSlide: 0,
                    noSwiping: true,
                    speed: 400
                }} ref={this.slider} onIonSlideDidChange={this.handleSlide}>
                    <IonSlide> */}
                <Swiper onSwiper={(swiper: SwiperClass) => this.setSwiperInstance(swiper)}
                    onSlideChangeTransitionEnd={this.handleSlide}>

                    <SwiperSlide>
                        <div className={"slide"} style={{ width: '100%' }}>
                            {this.state.device && <DeviceOutputs  {...this.props} device={this.state.device} />}
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={"slide"} style={{ width: '100%' }}>
                            {this.state.device && <DeviceSchedulers routerOutlet={this.props.routerOutlet} ref={this.scheduler} device={this.state.device} />}
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={"slide"} style={{ width: '100%' }}>
                            {this.state.device && <DeviceAutomation routerOutlet={this.props.routerOutlet} ref={this.automation} device={this.state.device} />}
                        </div>
                    </SwiperSlide>
                </Swiper>

                <IonFab horizontal={"end"}
                    className={"fab-custom " + (this.state.segment === "control" ? "fab-custom-hidden" : "")}
                    vertical={"bottom"} slot="fixed">
                    <IonFabButton onClick={() => {
                        this.state.segment === "programmer" ? this.automation.current!.addAutomation() : this.scheduler.current!.addScheduler()
                    }}>
                        <IonIcon icon={add} />
                    </IonFabButton>
                </IonFab>
                {this.state.device && <Payment routerOutlet={this.props.routerOutlet} device={this.state.device!} />}

                {!this.state.device && !this.state.loading && this.renderError()}
            </IonContent>
        </>
    }

    renderLoading() {
        const { lang } = this.props;
        return <IonLoading
            isOpen={this.state.loading!}
            message={lang?.l.main.loading_device()}
        />
    }

    renderError() {
        const { lang } = this.props;
        return <IonItem>
            <IonLabel>{lang?.l.main.error_loading_device()}</IonLabel>
            <IonButton onClick={this.handleReload}>{lang?.l.main.reload()}</IonButton>
        </IonItem>
    }

    renderSubscribe() {
        const { lang } = this.props;
        return <IonItem>
            <IonLabel>Subscribirse</IonLabel>
            <IonButton onClick={this.handleReload}>{lang?.l.main.reload()}</IonButton>
        </IonItem>
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        // const {id} = this.props.match.params;
        return <IonPage>
            {this.renderLoading()}
            {this.renderDevice()}
            {this.state.device && !this.state.device.id && this.renderError()}
            {/* {this.renderSubscribe()} */}
        </IonPage>

    }
}
