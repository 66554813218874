import UserRequest from "./UserRequest";
import {ModbusRequest} from "./RequestHandler";
import ModbusClient from "./ModbusClient";
import {ModbusResponse} from "./ResponseHandler";
import ExceptionResponseBody from "./response/ExceptionResponseBody";

const OUT_OF_SYNC = 'OutOfSync'
//const OFFLINE = 'Offline'
const MODBUS_EXCEPTION = 'ModbusException'

export interface RTUError {
    err: string,
    message?: string,
    response?: ModbusResponse,

}

export default abstract class ModbusClientRequestHandler {

    private timeout : number = 5000;
    private client:ModbusClient;

    private requests:UserRequest[] = [];

    private _currentRequest?:UserRequest;

    protected constructor(client: ModbusClient, timeout: number) {
        this.client = client;
        this.timeout = timeout;
    }

    protected register(request:ModbusRequest) {
        const userRequest = new UserRequest(request, this.timeout);
        this.requests.push(userRequest);

        this.flush();

        return userRequest.promise;
    }

    private clearCurrentRequest() {
        if(!this._currentRequest) {
            return;
        }
        this._currentRequest.done();
        this._currentRequest = undefined;
    }

    protected clearAllRequests() {
        this.clearCurrentRequest();

        while (this.requests.length > 0) {
            const req = this.requests.shift();
            req!.reject({
                'err': OUT_OF_SYNC,
                'message': 'rejecting because of earlier OutOfSync error'
            })
        }
    }

    handle(response:ModbusResponse) {
        const userRequest = this._currentRequest;

        if(!userRequest) {
            return;
        }

        const request = userRequest.request;

        if(response.body.fc < 0x80 && response.body.fc !== request.body.fc) {
            userRequest.reject({
                'err': OUT_OF_SYNC,
                'message': 'request fc and response fc does not match.'
                }
            )
            this.clearAllRequests();
            return;
        }

        if(response.body instanceof ExceptionResponseBody) {
            userRequest.reject({
                'err': MODBUS_EXCEPTION,
                'response': response
            })
            this.clearCurrentRequest()
            this.flush()
            return
        }

        userRequest.resolve(response)

        this.clearCurrentRequest()

        /* start next request */
        this.flush()

    }
    private flush() {
        if(this._currentRequest !== undefined) {
            return;
        }

        if(this.requests.length === 0) {
            return;
        }

        this._currentRequest = this.requests.shift();

        const payload = this._currentRequest?.createPayload();

        this._currentRequest?.start(() => {
            this.clearCurrentRequest();
            this.flush();
        });

        this.client.write(payload);
    }


    get currentRequest(): UserRequest {
        return this._currentRequest!;
    }
}
