import React from "react";
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonText,
    IonTitle,
    IonToast,
    IonToolbar
} from "@ionic/react";
import { add } from "ionicons/icons";
import AddPanel from "./AddPanel";
import { Store } from "../service/Store";
import { inject, observer } from "mobx-react";
import { RouteComponentProps, withRouter } from "react-router";
import { Panel } from "../service/API";
import Lang from "../service/Lang";

export interface AddToPanelProps extends RouteComponentProps {
    onClose: (result?: any) => void,
    store?: Store,
    lang?: Lang,
    data: any,
    open: any,
    routerOutlet?: HTMLIonRouterOutletElement,
}
export type AddToPanelState = {
    panels?: Panel[],
    openAdd: boolean
    toast?: string,
}

@inject("store", "lang")
@observer
class AddToPanel extends React.Component<AddToPanelProps, any> {

    state: AddToPanelState = {
        openAdd: false,
        panels: [],
    };
    componentDidMount() {
        this.loadData();
    }

    loadData() {
        let store: Store = this.props.store!;
        store.api.getPanels().then(value => {
            if (value.success) {
                this.setState({ panels: value.data });
            }

        })

    }

    handleDismiss = (ev: any) => {
        this.props.onClose();
        this.setState({ openAdd: false });
    }

    handleAddPanel = (ev: any) => {
        this.setState({ openAdd: true });
    }

    handleSelect = (id: number) => (ev: any) => {
        const { lang } = this.props;
        let store: Store = this.props.store!;
        store.api.putPanelControl(id, this.props.data).then(value => {
            if (value.success) {
                this.props.onClose({ panel: id });
                if (!store.getSessionItem("showDashboard")) {
                    store.setSessionItem("showDashboard", 1)
                    this.props.history.push("/dashboard?panel=" + id);
                } else {
                    this.setState({ toast: lang?.l.main.add_panel_success()! });
                }
            }
        })

    }

    handlePanelAdded = (panel?: Panel) => {
        this.setState({ openAdd: false });
        if (panel) {
            this.handleSelect(panel.id)(null);
        }
    }


    render() {
        const { lang } = this.props;
        return <>
            <IonToast
                htmlAttributes={{ tabindex: undefined }}
                isOpen={!!this.state.toast}
                onDidDismiss={() => this.setState({ toast: undefined })}
                message={this.state.toast}
                duration={2000}
                buttons={[
                    {
                        text: 'Ver',
                        handler: () => {
                            this.props.history.push("/dashboard");

                        }
                    }
                ]}
            />

            <IonModal presentingElement={this.props.routerOutlet} isOpen={this.props.open ? true : false} canDismiss={true}
                onDidDismiss={() => this.props.onClose()}>

                <IonHeader>
                    <IonToolbar>
                        <IonTitle>{lang?.l.main.add_panel_select()}</IonTitle>
                        <IonButtons slot={"secondary"}>
                            <IonButton onClick={() => this.props.onClose()}>{lang?.l.main.cancel()}</IonButton>
                        </IonButtons>
                        {/*<IonButtons slot={"primary"}>*/}
                        {/*    <IonButton onClick={this.handleAdd}><IonSpinner*/}
                        {/*        hidden={!this.state.loading}/> Aceptar</IonButton>*/}
                        {/*</IonButtons>*/}
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen >
                    <IonList>
                        {this.state.panels && this.state.panels.map((e, k) => {
                            return <IonItem button detail={false} onClick={this.handleSelect(e.id)} key={k}>
                                <IonLabel>{e.title}</IonLabel>
                            </IonItem>
                        })}

                        <IonItem button detail onClick={this.handleAddPanel} detailIcon={add}>
                            <IonLabel>{lang?.l.main.add_panel_title()}</IonLabel>
                        </IonItem>

                        <IonItem>
                            <IonText>{lang?.l.main.add_panel_help()}</IonText>

                        </IonItem>

                    </IonList>
                </IonContent>
            </IonModal>
            <AddPanel onClose={this.handlePanelAdded} open={this.state.openAdd} />
        </>
    }
}


export default withRouter(AddToPanel);
