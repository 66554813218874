import { Share } from "@capacitor/share";
import { IonButton, IonButtons, IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonItem, IonLoading, IonModal, IonPage, IonText, IonTitle, IonToolbar } from "@ionic/react";
import { arrowBack, share } from "ionicons/icons";
import { LatLngTuple } from "leaflet";
import { inject } from "mobx-react";
import React from "react";
import { FeatureGroup, Marker, Polyline, Tooltip } from "react-leaflet";
import { RouteComponentProps } from "react-router";
import Maps from "../components/Maps";
import { Route } from "../service/API";
import Lang from "../service/Lang";
import { Store } from "../service/Store";

export type RoutePOIRender = { position: LatLngTuple, title: string, notes: string, attachments: {file: string}[]}
export type RouteRender = {
    id: number,
    positions: LatLngTuple[],
    poi: RoutePOIRender[],
}

export interface MapRouteProps extends RouteComponentProps<{ id: string }> {
    store?: Store,
    lang?: Lang,

    routerOutlet?:HTMLIonRouterOutletElement,
}
export interface MapRouteState {
    loading: boolean,
    route?: RouteRender,
    poiDetails: boolean;
    poiDetailsObject?: RoutePOIRender;
    // map ? : L.Map
}
@inject("store", "lang")
export default class MapRoute extends React.Component<MapRouteProps, MapRouteState> {
    state: MapRouteState = {
        loading: false,
        poiDetails: false,

        route: { id: 0, poi: [], positions: [] },
    }
    map?: L.Map;
    componentDidUpdate(prevProps: Readonly<MapRouteProps>, prevState: Readonly<any>, snapshot?: any): void {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.loadData();
        }
    }
    componentDidMount(): void {
        this.loadData();
    }
    loadData() {
        let id = parseInt(this.props.match.params.id!);
        this.setState({ loading: true });
        return this.props.store?.api.getRoute(id).then(data => {
            if (data.data) {
                this.setRoute(data.data);
            }

        }).finally(() => this.setState({ loading: false }));

    }
    
    setRoute(route: Route) {

        let polyline : any[] = [];
        //this.routeRender.poi = [];
        let poi : any[] = [];
        route.points!.forEach(a => {
            polyline.push([a.lat, a.lng]);
            if (a.poi) {
                a.poi.map(poi1 => {
                    poi.push({
                        position: [a.lat, a.lng],
                        title: poi1.title,
                        notes: poi1.notes,
                        attachments: poi1.attachments,
                    });
                    return poi1;
                });
            }
        });
        
        this.setState({ route: { id: route.id, positions: polyline, poi } }, () => {
            if(this.map) {
                this.map.fitBounds(polyline, {animate: true, duration: 2, easeLinearity: 0.5});
            }
        });

    }
    handlePoiClick = (poi: RoutePOIRender) => (ev: any) => {
        this.setState({ poiDetails: true, poiDetailsObject: poi });
    }

    handleShare = () => {
        let id = this.props.match.params.id;
        Share.share({ dialogTitle: 'Compartir ruta', url: 'https://app.cloudrtu.com/map/route/' + id });
    }
    

    renderTrace(route ? : RouteRender) {

        return <FeatureGroup>
            {route && <><Polyline pathOptions={{ color: 'red' }} positions={route.positions} />
                {route.poi.map((a, k) => {
                    return <Marker key={k} position={a.position}
                        eventHandlers={ {click: this.handlePoiClick(a)} }>
                        <Tooltip permanent={true}>
                            <p>{a.title}</p>                        
                        </Tooltip>
                    </Marker>
                })}
            </>}
        </FeatureGroup>
    }


    render(): React.ReactNode {
        const {lang} = this.props;
        return <IonPage>
            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">
                        <IonButton routerDirection={"back"} routerLink="/map" ><IonIcon icon={arrowBack} /></IonButton>
                    </IonButtons>
                    <IonTitle>Detalles de la ruta</IonTitle>

                </IonToolbar>
            </IonHeader>
                

            <IonLoading isOpen={this.state.loading} message={lang?.l.main.loading()} />
            <Maps mapType="hybrid" onMap={(map) => this.map = map}>
                {this.renderTrace(this.state.route)}

            </Maps>

            <IonModal
                initialBreakpoint={1} breakpoints={[0, 0.25, 0.5, 0.75]}
                presentingElement={this.props.routerOutlet}
                isOpen={this.state.poiDetails! ? true : false} onDidDismiss={() => this.setState({ poiDetails: false })}>

                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Detalles</IonTitle>

                        <IonButtons slot={"secondary"}>
                            <IonButton onClick={() => this.setState({ poiDetails: false })}>{lang?.l.main.close()}</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    {this.state.poiDetailsObject && <>
                        <IonItem>
                            <IonText>{this.state.poiDetailsObject.title}</IonText>
                        </IonItem>
                        <IonItem>
                            <IonText>{this.state.poiDetailsObject.notes}</IonText>
                        </IonItem>
                        {this.state.poiDetailsObject.attachments.map((a, k) => {
                            return <IonItem key={k}>

                                <a target={"_blank"} rel="noreferrer" href={this.props.store?.api.mediaUrl(a.file)}><img alt="Attachment" src={this.props.store?.api.mediaUrl(a.file)} /></a>
                            </IonItem>
                        })}
                    </>}

                </IonContent>
            </IonModal>

            <IonFab vertical="bottom" horizontal="end" slot="fixed">
                <IonFabButton onClick={this.handleShare}>
                    <IonIcon icon={share} />
                </IonFabButton>

            </IonFab>
        </IonPage>
    }

}