import config from "../config";
import Utils from "./Utils";
import { ConditionConnector } from "./ConditionsValues";
import { Format } from "./Formats";

export type User = {
    username: string,
    password?: string,
    email: string,
    fullname: string,
    enabled: boolean,
    privileges: number,
    emailBills: boolean,
}


export type RegisterUser = {
    password1?: string,
    password2?: string,
    username?: string,
    email?: string,
    phone?: string,
    fullname?: string,
    business?: string,
    addressLine?: string,
    tos?: boolean,
    privacy?: boolean,
    subscribe?: boolean,
    googleToken?: string,
}

export type APIBase<T> = {
    data: T,
    success: boolean,
    error?: { code: string },
    statusCode: number,
    message: string,
    cache?: boolean,

}

export type UserAuth = {
    token: string
};

export type UserCreate = {
    token: string
};

export type Model = {
    id: number
    name: string
    image: string
    manufacturer: string
    createdAt?: Date
    updatedAt?: Date
}
export type SimpleDevice = {
    id: number
    user_id: number
    name: string
    password: string
    phoneNumber: string
    imei: string,
    enableScheduler: boolean
    gateway: boolean
    flags: number
    subscription: string
    subscriptionDate: number
    currentSubscription?: number
    subscriptionStatus?: string
    image?: string
    payment?: string
    protocol?: string
    online: boolean
    simulator: boolean
    createdAt?: Date
    updatedAt?: Date
    deletedAt?: Date,

    model: Model

    lat: number
    lng: number
}

export type PaymentMethod = {
    id: string,
    name: string,
    card: {
        brand: string,
        country: string,
        last4: string,
    },
    sepa_debit: any,
    type: 'card' | 'sepa_debit'
}

export type DeviceSuscriptionProduct = {
    contract_id: number
    createdAt: string
    description_internal: string
    duration: number
    id: number
    price: number
    stripe_id: string
    title: string
    trial: number
    updatedAt: string
}
export type DeviceSuscription = {
    active: boolean,
    cancellation_reason: string
    coupon: string
    createdAt: string
    date_end: string
    date_start: string
    device_id: number
    id: number
    payment_code: string
    payment_method: string
    product: DeviceSuscriptionProduct
    product_id: number
    stripe_payment: string
    payment_error: string
    stripe_suscription: string
    trial_duration: number
    updatedAt: string
}

export type DeviceSubscription = {
    startDate: number,
    expirationDate: number,
    trialDate: number,
    firstPayment: number,
    nextPayment?: number,
    paymentDetails?: PaymentMethod,
    details: string,
    subscription: string,
    subscriptions: { stripe_id: string, title: string }[],
    subscriptionStatus: string,
    subscriptionLastPaymentError: string,
    payment: string,
    pending: boolean,
}

export type DeviceOutputValue = {
    title: string,
    value: number,
}


export type DeviceOutputProcess = {
    delay: number,
    exec_output_id?: number,
    exec_output_state?: number,
}
export type DeviceOutput = {

    id: number,
    oindex: number,
    name: string,
    type: 'toggle' | 'toggle2' | 'value' | 'select' | 'fixed' | 'process' | 'panel' | 'input',

    timeout: number,
    sending_log_id: number,
    sending?: LogOutput,
    internal: number,
    modbusWrite?: ModbusType,
    modbusRead?: ModbusType,

    values?: DeviceOutputValue[],
    process?: DeviceOutputProcess[],
    factor?: number,
    fixed_value?: number,
    units: string,
    visible: number,
    on_value: number,
    off_value: number,
    sound: boolean,

    on_name: string,
    off_name: string,

    modbusValues?: ModbusValue[],
    modbusValuesOn?: ModbusValue[],
    modbusValuesOff?: ModbusValue[],

    group_id?: number | null;
    input_id?: number | null,

    // extension?: string | 'lora-2.2',
    // extension_data?: string,
    
    output_service_id?: string,
    outputService ? : {
        id: number,
        device_id: number,
        name: string,
        title: string,
        settings: string,
    }

    iot2operation: number,
    iot2output: number,
}


export type DeviceOutputGroup = {

    id: number,
    name: string,
    visible: boolean,
    expandable: boolean,
    expanded: boolean,


}
export type AutomationConditionToken = {
    token: string
}
export type AutomationCondition = {
    connector: ConditionConnector,
    left: string,
    op: string,
    right: string,
    tokens: AutomationConditionToken[]
}
export type AutomationAction = {
    action: string,
    output_index?: string,
    output_state?: string,
    email_email?: string,
    email_body?: string,
    automations_id?: number,
    input_id?: string,
    input_value?: string,
    notification?: string,
    wait_secs?: string,

}
export type Automation = {
    id: number,
    device_id: number,
    title: string,
    description: string,
    enabled: boolean,
    // action: string,
    mode: string,
    triggered: boolean,
    trigger_time: number,
    trigger_delay: number,
    trigger_rearm: number,
    exec: string,
    exec_last: boolean,
    conditions: AutomationCondition[]
    actions: AutomationAction[]

}
export type SchedulerDate = {
    date: string,
}
export type SchedulerMode = 'time' | 'date' | 'multi-date'
export type Scheduler = {
    id: number,
    device_id: number,
    running: boolean,
    time: string,
    date: string,
    week: string,
    output_id: number,
    state: string,
    transport: 'api',
    mode: SchedulerMode,
    dates: SchedulerDate[],
    timer_repeat: number,
    timer_interval: number,
    id_scheduler_template: number | null,
}
export type SchedulerOutput = {

    id: number,
    oindex: number,
    device_id: number,
    id_scheduler_template: number | null,
    name: string,
    type:  'toggle' | 'toggle2' | 'value' | 'select' | 'fixed' | 'process' | 'extension' | 'input',

    schedulers: Scheduler[],

    values?: DeviceOutputValue[],
    visible?: boolean,
    on_name: string,
    off_name: string,

}

export type SchedulerTemplate = {
    id: number,
    device_id: number,
    title: string,

}
export type DeviceInputGroup = {
    id: number,
    iindex: number,
    name: string,
    type: string | "status" | "digital" | "analog" | "temp" | "modbus",
    graph?: boolean,
    graphInterval: "minute" | "hour" | "day" | "month",
    graphProps?: string,
    graphRange: number,
    inputs: DeviceInputs[],
    createdAt?: Date,
    updatedAt?: Date,
    device_id: number,
    visible: boolean,
    serviceSettings?: ServiceSettings | null,
    device?: Device,
    display_order: number,
}
export type DeviceInputs = {
    id: number,
    name: string,
    value: string,
    type: string | "output" | "signal" | "voltage",
    index: number,
    widget: string,
    unit: string,
    max: string,
    min: string,
    tag: string,
    createdAt?: number
    updatedAt?: number
    // Rango en horas
    graphRange: number,

    // device?:Device,
    source: string,
    on_value?: string,
    off_value?: string,
    precission?: number,
    invert?: boolean,
    base_value?: number,
    group?: DeviceInputGroup,
    group_id: number,
    scale: number,
    notification: number,
    linkToInput: number,
    display_order: number,
    modbus?: ModbusType,
    distance_location?: LocationType,
    flow_input: number,
    cierre_input: number,
    cierre_interval: number,
    description: string,
    error?: string,
}

export type Device = {
    id: number,
    name: string,
    // subscription: string,
    // subscriptionStatus: 'unconfigured' | 'configured' | 'cancelled' | 'payment_error',
    // subscriptionDate: number,
    // subscriptionExpiration: number,
    suscription: DeviceSuscription,
    lat: number,
    lng: number,
    online: boolean,
    protocol: string,
    enableScheduler: number,
    flags: number,
    outputs: DeviceOutput[],
    outputGroups: DeviceOutputGroup[],
    inputGroups: DeviceInputGroup[],
    user: {
        fullname: string
    }
    user_id: number
    password: string
    phoneNumber: string
    imei: string,
    gateway: boolean
    currentSubscription?: number
    dataInterval?: number
    image?: string
    payment?: string
    createdAt?: Date
    updatedAt?: Date
    deletedAt?: Date,

    model: Model,
    id_scheduler_template: number | null,

    // Recents alarms
    alarms: LogAlarm[],
    observations?: string,
}

export type DemoRequest = {
    username: string
    password: string
}

export type ServiceSettings = {
    name: string,
    user: string,
    pass: string,
    error?: string,
    interval: number,

    location?: string,
}
export type InputItemSettings = {
    // name: string,
    // type: "hide" | "gaug" | "perc" | "text",
    min?: number,
    max?: number,
    factor?: number,
    tension?: number,
    hidden?: boolean,
    fill?: boolean,
    color?: string,
    unit?: string,
}

export type InputSettings = InputItemSettings[] | ServiceSettings;


export type UserPermission = {
    fullname: string,
}
export type Invitation = {
    token: string,
    createdAt: Date,
    updatedAt: Date,
}
export type Permission = {
    id: number,
    user_id: number,
    contact?: string,
    invitation?: Invitation,
    permissions: number,
    flag: number,
    created_at: Date,
    updated_at: Date,
}

export type LogOutput = {
    id: number,
    oname: string,
    date: number,
    fullname: string,
    error: string,
    trigger: number,
    oindex: number,
    result: number,
    timeout: number,
    state: boolean | number,
    sent: boolean | null,
}
export type LogInput = {
    data: number
    device_id: number
    id: number
    iindex: number
    input_id: number
    time: number
    date_format: string,
}

export type LogAlarm = {
    id: number
    device_id: number
    seen: number
    createdAt: string
    updatedAt: string
    event: number
    message: string
    entity_id: number

}


export type HelpContentFormField = {
    type: 'text' | 'button' | 'checkbox' | 'password' | 'select',
    name: string,
    description: string,
    list?: string[]
}
export type HelpContentForm = {
    type: 'form',
    name: string,
    description: string,
    fields: HelpContentFormField[]
};

export type HelpContentListItem = {
    name: string,
    description: string,
}
export type HelpContentList = {
    type: 'list',
    name: string,
    description: string,
    list: HelpContentListItem[],
};
export type HelpContentText = {
    type: 'text',
    text: string
};
export type HelpContentScreenshot = {
    type: 'screenshot',
    title: string,
    src: {
        desktop: string,
        mobile: string,
    },
};
export type HelpContent = string | HelpContentText | HelpContentForm | HelpContentScreenshot | HelpContentList
export type HelpTopic = {
    name: string;
    title: string;
    url?: string;
    section?: string;
    keywords: string[];
    content: HelpContent[];
}


export type Panel = {
    id: number,
    title: string,
    size: number,
}

export type PanelControl = {
    id: number,
    type: 'input' | 'output',
    input_index: number,
    input?: DeviceInputs,
    output?: any,
}

export type PanelData = {
    controls: PanelControl[]
} & Panel;

export enum DataFormatEnum {
    BOOL,
    INT16,
}

export type ModbusType = {

    id?: number,

    name: string,
    station: number,
    functionCode: number,
    address: number,
    registers: number,
    dataFormat: Format,
    protocol: number,
    host: string,
    port: number,
    retries: number,
    enabled: boolean,

    value?: number | number[],
    mappers: ModbusMapper[],

}

export type ModbusMapper = {
    id: number
    type: string | 'input' | 'status'

    format: number
    groupIndex: number
    index: number
    offset: number
    size: number

    values?: number[],
}

export type LocationType = {

    id?: number | null,

    lat: number,
    lng: number,

}

export type ModbusValue = {
    value: number,
}

export type ProductSuscription = {
    id: number,
    price: string,
    duration: string,
    title: string,
}

export type Contract = {
    id: number,
    payments: number,
    title: string,
    subscriptions: ProductSuscription[],
}

export type RemoticHardwareSettings = {
    inputs: { index: number, fromLow: number, fromHigh: number, toLow: number, toHigh: number, mode: number, confirm: number, max: number, min: number }[],
    automations: {
        title: string,
        prevAnd: number,
        action: number,
        actionArg1: number,
        actionArg2: number,
        cond: number,
        index: number,
        inputIndex: number,
        inputType: number, // Input type: 0 = OFF, 1 = Analog In, 2 = Relay Out
        secondsRearm: number,
        secondsWait: number,
        value: number,
    }[],
    timers: {
        index: number,
        hourMinute: number,
        week: number,
        output: number,
        state: number,
    }[],
};

export type DeviceTraffic = {
    sent: number,
    recv: number,
    date: string,
}[]
export type HMIPanel = {
    id: string,
    name: string,
    svg_data: string,
    url: string,
}

export type ClientDevices = {
    client_id: string,
    user: {
        fullname: string,
    },
    devices: SimpleDevice[],
}

export type Route = {
    id: number,
    name: string,
    createdAt: string,

    points?: RoutePoint[],
}

export type RoutePoint = {
    id: number,
    id_route: number,
    timestamp: number,
    lat: number,
    lng: number,
    poi?: RoutePOI[],
}

export type RoutePOI = {
    id: number,
    id_route_point: number,
    title: string,
    notes: string,
    attachments?: RoutePOIAttachment[],
}

export type RoutePOIAttachment = {
    id: number,
    id_route_poi: number,
    title: string,
    contentType: string,
    url: string,
}
export type NewDashboard = {
    id: number,
    title: string,
    subtitle: string,
    content: string,
    image: string,
    action_url?: string,
    action_text?: string,
}

export type ReportType = {
    report: string | 'daily' | '',
    results: any[],
    input: DeviceInputs
} & ReportTypeList;
export type ReportTypeList = {
    report: string | 'list',
    results: {
        id: string,
        time: number,
        data: number,
        diff: number,
    }[],
    input: DeviceInputs
};


export type DeviceServiceOutput = {
    id: number,
    device_id: number,
    name: string,
    title: string,
    settings: string,
    input_group: string,
    service_type: string,
}

export class API {
    private _url: string;
    private _token?: string;
    private _clientId?: string;
    private onLoginRequiredFn: any;
    private onError: any;


    constructor(url: string) {
        this._url = url;
    }

    usersAuth(username: string, password: string, clientId: string): Promise<APIBase<UserAuth>> {
        return this.request('/users/auth', "POST", { username: username, password: password, clientId });

    }

    usersChangePassword(oldPassword: string, password: string): Promise<APIBase<UserAuth>> {
        return this.request('/users/password', "POST", { oldPassword: oldPassword, newPassword: password });

    }

    usersDemo(name: string, business: string, phone: string, email: string): Promise<APIBase<DemoRequest>> {
        return this.request('/users/demo', "POST", { name, business, phone, email });
    }

    usersCreate(username: string, password: string, email: string, phoneNumber: string, fullname: string, timezone: string, company: string, address: string, mailing_subscription: boolean, referer: string, googleToken: string): Promise<APIBase<UserCreate>> {
        return this.request('/users/create', "POST", {
            username,
            password,
            email,
            phoneNumber,
            fullname,
            timezone,
            company,
            address,
            mailing_subscription: mailing_subscription ? "1" : "0",
            referer,
            googleToken
        });
    }

    user(): Promise<APIBase<User>> {
        return this.request('/users/user', "GET", {});
    }

    usersRecovery(account: string): Promise<APIBase<{}>> {
        return this.request('/users/recovery', "POST", { account });
    }

    usersRecoveryPassword(token: string, password: string): Promise<APIBase<{}>> {
        return this.request('/users/recovery/' + token, "POST", { password });
    }

    getDevices(): Promise<APIBase<{ devices: SimpleDevice[] }>> {
        return this.request('/devices', "GET", {});

    }
    getDevicesClients(): Promise<APIBase<{ clients: ClientDevices[] }>> {
        return this.request('/devices/clients', "GET", {});

    }

    deviceAdd(name: string, code: string, payment_method: string, suscription: string, stripe_payment: string, coupon: string): Promise<APIBase<{ devices: SimpleDevice[] }>> {
        return this.request('/device', "POST", { name, code, suscription, payment_method, stripe_payment, coupon });

    }

    deleteDevice(id: string) {
        return this.request('/device/' + id, "DELETE", {});

    }
    deviceTransfer(id: number, data: { recipient: string }): Promise<APIBase<any>> {
        return this.request('/device/' + id + "/transfer", "POST", data);
    }

    deviceGet(id: number, section: string = 'all'): Promise<APIBase<Device>> {
        return this.request('/device/' + id + "/section/" + section, "GET", {});
    }

    deviceTraffic(id: number): Promise<APIBase<DeviceTraffic>> {
        return this.request('/device/' + id + "/traffic", "GET", {});
    }

    devicePost(id: number, data: { name?: string, flags?: number, image?: string, lat?: number, lng?: number, dataInterval?: number, observations?: string }): Promise<APIBase<any>> {
        return this.request('/device/' + id, "POST", data);
    }

    deviceGetSettingsOutputGroups(id: number): Promise<APIBase<any>> {
        return this.request('/device/' + id + "/settings/outputGroups", "GET", {});
    }

    deviceSettingsOutputGroup(id: number, outputGroup: DeviceOutputGroup): Promise<APIBase<any>> {
        return this.request('/device/' + id + "/settings/outputGroups", "POST", { ...outputGroup });
    }

    deviceSettingsOutputGroupDelete(id: number, outputGroup: DeviceOutputGroup): Promise<APIBase<any>> {
        return this.request('/device/' + id + "/settings/outputGroups", "DELETE", { ...outputGroup });
    }


    deviceGetSettingsOutput(id: number): Promise<APIBase<any>> {
        return this.request('/device/' + id + "/settings/output", "GET", {});
    }


    deviceDeleteSettingsOutput(id: number, output_id: number): Promise<APIBase<any>> {
        return this.request('/device/' + id + "/settings/output", "DELETE", { id: output_id });
    }

    deviceSettingsOutput(id: number, outputs: any[]): Promise<APIBase<any>> {
        return this.request('/device/' + id + "/settings/output", "POST", { outputs: outputs });
    }

    deviceSettingsInputGroup(id: number, inputs: any[]): Promise<APIBase<any>> {
        return this.request('/device/' + id + "/settings/inputGroup", "POST", { inputs: inputs });
    }

    deviceInputGroupDelete(id: number, input_id: number): Promise<APIBase<any>> {
        return this.request('/device/' + id + "/settings/inputGroup", "DELETE", { input_id: input_id });
    }

    deviceSettingsInputGroupGet(id: number): Promise<APIBase<DeviceInputGroup[]>> {
        return this.request('/device/' + id + "/settings/inputGroup", "GET", {});
    }

    deviceInputGroupAdd(id: number, type: "service" | "modbus"): Promise<APIBase<DeviceInputGroup>> {
        return this.request('/device/' + id + "/settings/inputGroup", "PUT", { type });
    }

    deviceInputAdd(id: number, group_id: number): Promise<APIBase<DeviceInputs>> {
        return this.request('/device/' + id + "/settings/input", "PUT", { group_id });
    }

    deviceInputSave(id: number, input: DeviceInputs): Promise<APIBase<any>> {
        return this.request('/device/' + id + "/settings/input", "POST", { input });
    }

    deviceInputDeleteLog(id: number, input: number): Promise<APIBase<any>> {
        return this.request('/device/' + id + "/settings/input/log", "DELETE", { id: input });
    }

    deviceInputDelete(id: number, input: number): Promise<APIBase<any>> {
        return this.request('/device/' + id + "/settings/input", "DELETE", { id: input });
    }


    getModbus(device: number): Promise<APIBase<ModbusType[]>> {
        return this.request('/device/' + device + "/settings/modbus", "GET", {});
    }

    addModbus(device: number, data: ModbusType): Promise<APIBase<ModbusType>> {
        return this.request('/device/' + device + "/settings/modbus", "PUT", data);
    }

    editModbus(device: number, id: number, data: ModbusType): Promise<APIBase<ModbusType>> {
        return this.request('/device/' + device + "/settings/modbus", "POST", { ...data, id: id });
    }

    removeModbus(device: number, id: number): Promise<APIBase<ModbusType>> {
        return this.request('/device/' + device + "/settings/modbus", "DELETE", { id: id });
    }

    deviceSubscribe(id: number): Promise<APIBase<any>> {
        return this.request('/device/push/subscribe/' + id, "GET", {});
    }


    deviceOutputState(device_id: number, output: number, transport: number, state: number): Promise<APIBase<{}>> {
        return this.request('/device/' + device_id + "/output/" + output + "/state", "POST", {
            transport: transport,
            state: state
        });
    }

    deviceInputData(id: number): Promise<APIBase<any>> {
        return this.request('/device/' + id + "/input", "GET", {});
    }

    deviceScheduler(device_id: number, enableScheduler: boolean): Promise<APIBase<SchedulerOutput[]>> {
        return this.request('/device/' + device_id + "/scheduler/state", "POST", { enableScheduler });
    }
    deviceSchedulerTemplate(device_id: number, id_scheduler_template: number | null): Promise<APIBase<any>> {
        return this.request('/device/' + device_id + "/scheduler/template", "POST", { id_scheduler_template });
    }

    deviceModbusQuery(device_id: number, modbus: ModbusType): Promise<APIBase<{ modbusResponse: number[] }>> {
        return this.request('/device/' + device_id + "/modbus/query", "POST", { modbus });

    }

    schedulers(device_id: number): Promise<APIBase<SchedulerOutput[]>> {
        return this.request('/device/' + device_id + "/schedulers", "GET", {});
    }

    schedulerAdd(device_id: number, scheduler: Scheduler): Promise<APIBase<SchedulerOutput[]>> {
        scheduler.device_id = device_id;
        return this.request('/scheduler', "POST", scheduler);
    }

    removeScheduler(scheduler_id: number): Promise<APIBase<SchedulerOutput[]>> {
        return this.request("/scheduler/" + scheduler_id, "DELETE", {});
    }

    schedulersTemplates(device_id: number): Promise<APIBase<SchedulerTemplate[]>> {
        return this.request('/device/' + device_id + "/schedulerTemplate", "GET", {});
    }

    schedulerTemplateAdd(device_id: number, schedulerTemplate: SchedulerTemplate): Promise<APIBase<SchedulerTemplate>> {

        schedulerTemplate.device_id = device_id;
        return this.request('/schedulerTemplate', "POST", schedulerTemplate);
    }

    schedulerTemplateDelete(scheduler_template_id: number): Promise<APIBase<SchedulerTemplate>> {

        return this.request("/schedulerTemplate/" + scheduler_template_id, "DELETE", {});
    }

    automation(device_id: number): Promise<APIBase<Automation[]>> {
        return this.request('/device/' + device_id + "/automation", "GET", {});

    }

    automationAdd(automation: Automation) {
        return this.request("/automation", "POST", automation);

    }

    removeAutomation(device_id: number, automation_id: number): Promise<APIBase<{}>> {
        return this.request("/device/" + device_id + "/automation/" + automation_id, "DELETE", {});
    }

    getPaymentMethods(): Promise<APIBase<PaymentMethod[]>> {
        return this.request('/payments/methods', "GET", {});
    }

    getDevicePaymentMethods(device_id: number): Promise<APIBase<PaymentMethod[]>> {
        return this.request('/device/' + device_id + '/payments/methods', "GET", {});
    }

    paymentsIntent(method: string): Promise<APIBase<{ client_secret: string }>> {
        return this.request('/payments/intent', "POST", { method });
    }

    paymentsDeviceIntent(device_id: string, method: string): Promise<APIBase<{ client_secret: string }>> {
        return this.request('/device/' + device_id + '/payments/intent', "POST", { method });
    }

    paymentsCreate(token: string): Promise<APIBase<{ payments: [] }>> {
        return this.request('/payments/methods', "POST", { token });
    }
    paymentsDeviceCreate(device_id: string, token: string): Promise<APIBase<{ payments: [] }>> {
        return this.request('/device/' + device_id + '/payments/methods', "POST", { token });
    }

    paymentsGetTax(): Promise<APIBase<any[]>> {
        return this.request('/payments/tax', "GET", {});
    }

    paymentsSetTax(data: any): Promise<APIBase<any>> {
        return this.request('/payments/tax', "POST", { ...data });
    }


    paymentsInvoices(): Promise<APIBase<any[]>> {
        return this.request('/payments/invoices', "GET", {});
    }

    deviceSubscription(id: string): Promise<APIBase<{ subscription: DeviceSubscription }>> {
        return this.request('/device/' + id + '/subscription', "GET", {});
    }

    deviceSuscription(id: string): Promise<APIBase<{ suscription: DeviceSuscription, suscriptions: DeviceSuscription[], contract: Contract }>> {
        return this.request('/device/' + id + '/suscription', "GET", {});
    }

    deviceListPayments(id: string): Promise<APIBase<{}>> {
        return this.request('/device/' + id + '/suscription', "GET", {});
    }

    deviceQr(qr: string): Promise<APIBase<{ model: any, suscriptions: ProductSuscription[], contract: Contract }>> {
        return this.request('/devices/qr/' + qr, "GET", {});
    }

    setDeviceSuscription(id: string, product_id: string, payment_method: string, stripe_payment: string, coupon: string): Promise<APIBase<{}>> {
        return this.request('/device/' + id + '/suscription', "POST", { product_id, payment_method, stripe_payment, coupon });
    }

    deleteDeviceSuscription(id: string, reason: string): Promise<APIBase<{}>> {
        return this.request('/device/' + id + '/suscription', "DELETE", { reason });
    }

    setOnLoginRequired(fn: any) {
        this.onLoginRequiredFn = fn;
    }

    setOnError(fn: any) {
        this.onError = fn;
    }

    permissionsGet(device_id: number): Promise<APIBase<Permission[]>> {
        return this.request('/permissions/' + device_id, "GET", {});
    }

    permissionsAdd(device_id: number, contact: string, permissions: Permission): Promise<APIBase<any>> {
        return this.request('/permissions/' + device_id, "POST", { contact, permission: permissions.permissions });
    }

    permissionsUpdate(device_id: number, permission: Permission): Promise<APIBase<any>> {
        return this.request('/permissions/' + device_id + "/" + permission.id, "POST", permission);
    }

    permissionsDelete(device_id: number, permission_id: number): Promise<APIBase<any>> {
        return this.request('/permissions/' + device_id + "/" + permission_id, "DELETE", {});
    }

    permissionsResend(device_id: number, permission_id: number): Promise<APIBase<any>> {
        return this.request('/permissions/' + device_id + "/" + permission_id + "/resend", "POST", {});
    }

    verificationResend(): Promise<APIBase<any>> {
        return this.request('/users/verify/resend', "POST", {});
    }

    verification(token: string): Promise<APIBase<any>> {
        return this.request('/users/verify/validate', "POST", { code: token });
    }

    usersPush(token: string, device: string) {
        return this.request('/users/push', "POST", { token, device, clientId: this.clientId });

    }


    getHardwareRelay(id: number): Promise<APIBase<{ relays: any[] }>> {
        return this.request('/device/' + id + "/settings/hardware/relay", "GET", {});
    }

    setHardwareRelay(id: number, relays: any[]): Promise<APIBase<{ result: boolean }>> {
        return this.request('/device/' + id + "/settings/hardware/relay", "POST", { relays });
    }

    getHardwareAIN(id: number): Promise<APIBase<{ ains: any[] }>> {
        return this.request('/device/' + id + "/settings/hardware/ain", "GET", {});
    }

    setHardwareAIN(id: number, ains: any[]): Promise<APIBase<{ result: boolean }>> {
        return this.request('/device/' + id + "/settings/hardware/ain", "POST", { ains });
    }

    getHardwareTemp(id: number): Promise<APIBase<{ tempHum: any[] }>> {
        return this.request('/device/' + id + "/settings/hardware/temp", "GET", {});
    }

    setHardwareTemp(id: number, tempHum: any): Promise<APIBase<{ result: boolean }>> {
        return this.request('/device/' + id + "/settings/hardware/temp", "POST", { tempHum });
    }

    getHardwareDIN(id: number): Promise<APIBase<{ dins: any[] }>> {
        return this.request('/device/' + id + "/settings/hardware/din", "GET", {});
    }

    setHardwareDIN(id: number, dins: any[]): Promise<APIBase<{ result: boolean }>> {
        return this.request('/device/' + id + "/settings/hardware/din", "POST", { dins });
    }

    getHardwareTimer(id: number): Promise<APIBase<{ events: any[] }>> {
        return this.request('/device/' + id + "/settings/hardware/timer", "GET", {});
    }

    setHardwareTimer(id: number, events: any[]): Promise<APIBase<{ result: boolean }>> {
        return this.request('/device/' + id + "/settings/hardware/timer", "POST", { events });
    }

    getHardwareEvents(id: number): Promise<APIBase<{ events: any[] }>> {
        return this.request('/device/' + id + "/settings/hardware/events", "GET", {});
    }

    setHardwareEvents(id: number, events: any[]): Promise<APIBase<{ result: boolean }>> {
        return this.request('/device/' + id + "/settings/hardware/events", "POST", { events });
    }

 
    getHardwareModbus(id: number): Promise<APIBase<{ modbus: any[], baudRate: number, portConfig: number }>> {
        return this.request('/device/' + id + "/settings/hardware/modbus", "GET", {});
    }

    setHardwareModbus(id: number, modbus: any[], baudRate: number, portConfig: number): Promise<APIBase<{ result: boolean }>> {
        return this.request('/device/' + id + "/settings/hardware/modbus", "POST", { modbus, baudRate, portConfig });
    }

    getRemoticHardwareSettings(id: number, type: number): Promise<APIBase<RemoticHardwareSettings>> {
        return this.request('/device/' + id + "/settings/hardware/remotic/" + type, "GET", {});
    }

    setRemoticHardwareSettings(id: number, settings: any): Promise<APIBase<{ result: boolean }>> {
        return this.request('/device/' + id + "/settings/hardware/remotic", "POST", settings);
    }

    invitation(token: string): Promise<APIBase<{ result: boolean }>> {
        return this.request('/invitation/' + token, "GET", {});
    }

    deviceInputChart(id: number, start: number, end: number, interval: string | number): Promise<APIBase<any[]>> {
        return this.request('/device/input/' + id + '/chart', "POST", { start, end, interval });
    }

    deviceInputGroupChart(id: number, start: number, end: number, interval: string | number): Promise<APIBase<any[]>> {
        return this.request('/device/group/' + id + '/chart', "POST", { start, end, interval });
    }

    logTime(device: number, start: number, length: number): Promise<APIBase<{ log: LogOutput[], next: number }>> {
        return this.request('/devices/' + device + '/log/timeline/' + start + "/" + length, "GET", {});
    }

    logDate(device: number, start: string, end: string): Promise<APIBase<{ log: LogOutput[] }>> {
        return this.request('/devices/' + device + '/log/date/' + start + "/" + end, "GET", {});
    }

    deviceInputLog(device: number, input_id: number, start: string, end: string, unique: boolean): Promise<APIBase<{ log: LogInput[] }>> {
        return this.request('/devices/' + device + '/input/' + input_id + '/log', "POST", { start, end, unique });
    }

    deviceInputTimeline(device: number, input_id: number, start: string | null, itemsPerPage: number, page: number): Promise<APIBase<{ log: LogInput[], page: number, first: number }>> {
        return this.request('/devices/' + device + '/input/' + input_id + '/timeline', "POST", { start, page, ipp: itemsPerPage });
    }
    deviceInputTimetable(device: number, input_id: number, start: string, end: string): Promise<APIBase<{ timetable: any, total: any }>> {
        return this.request('/devices/' + device + '/input/' + input_id + '/timetable', "POST", { start, end });
    }

    deviceInputReport(device: number, input_id: number, start: string, end: string, report: string): Promise<APIBase<ReportType>> {
        return this.request('/devices/' + device + '/input/' + input_id + '/report', "POST", { start, end, report });
    }

    deviceAlarmsLog(device: number, start: number, length: number): Promise<APIBase<{ log: LogAlarm[], next: number }>> {
        return this.request('/devices/' + device + '/log/alarms', "POST", { start, length });
    }

    setLogAlarmsSeen(device: number): Promise<APIBase<{}>> {
        return this.request('/devices/' + device + '/log/alarms/seen', "POST", {});
    }

    setLogAlarmSeen(device: number, alarm_id: number): Promise<APIBase<{}>> {
        return this.request('/devices/' + device + '/log/alarms/' + alarm_id + '/seen', "POST", {});
    }

    help(): Promise<APIBase<HelpTopic[]>> {
        return this.request('/help', "GET", {});
    }

    link(token: string): Promise<APIBase<{ url: string }>> {
        return this.request('/url/' + token, "GET", {});
    }

    gallery(): Promise<APIBase<any[]>> {
        return this.request('/gallery', "GET", {});
    }

    version(): Promise<APIBase<{ version: string }>> {
        return this.request('/version', "GET", {});
    }

    setDeviceLocation(device_id: number, lat: number, lng: number): Promise<APIBase<{}>> {
        return this.request('/device/' + device_id + "/location", "POST", { lat, lng });
    }

    getPanels(): Promise<APIBase<Panel[]>> {
        return this.request('/panel', "GET", {});
    }

    getPanel(id: number): Promise<APIBase<Panel>> {
        return this.request('/panel/' + id, "GET", {});
    }

    postPanel(data: { title: string, size: number }, id?: number): Promise<APIBase<Panel>> {
        return this.request('/panel' + (id ? '/' + id : ''), "POST", data);
    }

    deletePanel(id: number): Promise<APIBase<{}>> {
        return this.request('/panel/' + id, "DELETE", {});
    }


    putPanelControl(idPanel: number, data: { input_id?: number, input_index?: number, output_id?: number, }): Promise<APIBase<{}>> {
        return this.request('/panel/' + idPanel + '/control', "PUT", data);
    }

    deletePanelControl(idControl: number): Promise<APIBase<{}>> {
        return this.request('/panel/control/' + idControl, "DELETE", {});
    }

    getPanelData(idPanel: number): Promise<APIBase<PanelData>> {
        return this.request('/panel/' + idPanel + "/data", "GET", {});
    }

    getPromotionCode(code: string): Promise<APIBase<any>> {
        return this.request("/promotion_codes/" + code, "GET", {});
    }

    getFile(file: string) {
        return this.internalCall(file, "GET", {});
    }


    getSimulatorModels(): Promise<APIBase<Model[]>> {
        return this.request('/simulator/models', "GET", {});
    }
    createSimulator(name: string, model: number, protocol: number): Promise<APIBase<any>> {
        return this.request('/simulator', "POST", { name, model, protocol });
    }


    getDeviceSettingsIp(id: number): Promise<APIBase<{ ips: any[] }>> {
        return this.request('/device/' + id + "/settings/hardware/ip", "GET", {});
    }

    setDeviceSettingsIp(id: number, ips: any[]): Promise<APIBase<{ result: boolean }>> {
        return this.request('/device/' + id + "/settings/hardware/ip", "POST", { ips });
    }


    getHMIPanels(): Promise<APIBase<HMIPanel[]>> {
        return this.request('/hmi/panels', "GET", {});
    }

    removeHMIPanel(id: string): Promise<APIBase<any>> {
        return this.request('/hmi/panel/' + id, "DELETE", {});
    }

    addHMIPanel(name: string, svg_data: string): Promise<APIBase<any>> {
        return this.request('/hmi/panel', "POST", { name, svg_data });
    }

    getInputsData(inputs: string[]): Promise<APIBase<({ input_name: string } & DeviceInputs)[]>> {
        let req = inputs.map(a => "input[]=" + a).join("&")
        return this.request('/inputs/array?' + req, "GET", {});
    }

    usersGoogleAuth(googleToken: string): Promise<APIBase<any>> {
        return this.request('/users/auth/google', "POST", { googleToken });
    }
    deviceDisconnect(device_id: string): Promise<APIBase<any>> {
        return this.request('/device/' + device_id + '/disconnect', "POST", {});
    }
    deviceArmDissarm(device_id: string, armed: boolean): Promise<APIBase<any>> {
        return this.request('/device/' + device_id + '/arm', "POST", { arm: armed ? "1" : "0" });
    }

    usersWhatsapp(): Promise<APIBase<{ token: string }>> {
        return this.request('/users/whatsapp', "POST", {});
    }
    deviceSubscriptionInvoices(device_id: string): Promise<APIBase<any>> {
        return this.request('/device/' + device_id + '/invoices', "GET", {});
    }
    deviceSubscriptionInvoicePDF(invoice_id: string): Promise<Response> {
        return this.internalCall('api/payments/invoice/' + invoice_id + '/pdf', "GET", {});
    }

    sendInput(input: string, value: string) {
        return this.request("/input/" + input + "?value=" + value, "GET", {});

    }
    sendInputLatLng(input: string, value: { lat: number, lng: number }) {
        return this.request("/input/" + input + "?value[lat]=" + value.lat + "&value[lng]=" + value.lng, "GET", {});

    }

    paymentsEmailBills(state: boolean) {
        return this.request('/payments/emailBills', "POST", { enable: state });

    }

    getForecast(lat: number, lng: number): Promise<any> {
        return this.getRequest("https://api.openweathermap.org/data/2.5/forecast?lat=" + lat + "&lon=" + lng + "&units=metric&appid=" + config.FORECAST_API_KEY).then(res => res.json());
    }

    createRoute(name: string): Promise<APIBase<Route>> {
        return this.request('/routes', "POST", { name });
    }
    createRoutePoint(route_id: number, lat: number, lng: number, alt: number | null, timestamp: number): Promise<APIBase<RoutePoint>> {
        return this.request('/routes/points', "POST", { route_id, lat, lng, alt, timestamp });
    }


    createRoutePoi(route_point_id: number, name: string, notes: string): Promise<APIBase<RoutePOI>> {
        return this.request('/routes/points/poi', "POST", { route_point_id, name, notes, });
    }

    createRoutePoiAttachment(route_poi_id: number, file: Blob): Promise<APIBase<RoutePOI>> {
        return this.internalUploadCall('api/routes/points/poi/' + route_poi_id + '/attachment', "POST", file).then(res => {
            return res.json().then(json => {
                return json;
            })
        });
    }

    getRoutes(): Promise<APIBase<Route[]>> {
        return this.request('/routes', "GET", {});
    }

    getRoute(id: number): Promise<APIBase<Route>> {
        return this.request('/route/' + id, "GET", {});
    }

    deleteUserAccount(code?: string) {
        return this.request('/users/remove', "POST", { confirmation_token: code });
    }

    reportError(info: any) {
        return this.request('/report', "POST", info);
    }

    getNews(): Promise<APIBase<NewDashboard[]>> {
        return this.request('/news', "GET", {});
    }

    deviceGetSettingsServiceOutput(id: number): Promise<APIBase<DeviceServiceOutput[]>> {
        return this.request('/device/' + id + "/settings/serviceOutput", "GET", {});
    }


    deviceSettingsServiceOutput(id: number, outputService: DeviceServiceOutput): Promise<APIBase<any>> {
        return this.request('/device/' + id + "/settings/serviceOutput", "POST", { ...outputService });
    }


    deviceServiceOutputRequest(id: number, output_id: number, command: number, data: number[] = []): Promise<APIBase<any>> {
        ///device/:device_id/serviceOutput/:output_id
        return this.request('/device/' + id + "/serviceOutput/" + output_id , "POST", {command,data });
    }

    
    modiconRequest(id: number, json: any) {
        return this.request('/device/' + id + "/settings/hardware/modicon", "POST", json);
    }

    modiconRequestActions(id: number): Promise<APIBase<{time: string, actions: string[]}>> {
        return this.modiconRequest(id, {'action': 'get-actions'});
    }
    modiconRequestGetTimers(id: number): Promise<APIBase<{time: string, t: any[]}>> {
        return this.modiconRequest(id, {'action': 'get-timer'});
    }
    modiconRequestSetTimers(id: number, timers:  any[]): Promise<APIBase<{time: string, t: any[]}>> {
        return this.modiconRequest(id, {'action': 'set-timer', t: timers});
    }
    /**
     *
     * @param endpoint
     * @param method
     * @param data
     * @param parser
     * @returns {Promise<any>}
     */
    request(endpoint: string, method: string, data: any, parser: (json: any) => any = (a: any) => {
        return a;
    }) {
        let stacktrace = new Error('').stack;

        return new Promise<APIBase<any>>((resolve, reject) => {
            Utils.log(endpoint);
            this.internalCall("api" + endpoint, method, data).then((response: any) => {
                response.json().then((json: any) => {
                    Utils.log(json);
                    if (json.loginRequired) {
                        reject();
                        if (this.onLoginRequiredFn) {
                            this.onLoginRequiredFn();
                        }
                    } else {
                        if (!json.success && json.error && this.onError !== undefined) {
                            this.onError(json);
                        }
                        if (parser) {
                            json = parser(json);
                        }
                        resolve(json);
                    }
                }).catch((error: any) => {
                    Utils.log("Error", error);
                    Utils.log("Stack", stacktrace);
                    let json = ({
                        success: false,
                        message: 'Error inesperado 1409: ' + error.toString(),
                        error: error,
                        statusCode: -1,
                        data: {}
                    });

                    if (this.onError) {
                        this.onError(json);
                    }
                    resolve(json);

                });

            }).catch((error) => {
                Utils.log("Error", error);
                Utils.log("Stack", stacktrace);

                let json = ({
                    success: false,
                    message: 'Error inesperado 1428: ' + error.toString(),
                    error: error,
                    statusCode: -1,
                    data: {}
                });

                if (this.onError)
                    this.onError(json);
                resolve(json);
            });
        })
    }


    private generateHeaders() {
        var version = Utils.getVersion();
        const requestHeaders: HeadersInit = new Headers();
        requestHeaders.set('Content-Type', 'application/json');
        requestHeaders.set('X-Requested-With', 'XMLHttpRequest');
        if (this._token) {
            requestHeaders.set('X-Token', this._token);
        }
        if (this._clientId) {
            requestHeaders.set('X-ClientID', this._clientId);
        }
        if (version) {
            requestHeaders.set('X-Version', version);
        }
        return requestHeaders;
    }

    internalCall(endpoint: string, method: string, data: any) {
        data = data || {};
        // @ts-ignore

        var request: RequestInit = {
            method: method,
            headers: this.generateHeaders(),
        };
        if (method !== "HEAD" && method !== "GET") {
            try {
                request.body = JSON.stringify(data);
            } catch (e) {

            }
        } else {

        }
        return fetch(this._url + endpoint, request);
    }
    internalUploadCall(endpoint: string, method: string, data: Blob) {
        let headers = this.generateHeaders();
        headers.delete('Content-Type');
        const formData = new FormData();
        formData.append('file1', data, 'file1.jpeg');

        var request: RequestInit = {
            method: method,
            headers: headers,
            body: formData,
        };
        return fetch(this._url + endpoint, request);
    }


    getRequest(url: string) {

        var request: RequestInit = {
            method: 'GET',
        };
        return fetch(url, request);
    }


    get token(): string {
        return String(this._token);
    }

    set token(value: string) {
        this._token = value;
    }


    get url(): string {
        return this._url;
    }


    get clientId(): string {
        return this._clientId!;
    }

    set clientId(value: string) {
        this._clientId = value;
    }

    mediaUrl(file: string) {
        return this.url + file;
    }
}

const api = new API(config.API);

export function getAPI(): API {
    return api;
}

