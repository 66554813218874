import React from "react";
import {
    IonBackButton,
    IonBadge,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonLoading,
    IonPage,
    IonText,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import {inject} from "mobx-react";
import {Store} from "../service/Store";
import moment from "moment";
import Lang from "../service/Lang";

export interface UserAccountInvoicesProps {
    store?:Store;
    lang?:Lang;
}
export interface UserAccountInvoicesState {
    loading: boolean;
    invoices: any[];
}

@inject("store", "lang")
export default class UserAccountInvoices extends React.Component<UserAccountInvoicesProps, UserAccountInvoicesState> {

    componentDidMount(): void {
        this.setState({invoices: []});
        this.loadInvoices();
    }

    loadInvoices() {
        this.setState({loading: true});
        this.props.store?.api.paymentsInvoices().then(value => {
            this.setState({loading: false});
            this.setState({invoices: value.data});
        })
    }

    getStatus(status:string) {
        const {lang} = this.props;
        if(status === "draft") {
            return lang?.l.main.draft()!;
        }
        if(status === "open") {
            return lang?.l.main.bill_open()!;
        }
        if(status === "paid") {
            return lang?.l.main.bill_paid()!;
        }
        if(status === "void") {
            return lang?.l.main.bill_void()!;
        }
        if(status === "uncollectible") {
            return lang?.l.main.bill_unpaid()!;
        }
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {

        const {lang} = this.props;
        if(!this.state) return "";
        return <IonPage>
            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/account"/>
                    </IonButtons>
                    <IonTitle>{lang?.l.main.bills_list()}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    <IonListHeader>
                        <IonText>{lang?.l.main.bill_info_header()}</IonText>
                    </IonListHeader>
                    {this.state.invoices && this.state.invoices.length > 0 && this.state.invoices.map((value, index) => {
                        return <IonItem href={value.invoice_pdf} target={"_blank"}>
                            <IonLabel><IonBadge>{value.number}</IonBadge><IonBadge>{value.name}</IonBadge></IonLabel>
                            <IonLabel>{moment(value.created * 1000).format("DD/MM/YYYY")}</IonLabel>
                            <IonLabel>{value.total / 100} €</IonLabel>
                            <IonLabel><IonBadge>{this.getStatus(value.status)}</IonBadge></IonLabel>
                        </IonItem>
                    })}
                </IonList>
            </IonContent>
            <IonLoading
                isOpen={this.state.loading!}
                message={lang?.l.main.loading()}
            />
        </IonPage>
    }
}
