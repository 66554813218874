import MemoryMapper, {MemoryAddress} from "./MemoryMapper";

export default class MemoryMapperV2_7 extends MemoryMapper {

    constructor() {
        super();
        this.set(MemoryAddress.BASIC_SETTINGS, 2);
        this.set(MemoryAddress.VERSION_MODEL, 1868);
        this.set(MemoryAddress.GPRS_ENABLE, 535);
        this.set(MemoryAddress.GPRS_APN_CONNECT, 704);
        this.set(MemoryAddress.GPRS_APN_SET, 120);
        this.set(MemoryAddress.GPRS_DOMAIN_ENABLE, 1744);

        this.set(MemoryAddress.GPRSINFO_ADDRESS, 40960);
        this.set(MemoryAddress.GPRSIP_ADDRESS, 41024);
        this.set(MemoryAddress.GPRSAPN_ADDRESS, 41216);
        this.set(MemoryAddress.GPRSUSER_ADDRESS, 41216);
        this.set(MemoryAddress.GPRSPWD_ADDRESS, 41280);

        this.set(MemoryAddress.PERIODIC_TIMER, 472);
        this.set(MemoryAddress.PERIODIC_TIMER_SAVE, 471);
        this.set(MemoryAddress.RS485, 520);
        this.set(MemoryAddress.DIN_SETTINGS, 208);
        this.set(MemoryAddress.DIN_VALUES_SETTINGS, 1792);

        this.set(MemoryAddress.A_DEV_INFO, 25600);

        // this.set(MemoryAddress.GPRSDATAPACK_ADDRESS, 253952);
        // this.set(MemoryAddress.GPRSPACKREG_ADDRESS, 253952);
        // this.set(MemoryAddress.GPRSPACKREGRESP_ADDRESS, 254016);
        // this.set(MemoryAddress.GPRSPACKDISCONN_ADDRESS, 254080);
        // this.set(MemoryAddress.GPRSPACKHEART_ADDRESS, 254144);
        // this.set(MemoryAddress.GPRSPACKHEARTRESP_ADDRESS, 254208);
    }


}
