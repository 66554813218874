import React from "react";
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonLoading,
    IonModal,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import {inject} from "mobx-react";
import {RouteComponentProps} from "react-router-dom";
import {Store} from "../../../service/Store";
import {Device} from "../../../service/API";
import Utils from "../../../service/Utils";

export interface DeviceSettingsRemoticAnalogProps extends RouteComponentProps<{id: string}> {
    store?:Store,
}
export type DeviceSettingsRemoticAnalogState = {
    loading?: boolean,
    settings: {
        inputs: { index: number, fromLow: number, fromHigh: number, toLow: number, toHigh: number, mode: number, confirm: number, max: number, min: number }[]
    },
    index: number,
    device:Device,
    error: boolean | string,
    edit: boolean,
}

@inject("store", "lang")
export default class DeviceSettingsRemoticAnalog extends React.Component<DeviceSettingsRemoticAnalogProps, DeviceSettingsRemoticAnalogState> {

    componentDidMount(): void {

        this.loadDevice();
    }

    loadDevice() {
        this.setState({loading: true});
        let id = this.props.match?.params.id!;
        this.props.store?.api.deviceGet(Number(id)).then(value => {
            if(value.success) {
                this.setState({device: value.data})
            }
            this.props.store?.api.getRemoticHardwareSettings(Number(id), 1).then(value => {
                if(value.success) {
                    this.setState({settings: value.data});
                } else {
                    this.setState({error: 'El dispositivo no está conectado'});
                }
            }).finally(() => this.setState({loading: false}));
        });
    }
    componentDidUpdate(prevProps: Readonly<DeviceSettingsRemoticAnalogProps>, prevState: Readonly<DeviceSettingsRemoticAnalogState>, snapshot?: any) {
        if(this.props.match.params.id !== prevProps.match.params.id) {
            this.loadDevice();
        }
    }

    handleSave = (ev: any) => {
        this.setState({loading: true, error: false});
        let id = this.props.match?.params.id!;
        const {history} = this.props;

        this.props.store?.api.setRemoticHardwareSettings(Number(id), this.state.settings).then(value => {
            this.setState({loading: false});
            if(value.success) {
                if(value.data.result) {
                    history.goBack();
                } else {
                    this.setState({error: 'No se puede configurar el dispositivo'});
                }
            }
        });

    };

    handleChange = (item:any, name:string) => (ev:any) => {
        item[name] = ev.currentTarget.value;
    }

    renderItem(value:any, index:number) {

        let name = Utils.getNameBySubInputGroup(this.state.device.inputGroups, 1, index);
        return <IonItem detail button onClick={() => this.setState({edit: true, index: index})}>
            <IonLabel>{name}</IonLabel>
        </IonItem>

    }

    renderEditor() {
        if(!this.state.device) return "";
        if(!this.state.settings) return "";
        if(this.state.index === undefined) return "";
        let index = this.state.index;
        let value = this.state.settings.inputs[index];
        let name = Utils.getNameBySubInputGroup(this.state.device.inputGroups, 1, index);

        return <>

            <IonItemDivider>
                <IonLabel>
                    {name}
                </IonLabel>
            </IonItemDivider>
            {/*<IonItem>*/}
            {/*    <IonLabel>Tipo</IonLabel>*/}

            {/*    <IonSelect value={value.type} onIonChange={this.handleChange(value, 'type')}>*/}
            {/*        <IonSelectOption value={0}>Desactivar</IonSelectOption>*/}
            {/*        <IonSelectOption value={1}>0-5V</IonSelectOption>*/}
            {/*        <IonSelectOption value={2}>0-20mA</IonSelectOption>*/}
            {/*        <IonSelectOption value={3}>4-20mA</IonSelectOption>*/}
            {/*    </IonSelect>*/}
            {/*</IonItem>*/}
            <IonItem>
                <IonInput label="Lectura Mínimo" labelPlacement="stacked" value={value.fromLow} step={"1"} max={"9999"} min={"-9999"} onIonInput={this.handleChange(value, 'fromLow')}></IonInput>
            </IonItem>
            <IonItem>
                <IonInput label="Lectura Máximo" labelPlacement="stacked" value={value.fromHigh} step={"1"} max={"9999"} min={"-9999"} onIonInput={this.handleChange(value, 'fromHigh')}></IonInput>
            </IonItem>
            <IonItem>
                <IonInput label="Valor Bajo" labelPlacement="stacked" value={value.toLow} step={"1"} max={"9999"} min={"-9999"} onIonInput={this.handleChange(value, 'toLow')}></IonInput>
            </IonItem>
            <IonItem>
                <IonInput label="Valor Alto" labelPlacement="stacked" value={value.toHigh} step={"1"} max={"9999"} min={"-9999"} onIonInput={this.handleChange(value, 'toHigh')}></IonInput>
            </IonItem>
            <IonItem>
                <IonLabel position="stacked">Modo</IonLabel>
                <IonSelect value={value.mode} onIonChange={this.handleChange(value, 'mode')}>
                    <IonSelectOption value={0}>(Desactivar reporte)</IonSelectOption>
                    <IonSelectOption value={1}>Analogica rango</IonSelectOption>
                    <IonSelectOption value={5}>Analogica con cambio</IonSelectOption>
                    <IonSelectOption value={3}>Digital</IonSelectOption>
                </IonSelect>
            </IonItem>
            <IonItem>
                <IonInput label="Tiempo confirmacion (s)" labelPlacement="stacked" value={value.confirm} step={"1"} max={"9999"} min={"0"} onIonInput={this.handleChange(value, 'confirm')}></IonInput>
            </IonItem>
            <IonItem>
                <IonInput label="Límite alto" labelPlacement="stacked" value={value.max} step={"0.01"} max={"9999.99"} min={"-9999.99"} onIonInput={this.handleChange(value, 'max')}></IonInput>
            </IonItem>
            <IonItem>
                <IonInput label="Límite bajo" labelPlacement="stacked" value={value.min} step={"0.01"} max={"9999.99"} min={"-9999.99"} onIonInput={this.handleChange(value, 'min')}></IonInput>
            </IonItem>
        </>
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let id = this.props.match?.params.id!;

        if(!this.state) return "";
        return <IonPage>

            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={"/device/" + id + "/settings"}/>
                    </IonButtons>
                    <IonTitle>Entradas analógicas</IonTitle>

                    <IonButtons slot="primary">
                        <IonButton onClick={this.handleSave}>Guardar</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    {this.state.error && <IonItem color={"danger"}>
                        <IonLabel>{this.state.error}</IonLabel>
                    </IonItem>}
                    {this.state.settings && this.state.settings.inputs.map((value:any, index:number) => this.renderItem(value, index))}

                </IonList>
                <IonLoading
                    isOpen={this.state.loading!}
                    message={'Cargando...'}
                    duration={5000}
                />
            </IonContent>


            <IonModal isOpen={this.state.edit! ? true: false} onDidDismiss={() => this.setState({edit: false})}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Editar Entrada</IonTitle>
                        <IonButtons slot={"primary"}>
                            <IonButton onClick={() => this.setState({edit: false})}>OK</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    {this.renderEditor()}
                </IonContent>

            </IonModal>
        </IonPage>
    }

}
