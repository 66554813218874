import React, {Component} from 'react';

import PropertyGroup from './PropertyGroup';
import Button from './Button';
import Columns from './Columns';
import Column from './Column';
import {IonIcon} from "@ionic/react";
import {returnDownBack, returnUpBack} from "ionicons/icons";

export default class ArrangePanel extends Component<any, any> {
  render() {
    // let {object} = this.props;
    return (
      <PropertyGroup>
          <Columns label="Arrange">
            <Column>
              <Button onClick={this.props.onArrange.bind(this, 'back')}>
                <IonIcon icon={returnDownBack} />
                <span>send to back</span>
              </Button>
              <Button onClick={this.props.onArrange.bind(this, 'front')}>
                <IonIcon icon={returnUpBack} />
                <span>bring to front</span>
              </Button>
            </Column>
          </Columns>
        </PropertyGroup>
    );
  }
}
