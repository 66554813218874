import React from "react";
import { inject } from "mobx-react";
import { IonDatetime } from "@ionic/react";
import moment from "moment-timezone";

export type DatetimeSelectProps = {
    visible: boolean,
    onSelectDatetime?: (date: number) => void,
    onCancel?: () => void,
    date?: number,
    max?: Date,
    min?: Date,

}
export type DatetimeSelectState = {
    value: string,

}

@inject("store", "lang")
export default class DatetimeSelect extends React.Component<DatetimeSelectProps, DatetimeSelectState> {
    state: DatetimeSelectState = {
        value: "",

    }
    trigger: boolean = true;

    handleChange = (ev: any) => {
        if (ev.detail.value !== undefined && this.trigger) {
            this.trigger = false;
            let value = ev.detail.value;
            if (this.props.onSelectDatetime) {
                this.props.onSelectDatetime(moment(value).tz('Europe/Madrid').unix() * 1000);
            }
        }
    }
    handleCancel = (ev: any) => {
        
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    componentDidUpdate(prevProps: Readonly<DatetimeSelectProps>, prevState: Readonly<DatetimeSelectState>, snapshot?: any) {
        if (this.props.date !== prevProps.date) {
            this.setState({ value: moment(this.props.date).format('YYYY-MM-DDTHH:mm:ssZ') });
        }
        if (this.props.visible) {
            this.trigger = true;
        }
    }
    render() {
        return <IonDatetime hidden={!this.props.visible}
            value={this.state.value}
            firstDayOfWeek={1}
            locale="es-ES"
            onIonChange={this.handleChange}
            onIonCancel={this.handleCancel}

            showDefaultButtons={true}
            doneText="Seleccionar fecha"
            cancelText="Cancelar"


        >
            <span slot="time-label">Hora</span>
        </IonDatetime>
    }
}
