import React, { RefObject } from "react";
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCheckbox,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonModal,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTextarea,
    IonTitle,
    IonToggle,
    IonToolbar
} from "@ionic/react";
import { withRouter } from "react-router";
import { inject } from "mobx-react";
import { Store } from "../../service/Store";
import { Device, DeviceInputGroup, DeviceInputs, InputItemSettings, ServiceSettings } from "../../service/API";
import Utils from "../../service/Utils";
import { RouteComponentProps } from "react-router-dom";
import ModbusInputEditor from "../../components/ModbusInputEditor";

import { FeatureGroup, LayersControl, MapContainer, Marker, TileLayer, Tooltip } from "react-leaflet";
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';

const { BaseLayer } = LayersControl
export interface DeviceSettingsInputProps extends RouteComponentProps<{ id: string, inputGroup: string, input: string }> {
    store?: Store,
    routerOutlet: HTMLIonRouterOutletElement,
}

export type DeviceSettingsInputState = {
    loading?: boolean,
    // device?: Device,
    inputGroups?: DeviceInputGroup[],
    device_id?: number,
    edit?: boolean,
    inputGroup?: DeviceInputGroup,
    input?: DeviceInputs,
    loadingEdit: boolean,

    editGraph?: boolean,
    mapModal?: boolean,
    device?: Device,
}

@inject("store", "lang")
class DeviceSettingsInput extends React.Component<DeviceSettingsInputProps, DeviceSettingsInputState> {

    state: DeviceSettingsInputState = {
        inputGroup: {} as any,
        input: {} as any,
        edit: false,
        loadingEdit: false,
        editGraph: false,
        mapModal: false,
    }

    inputGroupModal: RefObject<HTMLIonModalElement> = React.createRef();
    inputModal: RefObject<HTMLIonModalElement> = React.createRef();

    componentDidMount(): void {
        // const map = useMap();
        // if(map) {
            
        //     map.addEventListener('click', (ev: any) => {
        //         let id = this.state.input?.distance_location ? this.state.input?.distance_location!.id! : null;
        //         let input = this.state.input!
        //         if (input) {
        //             input.distance_location = { id: id, lat: Number(ev.latlng.lat), lng: Number(ev.latlng.lng) };
        //         }
        //         this.setState({});

        //     });
        // }

        this.loadDevice();
    }

    loadDevice() {
        this.updateData()!.then(() => {

            if (this.props.match.params.inputGroup !== undefined) {
                let inputGroupId = parseInt(this.props.match.params.inputGroup);
                let inputGroup = this.state.inputGroups?.find(a => a.id === inputGroupId);
                if (inputGroup) {
                    this.setState({ edit: true, inputGroup: inputGroup });

                    if (this.props.match.params.input) {
                        let inputId = parseInt(this.props.match.params.input);
                        let input = inputGroup!.inputs.find(a => a.id === inputId);
                        if (input) {
                            this.setState({ input: input });
                        }
                    }
                }
                if (inputGroup) {
                    this.setState({ edit: true, inputGroup: inputGroup });
                }
            }
        });
    }

    componentDidUpdate(prevProps: Readonly<DeviceSettingsInputProps>, prevState: Readonly<DeviceSettingsInputState>, snapshot?: any): void {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.loadDevice();
        }
        if (this.props.match.params.input && this.props.match.params.input !== prevProps.match.params.input) {
            this.loadDevice();
        }
    }

    async updateData() {
        let id = this.props.match?.params.id!;
        //DeviceSettingsInputGet
        this.setState({ loading: true, device_id: parseInt(id) });
        let value = await this.props.store?.api.deviceSettingsInputGroupGet(Number(id));
        if (value && value.success) {
            this.setState({ inputGroups: value.data });
            let device = await this.props.store?.api.deviceGet(Number(id), 'all');
            if (device) {
                this.setState({ device: device.data });
            }
            if (this.state.inputGroup) {
                let item = value.data.find(value1 => value1.id === this.state.inputGroup!.id);
                if (item) {
                    this.setState({ inputGroup: item });
                }
            }
        }
        this.setState({ loading: false });
    }

    editInput = (input: DeviceInputGroup) => (ev: any) => {
        // if (!input.settings) {
        //     input.settings = Utils.createDefaultSettings(input, JSON.parse(input.data!));
        // }
        this.setState({ edit: true, inputGroup: input });

    };
    handleInputChange = (input: DeviceInputs, name: keyof DeviceInputs) => (ev: CustomEvent<any>) => {
        // let input = this.state.inputGroup.inputs[index];
        if (typeof ev.detail.checked !== 'undefined') {
            (input as any)[name] = ev.detail.checked;
        } else {
            (input as any)[name] = ev.detail.value;
        }
        // eslint-disable-next-line react/no-direct-mutation-state
        // this.state.inputGroup.inputs[index] = input;
        this.setState({})
    }

    handleInputNotificationChange = (input: DeviceInputs, flag: number) => (ev: CustomEvent<any>) => {
        if (ev.detail.checked) {
            input.notification |= (flag)
        } else {
            input.notification &= ~(flag)
        }

    }

    handleShowPosition = (ev: any) => {

        this.setState({ mapModal: true });
        setTimeout(() => {

            window.dispatchEvent(new Event('resize'));
        }, 1000);
    };

    // handleSettingChange = (index: number, name: string) => (ev: any) => {
    //     if (ev.currentTarget.checked !== undefined) {
    //         this.setSetting(index, name, ev.currentTarget.checked);
    //     } else if (ev.detail.value !== undefined) {
    //         this.setSetting(index, name, ev.detail.value);
    //     }
    // }

    handleSettingsServiceChange = (name: string, value: any) => {
        let settings = (this.state.inputGroup!.serviceSettings! || {}) as ServiceSettings;

        if (settings.error) {
            settings.error = undefined;
        }
        settings = { ...settings, [name]: value };
        this.setState({ inputGroup: { ...this.state.inputGroup!, serviceSettings: settings } });
    }

    handleGraphChange = (index: number, name: string) => (ev: CustomEvent) => {
        let settings = Utils.fromJSON(this.state.inputGroup!.graphProps!) as InputItemSettings[];
        settings = settings || [];
        settings[index] = { ...settings[index], [name]: ev.detail.value }
        this.setState({ inputGroup: { ...this.state.inputGroup!, graphProps: JSON.stringify(settings) } });
    }

    handleReset = (ev: any) => {
        this.setState({ inputGroup: { ...this.state.inputGroup!, serviceSettings: null } });
    }

    handleRemove = (ev: any) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("¿Realmente deseas eliminar esta entrada? Es posible que las automatizaciones asociadas también se eliminen.")) {

            this.setState({ loading: true, edit: false });

            this.props.store?.api.deviceInputGroupDelete(this.state.device_id!, this.state.inputGroup!.id).then(value => {
                this.setState({ loading: false });

                if (value.success) {
                    this.updateData();
                }
            });
        }
    }
    handleRemoveInputLog = (ev: any) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("¿Realmente deseas reiniciar los datos? La entrada se reiniciará.")) {

            this.setState({ loading: true });

            this.props.store?.api.deviceInputDeleteLog(this.state.device_id!, this.state.input!.id).then(value => {
                this.setState({ loading: false });

                if (value.success) {
                    this.handleGoBack();

                }
            });
        }
    }

    handleRemoveInput = (ev: any) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("¿Realmente deseas eliminar parte de esta entrada? Es posible que las automatizaciones asociadas también se eliminen.")) {

            this.setState({ loading: true });

            this.props.store?.api.deviceInputDelete(this.state.device_id!, this.state.input!.id).then(value => {
                this.setState({ loading: false });

                if (value.success) {
                    this.handleGoBack();

                }
            });
        }
    }

    validInput(errors: string[] = []) {
        let tag = this.state.input?.tag;
        if(tag !== undefined && tag !== "" && !tag.match(/^[a-zA-Z0-9._-]+$/)) {
            document.getElementById("input-tag")?.focus();
            errors.push("Valor de la etiqueta no válido.");
            return false;

        }
        return true;
    }

    validateInputs() {
        let errors : string[] = [];
        let result = this.validInput(errors);
        if(!result) {
            alert(errors.join("\n"));
            return false;
        }
        return true;
    }

    handleSaveInput = (ev: any) => {
        if(this.validInput()) {
            
            this.setState({ loading: true });
            this.props.store?.api.deviceInputSave(this.state.device_id!, this.state.input!).then(value => {
                this.setState({ loading: false });
                this.handleGoBack();
            });
        }
    }

    handleGoBack() {
        let id = this.props.match?.params.id!;
        let inputGroupId = this.props.match?.params.inputGroup!;
        this.props.history.replace("/device/" + id + "/settings/inputGroup/" + inputGroupId);

    }
    // setSetting(index: number, name: string, value: string) {
    //     let settings = Utils.getInputSettings(this.state.input);
    //     if (settings instanceof Array) {
    //         settings = settings as InputItemSettings[];
    //         settings[index] = {...settings[index], [name]: value};
    //         this.setState({input: {...this.state.input, settings: settings}});
    //     }
    //     else if ((settings as ServiceSettings).name !== undefined) {
    //         settings = settings as ServiceSettings;
    //         settings.inputs[index] = {...settings.inputs[index], [name]: value};
    //         this.setState({input: {...this.state.input, settings: settings}});
    //     }
    // }

    renderInputs() {
        return <>
            {this.state.device!.inputGroups.map(inputGroup => {
                return inputGroup.inputs.map(input => {
                    return <IonSelectOption value={input.id}>{inputGroup.name} - {input.name}</IonSelectOption>
                })
            })}
        </>
    }

    /* eslint-disable @typescript-eslint/no-unused-vars */
    renderPropsAnalog(input: DeviceInputs, index: number) {
        return <>

            <IonItem id="input-tag">
                <IonInput label="Etiqueta (Formato xxx.yyy...)" labelPlacement="floating" pattern="[0-9A-Za-z\-_.]+" onIonInput={this.handleInputChange(input, 'tag')}
                    value={input.tag} />
            </IonItem>

            <IonItem>
                <IonSelect label="Widget" labelPlacement="floating" value={input.widget} placeholder="Tipo de display"
                    onIonChange={this.handleInputChange(input, 'widget')}>
                    <IonSelectOption value="hide">(Oculto)</IonSelectOption>
                    <IonSelectOption value="text">Texto</IonSelectOption>
                    <IonSelectOption value="perc">Porcentaje</IonSelectOption>
                    <IonSelectOption value="gaug">Manómetro</IonSelectOption>
                    <IonSelectOption value="onoff">ON/OFF</IonSelectOption>
                    <IonSelectOption value="pulses">Contador Pulsos</IonSelectOption>
                    <IonSelectOption value="latlng">Ubicación</IonSelectOption>
                    <IonSelectOption value="distance">Distancia</IonSelectOption>
                    <IonSelectOption value="flow">Flujo</IonSelectOption>
                    <IonSelectOption value="cierre">Cierre</IonSelectOption>
                </IonSelect>
            </IonItem>
            {(input.widget === 'flow') && <>

                <IonItem>
                    <IonSelect label="Generar Flujo a partir de entrada" labelPlacement="floating" value={input.flow_input} placeholder="Seleccionar entrada" interface="action-sheet"
                        onIonChange={this.handleInputChange(input, 'flow_input')}>
                        <IonSelectOption value={0}>(Seleccionar entrada)</IonSelectOption>
                        {this.renderInputs()}
                    </IonSelect>
                </IonItem>

                <IonItem>
                    <IonSelect labelPlacement="floating" label="Intervalo de calculo" value={input.cierre_interval} interface="action-sheet"
                        onIonChange={this.handleInputChange(input, 'cierre_interval')}>
                        <IonSelectOption value={0}>(Desactivado)</IonSelectOption>
                        <IonSelectOption value={5}>1 minuto</IonSelectOption>
                        <IonSelectOption value={10}>15 minutos</IonSelectOption>
                        <IonSelectOption value={20}>1 hora</IonSelectOption>
                        <IonSelectOption value={30}>2 hora</IonSelectOption>
                        <IonSelectOption value={40}>6 horas</IonSelectOption>
                        <IonSelectOption value={50}>12 horas</IonSelectOption>
                        <IonSelectOption value={60}>24 horas</IonSelectOption>
                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonText>
                        El flujo se genera en la unidad de la entrada asociada entre 1s<br />
                        Tabla de equivalencia
                        <table className="custom-table">
                            <tr >
                                <th>Unidad Entrada</th>
                                <th>Unidad Esperada</th>
                                <th>Valor Escala</th>
                            </tr>
                            <tr>
                                <td>1m3 / s</td>
                                <td>L / h</td>
                                <td>3600000</td>
                            </tr>
                            <tr>
                                <td>1m3 / s</td>
                                <td>L / s</td>
                                <td>1000</td>
                            </tr>
                            <tr>
                                <td>100L / s</td>
                                <td>L / h</td>
                                <td>360000</td>
                            </tr>
                            <tr>
                                <td>100L / s</td>
                                <td>L / s</td>
                                <td>100</td>
                            </tr>
                            <tr>
                                <td>1L / s</td>
                                <td>L / h</td>
                                <td>3600</td>
                            </tr>
                        </table>
                        Ejemplo: 1m / s 
                        
                        
                    </IonText>

                </IonItem>
            </>}
            {(input.widget === 'cierre') && <>

                <IonItem>
                    <IonSelect labelPlacement="floating" label="Generar Cierre a partir de entrada" value={input.cierre_input} placeholder="Seleccionar entrada" interface="action-sheet"
                        onIonChange={this.handleInputChange(input, 'cierre_input')}>
                        <IonSelectOption value={0}>(Seleccionar entrada)</IonSelectOption>
                        {this.renderInputs()}
                    </IonSelect>
                </IonItem>

                <IonItem>
                    <IonSelect label="Intervalo del Cierre" labelPlacement="floating" value={input.cierre_interval} interface="action-sheet"
                        onIonChange={this.handleInputChange(input, 'cierre_interval')}>
                        <IonSelectOption value={0}>(Desactivado)</IonSelectOption>
                        <IonSelectOption value={10}>15 minutos</IonSelectOption>
                        <IonSelectOption value={20}>1 hora</IonSelectOption>
                        <IonSelectOption value={30}>2 hora</IonSelectOption>
                        <IonSelectOption value={40}>6 horas</IonSelectOption>
                        <IonSelectOption value={50}>12 horas</IonSelectOption>
                        <IonSelectOption value={60}>24 horas</IonSelectOption>
                    </IonSelect>
                </IonItem>
            </>}
            <IonItem>
                <IonInput labelPlacement="floating" label={input.widget === "pulses" ? "Litros / Pulso" : "Escala"} type={"number"} step={"1"}
                    onIonInput={this.handleInputChange(input, 'scale')}
                    value={input.scale} />
            </IonItem>
            <IonItem>
                <IonInput label="Valor Base" labelPlacement="floating" type={"number"} step={"1"}
                    onIonInput={this.handleInputChange(input, 'base_value')}
                    value={input.base_value} />
            </IonItem>
            <IonItem>
                <IonInput label="Precisión Decimales" labelPlacement="floating" type={"number"} step={"1"}
                    onIonInput={this.handleInputChange(input, 'precission')}
                    value={input.precission} />
            </IonItem>
            <IonItem>
                <IonText>
                    <b>Cálculo del valor de entrada: </b><br />
                    <code>
                        V(valor) = R((valor * escala) + base);<br />
                        R(v) = redondeo(v, num. decimales);
                    </code>
                </IonText>
            </IonItem>
            {(input.widget === 'prec' || input.widget === 'gaug') && <>

                <IonItem>
                    <IonInput label="Máximo" labelPlacement="floating" type={"number"} step={"0.001"}
                        onIonInput={this.handleInputChange(input, 'max')}
                        value={input.max} />
                </IonItem>
                <IonItem>
                    <IonInput label="Mínimo" labelPlacement="floating" type={"number"} step={"0.001"}
                        onIonInput={this.handleInputChange(input, 'min')}
                        value={input.min} />
                </IonItem>
            </>}
            <IonItem>
                <IonInput
                    label="Unidades"
                    labelPlacement="floating"
                    onIonInput={this.handleInputChange(input, 'unit')}
                    value={input.unit} />
            </IonItem>
            {input.widget === 'onoff' && <>

                <IonItem>
                    <IonInput
                        label="Texto ON"
                        labelPlacement="floating"
                        onIonInput={this.handleInputChange(input, 'on_value')}
                        value={input.on_value} />
                </IonItem>

                <IonItem>
                    <IonInput
                        label="Texto OFF"
                        labelPlacement="floating"
                        onIonInput={this.handleInputChange(input, 'off_value')}
                        value={input.off_value} />
                </IonItem>
                <IonItem>
                    <IonToggle labelPlacement="start" onIonChange={this.handleInputChange(input, 'invert')}
                        checked={input.invert!}>
                            Invertir Señal
                    </IonToggle>
                </IonItem>
            </>}
            {input.widget === 'distance' && <>

                <IonItem onClick={this.handleShowPosition} >
                    <IonLabel>Ubicación Lat, Lng</IonLabel>
                    <IonText>{this.state.input?.distance_location?.lat}, {this.state.input?.distance_location?.lng}</IonText>

                </IonItem>
            </>}

            <IonItem>
                <IonCheckbox
                    onIonChange={this.handleInputNotificationChange(input, 1)}
                    checked={(this.state.input!.notification & 1) === 1}>Notificar cambio</IonCheckbox>
            </IonItem>
            <IonItem>
                <IonCheckbox
                    onIonChange={this.handleInputNotificationChange(input, 2)}
                    checked={(this.state.input!.notification & 2) === 2}>Sonido en la notificación</IonCheckbox>
            </IonItem>
            <IonItem lines="none">
                <IonInput
                    label="Vincular entrada (ID)"
                    labelPlacement="floating"
                    type={"number"}
                    onIonInput={this.handleInputChange(input, 'linkToInput')}
                    value={input.linkToInput} />
            </IonItem>

            <IonItem>
                <IonText>Cuando esta entrada se actualice tambien actualizar la entrada vinculada</IonText>
            </IonItem>
            <IonItem>
                <IonTextarea label="Descripción (Aparece en estado)" labelPlacement="floating" value={input.description} onIonChange={this.handleInputChange(input, 'description')}></IonTextarea>
            </IonItem>

        </>
    }

    getCenter() {
        const position = {
            lat: 37.78517179004318,
            lng: -3.604101093980808
        };
        return this.state.input?.distance_location || position;
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let id = this.props.match?.params.id!;
        let inputGroupId = this.props.match?.params.inputGroup!;

        if (!this.state) return "";
        return <IonPage>

            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={"/device/" + id + "/settings/inputGroup/" + inputGroupId} />
                    </IonButtons>
                    <IonTitle>Entrada {this.state.input!.name}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={this.handleSaveInput} >Guardar</IonButton>

                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {this.state && this.state.input && <IonItem>
                    <IonInput label="Nombre" labelPlacement="floating" onIonInput={this.handleInputChange(this.state.input!, 'name')}
                        value={this.state.input?.name} />
                </IonItem>}

                {this.state && !!this.state.input && this.state.inputGroup!.type === 'modbus' &&
                    <ModbusInputEditor routerOutlet={this.inputModal.current!} input={this.state.input} />}

                {this.state && !!this.state.input &&
                    this.renderPropsAnalog(this.state.input!, this.state.input.index)}

                {this.state && !!this.state.input &&
                    <IonItem>

                        <IonLabel>Referencia de entrada en SCADA / id</IonLabel>
                        <IonText>input-{this.state.device?.id}-{this.state.inputGroup?.iindex}-{this.state.input?.index} / {this.state.input?.id}</IonText>

                    </IonItem>}

                {this.state.input && this.state.input.id > 0 &&
                    <IonItem detail={false} button color={"danger"} onClick={this.handleRemoveInputLog}>
                        <IonLabel>Reiniciar datos de log</IonLabel>
                    </IonItem>}
                {this.state.input && this.state.input.id > 0 &&
                    <IonItem detail={false} button color={"danger"} onClick={this.handleRemoveInput}>
                        <IonLabel>Eliminar</IonLabel>
                    </IonItem>}
                <IonLoading
                    isOpen={this.state.loading!}
                    message={'Cargando...'}
                    duration={5000}
                />
            </IonContent>

            <IonModal presentingElement={this.props.routerOutlet} isOpen={this.state.mapModal! ? true : false} onDidDismiss={() => this.setState({ mapModal: false })}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Establecer ubicación</IonTitle>
                        <IonButtons slot={"primary"}>
                            <IonButton onClick={() => this.setState({ mapModal: false })}>OK</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen>
                    <div style={{ width: '100%', height: '100%' }}>
                        <MapContainer
                            center={this.getCenter()} zoom={9}
                            style={{ width: '100%', height: '100%' }}>
                            
                            <LayersControl position="topright">

                                <BaseLayer checked name="Hibrido">
                                    <ReactLeafletGoogleLayer useGoogMapsLoader={false} type={'hybrid'} />
                                </BaseLayer>
                                <BaseLayer name="Terreno">
                                    <ReactLeafletGoogleLayer useGoogMapsLoader={false} type={'terrain'} />
                                </BaseLayer>
                                <BaseLayer name="Satélite">
                                    <ReactLeafletGoogleLayer useGoogMapsLoader={false} type={'satellite'} />
                                </BaseLayer>
                                <BaseLayer name="Carretera">
                                    <ReactLeafletGoogleLayer useGoogMapsLoader={false} type={'roadmap'} />
                                </BaseLayer>
                                <BaseLayer name="OpenStreetMap.Mapnik">
                                    <TileLayer
                                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                </BaseLayer>
                                <BaseLayer name="OpenStreetMap.BlackAndWhite">
                                    <TileLayer
                                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
                                    />
                                </BaseLayer>

                                <FeatureGroup>
                                    {this.state.input && this.state.input.distance_location && <Marker position={{ lat: this.state.input.distance_location.lat, lng: this.state.input.distance_location.lng }}

                                    >
                                        <Tooltip permanent={true} direction={"top"}>{this.state.input.name}</Tooltip>
                                    </Marker>}
                                </FeatureGroup>

                            </LayersControl>
                        </MapContainer>
                    </div>
                </IonContent>

            </IonModal>
        </IonPage>
    }

}

export default withRouter(DeviceSettingsInput);
