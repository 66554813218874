import Packet from "./Packet";

export type Builder<T = any> = (model:ModelData<T>, packet:Packet, name?:string) => any;
export type Parser<T = any> = (object:ModelData<T>, packet:Packet, name?:string) => any;
export type ModelData<T = any> = T;

export type Definition<T = any> = {

    [key:string]:string | number | Definition<T> | Builder<T> | Parser<T> | undefined,
    build?: Builder<T>, parse?: Parser<T>
}
export default class Model<T = any> {
    private definition: Definition<T>;
    private _size: number;


    private data?:T;
    /**
     * Define new model type.
     *
     * Example of register:
     * this.define({
     *     MainConfig: Model.value(),
     *     Name: Model.string(16),
     *     Alerts: Model.list(4, {
     *         Enable: Model.value(),
     *         Phones: Model.list(2, Model.phone)
     *     })
     * })
     *
     * @param builder
     * @param parser
     * @param size
     */
    static define(builder : Builder, parser: Parser, size?:number) : Definition {
        return {
            build: builder,
            parse: parser,
            size
        };
    }

    _builder(builder:Builder) {
        return builder;
    }
    _parser(parser:Parser) {
        return parser;
    }

    constructor(definition:Definition, size?:number) {
        this.definition = Model.main(definition);
        this._size = size?size:this.calculateSize(this.definition);
    }

    calculateSize(model:Definition) {
        let packet = new Packet();
        this.definition.parse!({} as any, packet);
        return packet.registers();

    }

    build(object:any) {
        let packet = new Packet();
        this.definition.build!(object, packet);
        return packet;
    }
    parse(packet:Packet) {
        let object = {} as any;
        packet.reset();
        return this.definition.parse!(object, packet);
    }

    static main(model1:Definition) {
        return Model.define((object, packet, name) => {
            if(typeof model1.build === "function") {
                model1.build(object, packet, name);
            } else {
                for(let name1 in model1) {
                    (model1[name1] as Definition).build!(object, packet, name1);
                }
            }
        }, (object, packet) => {
            if(typeof model1.parse === "function") {
                var tmp = {list: object};
                model1.parse(tmp, packet, 'list')
                return tmp.list;
            } else {
                for(let name in model1) {
                    (model1[name] as Definition).parse!(object, packet, name);
                }

                return object;
            }
        });
    };

    static value() {
        return Model.define((model, packet, name) => {
            packet.write(Model._read(model, name!, 0));
        }, (model, packet, name) => {
            Model._write(model, name!, packet.read());
        });
    }


    static valueBE() {
        return Model.define((model, packet, name) => {
            packet.write(Model._read(model, name!, 0), true);
        }, (model, packet, name) => {
            Model._write(model, name!, packet.read());
        });
    };

    static int32() {
        return Model.define((model, packet, name) => {
            let val = Model._read(model, name!, 0)
            packet.write((val >> 16) & 0xFFFF, true);
            packet.write((val) & 0xFFFF, true);
        }, (model, packet, name) => {
            let val = 0;
            val += (packet.read(true) << 16);
            val += (packet.read(true));
            Model._write(model, name!, val);
        });
    };

    static byte() {
        return Model.define((model, packet, name) => {
            packet.writeByte(Model._read(model, name!, 0));
        }, (model, packet, name) => {
            Model._write(model, name!, packet.readByte());
        });
    };

    static list(size:number, modelList:Definition | {[key:string]:Definition}) {
        return Model.define((model,  packet,name) => {
            var array = Model._read(model, name!, []);
            for (let i = 0; i < size; i++) {
                if(typeof (modelList as Definition).build === "function" ) {

                    (modelList as Definition).build!(array, packet, String(i))
                } else {
                    for(let name2 in modelList) {
                        (modelList[name2] as Definition).build!(array[i], packet, name2);
                    }
                }
            }
        }, (model, packet, name) => {

            var array = [];
            for (let i = 0; i < size; i++) {
                var modelRes = {};
                if(typeof modelList.parse === "function") {
                    modelList.parse(modelRes, packet, String(i));
                } else {
                    for(let name2 in modelList) {
                        (modelList[name2] as Definition).parse!(modelRes, packet, name2);
                    }
                }
                array.push(modelRes);
            }
            if(name) {
                Model._write(model, name!, array);
            } else {
                return array;
            }
        })
    };

    static values(size:number) {
        return Model.list(size, Model.value());
    }

    static string(size:number, bigendian:boolean = false) {
        return Model.define((model, packet, name) => {
            var string = Model._read(model, name!, "");
            for (let i = 0; i < size; i++) {
                if(i < string.length) {
                    packet.write(string.charCodeAt(i), bigendian)
                } else {
                    packet.write(0);
                }
            }
        }, (model, packet, name) => {
            var string = "";
            for (let i = 0; i < size; i++) {
                let val = packet.read(bigendian);
                if(val > 0) {
                    string += String.fromCharCode(val);
                }
            }
            Model._write(model, name!, string);

        })
    };


    static byteString2(size:number) {
        return Model.define((model, packet, name) => {
            var string = Model._read(model, name!, "");
            for (let i = 0; i < size; i++) {
                if(i < string.length) {
                    packet.writeByte(string.charCodeAt(i))
                } else {
                    packet.writeByte(0);
                }
            }
            if(size % 2 === 1) {
                packet.writeByte(0);
            }
        }, (model, packet, name) => {
            var string = "";
            for (let i = 0; i < Math.round(size / 2); i++) {
                let val1 = packet.readByte();
                let val2 = packet.readByte();
                console.log(val1, val2, String.fromCharCode(val1), String.fromCharCode(val2))
                if(val2 > 0) {
                    string += String.fromCharCode(val2);
                }
                if(val1 > 0) {
                    string += String.fromCharCode(val1);
                }
            }
            Model._write(model, name!, string);

        })
    };

    static byteString(size:number) {
        return Model.define((model, packet, name) => {
            var string = Model._read(model, name!, "");
            for (let i = 0; i < size; i++) {
                if(i < string.length) {
                    packet.writeByte(string.charCodeAt(i))
                } else {
                    packet.writeByte(0);
                }
            }
            if(size % 2 === 1) {
                packet.writeByte(0);
            }
        }, (model, packet, name) => {
            var string = "";
            for (let i = 0; i < size; i++) {
                let val = packet.readByte();
                if(val > 0) {
                    string += String.fromCharCode(val);
                }
            }
            Model._write(model, name!, string);

        })
    };
    static packedString(maxSize:number) {
        return Model.define((model, packet, name) => {
            var string = Model._read(model, name!, "");
            let type = string.type;
            let data = string.string;
            let size = string.size;
            packet.writeByte(type);
            packet.writeByte(size);
            for (let i = 0; i < maxSize; i++) {
                if(i < size) {
                    if(type !== 1) {
                        packet.writeByte(data.charCodeAt(i));
                    } else {
                        packet.writeByte(parseInt(data.substring(i * 2, i * 2 + 2), 16));
                    }
                } else {
                    packet.writeByte(0);
                }
            }


        }, (model, packet, name) => {
            var string = "";
            let type = packet.readByte();
            let size = packet.readByte();
            for (let i = 0; i < maxSize; i++) {
                let val = packet.readByte();
                if(i < size) {
                    if(type !== 1) {
                        string += String.fromCharCode(val);
                    } else {
                        string += (val <= 9?"0": "") + val.toString(16)
                    }
                }
            }
            Model._write(model, name!, {type, size, string});

        })
    };

    static PackString(string:string) {
        return {
            type: 0,
            string: string,
            size: string.length,
        }
    }
    static UnpackString(string:any) {
        return string.string;
    }
    static PackStringHex(string:Buffer) {
        return {
            type: 1,
            string: string.toString('hex'),
            size: string.length,
        }
    }


    private static _read(model:ModelData, name:string, defaultValue:any) : any {
        if(!model) {
            throw new Error("Error reading value for: " + name);
        }
        if(!name) {
            return model;
        }
        return (model[name] as any) || defaultValue;
    };
    private static _write(model:ModelData, name:string, value:any) : any {
        if(!model) {
            throw new Error("Error writing value for: " + name);
        }
        if(!name) {
            return model;
        }

        model[name] = value;
    };


    get size(): number {
        return this._size;
    }
}
