import React from "react";
import {withRouter} from "react-router";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonRow,
    IonSpinner
} from "@ionic/react";
import {personOutline} from "ionicons/icons";
import {inject} from "mobx-react";
import {Store} from "../service/Store";


@inject("store", "lang")
class ValidateAccount extends React.Component<any, any> {

    componentDidMount(): void {
        if(this.props.match && this.props.match.params) {
            this.setState({token: this.props.match.params.token});
            let store:Store = this.props.store;
            store.api?.verification(this.props.match.params.token).then((res) => {
                if(res.success) {
                    if(res.data.token) {
                        store.setToken(res.data.token, true);
                    }
                    this.setState({accountVerified: true})
                } else {

                    this.setState({error: true})
                }
            })

        } else {
            this.setState({error: true})
        }
    }

    renderLoading() {
        return <IonItem>
                <IonSpinner />
                <IonLabel>Verificando cuenta...</IonLabel>
            </IonItem>
    }
    renderError() {
        return <IonList>
            <IonItem color={"danger"}>
                <IonLabel>No se ha podido verificar su cuenta</IonLabel>
            </IonItem>
            <IonItem href={"/"}>
                <IonLabel>Volver</IonLabel>

            </IonItem>
        </IonList>
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {

        if(!this.state) return "";
        return <IonPage>
            <IonContent scrollEvents={true}>
                <div className={"register-background"}>

                    <IonGrid style={{maxWidth: '600px'}}>
                        <IonRow>
                            <IonCol size="12">
                                <IonCard style={{padding: 16, background: '#ffffff'}}>
                                    <IonCardHeader>
                                        <IonCardTitle><IonIcon icon={personOutline}/> CloudRTU</IonCardTitle>
                                        <IonCardSubtitle>Verificar cuenta</IonCardSubtitle>
                                    </IonCardHeader>

                                    <IonCardContent>
                                        {!this.state.accountVerified && this.state.error && this.renderError()}
                                        {!this.state.accountVerified && !this.state.error && this.renderLoading()}
                                        {this.state.accountVerified && <>
                                            Su cuenta ha sido verificada correctamente.


                                            <IonButton href={"/dashboard"} expand={"block"}>
                                                Acceder a su cuenta</IonButton>
                                        </>}

                                    </IonCardContent>
                                </IonCard>
                                <div style={{padding: 8, textAlign: "center"}}>

                                    <a href={"login"}>Volver al Inicio</a>
                                </div>
                            </IonCol>
                        </IonRow>
                    </IonGrid>

                </div>
            </IonContent>
        </IonPage>
    }

}

export default withRouter(ValidateAccount);
