import Utils from "./Utils"

export enum Format {
    BOOL = 0,
    UINT16,
    INT16,
    UINT32_AB,
    UINT32_BA,
    INT16_EXP_MINUS_1,      // (+/-) * 10^-1 -> 0.1
    INT16_EXP_MINUS_2,      // (+/-) * 10^-2 -> 0.01
    INT16_EXP_MINUS_3,      // (+/-) * 10^-3 -> 0.001
    UINT16_EXP_MINUS_1,      // (+) * 10^-1
    UINT16_EXP_MINUS_2,      // (+) * 10^-2
    UINT16_EXP_MINUS_3,      // (+) * 10^-3
    UINT16_EXP_PLUS_1,      // (+) * 10^-1
    UINT16_EXP_PLUS_2,      // (+) * 10^-1
    UINT16_EXP_PLUS_3,      // (+) * 10^-1
    FLOAT16,     
    UFLOAT16,



    DATA_FORMAT_29,

}

export default class Formats {
    // static BOOL : any =  []

    private static uint2int(n: number, bits: number) {
        return n - Math.pow(2, bits);
    }

    private static int2uint(n: number, bits: number) {
        return n + Math.pow(2, bits);
    }

    private static mapper : any = {
        [Format.BOOL]: {
            parse(data:number[]) { return Utils.toBoolean(data[0])},
            build(value: boolean, data:number[]) { return data[0] = Number(value) },
            size: 1,
        },
        [Format.UINT16]: {
            parse(data:number[]) { return (data[0])},
            build(value: number, data:number[]) { return data[0] = Number(value) },
            size: 1,
        },
        [Format.INT16]: {
            parse(data:number[]) { return Formats.uint2int(data[0], 16)},
            build(value: number, data:number[]) { data[0] = Formats.int2uint(value, 16) },
            size: 1,
        },
        [Format.UINT32_AB]: {
            parse(data:number[]) { return (data[0] + (data[1] << 16))},
            build(value: number, data:number[]) { data[0] = Number(value & 0xFFFF); data[1] = Number((value >> 16) & 0xFFFF);  },
            size: 2,
        },
        [Format.UINT32_BA]: {
            parse(data:number[]) { return (data[1] + (data[0] << 16))},
            build(value: number, data:number[]) { data[1] = Number(value & 0xFFFF); data[0] = Number((value >> 16) & 0xFFFF);  },
            size: 2,
        },
        [Format.INT16_EXP_MINUS_1]: {
            parse(data:number[]) { return Formats.uint2int(data[0], 16) * 0.1},
            build(value: number, data:number[]) { data[0] = Formats.int2uint(Math.round(value * 10), 16) },
            size: 1,
        },
        [Format.INT16_EXP_MINUS_2]: {
            parse(data:number[]) { return Formats.uint2int(data[0], 16) * 0.01},
            build(value: number, data:number[]) { data[0] = Formats.int2uint(Math.round(value * 100), 16) },
            size: 1,
        },
        [Format.INT16_EXP_MINUS_3]: {
            parse(data:number[]) { return Formats.uint2int(data[0], 16) * 0.001},
            build(value: number, data:number[]) { data[0] = Formats.int2uint(Math.round(value * 1000), 16) },
            size: 1,
        },
        [Format.UINT16_EXP_MINUS_1]: {
            parse(data:number[]) { return (data[0] * 0.1)},
            build(value: number, data:number[]) { return data[0] = Math.round(Number(value) * 10) },
            size: 1,
        },
        [Format.UINT16_EXP_MINUS_2]: {
            parse(data:number[]) { return (data[0] * 0.01)},
            build(value: number, data:number[]) { return data[0] =Math.round(Number(value) * 100) },
            size: 1,
        },
        [Format.UINT16_EXP_MINUS_3]: {
            parse(data:number[]) { return (data[0] * 0.001)},
            build(value: number, data:number[]) { return data[0] = Math.round((value) * 1000) },

            size: 1,
        },
        [Format.UINT16_EXP_PLUS_1]: {
            parse(data:number[]) { return (data[0] * 10)},
            build(value: number, data:number[]) { return data[0] = Math.round((value) * 0.1) },
            size: 1,
        },
        [Format.UINT16_EXP_PLUS_2]: {
            parse(data:number[]) { return (data[0] * 100)},
            build(value: number, data:number[]) { return data[0] = Math.round((value) * 0.01) },
            size: 1,
        },
        [Format.UINT16_EXP_PLUS_3]: {
            parse(data:number[]) { return (data[0] * 1000)},
            build(value: number, data:number[]) { return data[0] = Math.round((value) * 0.001) },
            size: 1,
        },
        [Format.FLOAT16]: {
            parse(data:number[]) {
                let value = data[0];
                let polarity = ((value >> 15) & 0b1); 
                let exp = (value >> 10) & 0b11; 
                let mantisa = (value) & 0b1111111111;
                if (polarity === 0) polarity = 1; else  polarity = -1; 
                return polarity * mantisa * Math.pow(10, exp - 2);
            },
            build(value: number, data:number[]) {
                let polarity = (Math.sign(value) === 1) ? 0 : 1;
                let exp = 0;
                let mantisa = 0;
                value = Math.abs(value);
                if(value >= 0.01 && value <= 9.99) {
                    mantisa = Math.round(value * 100);
                    exp = 0;
                } else if(value >= 10.0 && value <= 99.9) {
                    mantisa = Math.round(value * 10);
                    exp = 1;
                } else if(value >= 100 && value <= 999) {
                    mantisa = Math.round(value);
                    exp = 2;
                } else if(value >= 1000 && value <= 9990) {
                    mantisa = Math.round(value / 10);
                    exp = 3;
                }
                return data[0] = (polarity << 15) + (exp << 10) + (mantisa & 0b1111111111);
            },
            size: 1,
        },
        [Format.UFLOAT16]: {
            parse(data:number[]) {
                let value = data[0];
                let exp = (value >> 14) & 0b11; 
                let mantisa = (value) & 0b11111111111111;
                return mantisa * Math.pow(10, exp - 2);
            },
            build(value: number, data:number[]) {
                let exp = 0;
                let mantisa = 0;
                value = Math.abs(value);
                if(value >= 0.00 && value <= 99.99) {
                    mantisa = Math.round(value * 100);
                    exp = 0;
                } else if(value >= 100.0 && value <= 999.9) {
                    mantisa = Math.round(value * 10);
                    exp = 1;
                } else if(value >= 1000 && value <= 9999) {
                    mantisa = Math.round(value);
                    exp = 2;
                } else if(value >= 10000 && value <= 99990) {
                    mantisa = Math.round(value / 10);
                    exp = 3;
                }
                return data[0] = (exp << 14) + (mantisa & 0b11111111111111);
            },
            size: 1,
        },
        [Format.DATA_FORMAT_29]: {
            parse(data:number[]) { return (data[0] / 20000)},
            build(value: number, data:number[]) { return data[0] = Math.round((value) * 20000) },

            size: 1,
        },
    }

    static toDecimal(format:Format | number, values:number[] | boolean[]) : number {
        
        if(this.mapper[format]) {
            return Number(this.mapper[format].parse(values));
        }
        throw new Error("Invalid format type: " + format);
    }
    static fromDecimal(format:Format | number, value:number) : number[] {
        if(this.mapper[format]) {
            let values : any[] = [];
            this.mapper[format].build(value, values);
            return values;
        }
        throw new Error("Invalid format type: " + format);
    }


    static getMapper() {
        return this.mapper;
    }

}