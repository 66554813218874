import { Position } from "@capacitor/geolocation";
import { API, Route, RoutePOI, RoutePoint } from "./API";
import Geolocation from "./Geolocation";

export type Points = {
    priority: number,
    type: string | 'route' | 'point' | 'poi',

    route?: Route,

    routePoint?: RoutePoint,
    position?: Position,
    poi?: RoutePOI,
    media?: PointsMedia[],
    attachment ? : PointsMedia,
    
}

export type PointsMedia = {
    contentType: string,
    path: Blob
}

export default class LocationRouteTrackingService {
    
    listenId?: string;
    route?: Route;

    points: Points[] = [];
    
    current?: Points;
    lastPosition ? : Position;
    api: API;

    private _locationListener?: ((position: Position) => void) | undefined;

    public get locationListener(): ((position: Position) => void) | undefined {
        return this._locationListener;
    }
    setLocationListener(value: ((position: Position) => void) | undefined) {
        this._locationListener = value;
    }

    constructor(api: API) {
        this.api = api;
    }


    public isEnabled() {
        return !!this.listenId;
    }
    public start(name: string) {
        this.route = undefined;
        this.points = [];
        this.enqueue({ type: 'route', priority: 100, route: { name: name, createdAt: "", id: 0 } });
        Geolocation.watchPosition({ enableHighAccuracy: true }, this.handlePosition).then(id => this.listenId = id);

    }
    public stop() {
        Geolocation.clearWatch({ id: this.listenId! });
        this.listenId = undefined;

    }

    public addPOI(title: string, notes: string, media: PointsMedia[]) {
        if (!this.lastPosition) {
            throw new Error("No known position yet");
        }

        this.enqueue({ type: 'poi', poi: { title, notes, id: 0, id_route_point: 0 }, position: this.lastPosition, media, priority: 50 });
        
    }

    private handlePosition = (position: Position | null, err?: any) => {
        if (position) {
            this.lastPosition = position;
            this.enqueue({ position: position, type: 'point', priority: 0 });
            if (this.locationListener) {
                this.locationListener(position);
            }
        }
    }
    private enqueue(item: Points) {
        this.points.push(item);
        this.points.sort((a, b) => b.priority - a.priority);
        this.queue();

    }

    private queue() {
        if (!this.current && this.points.length > 0) {
            this.current = this.points.pop();
            if (this.current?.type === 'route') {
                this.api.createRoute(this.current.route!.name)
                    .then(res => {
                        if (res.success) {
                            this.route = res.data;
                            this.queueSuccess();
                        } else {
                            this.queueError(res);
                        }
                    });
                
            } else if (this.current?.type === 'point') {
                if (this.route) {
                    let { coords } = this.current?.position!;
                    let timestamp = this.current.position?.timestamp!;
                    this.api.createRoutePoint(this.route!.id, coords.latitude, coords.longitude, coords.altitude, timestamp)
                        .then(res => res.success ? this.queueSuccess() : this.queueError(res));
                } else {
                    this.queueError("No route");
                }
            } else if (this.current?.type === 'poi') {
                if (this.route) {
                    let { coords } = this.current?.position!;
                    let timestamp = this.current.position?.timestamp!;
                    this.api.createRoutePoint(this.route!.id, coords.latitude, coords.longitude, coords.altitude, timestamp)
                        .then(res => {
                            if (res.success) {
                                this.enqueue({ ...this.current!, routePoint: res.data,  type: 'poi_create' });
                                this.queueSuccess();
                            } else {
                                this.queueError(res)
                            }
                        });

                } else {
                    this.queueError("No route");
                }
            } else if (this.current?.type === 'poi_create') {
                if (this.route) {
                    let { routePoint, poi, media } = this.current!;
                    this.api.createRoutePoi(routePoint?.id!, poi?.title!, poi?.notes!)
                        .then(res => {
                            if (res.success) {
                                if (media && media.length > 0) {
                                    for (let index = 0; index < media.length; index++) {
                                        const element = media[index];
                                        this.enqueue({ ...this.current!, poi: res.data, attachment: element, type: 'poi_attachments' });
                                    }
                                }
                                this.queueSuccess();
                            } else {
                                this.queueError(res)
                            }
                        });

                } else {
                    this.queueError("No route");
                }
            } else if (this.current?.type === 'poi_attachments') {
                if (this.route) {
                    let { poi, attachment } = this.current!;
                    this.api.createRoutePoiAttachment(poi?.id!, attachment!.path).then(res => {
                        if (res.success) {
                            this.queueSuccess();
                        } else {
                            this.queueError(res);
                        }
                    }).catch(e => {
                        this.queueError(e);
                    })
                } else {
                    this.queueError("No route");
                }
            }
        }
    }

    private queueSuccess() {
        this.current = undefined;
        this.queue();
    }
    private queueError(err: any) {
        this.enqueue(this.current!);
        this.current = undefined;
        setTimeout(() => {
            this.queue();
        }, 5000);
    }

    public isSync() {
        return this.queue.length > 0;
    }

    public hasPosition() {
        return !!this.lastPosition;
    }
}